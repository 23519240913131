import moment from 'moment';

const getDateToString = (DT) => {
  if (!DT) return null;
  const date = new Date(DT);
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const formatted = date.toLocaleDateString('ru-RU', options);
  return formatted;
};
export const getDateAndTimeToString = (DT) => {
  if (!DT) {
    return {
      date: null,
      time: null,
    };
  }
  const date = new Date(DT);
  const hourse = date.getHours();
  const minutes = date.getMinutes();
  const formattedDT = getDateToString(date);
  return {
    date: formattedDT,
    time: `${hourse < 10 ? `0${hourse}` : hourse}:${minutes < 10 ? `0${minutes}` : minutes}`,
  };
};

export default getDateToString;

/* Метод который принимает дату и возвращает слово "Сегодня" если дата совпадает с сегодняшей
  или слово "Вчера" если дата совпадает со вчерашней,
  в ином случает просто дату */
export const getDateOrYesterdayOrToday = (DT) => {
  const { date, time } = getDateAndTimeToString(DT);
  const today = (new Date()).getUTCDate();
  const dayFromDT = (new Date(DT)).getUTCDate();
  if (today === dayFromDT) {
    return `Сегодня, ${time}`;
  } if (today - dayFromDT === 1) {
    return `Вчера, ${time}`;
  } return `${date}, ${time}`;
};

export const formattingDateToUTC = (DT) => moment(DT).utc().format();
