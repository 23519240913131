import styled from 'styled-components';
import calendarIcon from '../../../images/calendarIcon.svg';

const MAIN_COLOR = '#2EA8E5';

function hexToRgba(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // color: #FFFFFF;
  background-color: transparent;
  box-sizing: border-box;
  border-bottom: 1px solid #CED4DA;
  min-height: 30px;
  width: 168px;
  >span {
    position: 'relative';
    top: '-1px';
    right: '-1px';
    color: '#666666';
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  input {
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    height: 100%;
    width: 80px;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;

    &:focus {
      outline: none !important;
    }

    :hover,
    :focus {
      border: #414351;
    }
  }

  .monthName {
    color: #fff;
  }

  .DayPickerInput-OverlayWrapper {
    position: absolute;
    left: 0;
    top: calc(100% + 4px);
  }

  .DayPickerInput-Overlay {
    z-index: 21;
    background: #48484a;
    border-radius: 5px;
  }

  .DayPickerInput {
    height: 100%;
  }

  .DayPicker-Months {
    width: 620px;
  }

  .DayPicker-Weekdays {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #767676;
  }

  .DayPicker-Caption {
    display: none;
  }

  .DayPicker-Month {
    margin: 0 15px;
  }

  .DayPicker-Day {
    height: 40px;
    width: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    border-radius: 0;
    box-sizing: border-box;
    outline: none !important;

    :hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background: #3C3C3C;
      outline: none !important;
      box-shadow: inset 0 0 0 1px transparent !important;
      border-radius: 3px;
    }
    &.DayPicker-Day--disabled {
      cursor: not-allowed;
      color: #CED4DA;
    }
    &.DayPicker-Day--today:not(.DayPicker-Day--outside) {
      color: #fff !important;
      background-color: #a92c2c !important;
      border-radius: 3px !important;
    }
    &.DayPicker-Day--selected:not(.DayPicker-Day--today):not(.DayPicker-Day--outside) {
      background: rgba(60,60,60,0.8) !important;
      // font-weight: normal;
    }
    &.DayPicker-Day--selected:not(.DayPicker-Day--today):not(.DayPicker-Day--outside).DayPicker-Day--start,
    &.DayPicker-Day--selected:not(.DayPicker-Day--today):not(.DayPicker-Day--outside).DayPicker-Day--end {
      background: #3C3C3C !important;
    }
    &.DayPicker-Day--start:not(.DayPicker-Day--outside),
    &.DayPicker-Day--end:not(.DayPicker-Day--outside) {
      box-shadow: inset 0 0 0 1px transparent !important;
      border-radius: 3px;
    }
  }
  
  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #3C3C3C;
  }


  ${({ view }) => view ? `
    input {
      height: auto;
      // color: ${view === 'mainActivity' ? '#fff' : '#4a4a4a'};
    }
    .monthName {
      color: #4a4a4a;
    }

    .DayPickerInput {
      height: auto;
    }

    .DayPickerInput-OverlayWrapper {
      left: ${view === 'mainActivity' ? '-150px' : '-280px'};
    }
    .DayPickerInput-Overlay {
      background: #fff;
    }
    .DayPicker-Day {
      color: #4A4A4A;

      // color: #9b9b9b;
      border-radius: 0 !important;
      :hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background: ${MAIN_COLOR};
        color: #fff;
        box-shadow: inset 0 0 0 1px transparent !important;
        border-radius: 3px !important;
      }
      &.DayPicker-Day--selected:not(.DayPicker-Day--today):not(.DayPicker-Day--outside):not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
        background: ${hexToRgba(MAIN_COLOR, 0.2)} !important;
        background: ${hexToRgba('#000000', 0.1)} !important;

        color: #4a4a4a;
      }
      &.DayPicker-Day--selected:not(.DayPicker-Day--today):not(.DayPicker-Day--outside).DayPicker-Day--start,
      &.DayPicker-Day--selected:not(.DayPicker-Day--today):not(.DayPicker-Day--outside).DayPicker-Day--end {
        background: ${MAIN_COLOR} !important;
        border-radius: 3px !important;
      }
      &.DayPicker-Day--start:not(.DayPicker-Day--outside),
      &.DayPicker-Day--end:not(.DayPicker-Day--outside) {
        background: ${MAIN_COLOR} !important;
        box-shadow: inset 0 0 0 1px transparent !important;
      }
    }
    .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      background-color: ${MAIN_COLOR};
    }
  ` : ''}

  ${({ isForFilter }) => isForFilter ? `
  background: url(${calendarIcon}) no-repeat 149px center/22px 22px;
  background-color: #FFFFFF;
  border: 1px solid #CED4DA;
  border-radius: 4px;
  height: 32px;
  width: 183px;
  padding: 0 12px;
  >span {
    position: 'relative';
    top: '-1px';
    right: '1px';
    color: '#666666';
    font-size: 11px;
    line-height: 24px;
    font-weight: 500;
  }
  input {
    font-size: 11px;
    line-height: 24px;
    width: 56px;
    ::placeholder {
      color: #585858;   
      font-size: 12px;
      line-height: 14px;
    }
  }


  ` : ''}
`;

export const ClearButton = styled.button`
  position: absolute;
  top: calc(50% - 5px);
  right: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 20px;
  // height: 20px;
  background: transparent;
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  svg {
    width: 10px;
    height: 10px;
    &:hover {
      line {
        stroke: #737373;
    }
    }
  }
`;

export const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px 0;
  svg:nth-of-type(1) {
    transform: rotate(-90deg);
  }
  svg:nth-of-type(2) {
    transform: rotate(90deg);
  }
`;

export const MonthBtn = styled.span`
  cursor: pointer;
  color: #6a6e6e;
  width: 27px;
  height: 27px;
  transform: rotate(${({ rotate }) => rotate}deg);
  display: flex; 
  align-items: center;
  justify-content: center;
  :hover {
    border-radius: 50%;
    background-color: #E7E7E7;
    svg {
      * {
        fill: #4A4A4A;
      }
    }
  }
`;

export const Month = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

export const SelectMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px 0;
  z-index: 10;

  .item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 33px;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 18px;
    color: #404040;
    padding: 0 10px;

    &.dateItem {
      background: url(${calendarIcon}) 10px center no-repeat transparent;
      background-size: 12px auto;
      padding-left: 30px;
    }

    :after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 10px;
      width: calc(100% - 20px);
      height: 1px;
      background-color: #EAEAEA;
      opacity: 0.7;
    }

    :hover {
      background-color: #efefef;
    }

    &:last-child:after {
      display: none;
    }
  }

  :after {
    content: "";
    position: absolute;
    top: -8px;
    left: 10px;
    width: 23px;
    height: 23px;
    background-color: #fff;
    transform: rotate(45deg);
    z-index: -1;
  }
`;

export const SelectedDayInterval = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 20px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  background-color: ${hexToRgba(MAIN_COLOR, 0.7)};
  border-radius: 4px;
  padding: 3px 7px;
  cursor: pointer;
  margin: 10px 5px 0;

  ${ClearButton} {
    display: none;
    background-color: inherit;
  }

  :hover {
    color: rgba(255,255,255,0.7);
    ${ClearButton} {
      display: flex;
      svg * {
        fill: #fff;
      }
    }
  }
`;
