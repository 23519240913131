import React       from 'react';
import moment      from 'moment';
import PropTypes   from 'prop-types';
import travelImage from '../../images/travel_vacation_relax_summer.png';

moment.locale('ru');

class VacationItem extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { props } = this;
    return (
      nextProps.inactive !== props.inactive ||
      nextProps.days !== props.days ||
      nextProps.startDate !== props.startDate ||
      nextProps.endDate !== props.endDate ||
      nextProps.available !== props.available ||
      nextProps.comment !== props.comment
    );
  }

  render() {
    const { props } = this;
    return (
      <div className={`section-content ${props.inactive ? 'section-content__inactive' : ''}`}>
        <div className="d-flex flex-row">
          <div className="content-icon">
            <img src={travelImage} alt="icon" />
          </div>
          <div className="vacation-info">
            <div className="vacation-dates">
              С <b>{moment(props.startDate).format('DD MMMM YYYY, dddd')}</b> на <b>{props.days} дней</b>
              <p className="light">по {moment(props.startDate).add(props.days, 'day').format('DD MMMM YYYY, dddd')}</p>
            </div>
            <div>{`Буду ${props.available === 0 ? 'не' : ''} доступен в это время`}</div>
            <div>Выход на работу: <b>{moment(props.endDate).format('DD MMMM YYYY, dddd')}</b></div>
            <div className="light">{props.comment}</div>
          </div>
        </div>
        {props.children}
      </div>
    );
  }
}

VacationItem.defaultProps = {
  inactive: false,
};

VacationItem.propTypes = {
  inactive: PropTypes.bool,
  days: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  available: PropTypes.number.isRequired,
};

export default VacationItem;
