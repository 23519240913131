import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Datatime from 'react-datetime';
import { getNextWorkDate } from '../../redux/api/iterationApi';
import DropdownMenu from '../DropdownMenu';
import { Loader, Overlay } from '../Loader';

class AddVacation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startD: moment().format('YYYY-MM-DD'),
      endD: moment()
        .add(1, 'day')
        .format('YYYY-MM-DD'),
      exitD: moment()
        .add(1, 'day')
        .format('YYYY-MM-DD'),
      available: 1,
      days: 1,
      comment: '',
    };
    this.changeEndDate(this.state.startD, this.state.days);
    this.onStartDateChange = (ev) => {
      const date = ev.format('YYYY-MM-DD');
      const startDate = date;
      this.setState({ startD: startDate });
      this.changeEndDate(startDate, this.state.days);
    };
    this.onCommentChange = (ev) => {
      this.setState({ comment: ev.target.value });
    };
    this.onDaysChange = (ev) => {
      const quantityOfDays = this.arrayOfSelectOptionsItems[ev.target.value].split(' ')[0];
      if (quantityOfDays > 0) {
        const days = quantityOfDays;
        this.setState({
          days,
        });
        this.changeEndDate(this.state.startD, days);
      }
    };
    this.onAvailableChange = (ev) => {
      this.setState({ available: ev.target.checked ? 0 : 1 });
    };
    this.onSubmit = (ev) => {
      ev.preventDefault();
      const { startD, comment, available, days } = this.state;
      this.props.updateVacation({ startD, days, comment, available }, this.props.userID);
      this.setState({
        startD: moment().format('YYYY-MM-DD'),
        endD: moment()
          .add(1, 'day')
          .format('YYYY-MM-DD'),
        exitD: moment()
          .add(1, 'day')
          .format('YYYY-MM-DD'),
        available: 1,
        days: 1,
        comment: '',
      });
    };
    this.renderDay = (cellProps, currentDate, selectedDate) => {
      if (selectedDate) {
        if (currentDate.diff(selectedDate, 'days') === this.state.days) {
          if (cellProps.className.indexOf('workDay')) {
            cellProps.className = 'rdtDay endDay';
          }
        }
        if (!moment(this.state.exitD).diff(currentDate)) {
          cellProps.className = 'rdtDay workDay';
        }
      }
      return <td {...cellProps}>{currentDate.date()}</td>;
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { state, props } = this;
    return (
      nextProps.vacationDays !== props.vacationDays ||
      nextState.startD !== state.startD ||
      nextState.endD !== state.endD ||
      nextState.exitD !== state.exitD ||
      nextState.available !== state.available ||
      nextState.comment !== state.comment ||
      nextState.days !== state.days ||
      nextProps.isPending !== props.isPending
    );
  }
  changeEndDate(startD, days) {
    const self = this;
    getNextWorkDate(this.props.device.device, startD, '', days, (data) => {
      self.setState({
        exitD: data.exitD,
        endD: data.endD,
      });
    });
  }
  panelForVacationSelection() {
    if (this.props.vacationDays > 0) {
      this.arrayOfSelectOptionsItems = ['1 день', '2 дня', '3 дня', '7 дней', '14 дней', '21 день'];
      this.arrayOfSelectOptionsItems = this.arrayOfSelectOptionsItems.filter((item) => {
        if (item.split(' ')[0] <= this.props.vacationDays) {
          return <option key={`selectOption#${item}`}>{item}</option>;
        }
        return false;
      });
      return (
        <form>
          <div className="form-group row">
            <DropdownMenu values={this.arrayOfSelectOptionsItems} changeElement={this.onDaysChange} />
            <small className="help">из {this.props.vacationDays} доступных</small>
          </div>
          <div className="form-group row">
            <Datatime
              renderDay={this.renderDay}
              onChange={this.onStartDateChange}
              defaultValue={moment(this.state.startD)}
              className="col-lg-12"
              dateFormat
              timeFormat={false}
            />
          </div>

          <div className="form-check">
            <label htmlFor="enable" className="form-check-label col-12">
              <input
                className="form-check-input"
                type="checkbox"
                checked={this.state.available === 0}
                onChange={this.onAvailableChange}
              />
              Буду <b>не доступен</b> в это время
            </label>
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              rows="3"
              placeholder="Комментарий"
              value={this.state.comment}
              onChange={this.onCommentChange}
            />
          </div>
          <div className="d-flex control-dates-container">
            <div className="control-dates">
              <span>
                с {moment(this.state.startD).format('DD MM YYYY')}, на {this.state.days} дней
              </span>
              <span> {moment(this.state.exitD).format('DD MM YYYY')} - выход на работу</span>
            </div>
            <button type="submit" className="btn btn-primary" onClick={this.onSubmit}>
              Отправить
            </button>
          </div>
        </form>
      );
    }
    return <div className="no-vacation-days-message">Извините, у Вас нет отпускных дней</div>;
  }
  render() {
    return (
      <div className="section">
        <div className="section-header">
          <div className="badge badge-success">{this.props.vacationDays}</div>
          Отпускные дни
        </div>
        <div className="section-content vacation-section-content">
          <p>Заявление на предоставление отпускных дней:</p>
          {this.panelForVacationSelection()}
          {this.props.isPending && (
            <Overlay>
              <Loader show />
            </Overlay>
          )}
        </div>
      </div>
    );
  }
}

AddVacation.defaultProps = {
  vacationDays: 0,
};

AddVacation.propTypes = {
  updateVacation: PropTypes.func.isRequired,
  vacationDays: PropTypes.number,
  userID: PropTypes.string.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default AddVacation;
