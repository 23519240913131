/* eslint-disable no-mixed-operators */
import { Field, reduxForm } from 'redux-form';
import React from 'react';

import '../../styles/employee-form.css';
// eslint-disable-next-line
let PersonalInfoForm = props => (
  <div>
    <div className="form-group">
      <label htmlFor="skype">Skype</label>
      <Field name="skype" component="input" type="text" placeholder="Skype" className="form-control" />
    </div>
    <fieldset className="form-group">
      <label>Пол</label>
      <div className="form-check">
        <label className="form-check-label">
          <Field name="sex" component="input" type="radio" value="0" /> Женщина
        </label>
      </div>
      <div className="form-check">
        <label className="form-check-label">
          <Field name="sex" component="input" type="radio" value="1" /> Мужчина
        </label>
      </div>
    </fieldset>
    <fieldset className="form-group">
      <label>Предпочитаемый офис</label>
      <div className="form-check">
        <label className="form-check-label">
          <Field name="preferedOffice" component="input" type="radio" value="0" /> Казань, Россия
        </label>
      </div>
      <div className="form-check">
        <label className="form-check-label">
          <Field name="preferedOffice" component="input" type="radio" value="1" /> Лимассол, Кипр
        </label>
      </div>
    </fieldset>
    <fieldset className="form-group">
      <label>Семейное положение</label>
      <div className="form-check">
        <label className="form-check-label">
          <Field name="maritalStatus" component="input" type="radio" value="0" /> Женат/Замужем
        </label>
      </div>
      <div className="form-check">
        <label className="form-check-label">
          <Field name="maritalStatus" component="input" type="radio" value="1" /> Не женат/Не замужем
        </label>
      </div>
    </fieldset>
    <div className="form-check">
      <label htmlFor="kids" className="form-check-label">
        <Field name="kids" component="input" type="checkbox" className="form-check-input" />
        Есть дети
      </label>
    </div>
    <div className="form-check">
      <label htmlFor="personalCar" className="form-check-label">
        <Field name="personalCar" component="input" type="checkbox" className="form-check-input" />
        Есть личное авто
      </label>
    </div>
    <div className="form-group">
      <label htmlFor="address">Адрес места жительства</label>
      <Field name="address" component="textarea" className="form-control" />
    </div>
    <div className="form-group">
      <label htmlFor="contactPerson">Человек для связи, телефон, фио, кто</label>
      <Field name="contactPerson" component="textarea" className="form-control" />
    </div>
    <div className="form-group">
      <label htmlFor="university">ВУЗ, дата выпуска, специальность</label>
      <Field name="university" component="textarea" className="form-control" />
    </div>
    <div className="form-group">
      <label htmlFor="languages">Языки, уровень владения</label>
      <Field name="languages" component="textarea" className="form-control" />
    </div>
    <div className="form-group">
      <label htmlFor="hobbies">Хобби и интересы</label>
      <Field name="hobbies" component="textarea" className="form-control" />
    </div>
  </div>
);
PersonalInfoForm = reduxForm({
  form: 'personalInfo',
})(PersonalInfoForm);

const userValues = [
  'skype',
  'sex',
  'address',
  'contactPerson',
  'university',
  'languages',
  'hobbies',
  'preferedOffice',
  'maritalStatus',
];

export const getObjectByValues = (personalInfo) => {
  const result = {};
  for (let i = 0; i < userValues.length; i++) {
    if (personalInfo[userValues[i]]) result[userValues[i]] = personalInfo[userValues[i]];
  }

  result.kids = (personalInfo.kids && personalInfo.kids === '1') || false;
  result.personalCar = (personalInfo.personalCar && personalInfo.personalCar === '1') || false;
  return result;
};

export default PersonalInfoForm;
