import React from 'react';
import PropTypes from 'prop-types';

export default class DropdownMenu extends React.Component {
  constructor(param) {
    super(param);
    this.openMenu = this.openMenu.bind(this);
    this.getMenuRef = this.getMenuRef.bind(this);
    this.elementHasSelected = this.elementHasSelected.bind(this);
    this.mouseLeaveFromButton = this.mouseLeaveFromButton.bind(this);
    this.mouseLeaveFromUl = this.mouseLeaveFromUl.bind(this);
    this.state = { buttonValueNumber: 0 };
  }
  getMenuRef(node) {
    this._menu = node;
  }
  getListItems() {
    return this.props.values.map((item, i) => <li key={item} value={i}>{item}</li>);
  }
  openMenu(event) {
    event.preventDefault();
    this._menu.style.display = 'block';
  }
  elementHasSelected(event) {
    if (event.target.tagName !== 'UL') {
      this.props.changeElement(event);
      this._menu.style.display = 'none';
      this.setState({ buttonValueNumber: event.target.value });
    }
  }
  mouseLeaveFromButton(event) {
    const coordinates = event.target.getBoundingClientRect();
    if (coordinates.top + coordinates.height > event.clientY) {
      this._menu.style.display = 'none';
    }
  }
  mouseLeaveFromUl() {
    this._menu.style.display = 'none';
  }
  render() {
    const listItems = this.getListItems();
    return (
      <div className="dropdown-menu-component" >
        <button onMouseLeave={this.mouseLeaveFromButton} onClick={this.openMenu}>
          <span>{this.props.values[this.state.buttonValueNumber]}</span>
          <span>&#9660;</span>
        </button>
        <ul
          onMouseLeave={this.mouseLeaveFromUl}
          onClick={this.elementHasSelected}
          className="dropdown-menu"
          ref={this.getMenuRef}
        >
          {listItems}
        </ul>
      </div>);
  }
}
DropdownMenu.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  changeElement: PropTypes.func.isRequired,
};
