import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import app        from './app';
import user       from './user';
import device     from './device';
import employees  from './employees';
import hospitals  from './hospitals';
import vacations  from './vacations';
import issues     from './issues';
import workLogs   from './workLogs';
import documents  from './documents';
import iterations from './iterations';
import workstates from './workstates';

export default combineReducers({
  app,
  user,
  employees,
  hospitals,
  vacations,
  device,
  issues,
  workLogs,
  documents,
  iterations,
  workstates,
  form: formReducer.plugin({
    user: (state, action) => {
      switch (action.type) {
        case 'LOGOUT':
          return {};
        default:
          return state;
      }
    },
    newUser: (state, action) => {
      switch (action.type) {
        case 'LOGOUT':
          return {};
        default:
          return state;
      }
    },
    personalInfo: (state, action) => {
      switch (action.type) {
        case 'LOGOUT':
          return {};
        default:
          return state;
      }
    },
  }),
});
