import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function NameSurnameField({
  name,
  surname,
  size,
}) {
  const nameField = name || 'Имя';
  const surnameField = surname || 'Фамилия';
  return (
    <Wrapper size={size}>
      <NameSurname isExist={name}>
        {nameField}
        {' '}
      </NameSurname>
      <NameSurname isExist={surname}>{surnameField}</NameSurname>
    </Wrapper>
  );
}

const Wrapper = styled.span`
  font-weight: 500;
    ${({ size }) => {
    switch (size) {
      case 'small': {
        return (
          `font-size: 15px;
           line-height: 20px;
           color: #212223;
           font-weight: 400;
          `
        );
      }
      case 'large': {
        return (
          `font-size: 24px;
           line-height: 35px;
           color: #000000;
           word-break: break-word;
          `
        );
      }
      case 'middle':
      default: {
        return (
          `color: #4A4A4A;
           font-size: 16px;
           line-height: 20px;
          `
        );
      }
    }
  }}
`;

const NameSurname = styled.span`
  ${({ isExist }) => !isExist ? 'color: #DEDEDE' : ''};
`;

NameSurnameField.propTypes = {
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};
