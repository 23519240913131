import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import Spinner from 'react-bootstrap/Spinner';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import NameSurnameField from '../../../ui/Fields/NameSurnameField';
import Avatar from '../../../ui/Avatar';
import PhoneField from '../../../ui/PhoneField';

import russianFlug from '../../../../images/russianFlug.svg';

import Context from '../../../common/Context';

export default function ContactItem({
  contact,
  incomingRequests,
  userWaits,
  userBlocked,
  blockedUser,
}) {
  const {
    ID,
    createDT,
    email,
    modifyDT,
    name,
    phone,
    status,
    surname,
    userID,
  } = contact;

  const { acceptContact, unblockContact } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();

  const onAcceptIncomingContact = () => {
    setIsLoading(true);
    acceptContact(ID, 'incoming')
      .then(() => {
        setIsLoading(false);
        alert.show(<span style={{ textTransform: 'none' }}>Запрос принят</span>, { type: 'success' });
      })
      .catch(() => {
        setIsLoading(false);
        alert.show('Запрос не принят!', { type: 'error' });
      });
  };

  const onAcceptOutgoingContact = () => {
    setIsLoading(true);
    acceptContact(ID, 'outgoing')
      .then(() => {
        setIsLoading(false);
        alert.show(<span style={{ textTransform: 'none' }}>Запрос принят</span>, { type: 'success' });
      })
      .catch(() => {
        setIsLoading(false);
        alert.show('Запрос не принят!', { type: 'error' });
      });
  };

  const onUnblockContact = () => {
    setIsLoading(true);
    unblockContact(ID, 'userBlocked')
      .then(() => {
        setIsLoading(false);
        alert.show(<span style={{ textTransform: 'none' }}>Контакт разблокирован</span>, { type: 'success' });
      })
      .catch(() => {
        setIsLoading(false);
        alert.show('Контакт не разблокирован!', { type: 'error' });
      });
  };

  const onUnblockBlockedContact = () => {
    setIsLoading(true);
    unblockContact(ID, 'blockedUser')
      .then(() => {
        setIsLoading(false);
        alert.show(<span style={{ textTransform: 'none' }}>Контакт разблокирован</span>, { type: 'success' });
      })
      .catch(() => {
        setIsLoading(false);
        alert.show('Контакт не разблокирован!', { type: 'error' });
      });
  };

  return (
    <Wrapper ID={userID} phone={phone}>
      <div className="aboutContacts">
        <Avatar
          ID={userID}
          phone={phone}
          height={47}
          className="aboutContacts__avatar"
        />
        <div className="aboutContacts__info">
          <div className="aboutContacts__info-header">
            <NameSurnameField
              size="small"
              name={name}
              surname={surname}
            />
          </div>
          <div className="aboutContacts__info-footer">
            <PhoneField value={phone} />
            <span>{email}</span>
          </div>
        </div>
        {incomingRequests && (
        <Button
          disabled={isLoading}
          onClick={onAcceptIncomingContact}
          style={{ width: '64px' }}
        >
          {
            isLoading ? (
              <Spinner
                as="span"
                size="sm"
                animation="border"
                variant="light"
              />
            ) : 'Принять'
          }
        </Button>
        )}
        {userWaits && (
        <Button
          disabled={isLoading}
          style={{ width: '64px' }}
          onClick={onAcceptOutgoingContact}
        >
          {
            isLoading ? (
              <Spinner
                as="span"
                size="sm"
                animation="border"
                variant="light"
              />
            ) : 'Принять'
          }
        </Button>
        )}
        {userBlocked && (
        <Button
          disabled={isLoading}
          onClick={onUnblockContact}
          style={{ width: '112px' }}

        >
          {
            isLoading ? (
              <Spinner
                as="span"
                size="sm"
                animation="border"
                variant="light"
              />
            ) : 'Разблокировать'
          }
        </Button>
        )}
        {blockedUser && (
        <Button
          disabled={isLoading}
          onClick={onUnblockBlockedContact}
          style={{ width: '112px' }}
        >
          {
            isLoading ? (
              <Spinner
                as="span"
                size="sm"
                animation="border"
                variant="light"
              />
            ) : 'Разблокировать'
          }
        </Button>
        )}

      </div>
    </Wrapper>
  );
}

const Wrapper = styled.li`
    font-size: 13px;
    line-height: 20px;
    color: #9B9B9B;
    margin: 0 10px;
    .aboutContacts {
        display: flex;
        align-items: center;
        &__avatar {
        }
        &__info {
            margin-left: 16px;
            &-header {
            }
            &-footer {
                display: flex; 
                align-items: center;
                // span:first-of-type {
                //     margin-left: 5px;
                // }
                span:not(:first-of-type) {
                    margin-left: 17px;
                }

            }
        }
    }
`;

const Button = styled.button`
    width: auto;
    height: 29px;
    background: #2EA8E5;
    border-radius: 4px;
    border: none;
    color: #FFFFFF;
    margin-left: auto;
    margin-right: 16px;
`;

ContactItem.propTypes = {
  contact: PropTypes.shape({
    ID: PropTypes.string,
    userID: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    createDT: PropTypes.string,
    modifyDT: PropTypes.string,
    status: PropTypes.number,
  }).isRequired,
  incomingRequests: PropTypes.bool,
  userWaits: PropTypes.bool,
  userBlocked: PropTypes.bool,
  blockedUser: PropTypes.bool,
};

ContactItem.defaultProps = {
  incomingRequests: false,
  userWaits: false,
  userBlocked: false,
  blockedUser: false,
};
