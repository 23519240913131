import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WorkStates from '../components/workState/WorkState';
import { switchWorkstateRequest } from '../redux/actions/workstatesActions';
import { getDepartment } from '../redux/reducers/employees';
import { logout } from '../redux/actions/deviceActions';

function mapStateToProps(state) {
  return {
    userID: state.user.ID,
    developers: getDepartment(state.employees, 'Developers', state.workstates),
    other: getDepartment(state.employees, 'QA, Admins, UIX', state.workstates),
    workstates: state.workstates,
    isPending: state.app.isPending.switchWorkstate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSwitchWorkstate: bindActionCreators(switchWorkstateRequest, dispatch),
    logout: bindActionCreators(logout, dispatch),
  };
}

const WorkStatesContainer = connect(mapStateToProps, mapDispatchToProps)(WorkStates);

export default WorkStatesContainer;
