import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import pure from 'recompose/pure';
import moment from 'moment';
import gravatar from '../../utils/gravatar';
import { Loader, RoundedOverlay } from '../Loader';

const RoundedOverlayExt = RoundedOverlay.extend`
  border-radius: 10px;
  @media only screen and (min-width: 993px) {
    border-radius: 50%;
  }
`;

const noop = () => {};

const UserInfo = props => (
  <div className="user-info-container col-lg-6">
    <div className={`user eq-height d-flex flex-column ${props.active ? 'user-active' : ''}`}>
      <div className="d-flex flex-row about-user">
        <div className="user-avatar">
          <img className="rounded-circle" src={gravatar(props.email, { size: 100 })} alt="avatar" />
        </div>
        <div className="user-info">
          <div className="user-info__name">{props.name}</div>
          <div className="user-info__surname">{props.surname}</div>
          <div className="user-info__position">{props.position}</div>
        </div>
        <div className="sprint-info ml-auto">
          <div className="sprint-info__title">{props.sprintDate}</div>
          <div className="sprint-info__current-time">{props.workedHrs}</div>
          <div className="sprint-info__hours">{props.sprintReqHrs}</div>
        </div>
        <div
          className="d-flex user-work-status rounded-circle flex-column justify-content-center align-items-center"
          data-active={props.active}
          data-id={props.ID}
          onClick={props.isPending ? noop : props.onSwitchWorkstate}
        >
          <div className="current-date">{moment(Date()).format('DD MMMM YYYY')}</div>
          <div className="start-time">{props.startTime}</div>
          <div className="worked-hrs">{`${props.workedHrs} / ${props.sprintReqHrs.split(':')[0]}`}</div>
          <div className="current-time">{props.currentTime}</div>
          <div className="end-time">{props.endTime}</div>
          {props.isPending && (
            <RoundedOverlayExt>
              <Loader show />
            </RoundedOverlayExt>
          )}
        </div>
      </div>

      <div className="d-flex flex-row jira-links">
        <Link to="/">Канбан</Link>
        <Link to="/">Все фичи</Link>
      </div>
    </div>
  </div>
);

UserInfo.defaultProps = {
  email: '',
  ID: '',
  name: '',
  surname: '',
  position: '',
};

UserInfo.propTypes = {
  active: PropTypes.bool.isRequired,
  onSwitchWorkstate: PropTypes.func.isRequired,
  email: PropTypes.string,
  ID: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  position: PropTypes.string,
  sprintDate: PropTypes.string.isRequired,
  workedHrs: PropTypes.string.isRequired,
  sprintReqHrs: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  currentTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default pure(UserInfo);
