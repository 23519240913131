import Validator from '../../utils/validate';

const LVL = {
  NO: 0,
  DATA: 1,
  DBG: 2,
  DBGPRC: 3,
  INFO: 5,
  INFOAPP: 7,
  WARN: 10,
  EXEPT: 50,
  ERROR: 100,
  CRASH: 500,
};

const defaultLogger = {
  dbg: console.log,
  info: console.log,
  error: console.error,
  LVL: {},
  state: function () {},
};

class Entity {

  constructor(resourses = {}, debug = 0) {
    this._debug  = debug || 100;
    this._logger = resourses.logger  || defaultLogger;
    this._cacheTTL   = resourses.cacheTTL || 3600 * 24 * 90;
    this.dbg(LVL.DATA, 'Model', this._model);
    this.dbg(LVL.DBG,  'Constructed');
  }

  setID(ID) {
    if (!ID) this.error('Need ID to init', true);

    this.ID = ID;

    this._keyCache   = `${this._model.tbl}.${this._model.key}:${this.ID}`;
    this._keyBD      = {
      [`${this._model.tbl}.${this._model.key}`]: this.ID,
    };

    this._logger.state({ [this._model.key]: this.ID });
  }

  validate(data, showWarn = true) {
    const validateResult = Validator.validate(data, this._model.fields, false);
    if (!validateResult || validateResult.errorsMaxLevel === undefined || validateResult.errorsMaxLevel === 100) {
      this.error('Validating Errors', true, Object.merge(data, validateResult.errors['100']), 400);
      return undefined;
    }
    if (showWarn && validateResult.errorsMaxLevel > 0) {
      this.dbg(LVL.WARN, 'Validating WARN', validateResult.errors['1']);
    }

    if (showWarn && validateResult.errors && validateResult.errors['0']) {
      this.dbg(LVL.WARN, 'Validating NOTICE', validateResult.errors['0']);
    }

    this.dbg(LVL.DATA, 'validated', validateResult.object);

    return validateResult.object;
  }

  init(data) {
    this.dbg(LVL.DBG, 'Init');
    this.dbg(LVL.DATA, JSON.stringify(data));

    if (!data) return;

    if (typeof data === 'string' && data.length) {
      data = JSON.parse(data);
    }

    const validData = this.validate(data);

    this.setID(validData[this._model.key]);

    Object.assign(this, validData);

    this.__initFields = validData;

    return this.fields();
  }

  fields(allowLevel = 100) {
    const fields = {};
    Object.keys(this._model.fields).forEach((key) => {
      if (this[key] !== undefined && (!this._model.fields[key].allow || this._model.fields[key].allow <= allowLevel)) {
        fields[key] = this[key];
      }
    });

    this.dbg(LVL.DATA, 'fields', fields);
    return fields;
  }

  update(data) {
    this.dbg(LVL.DBG, 'update');
    if (!data) return;
    if (typeof data === 'string' && data.length) {
      data = JSON.parse(data);
    }
    const fields = this.fields();

    Object.keys(this._model.fields).forEach((key) => {
      const rule = this._model.fields[key];
      if (rule && rule.allow === 1 && data[key]) {
        fields[key] = data[key];
      }
    });
    this.dbg(LVL.DATA, 'fieldsToUpdate', fields);
    return this.init(fields);
  }

  error(err, throwErr = false, params = null, code = 500) {
    if (typeof err === 'string') {
      err = new Error(err);
    }
    err.message = `SAEntity Error: ${err.message}`;
    err.code = code;
    err.status = code;
    err.statusCode = code;
    if (params) err.params = params;
    if (throwErr) {
      throw err;
    } else {
      this._logger.error(err, false);
    }
  }

  dbg(level, message, params) {
    if (level >= this._debug) {
      level = (level > this._logger.errLevel || level >= LVL.WARN) ? level : this._logger.errLevel + 1;
      this._logger.log(`${this.constructor.name} [ ${this.ID} ]     ${message}`, params, level);
    }
  }
}

export default Entity;

