import React     from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import moment    from 'moment';
import { Link }  from 'react-router-dom';
import helpers   from '../../utils/viewHelpers';

class Iteration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qcC: this.props.qcC,
      qcNote: this.props.qcNote,
      edit: false,
    };
    this.coefChangeHandler = (ev) => {
      const value = +ev.target.value;
      if (value >= 0 && value <= 2) {
        this.setState({ qcC: ev.target.value });
      }
    };
    this.commentChangeHandler = (ev) => {
      const { value } = ev.target;
      this.setState({ qcNote: value });
    };
    this.toggle = (state) => {
      this.setState({ edit: state });
    };
    this.cancelModal = () => {
      this.setState({ qcC: this.props.qcC });
    };
    this.saveCoef = () => {
      const { setQccIteration, iterId, ID } = this.props;
      setQccIteration(iterId, '', ID, this.state.qcC, this.state.qcNote);
    };
  }
  render() {
    const {
      iterId,
      sprintId,
      ID,
      startD,
      endD,
      reqSec,
      workedSec,
      planedSec,
      vacationSec,
      issuesDone,
      issuesFixed,
      issuesWaiting,
      moneySalary,
      moneyAward,
      moneyTotal,
      isAdmin,
    } = this.props;
    const startDay = moment(startD).format('D');
    const endDay = moment(endD).format('D');
    const month = moment(startD).format('MMMM');
    const sprintInfo = `${startDay} - ${endDay} ${month} [${reqSec / 3600}]`;
    return (
      <div
        key={iterId}
        className={`sprint ${iterId === sprintId ? 'active' : ''}`}
      >
        <Link to={`/personalSprint/${ID}/${iterId}`} style={{ textDecoration: 'none' }}>
          <div className="badge badge-primary current-budge">current iteration</div>
          <div className="sprint-date primary">{sprintInfo}</div>
          <div className="d-flex">
            <div className="sprint-tasks-info d-flex flex-column">
              <div className="sprint-info-line">
                <div className="sprint-info-line__label">Logged</div>
                <div className="sprint-info-line__value danger large bold logged-time">
                  {helpers.HMfromSec(workedSec, ':')}
                </div>
              </div>
              <div className="d-flex">
                <div className="col-md-6 sprint-info-line">
                  <div className="sprint-info-line__label">Planned</div>
                  <div className="sprint-info-line__value primary">{helpers.HMfromSec(planedSec, ':')}</div>
                </div>
                <div className="col-md-6 sprint-info-line">
                  <div className="sprint-info-line__label">Vacation</div>
                  <div className="sprint-info-line__value">{helpers.HMfromSec(vacationSec, ':')}</div>
                </div>
              </div>
              <div className="d-flex sprint-issues-hours">
                <div className="sprint-info-line">
                  <div className="sprint-info-line__label">Done:</div>
                  <div className="sprint-info-line__value primary">{issuesDone}</div>
                </div>
                <div className="sprint-info-line">
                  <div className="sprint-info-line__label">Errors:</div>
                  <div className="sprint-info-line__value warning">{issuesFixed}</div>
                </div>
                <div className="sprint-info-line">
                  <div className="sprint-info-line__label">Wait:</div>
                  <div className="sprint-info-line__value danger">{issuesWaiting}</div>
                </div>
              </div>
            </div>
            <div className="vertical-line" />
            <div className="sprint-salary-info  d-flex flex-column">
              <div className="sprint-info-line">
                <div className="sprint-info-line__label">Salary</div>
                <div className="sprint-info-line__value">
                  {`${helpers.formatMoney(moneySalary)}`}
                  <span className="ruble ruble-small" />
                </div>
              </div>
              {
                isAdmin &&
                <ReactModal
                  isOpen={this.state.edit}
                  contentLabel="Inline Styles Modal Example"
                  style={{
                    overlay: {
                      backgroundColor: 'rgba(0,0,0,0.3)',
                    },
                    content: {
                      color: 'lightsteelblue',
                      top: 'calc(35%)',
                      left: 'calc(35%)',
                      height: '260px',
                      width: '400px',
                      border: '1px solid silver',
                    },
                  }}
                >
                  <div className="form-group">
                    <label>Введите новый коэффициент</label>
                    <input
                      className="form-control"
                      value={this.state.qcC}
                      onChange={this.coefChangeHandler}
                      type="number"
                      step="0.25"
                      min="0"
                      max="2"
                    />
                  </div>
                  <div className="form-group">
                    <label>Введите комментарий</label>
                    <input
                      className="form-control"
                      type="text"
                      value={this.state.qcNote}
                      onChange={this.commentChangeHandler}
                    />
                  </div>
                  <div className="d-flex justify-content-around">
                    <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => { this.toggle(false); this.cancelModal(); }}
                    >
                      Отменить
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={() => { this.toggle(false); this.saveCoef(); }}
                    >
                      Сохранить
                    </button>
                  </div>
                </ReactModal>
              }
              <div className="sprint-info-line">
                <div className="sprint-info-line__label">Award</div>
                <div className="sprint-info-line__value">
                  {`${helpers.formatMoney(moneyAward)}`}
                  <span className="ruble ruble-small" />
                  <span
                    className={`sprint-qcoefficient-value ${this.state.qcC === 1 ? '' : 'bold'}`}
                    onClick={() => { this.toggle(true); }}
                    title={this.state.qcC === 1 ? '' : this.state.qcNote}
                  >
                    [{this.state.qcC}]
                  </span>
                </div>
              </div>
              <div className="sprint-info-line">
                <div className="sprint-info-line__label">Total</div>
                <div className="sprint-info-line__value large black">
                  {`${helpers.formatMoney(moneyTotal)}`}
                  <span className="ruble" />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

Iteration.propTypes = {
  iterId: PropTypes.number.isRequired,
  sprintId: PropTypes.number.isRequired,
  qcC: PropTypes.number.isRequired,
  ID: PropTypes.string.isRequired,
  qcNote: PropTypes.string.isRequired,
  startD: PropTypes.string.isRequired,
  endD: PropTypes.string.isRequired,
  reqSec: PropTypes.number.isRequired,
  workedSec: PropTypes.number.isRequired,
  planedSec: PropTypes.number.isRequired,
  vacationSec: PropTypes.number.isRequired,
  issuesDone: PropTypes.number.isRequired,
  issuesFixed: PropTypes.number.isRequired,
  issuesWaiting: PropTypes.number.isRequired,
  moneySalary: PropTypes.number.isRequired,
  moneyAward: PropTypes.number.isRequired,
  moneyTotal: PropTypes.number.isRequired,
  setQccIteration: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default Iteration;
