import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Pillow from '../Pillows';

import { getDateAndTimeToString } from '../../../utils/formattingDate';

const Wrapper = styled.div`
    width: 472px;
    cursor: pointer;
    .infoSubscribe {
      width: 100%;
      height: 56px;
      padding: 11px;
      background: ${({ color }) => color || '#C1C1C1'};
      border-radius: 8px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      &__name {
        font-size: 40px;
        font-weight: 600;
        line-height: 20px;
      }
      &__comment {
        font-size: 12px;
        line-height: 14px;  
        flex-direction: column;
        height: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 8px;
      }
      &__dayCountDates {
        display: flex;
        margin-left: auto;
        align-items: center;
        height: 100%;
        &-dayCount {
          font-weight: 500;
          font-size: 40px;
          line-height: 20px;
        }
        &-dates {
          height: 100%;
          font-size: 12px;
          line-height: 14px;
          color: rgba(255, 255, 255, 0.67);
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &-start {
  
          }
          &-end {
  
          }
        } 
      }
    }

`;

const Comment = styled.span`
    font-size: 13px;
    line-height: 16px;
    padding-left: 10px;
    color: #8F8F8F;
    display: inline-block;
    margin-top: 9px;
`;

export default function SubscribeInfo({
  large,
  isRenewing,
  type,
  source,
  startDT,
  endDT,
  daysCount,
  comment,
}) {
  let color;
  if (daysCount < 0) {
    color = '#D4454D';
  } else if (type === 'basic') {
    color = '#2EA8E5';
  } else if (type === 'premium') {
    color = '#E4A500';
  } else {
    color = '#C1C1C1';
  }
  const renderLargeSubscribeInfo = () => {
    let title;
    const renewingTitle = isRenewing ? 'автопродление' : 'НЕТ автопродления';
    const startDToptions = getDateAndTimeToString(startDT);
    // if (startDT === '') {
    //   const DAY_IN_MSEC = 86400000;
    //   const millisecondEndDT = new Date(endDT).getTime();
    //   const millisecindStartDT = new Date(millisecondEndDT - DAY_IN_MSEC * 30);
    //   startDToptions = getDateAndTimeToString(millisecindStartDT);
    // }
    const endDToptions = getDateAndTimeToString(endDT);
    if (type === 'basic') {
      title = type.toUpperCase();
    } else if (type === 'premium') {
      title = 'PREM';
    } else {
      title = type.toUpperCase();
    }
    return (
      <Wrapper color={color}>
        <div className="infoSubscribe">
          <div className="infoSubscribe__name">{title}</div>
          <div className="infoSubscribe__comment">
            <span>*No info*</span>
            <span>{source}</span>
          </div>
          <div className="infoSubscribe__dayCountDates">
            <div className="infoSubscribe__dayCountDates-dayCount">{daysCount}</div>
            <div className="infoSubscribe__dayCountDates-dates">
              <span className="infoSubscribe__dayCountDates-dates-start">
                {startDToptions.date}
                {' '}
                {startDToptions.time}
              </span>
              <span className="infoSubscribe__dayCountDates-dates-end">
                {endDToptions.date}
                {' '}
                {endDToptions.time}
              </span>
            </div>
          </div>
        </div>
        <Comment>{comment}</Comment>
      </Wrapper>
    );
  };
  return large ? renderLargeSubscribeInfo() : <Pillow content={daysCount} color={color} size="small" />;
}

SubscribeInfo.propTypes = {
  large: PropTypes.bool,
  isRenewing: PropTypes.bool,
  type: PropTypes.string.isRequired,
  source: PropTypes.string,
  startDT: PropTypes.string,
  endDT: PropTypes.string,
  daysCount: PropTypes.number.isRequired,
  comment: PropTypes.string,

};

SubscribeInfo.defaultProps = {
  large: false,
  isRenewing: false,
  source: '',
  startDT: '',
  endDT: '',
  comment: '',
};
