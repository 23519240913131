import React, { useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as fromStyled from '../styles';
import SelectForFilter from '../../../ui/Select/SelectForFilter';

import Context from '../../../common/Context';

import MessageList from './MessageList';
import DateFilter from '../../../ui/DateFilter/DateFilter';
import { formattingDateToUTC } from '../../../../utils/formattingDate';

const CHANGE_FILTER = 'CHANGE_FILTER';

const CHANGE_DATE_RANGE_ON_FILTER = 'CHANGE_DATE_RANGE_ON_FILTER';

const SET_HISTORY_MESSAGES = 'SET_HISTORY_MESSAGES';

const SET_FILTERED_HISTORY_MESSAGES = 'SET_FILTERED_HISTORY_MESSAGES';

const optionsStatus = {
  null: 'Отправлено',
  4001: '4001',
  4002: '4002',
  4003: '4003',
  4004: '4004',
  4005: '4005',
  4006: '4006',
};

const optionsTypeMessage = {
  push: 'push',
  sms: 'SMS',
  email: 'email',
  emailSupport: 'emailSupport',
};

const optionsSource = {
  system: 'Система',
  support: 'Саппорт',
};

const initialState = {
  filter: {
    status: '',
    typeMessage: null,
    source: null,
    dateRange: {
      to: undefined,
      from: undefined,
    },
  },
  historyMessages: null,
  filteredHistoryMessages: null,
};

function reducer(state, action) {
  switch (action.type) {
    case SET_HISTORY_MESSAGES:
      return {
        ...state,
        historyMessages: action.data,
      };
    case SET_FILTERED_HISTORY_MESSAGES:
      return {
        ...state,
        filteredHistoryMessages: action.data,
      };
    case CHANGE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.filterKey]: action.filterValue,
        },
      };
    case CHANGE_DATE_RANGE_ON_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateRange: {
            ...state.filter.dateRange,
            [action.toOrFrom]: action.value,
          },
        },
      };
    default:
      return state;
  }
}

export default function HistoryMessages({
  propsFromModal: { ID },
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getMessageList } = useContext(Context);

  const {
    filter,
    historyMessages,
    filteredHistoryMessages,
  } = state;

  const filteredWithDR = (list, DRfrom, DRto) => {
    const from = formattingDateToUTC(DRfrom.setHours(0, 0, 0, 0));
    const to = formattingDateToUTC(DRto.setHours(23, 59, 59, 59));
    return list.filter(({ createDT }) => {
      const first = createDT >= from;
      const second = createDT <= to;
      return first && second;
    });
  };

  const filteredWithStatus = (list, ST) => list
    .filter(({ status }) => {
      if (status === null) return ST === 'null';
      return status?.toString() === ST;
    });

  const filteredWithTypeMessage = (list, TM) => list
    .filter(({ typeMessage }) => typeMessage === TM);

  const filteredWithSource = (list, SR) => list
    .filter(({ type }) => {
      if (type === 'Support Message') {
        return SR === 'support';
      } else return SR === 'system';
    });

  useEffect(() => {
    getMessageList(ID);
    const data = [{
      createDT: '2021-04-07T08:29:03.209Z',
      typeMessage: 'push',
      content: 'Ваш запрос в команду поддержки - обработан, спасибо за использование нашего продукта!',
      status: 4005,
      type: 'Support Message',
    },
    {
      createDT: '2021-04-06T08:09:22.630Z',
      typeMessage: 'sms',
      content: 'Ваш запрос в команду поддержки - обработан, спасибо за использование нашего продукта!',
      status: 4001,
      type: 'deviceSignin',
    },
    {
      createDT: '2021-04-05T08:09:22.630Z',
      typeMessage: 'push',
      content: 'Ваш запрос в команду поддержки - обработан, спасибо за использование нашего продукта!',
      status: 4002,
      type: 'deviceSignin',
    },
    {
      createDT: '2021-04-04T08:09:22.630Z',
      typeMessage: 'emailSupport',
      content: 'Ваш запрос в команду поддержки - обработан, спасибо за использование нашего продукта!',
      status: null,
      type: 'deviceSignin',
    },
    {
      createDT: '2021-04-03T08:09:22.630Z',
      typeMessage: 'email',
      content: 'Ваш запрос в команду поддержки - обработан, спасибо за использование нашего продукта!',
      status: 4006,
      type: 'deviceSignin',
    }];
    dispatch({ type: SET_HISTORY_MESSAGES, data });
  }, []);

  const setFilteredHistoryMessages = (data) => {
    dispatch({ type: SET_FILTERED_HISTORY_MESSAGES, data });
  };

  useEffect(() => {
    const prevHistory = historyMessages;
    const { status, typeMessage, source, dateRange } = filter;
    /*
    DR - dateRange
    ST - status
    TM - typeMessage
    SR - source
     */
    let SR;
    let TM;
    let TM_SR;
    let ST;
    let ST_SR;
    let ST_TM;
    let ST_TM_SR;
    let DR;
    let DR_SR;
    let DR_TM;
    let DR_TM_SR;
    let DR_ST;
    let DR_ST_SR;
    let DR_ST_TM;
    let DR_ST_TM_SR;
    if (dateRange.from && dateRange.to) {
      DR = filteredWithDR(prevHistory, dateRange.from, dateRange.to);
      if (status !== '') {
        DR_ST = filteredWithStatus(DR, status);
        if (typeMessage) {
          DR_ST_TM = filteredWithTypeMessage(DR_ST, typeMessage);
          if (source) {
            DR_ST_TM_SR = filteredWithSource(DR_ST_TM, source);
            setFilteredHistoryMessages(DR_ST_TM_SR);
          } else setFilteredHistoryMessages(DR_ST_TM);
        } else if (source) {
          DR_ST_SR = filteredWithSource(DR_ST, source);
          setFilteredHistoryMessages(DR_ST_SR);
        } else setFilteredHistoryMessages(DR_ST);
      }  else if (typeMessage) {
        DR_TM = filteredWithTypeMessage(DR, typeMessage);
        if (source) {
          DR_TM_SR = filteredWithSource(DR_TM, source);
          setFilteredHistoryMessages(DR_TM_SR);
        } else setFilteredHistoryMessages(DR_TM);
      } else if (source) {
        DR_SR = filteredWithSource(DR, source);
        setFilteredHistoryMessages(DR_SR);
      } else setFilteredHistoryMessages(DR);
    } else if (status !== '') {
      ST = filteredWithStatus(prevHistory, status);
      if (typeMessage) {
        ST_TM = filteredWithTypeMessage(ST, typeMessage);
        if (source) {
          ST_TM_SR = filteredWithSource(ST_TM, source);
          setFilteredHistoryMessages(ST_TM_SR);
        } else setFilteredHistoryMessages(ST_TM);
      } else if (source) {
        ST_SR = filteredWithSource(ST, source);
        setFilteredHistoryMessages(ST_SR);
      } else setFilteredHistoryMessages(ST);
    } else if (typeMessage) {
      TM = filteredWithTypeMessage(prevHistory, typeMessage);
      if (source) {
        TM_SR = filteredWithSource(TM, source);
        setFilteredHistoryMessages(TM_SR);
      } else setFilteredHistoryMessages(TM);
    } else if (source) {
      SR = filteredWithSource(prevHistory, source);
      setFilteredHistoryMessages(SR);
    } else setFilteredHistoryMessages(null);
  }, [filter]);

  const changeFilter = (filterKey, filterValue) => {
    dispatch({ type: CHANGE_FILTER, filterKey, filterValue });
  };

  const onChangeDateRangeOnFilter = (toOrFrom, value) => {
    dispatch({ type: CHANGE_DATE_RANGE_ON_FILTER, toOrFrom, value });
  };

  const onChangeStatusOnFilter = (value) => {
    const st = value !== null ? value : '';
    changeFilter('status', st);
  };

  const onChangeTypeMessageOnFilter = (value) => {
    changeFilter('typeMessage', value);
  };

  const onChangeSourceOnFilter = (value) => {
    changeFilter('source', value);
  };

  return (
    <Wrapper>
      <FilterWrapper>
        <DateFilter
          isForFilter
          to={filter.dateRange.to}
          from={filter.dateRange.from}
          onChange={onChangeDateRangeOnFilter}
        />
        <section>
          <SelectForFilter
            options={optionsStatus}
            defaultValue={{ value: 'status', label: 'Статус' }}
            selectedOption={filter.status}
            onSelectOption={onChangeStatusOnFilter}
          />
        </section>
        <section>
          <SelectForFilter
            options={optionsTypeMessage}
            defaultValue={{ value: 'typeMessage', label: 'Способ' }}
            selectedOption={filter.typeMessage}
            onSelectOption={onChangeTypeMessageOnFilter}
          />
        </section>
        <section>
          <SelectForFilter
            options={optionsSource}
            defaultValue={{ value: 'source', label: 'Источник' }}
            selectedOption={filter.source}
            onSelectOption={onChangeSourceOnFilter}
          />
        </section>

      </FilterWrapper>
      <MessageList
        list={(filteredHistoryMessages !== null || filteredHistoryMessages?.length === 0)
          ? filteredHistoryMessages
          : historyMessages}
      />
    </Wrapper>
  );
}

const Wrapper = styled(fromStyled.WrapperForHistoryModal)`
  padding: 0px 12px 13px 30px;
`;

const FilterWrapper = styled(fromStyled.WrapperForFilter)`
  display: flex;
  align-items: center; 
  padding: 0 20px;
  width: 625px;

`;

HistoryMessages.propTypes = {
  propsFromModal: PropTypes.shape({
    ID: PropTypes.string,
  }).isRequired,
};
