import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import icons from '../../../utils/icons';

const IndicatorsContainer = () => icons.arrowDown;

const colourStyles = {
  container: (styles, { selectProps }) => ({
    ...styles,
    backgroundColor: '#FFFFFF',
    width: `${selectProps.controlWidth}px`,
    border: '1px solid #CED4DA',
    borderRadius: '4px',
    padding: '0 3px 0 12px',
    boxSizing: 'border-box',

  }),
  control: (styles, { selectProps }) => ({
    ...styles,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    borderBottom: selectProps.renewing ? '1px solid #BBBBBB' : 'none',
    borderRadius: 'none',
    backgroundColor: 'none',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    position: 'relative',
    boxSizing: 'border-box',
    minHeight: 30,
    maxHeight: 90,
    fontSize: '15px',
    lineHeight: '17px',
    width: 'auto',
    // width: selectProps.language ? '39px' : selectProps.renewing ? '100%' : '87px',
    overflowY: 'auto',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  menu: (styles, { selectProps }) => ({
    ...styles,
    position: 'absolute',
    top: 'calc(100% + 2px)',
    width: 'auto',
    // width: selectProps.language ? '50px' : selectProps.renewing ? '100%' : '303px',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
    overflowX: 'hidden',
    borderRadius: '4px',
    fontSize: '15px',
    lineHeight: '17px',
    zIndex: 99,
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    borderBottom: '1px solid #DFDFDF',
    backgroundColor: !isSelected ? '#FFFFFF' : '#F4F4F4',
    color: '#000000',
    cursor: 'pointer',
    ':hover': {
      ...styles[':hover'],
      backgroundColor: '#F4F4F4',
    },
  }),
  singleValue: (styles, { isClearable }) => ({
    ...styles,
    fontSize: 12,
    color: '#585858',
    ':hover': {
      color: '#585858',
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: 12,
    color: '#585858',
    ':hover': {
      color: '#585858',
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: 0,
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: 0,
  }),
};

export default function SelectForFilter({
  width,
  options,
  defaultValue,
  selectedOption,
  onSelectOption,
}) {
  const optionsForSelect = Object.keys(options).map((contentOption) => (
    { value: contentOption, label: options[contentOption] }
  ));
  const selectOption = (option) => {
    if (!option) {
      onSelectOption(null);
      return;
    }
    if (String(selectedOption) === option.value) return;
    onSelectOption(option.value);
  };
  return (
    <ReactSelect
      defaultValue={defaultValue}
      placeholder={defaultValue.label}
      options={optionsForSelect}
      maxMenuHeight={250}
      styles={colourStyles}
      isSearchable={false}
      isClearable={selectedOption}
      // isLoading
      controlWidth={width}
      noOptionsMessage={() => 'Нет данных'}
      clearValue={() => defaultValue}
      onChange={selectOption}
      components={{
        IndicatorSeparator: () => null,
        // IndicatorsContainer,
      }}
    />
  );
}

SelectForFilter.propTypes = {
  width: PropTypes.number,
  options: PropTypes.shape().isRequired,
  defaultValue: PropTypes.shape().isRequired,
  selectedOption: PropTypes.string,
  onSelectOption: PropTypes.func.isRequired,
};

SelectForFilter.defaultProps = {
  width: 124,
  selectedOption: null,
};
