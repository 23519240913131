/* eslint-disable no-mixed-operators */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { adminID } from '../../../shared/config';
import WorkLogDay from './WorkLogDay';
import Issue from './Issue';
import Feature from './Feature';
import UserInfo from './UserInfo';
import LastIssue from './LastIssue';

moment.locale('ru');

export default class Productivity extends React.Component {
  onSwitchWorkstate = (event) => {
    const active = event.currentTarget.getAttribute('data-active');
    const id = event.currentTarget.getAttribute('data-id');
    this.props.onSwitchWorkstate(id, active === 'true' ? 'off' : 'on');
  };

  render() {
    const {
      user,
      justLogin,
      userIteration,
      workstate,
      icons,
      summaries,
      onResetJustLogin,
      onTransitIssue,
      pauseIssues,
      features,
      worklogs,
      onDeleteWorklog,
      waitingIssues,
      ids,
      isPendingTransitIssue,
      isPendingDeleteWorkLog,
      isPendingSwitchWorkstate,
    } = this.props;

    if (justLogin) {
      onResetJustLogin();
      this.props.getUserWorkstate();
      this.props.getUserList();
      this.props.getUserInfo();
      return <Redirect to={{ pathname: '/employee' }} />;
    }
    if (user.ID === adminID) {
      return <Redirect to={{ pathname: '/workstate' }} />;
    }
    const { name, surname, position, email, login, ID } = user;
    const iteration = userIteration.ID ? userIteration : null;
    let sprintDate = '1 - 15 August';
    if (iteration) {
      const startD = moment(iteration.iteration.startD).format('D');
      const endD = moment(iteration.iteration.endD).format('D');
      const month = moment(iteration.iteration.startD).format('MMMM');
      sprintDate = `${startD} - ${endD} ${month}`;
    }
    const sprintReqHrs = iteration ? `${iteration.iteration.reqHrs}:00` : '00:00';
    const workedHrs = iteration ? iteration.workedTime || '00:00' : '00:00';
    const startTime = workstate ? workstate.fromTime || '00:00' : '00:00';
    const endTime = workstate ? workstate.toTime || '00:00' : '00:00';
    const currentTime = workstate ? workstate.workedTime || '00:00' : '00:00';
    let lastIssue = {
      key: '',
      status: '',
      summary: '',
      timeEstimate: 0,
      workedSec: 0,
    };
    if (workstate && workstate.issues && workstate.issues.length) {
      lastIssue = workstate.issues[workstate.issues.length - 1];
    }
    const featuresList = features.map(issue => (
      <Feature
        key={`feature#${issue.issueKey}`}
        issueKey={issue.issueKey}
        iconType={issue.iconType}
        SUMMARY={issue.summary}
      />
    ));
    const pauseList = pauseIssues.map(issue => (
      <Issue
        key={`issue#${issue.issueKey}#${Math.random()}`}
        type="pause"
        issueKey={issue.issueKey}
        iconType={issue.iconType}
        status={issue.status}
        SUMMARY={issue.summary}
        TIMEORIGINALESTIMATE={+issue.timeEstimate}
        TIMESPENT={+issue.workedSec}
        onTransitIssue={onTransitIssue}
        isPending={issue.issueKey in isPendingTransitIssue}
      />
    ));
    const workLogsList = worklogs.map(log => (
      <WorkLogDay
        key={`workLog#${log.ID}#${log.day}`}
        workedIssues={log.workLogs}
        day={log.day}
        startHr={log.fromTime}
        endHr={log.toTime}
        icons={icons}
        summaries={summaries}
        ids={ids}
        workedSec={log.workedSec}
        deleteWorklog={onDeleteWorklog}
        isPending={isPendingDeleteWorkLog}
      />
    ));

    const waitingList = waitingIssues.map(issue => (
      <Issue
        key={`waiting#${issue.issueKey}`}
        type="waiting"
        issueKey={issue.issueKey}
        iconType={issue.iconType}
        status={issue.status}
        SUMMARY={issue.summary}
        TIMEORIGINALESTIMATE={+issue.timeEstimate}
        TIMESPENT={+issue.issueWorkedSec}
        onTransitIssue={onTransitIssue}
        isPending={issue.issueKey in isPendingTransitIssue}
      />
    ));
    return (
      <div className="container productivity">
        <div className="row main-productivity-block">
          <UserInfo
            email={email}
            ID={ID}
            login={login}
            name={name}
            surname={surname}
            position={position}
            sprintDate={sprintDate}
            workedHrs={workedHrs}
            sprintReqHrs={sprintReqHrs}
            startTime={startTime}
            currentTime={currentTime}
            endTime={endTime}
            onSwitchWorkstate={this.onSwitchWorkstate}
            active={workstate ? workstate.state === 10 : false}
            isPending={ID in isPendingSwitchWorkstate}
          />
          <LastIssue
            active={workstate ? workstate.state === 10 : false}
            status={lastIssue.status}
            SUMMARY={lastIssue.summary}
            TIMESPENT={+lastIssue.workedSec}
            TIMEORIGINALESTIMATE={+lastIssue.timeEstimate}
            issueKey={lastIssue.issueKey}
            iconType={lastIssue.iconType}
            onTransitIssue={onTransitIssue}
            isPending={lastIssue.issueKey in isPendingTransitIssue}
          />
        </div>

        <div className="row productivity-issues">
          <div className="col-md-12 col-lg-6">
            <div className="section worklogs">
              <div className="section-header">Work Logs:</div>
              <div className="section-content">{workLogsList}</div>
            </div>

            <div className="section features">
              <div className="section-header">
                <div className="badge">{featuresList.length}</div>
                Features:
              </div>
              <div className="section-content">{featuresList}</div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="section paused">
              <div className="section-header">
                <div className="badge">{pauseList.length}</div>
                Paused:
              </div>
              <div className="section-content">
                {pauseList.length > 0 ? (
                  pauseList
                ) : (
                  <div className="section-content empty">У вас нет задач на паузе</div>
                )}
              </div>
            </div>

            <div className="section planned">
              <div className="section-header">
                <div className="badge">{waitingList.length}</div>
                ToDo:
              </div>
              <div className="section-content">
                {waitingList.length > 0 ? (
                  waitingList
                ) : (
                  <div className="section-content empty">У вас нет запланированных задач</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Productivity.propTypes = {
  user: PropTypes.shape().isRequired,
  workstate: PropTypes.shape().isRequired,
  userIteration: PropTypes.shape().isRequired,
  icons: PropTypes.shape().isRequired,
  summaries: PropTypes.shape().isRequired,
  pauseIssues: PropTypes.arrayOf(PropTypes.object).isRequired,
  features: PropTypes.arrayOf(PropTypes.object).isRequired,
  waitingIssues: PropTypes.arrayOf(PropTypes.object).isRequired,
  worklogs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSwitchWorkstate: PropTypes.func.isRequired,
  onTransitIssue: PropTypes.func.isRequired,
  onDeleteWorklog: PropTypes.func.isRequired,
  onResetJustLogin: PropTypes.func.isRequired,
  getUserList: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  getUserWorkstate: PropTypes.func.isRequired,
  justLogin: PropTypes.bool.isRequired,
  ids: PropTypes.shape().isRequired,
  isPendingTransitIssue: PropTypes.objectOf(PropTypes.bool).isRequired,
  isPendingDeleteWorkLog: PropTypes.objectOf(PropTypes.bool).isRequired,
  isPendingSwitchWorkstate: PropTypes.objectOf(PropTypes.bool).isRequired,
};
