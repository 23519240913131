import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import helpers from '../../utils/viewHelpers';
import { Loader, Overlay } from '../Loader';

moment.locale('ru');

const Issue = ({
  issueKey,
  iconType,
  status,
  SUMMARY,
  type,
  TIMESPENT,
  TIMEORIGINALESTIMATE,
  onTransitIssue,
  isPending,
}) => {
  let issueButtons;
  switch (type) {
    case 'pause': {
      issueButtons = (
        <div className="issue-buttons mt-auto d-flex flex-row">
          <div className="rounded-circle primary d-flex justify-content-center align-items-center">
            <i
              className="fa fa-long-arrow-down"
              aria-hidden="true"
              onClick={() => {
                onTransitIssue(issueKey, 'wait');
              }}
            />
          </div>
          <div className="rounded-circle success d-flex justify-content-center align-items-center">
            <i
              className="fa fa-play"
              aria-hidden="true"
              onClick={() => {
                onTransitIssue(issueKey, 'start');
              }}
            />
          </div>
        </div>
      );
      break;
    }
    case 'waiting': {
      issueButtons = (
        <div className="issue-buttons mt-auto d-flex flex-row">
          <div className="rounded-circle success d-flex justify-content-center align-items-center ml-auto">
            <i
              className="fa fa-play"
              aria-hidden="true"
              onClick={() => {
                onTransitIssue(issueKey, 'start');
              }}
            />
          </div>
        </div>
      );
      break;
    }
    default: {
      issueButtons = (
        <div className="issue-buttons mt-auto d-flex flex-row">
          <div className="rounded-circle success d-flex justify-content-center align-items-center ml-auto">
            <i className="fa fa-play" aria-hidden="true" />
          </div>
        </div>
      );
    }
  }
  return (
    <div className={`issue issue-${type} d-flex flex-row`}>
      <div className="c1 d-flex flex-column">
        <div className="issue-info d-flex flex-row align-items-center">
          <img src={iconType} alt="" />
          <a target="_blank" href={`http://jira.soft-artel.com/browse/${issueKey}`}>
            {issueKey}
          </a>
          <div className="badge">{status}</div>
        </div>
        <div className="issue-title">{SUMMARY}</div>
        <div className="components-label">Components</div>
      </div>
      <div className="c2 d-flex flex-column ml-auto">{helpers.HMfromSec(TIMEORIGINALESTIMATE, 'h')}</div>
      <div className="c3 d-flex flex-column">
        <div className="issue-hours">{helpers.HMfromSec(TIMESPENT, 'hm')}</div>
        {issueButtons}
      </div>
      {isPending && (
        <Overlay>
          <Loader show scale={0.8} />
        </Overlay>
      )}
    </div>
  );
};

Issue.propTypes = {
  issueKey: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  SUMMARY: PropTypes.string.isRequired,
  TIMEORIGINALESTIMATE: PropTypes.number.isRequired,
  TIMESPENT: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onTransitIssue: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default pure(Issue);
