/* eslint-disable no-mixed-operators */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import gravatar from '../utils/gravatar';
import { Loader } from '../components/Loader';
import Worklogs from '../components/personalSprint/Worklogs';
import SprintIssues from '../components/personalSprint/SprintIssues';
import IterationsList from '../components/personalSprint/IterationsList';
import { setQccIssues, deleteWorklog } from '../redux/actions/issuesActions';
import { getAllEmployees, getEmployee } from '../redux/reducers/employees';
import { getIteration, setQccIteration } from '../redux/actions/iterationActions';
import '../styles/sprints.css';

const scrollToEmployee = (empListNode, userIndex) => {
  if (empListNode) {
    empListNode.scrollLeft = empListNode.childNodes[userIndex].offsetLeft - 30;
    setTimeout(() => {
      empListNode.scrollLeft = empListNode.childNodes[userIndex].offsetLeft - 30;
    }, 100);
  }
};

class SprintInfo extends React.Component {
  constructor(props) {
    super(props);
    this.props.getIteration(this.props.sprintId, '', this.props.user.ID);
  }
  componentDidMount() {
    scrollToEmployee(this.empList, this.props.userIndex);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.sprintId !== nextProps.sprintId || this.props.user.ID !== nextProps.user.ID) {
      this.props.getIteration(nextProps.sprintId, '', nextProps.user.ID);
    }
  }
  render() {
    const { iterations, employees, iteration, user, isPending } = this.props;
    const loading = (iterations[this.props.user.ID] && iterations[this.props.user.ID].loading) || false;
    let workLogs = [];
    const icons = {};
    const summaries = {};
    const ids = {};
    let doneIssues = [];
    let fixedIssues = [];
    let waitIssues = [];
    if (iteration && iteration.ID) {
      workLogs = iteration.daysOrder.map(day => iteration.days[day]);
      Object.keys(iteration.issues).forEach((key) => {
        icons[key] = iteration.issues[key].iconType;
        summaries[key] = iteration.issues[key].summary;
        ids[key] = iteration.issues[key].id;
      });
      doneIssues = iteration.issuesDone.map(key => iteration.issues[key]);
      fixedIssues = iteration.issuesFixed.map(key => iteration.issues[key]);
      waitIssues = iteration.issuesWaiting.map(key => iteration.issues[key]);
    }
    const employeesList = employees.map((employee) => {
      if (employee.login.trim() && employee.name.trim()) {
        return (
          <Link
            key={employee.ID}
            to={`/personalSprint/${employee.ID}/${this.props.sprintId}`}
            style={{ textDecoration: 'none' }}
          >
            <div
              className={`admin-sprints-employees-list-item d-flex ${user.ID === employee.ID ? 'active' : ''}`}
              data-id={employee.ID}
            >
              <div className="admin-sprints-employees-list-item-image">
                <img className="rounded-circle" src={gravatar(employee.email, { size: 70 })} alt="icon" />
              </div>
              <div className="admin-sprints-employees-list-item-user">
                <div className="admin-sprints-employees-list-item-user__name">
                  {`${employee.name} ${employee.surname}`}
                </div>
                <div className="admin-sprints-employees-list-item-user__position">{employee.position}</div>
              </div>
            </div>
          </Link>
        );
      }
      return null;
    });

    return (
      <div className="container-fluid personal-sprint">
        {this.props.isAdmin && (
          <div className="sprints-admin-header">
            <Link to="/sprints">&lt; Выработка по команде</Link>
            <div className="line" />
            <div
              className="admin-sprints-employees-list d-flex"
              ref={(empList) => {
                this.empList = empList;
              }}
            >
              {employeesList}
            </div>
            <div className="line" />
          </div>
        )}
        <Loader show={loading} />
        <div className="sprints row" style={{ height: `${window.innerHeight - 80}px`, opacity: loading ? 0.05 : 1 }}>
          <IterationsList
            iterations={iterations[this.props.user.ID] || { list: { indexes: [], DS: {} } }}
            isAdmin={this.props.isAdmin}
            sprintId={this.props.sprintId}
            setQccIteration={this.props.setQccIteration}
          />
          <SprintIssues
            doneIssues={doneIssues}
            fixedIssues={fixedIssues}
            waitIssues={waitIssues}
            setQccIssues={this.props.setQccIssues}
            iterId={this.props.sprintId}
            isAdmin={this.props.isAdmin}
          />
          <Worklogs
            workLogs={workLogs}
            icons={icons}
            summaries={summaries}
            ids={ids}
            deleteWorklog={this.props.deleteWorklog}
            isPending={isPending}
          />
        </div>
      </div>
    );
  }
}

SprintInfo.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  setQccIssues: PropTypes.func.isRequired,
  setQccIteration: PropTypes.func.isRequired,
  deleteWorklog: PropTypes.func.isRequired,
  getIteration: PropTypes.func.isRequired,
  sprintId: PropTypes.number.isRequired,
  userIndex: PropTypes.number.isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
  iteration: PropTypes.shape().isRequired,
  iterations: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  isPending: PropTypes.objectOf(PropTypes.bool).isRequired,
};

function mapStateToProps(state, props) {
  const { iterId, userID } = props.match.params;
  const user = getEmployee(state.employees.byId, userID);
  const employees = getAllEmployees(state.employees);
  const userIndex = state.employees.ids.indexOf(userID);
  return {
    iterations: state.iterations.byUserID,
    iteration: (state.iterations.byUserID[userID] && state.iterations.byUserID[userID].byID[iterId]) || {},
    employees,
    user,
    userIndex,
    isAdmin: state.user.allows === 100,
    sprintId: +iterId,
    isPending: state.app.isPending.deleteWorkLog,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getIteration: bindActionCreators(getIteration, dispatch),
    setQccIssues: bindActionCreators(setQccIssues, dispatch),
    setQccIteration: bindActionCreators(setQccIteration, dispatch),
    deleteWorklog: bindActionCreators(deleteWorklog, dispatch),
  };
}

const SprintInfoContainer = connect(mapStateToProps, mapDispatchToProps)(SprintInfo);

export default SprintInfoContainer;
