import { take, fork, all, put, select, call } from 'redux-saga/effects';
import {
  updateDeviceSuccess,
  codeDeviceSuccess,
  codeDeviceFailure,
  signInSuccess,
  signInFailure,
  UPDATE_DEVICE_REQUEST,
  CODE_DEVICE_REQUEST,
  SIGN_IN_DEVICE_REQUEST,
} from '../../redux/actions/deviceActions';
import { getDevice } from '../../redux/reducers/device';
import { getUserListWithWorkstate } from './userSaga';

export function* updateDevice() {
  const device = yield select(getDevice);
  const updatedDeviceResponse = yield device.update();
  if (updatedDeviceResponse.status === 200) {
    yield put(updateDeviceSuccess(updatedDeviceResponse.body.data));
  } else {
    console.log('error when updating device');
  }
}

export function* signInDevice(phone, code) {
  const device = yield select(getDevice);
  const updateResponse = yield device.update();
  if (updateResponse.status === 200) {
    const signInResponse = yield device.signin(phone, code);
    if (signInResponse) {
      if (signInResponse.status === 200) {
        const { data } = signInResponse.body.data;
        yield put(signInSuccess(data.device, data.user));
        yield call(getUserListWithWorkstate);
        yield put({ type: 'LOGIN' });
      } else {
        yield put(signInFailure(signInResponse.data));
      }
    }
    else {
      yield put(signInFailure('the wrong code is entered'));
    }
  } else {
    console.log('error when updating device');
  }
}

export function* codeDevice(phone) {
  const device = yield select(getDevice);
  const updateResponse = yield device.update();
  if (updateResponse.status === 200) {
    const codeResponse = yield device.code(phone);
    if (codeResponse) {
      if (codeResponse.status === 200) {
        yield put(codeDeviceSuccess());
      } else if (codeResponse.status === 422) {
        yield put(codeDeviceFailure(codeResponse.data));
      }
    }
  } else {
    console.log('error when updating device');
  }
}

export function* watchUpdateDevice() {
  while (true) {
    yield take(UPDATE_DEVICE_REQUEST);
    yield fork(updateDevice);
  }
}

export function* watchSignInDevice() {
  while (true) {
    const { phone, code } = yield take(SIGN_IN_DEVICE_REQUEST);
    yield fork(signInDevice, phone, code);
  }
}

export function* watchCodeDevice() {
  while (true) {
    const { phone } = yield take(CODE_DEVICE_REQUEST);
    yield fork(codeDevice, phone);
  }
}


export default function* deviceSaga() {
  yield all([
    fork(watchUpdateDevice),
    fork(watchSignInDevice),
    fork(watchCodeDevice),
  ]);
}
