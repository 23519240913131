import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SearchPanel from '../SearchPanel';
import UserList from '../UserList';
import Preloader from '../../common/Preloader';

export default function FirstPage({
  loadingUserListStatus,
  searchPanelValue,
  foundUsers,
  setLoadingUserListStatus,
  setValueSearchPanel,
  onSelectUser,
  setFoundUsers,
}) {
  const resetSearchPanel = () => {
    setValueSearchPanel('');
    setLoadingUserListStatus(0);
    setFoundUsers([]);
  };
  return (
    <>
      <SearchPanel
        loadingStatus={loadingUserListStatus}
        searchPanelValue={searchPanelValue}
        onSubmitSearchPanel={setValueSearchPanel}
      />
      {loadingUserListStatus === 1 ? <Preloader />
        : loadingUserListStatus === 0 ? (
          <UserList
            foundUsers={foundUsers}
            onSelectUser={onSelectUser}
          />
        ) : (
          <UsersNotFoundText>
            <span>Пользователи не найдены.</span>
            <span className="resetSearchPanel" onClick={resetSearchPanel}>Очистить поле поиска</span>
          </UsersNotFoundText>
        )}

    </>
  );
}
const UsersNotFoundText = styled.div`
  display: flex;
  justify-content: center;
  color: #979797;
  margin-top: 20px;
  font-size: 16px;
  line-height: 19px;
  .resetSearchPanel {
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #5d5d5d;
    }
  }
`;

FirstPage.propTypes = {
  loadingUserListStatus: PropTypes.number.isRequired,
  searchPanelValue: PropTypes.string.isRequired,
  foundUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setValueSearchPanel: PropTypes.func.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  setLoadingUserListStatus: PropTypes.func.isRequired,
  setFoundUsers: PropTypes.func.isRequired,
};
