import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Wrapper = styled.span`
.react-phone-container {
  width: auto;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 300;
  .react-phone-input {
    padding: 0px;
    padding-left: 25px;
    width: auto;
    background-color: #FFFFFF;
    color: #9B9B9B;
    cursor: text;
    &.form-control {
      &[readonly] {
        background-color: transparent;
      }
    }
  }
  .flag-dropdown {
    background-color: transparent;
    border: none;
    .selected-flag {
      padding: 0;
      cursor: default;
      width: auto;
    }
  }
}
`;

export default function PhoneField({
  value,
}) {
  return (
    <Wrapper>
      <PhoneInput
        containerClass="react-phone-container"
        inputClass="react-phone-input"
        dropdownClass="react-phone-dropdown"
        searchClass="react-phone-search"
        inputProps={{
          type: 'tel',
          readOnly: true,
          cursor: 'text',
        }}
        inputStyle={{
          border: 'none',
          backgroundColor: '#FFFFFFF',
          height: '20px',
        }}
        disableDropdown
        disabled
        value={value}
      />
    </Wrapper>
  );
}

PhoneField.propTypes = {
  value: PropTypes.string.isRequired,
};
