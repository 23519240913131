import styled from 'styled-components';
import * as fromStyled from '../../styles';

export const WrapperForFilter = styled.div`
  display: flex;
  align-items: center; 
  padding: 0 20px;
  height: 66px;
  background: #F3F3F3;
  border-radius: 5px;
  margin-bottom: 10px;
  section {
    margin-left: 10px;
  }
`;

export const WrapperForHistoryModal = styled.div`
  width: 100%;
`;

export const ListWrapperForHistory = styled(fromStyled.List)`
  max-height: 350px;
  padding-right: 15px;
  margin-bottom: 0;
  li {
      padding: 12px 0;
  }
  li:not(:last-child) {
    border-bottom: 1px solid #DADADA; 
  }
`;
