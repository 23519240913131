/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ReactDOM from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
import AlertTemplate from 'react-alert-template-basic';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';

import Support from './Support';
// import '../web/styles/index.css';
// import './styles/fonts.css';
// import './styles/index.css';
// import './styles/workState.css';
// import './styles/login.css';
// import './styles/productivity.css';
// import './styles/media.css';

import  'bootstrap/dist/css/bootstrap.min.css';
// import './index123.css';

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '5px',
  transition: transitions.FADE,
};

export default function SupportApp() {
  return (
    <>
      <AlertProvider template={AlertTemplate} {...options}>
        <Support />
      </AlertProvider>
    </>
  );
}
