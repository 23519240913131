import React     from 'react';
import PropTypes from 'prop-types';
import moment    from 'moment';
import pure      from 'recompose/pure';
import gravatar  from '../../utils/gravatar';

const VacationItem = (props) => {
  const {
    email, name, surname,
    startD, endD, comment,
    available, days, ID, userID,
    onAccept, onReject,
  } = props;
  return (
    <div className="admin-vacations-list-item">
      <div className="section-content">
        <div className="d-flex flex-row">
          <div className="content-icon">
            <img className="rounded-circle" src={gravatar(email, { size: 90 })} alt="icon" />
          </div>
          <div className="vacation-info">
            <div className="vacation-employee">{`${name || ''} ${surname || ''}`}</div>
            <div className="vacation-dates">
              С <b>{moment(startD).format('DD MMMM YYYY, dddd')}</b> на <b>{days} дней</b>
              <p className="light">по {moment(startD).add(days, 'day').format('DD MMMM YYYY, dddd')}</p>
            </div>
            <div>{`Буду ${available === 0 ? 'не' : ''} доступен в это время`}</div>
            <div>Выход на работу: <b>{moment(endD).format('DD MMMM YYYY, dddd')}</b></div>
            <div className="light">{comment}</div>
          </div>
        </div>
        <div className="d-flex control-buttons">
          <button
            type="submit"
            className="btn btn-success"
            onClick={() => { onAccept(ID, userID); }}
          >
            Принять
          </button>
          <button
            type="submit"
            className="btn btn-danger"
            onClick={() => { onReject(ID); }}
          >
            Отклонить
          </button>
        </div>
      </div>
    </div>
  );
};

VacationItem.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  startD: PropTypes.string.isRequired,
  endD: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  ID: PropTypes.number.isRequired,
  userID: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  available: PropTypes.number.isRequired,
  days: PropTypes.number.isRequired,
};

export default pure(VacationItem);
