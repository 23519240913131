import RenewingSubscribe from './components/view/Modal/RenewingSubscribe';
import SendMessage from './components/view/Modal/SendMessage';
import HistoryMessages from './components/view/Modal/HistoryMessages';
import HistorySubscribes from './components/view/Modal/HistorySubscribes';
import HistoryChanges from './components/view/Modal/HistoryChanges';
import DeletedEntries from './components/view/Modal/DeletedEntries';

export const MODAL_WINDOWS = {
  renewingSubscribe: {
    title: 'Продление подписки',
    component: RenewingSubscribe,
  },
  sendMessage: {
    title: 'Отправить сообщение',
    component: SendMessage,
  },
  historyMessages: {
    title: 'История сообщений',
    component: HistoryMessages,
  },
  historySubscribes: {
    title: 'История подписок',
    component: HistorySubscribes,
  },
  historyChanges: {
    title: 'История изменений',
    component: HistoryChanges,
  },
  deletedEntries: {
    title: 'Удалённые записи',
    component: DeletedEntries,
  },
};
