/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';

import withTooltip from '../../../common/withTooltip';

import Sessions from '../Sessions';
import Contacts from '../Contacts';

import AvatarWithIcon from '../../../ui/AvatarWithIcon';
import IdField from '../../../ui/Fields/IdField';
import Field from '../../../ui/Fields';
import ExtraEmailForm from '../../../ui/ExtraEmailForm';
import TextareaCommentForm from '../../../ui/TextareaCommentForm';
import NameSurnameField from '../../../ui/Fields/NameSurnameField';
import SubscribeInfo from '../../../ui/SubscribeInfo';
import RegistrationDate from '../../../ui/RegistrationDate';
import ProgressBar from '../../../ui/ProgressBar';
import Pillow from '../../../ui/Pillows';
import BonusButton from '../../../ui/BonusButton';

import { getDateAndTimeToString } from '../../../../utils/formattingDate';

import Modal from '../../Modal';

export default function UserCard({
  infoAboutUser,
  updateExtraEmail,
  addComment,
  resetCode,
  addBonus,
}) {
  const { userID, userType, createDT, modifyDT,
    personal: { phone, name, surname, email, extraEmail,
      subscrEndDays, supportComment, registeredDays },
    subscription,
    userData: { entries, storage, notebooks,
      trashbin, sessions, contacts } } = infoAboutUser;

  // Форматирование даты последнего изменения к нормальному виду
  const formattedModifyDT = getDateAndTimeToString(modifyDT);

  const historyFromUseHistory = useHistory();
  const alert = useAlert();

  const renderEntriesNotebooksCounts = (title, ...args) => {
    let textsArr = [];
    let placementArr = [];
    switch (title) {
      case 'Записи': {
        textsArr = ['Количество событий', 'Количество заметок', 'Количество общих записей'];
        placementArr = ['left', 'bottom', 'bottom'];
        break;
      }
      case 'Блокноты': {
        textsArr = ['Количество блокнотов (всего)', 'Количество персональных блокнотов', 'Количество общих блокнотов'];
        placementArr = ['bottom', 'bottom', 'right'];
        break;
      }
      case 'Вложения': {
        textsArr = ['Количество записей с вложениями', 'Количество вложений'];
        placementArr = ['bottom', 'right'];
        break;
      }
      default: {
        textsArr = [];
      }
    }
    const pillows = args[0].map((i, ind) => (
      <Pillow
        // eslint-disable-next-line react/no-array-index-key
        key={title + ind}
        content={i}
        size="large"
        color="#C1C1C1"
        tooltip={{
          placement: placementArr[ind],
          text: textsArr[ind],
        }}
      />
    ));
    return (
      <div className="counts">
        <div className="title">
          {title}
          :
        </div>
        {pillows}
      </div>
    );
  };

  const onClickOnSubscribeInfo = () => {
    historyFromUseHistory.push(`modal/renewingSubscribe/${userID}`);
  };

  const onClickOnButtonSendMessage = () => {
    historyFromUseHistory.push(`modal/sendMessage/${userID}`);
  };

  const onClickOnButtonHistoryMessages = () => {
    alert.show(<span style={{ textTransform: 'none' }}>
      Пока не реализовано.
    </span>, { type: 'info' });
    // historyFromUseHistory.push(`modal/historyMessages/${userID}`);
  };

  const onClickOnButtonResetSecurityCode = () => {
    historyFromUseHistory.push(`modal/resetCode/${userID}`);
  };

  const onClickOnButtonHistorySubscribe = () => {
    historyFromUseHistory.push(`modal/historySubscribes/${userID}`);
  };

  const onClickOnButtonHistoryChanges = () => {
    historyFromUseHistory.push(`modal/historyChanges/${userID}`);
  };

  const onClickOnButtonDeletedEntries = () => {
    historyFromUseHistory.push(`modal/deletedEntries/${userID}`);
  };

  const onUpdateExtraEmail = (value) => {
    console.log(`Пользователю с ID: ${userID} добавлен дополнительный email: ${value}`);
    updateExtraEmail(userID, value).then(() => {
      alert.show(<span style={{ textTransform: 'none' }}>Альтернативный email добавлен!</span>, { type: 'success' });
    }).catch(() => {
      alert.show('Альтернативный email не добавлен!', { type: 'error' });
    });
  };

  const onResetCode = () => {
    resetCode(userID)
      .then(() => {
        alert.show(<span style={{ textTransform: 'none' }}>
          Код безопасности сброшен
        </span>, { type: 'success' });
      })
      .catch(() => {
        alert.show('Код безопасности не сброшен!', { type: 'error' });
      });
  };

  const onAddBonus = (type, value) => addBonus(userID, type, value);

  const onAddComment = (comment) => addComment(userID, comment);

  const { history } = subscription;
  const lastHistory = history[history.length - 1];
  const subscrInfo = typeof lastHistory !== 'undefined' ? {
    type: lastHistory.subscrType,
    source: lastHistory.source,
    startDT: lastHistory.purchaseDT,
    endDT: lastHistory.subscrEndDT,
    comment: lastHistory.comment,
  } : {
    type: subscription.type,
    source: subscription.source,
    startDT: subscription.startDT,
    endDT: subscription.endDT,
    comment: subscription.comment,
  };

  return (
    <>
      <Wrapper>
        <Header>
          <div>
            <div className="mainInfo">
              <AvatarWithIcon
                ID={userID}
                phone={phone}
                userType={userType}
                size="large"
              />
              <div className="fields">
                <IdField
                  size="large"
                  content={userID}
                />
                <NameSurnameField
                  size="large"
                  name={name}
                  surname={surname}
                />
                <Field
                  active
                  size="large"
                  content={phone}
                  type="phone"
                  onClick={onClickOnButtonSendMessage}
                />
                <Field
                  active={false}
                  size="large"
                  content={email}
                  type="email"
                  onClick={onClickOnButtonSendMessage}
                />
              </div>
            </div>
            <div className="registrationData">
              <RegistrationDate
                size="middle"
                registeredDays={registeredDays}
                createDT={createDT}
                tooltip={{
                  placement: 'left',
                  text: 'Дней с регистрации',
                }}
              />
            </div>
            <div onClick={onClickOnSubscribeInfo} className="subscibeInfo">
              <SubscribeInfo
                large
                isRenewing={false}
                daysCount={subscrEndDays}
                {...subscrInfo}
              />
            </div>
          </div>
          <div className="actionBlock">
            <div className="leftSide">
              <Button onClick={onClickOnButtonSendMessage}>Отправить сообщение</Button>
              <Button onClick={onClickOnButtonHistoryMessages}>История сообщений</Button>
              <Button onClick={onClickOnButtonResetSecurityCode}>Сбросить код-безоп.</Button>
              <Button onClick={onClickOnButtonHistorySubscribe} style={{ width: '224px', height: '56px' }}>История подписок</Button>
            </div>
            <div className="rightSide">
              <ExtraEmailForm
                extraEmail={extraEmail}
                onBlurExtraEmail={onUpdateExtraEmail}
              />
              <TextareaCommentForm
                comment={supportComment}
                onBlurTextareaCommentForm={onAddComment}
              />
              <div className="rightSide__lastSyncDT">
                {withTooltip(
                  <span>
                    {formattedModifyDT.date}
                    {' '}
                    {formattedModifyDT.time}
                  </span>, 'right', 'Дата и время последнего изменения',
                )}
              </div>
              <Button onClick={onClickOnButtonHistoryChanges}>Журнал изменений</Button>
            </div>
          </div>
        </Header>
        <Middle>
          <div className="entriesBlock">
            <div className="header">
              <ProgressBar
                type="entries"
                filledCount={entries.count}
                totalCount={entries.limit}
              />
              <BonusButton
                type="entries"
                onAddBonus={onAddBonus}
              />
            </div>
            <div className="bottomProgressBar">
              <div className="entriesCounts">
                {renderEntriesNotebooksCounts('Записи', [entries.events, entries.notes, entries.shared])}
              </div>
              <div className="notebookCounts">
                {renderEntriesNotebooksCounts('Блокноты', [notebooks.count, notebooks.personal, notebooks.shared])}
              </div>
              <Button onClick={onClickOnButtonDeletedEntries} style={{ width: '200px' }}>Удалённые записи</Button>
            </div>
          </div>
          <div className="notebooksBlock">
            <div className="header">
              <ProgressBar
                type="storage"
                filledCount={storage.attachments}
                totalCount={storage.limit}
              />
              <BonusButton
                type="storage"
                onAddBonus={onAddBonus}
              />
            </div>
            <div className="bottomProgressBar">
              {renderEntriesNotebooksCounts('Вложения', [entries.entriesWithAttachments, entries.attachments])}
            </div>
          </div>
        </Middle>
        <Footer>
          <div className="sessionsBlock">
            <Sessions sessions={sessions} />
          </div>
          <div className="contactsBlock">
            <Contacts contacts={contacts} />
          </div>
        </Footer>
        <Route
          path="/support/userCard/modal/resetCode/:id"
          render={() => (
            <Modal
              confirmation
              props={{
                onConfirm: onResetCode,
              }}
            />
          )}
        />

      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
    width: 1117px;
    height: 861px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(167, 167, 167, 0.4);
    border-radius: 6px;
`;

// Верхняя информация: аватарка, данные, кнопки
const Header = styled.div`
    padding: 18px 28px 8px 24px;
    display: flex;
    .mainInfo {
        display: flex;
        width: 472px;
        // span {
        //   user-select: text;
        // }
        .fields {
            margin-left: 19px;
            padding-top: 3px;
            display: flex;
            flex-direction: column;
            height: 136px;
            justify-content: space-between;
        }
    }
    .registrationData {
        margin: 16px 0 11px 0;
    }
    .actionBlock {
        margin-left: 30px;
        display: flex;
        padding-bottom: 41px;
        .leftSide {
            display: flex;
            flex-direction: column;
            & :nth-child(2), & :nth-child(3) {
                margin-top: 8px;
            }
            & :nth-child(4) {
                margin-top: auto;
            }
        }
        .rightSide {
            margin-left: 39px;
            .form-control {
                height: 78px;
                margin-top: 13px;
                font-size: 11px;
                line-height: 13px;
                ::placeholder {
                  color: #9E9E9E;
                }
            }
            &__lastSyncDT  {
              display: flex;
              justify-content: flex-end;
              span {
                display: inline-block;
                margin: 12px 0;
                font-size: 14px;
                line-height: 17px;
                color: #898989;
                text-align: right;
              }
            }
            button {
                margin-top: auto;
                height: 56px;
                width: 100%;
            }
        }

    }
`;

const Button = styled.button`
    border: none;
    width: 224px;
    height: 44px;
    background: #F1F1F1;
    border-radius: 8px;
    text-align: center;
    color: #505050;
    font-size: 16px;
    line-height: 19px;
    padding: 0px;
    :hover {
        background: #9C9C9C;
        color: #FFFFFF;
        transition: all 0.2s ease;    
    }
`;

// Информация о записях и вложениях
const Middle = styled.div`
    width: auto;
    height: 154px;
    margin-top: 8px;
    background: #F9F9F9;
    padding: 24px 68px 24px 36px;
    display: flex;
    justify-content: space-between;
    .entriesBlock {
      width: 463px;
    }
    .notebooksBlock {
      width: 521px;

    }
    .header {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .bottomProgressBar {
      display: flex;
      align-items: center;
      margin-top: 27px;
      .counts {
        .title {
          font-size: 10px;
          line-height: 12px;
          color: #AEAEAE;
          margin-bottom: 4px;
        }
        & span:not(:first-of-type) {
          margin-left: 4px;
        }
      }
      .notebookCounts {
        margin-left: 37px;
      }
    }
    .bonusButton, button {
      margin-left: auto;
    }

`;

// Информация о сессиях и контактах
const Footer = styled.div`
    display: flex;
    .sessionsBlock {
      width: 462px;
      height: 341px;
      margin-left: 32px;
      margin-top: 20px;
    }
    .contactsBlock {
      width: 553px;
      height: 341px;
      margin-left: 32px;
      margin-top: 20px;
    }
`;

UserCard.propTypes = {
  infoAboutUser: PropTypes.shape({
    userID: PropTypes.string,
    userType: PropTypes.string,
    createDT: PropTypes.string,
    modifyDT: PropTypes.string,
    personal: PropTypes.shape({
      name: PropTypes.string,
      surname: PropTypes.string,
      email: PropTypes.string,
      extraEmail: PropTypes.string,
      phone: PropTypes.string,
      registeredDays: PropTypes.number,
      subscrEndDays: PropTypes.number,
      supportComment: PropTypes.string,
    }),
    subscription: PropTypes.shape({
      history: PropTypes.arrayOf(PropTypes.shape({
        comment: PropTypes.string,
        source: PropTypes.string,
        subscrType: PropTypes.string,
        purchaseDT: PropTypes.string,
        subscrEndDT: PropTypes.string,
      })),
      startDT: PropTypes.string,
      endDT: PropTypes.string,
      comment: PropTypes.string,
      source: PropTypes.string,
      type: PropTypes.string,
    }),
    userData: PropTypes.shape({
      contacts: PropTypes.shape(),
      entries: PropTypes.shape(),
      notebooks: PropTypes.shape(),
      sessions: PropTypes.shape(),
      storage: PropTypes.shape(),
      trashbin: PropTypes.shape(),
    }),
  }).isRequired,
  updateExtraEmail: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired,
  resetCode: PropTypes.func.isRequired,
  addBonus: PropTypes.func.isRequired,
};
