import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUserDocuments } from '../redux/reducers/documents';
import { updateUserRequest, getUserInfo } from '../redux/actions/userActions';
import { getUser } from '../redux/reducers/user';
import { getDevice } from '../redux/reducers/device';
import { getEmployee } from '../redux/reducers/employees';
import { getFileUrl } from '../redux/api/documentsApi';
import { uploadDocument, removeDocument, updateDocument, getDocumentsList } from '../redux/actions/documentsActions';

import Employee from '../components/personal';

class Container extends React.PureComponent {
  componentWillMount = () => {
    const userID = this.props.user.ID;
    this.props.getDocumentsList(userID);
    this.props.getUserInfo(userID);
  };

  render() {
    return <Employee {...this.props} />;
  }
}

function mapStateToProps(state, props) {
  const employeeID = props.match.params.id;

  return {
    user: state.employees.byId[employeeID],
    userDocuments: getUserDocuments(state.documents, employeeID),
    getFileUrl: ID => getFileUrl(getDevice(state).device, ID),
    isPendingEditDocument: {}, // state.app.isPending.editDocument,
    isPendingUpdateAboutMe: false, // state.app.isPending.updateAboutMe,
    isPendingAddDocument: false, // state.app.isPending.addDocument,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submit: () => {}, // bindActionCreators(updateUserRequest, dispatch),
    uploadDocument: () => {}, // bindActionCreators(uploadDocument, dispatch),
    removeDocument: () => {}, // bindActionCreators(removeDocument, dispatch),
    updateDocument: () => {}, // bindActionCreators(updateDocument, dispatch),
    getDocumentsList: bindActionCreators(getDocumentsList, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
  };
}

const EmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);

export default EmployeeContainer;
