import {
  LOGOUT,
} from '../actions/deviceActions';

import {
  GET_DOCUMENTS_LIST_SUCCESS,
  REMOVE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_SUCCESS,
} from '../actions/documentsActions';

export function getUserDocuments(state, userID) {
  return state[userID] || [];
}

export default function app(state = {}, action = {}) {
  switch (action.type) {
    case GET_DOCUMENTS_LIST_SUCCESS: {
      let newState = { ...state };
      Object.keys(action.data).forEach((ID) => {
        const documents = action.data[ID];
        newState = Object.assign({}, newState, {
          [ID]: documents,
        });
      });
      return newState;
    }
    case REMOVE_DOCUMENT_SUCCESS: {
      const documents = state[action.data.ownerID].filter(doc => (
        doc.ID !== action.data.ID
      ));
      return Object.assign({}, state, {
        [action.data.ownerID]: documents,
      });
    }
    case UPDATE_DOCUMENT_SUCCESS: {
      let documents = state[action.data.ownerID];
      let index = 0;
      let isExist = false;
      while (index < documents.length && !isExist) {
        if (documents[index].ID === action.data.ID) isExist = true;
        index++;
      }
      if (isExist) {
        documents = [
          ...documents.slice(0, index - 1),
          action.data,
          ...documents.slice(index),
        ];
        return Object.assign({}, state, {
          [action.data.ownerID]: documents,
        });
      }
      return state;
    }
    case UPLOAD_DOCUMENT_SUCCESS: {
      let documents = state[action.data.ownerID];
      if (typeof documents !== 'undefined') {
        let i = 0;
        let isExist = false;
        while (i < documents.length && !isExist) {
          if (documents[i].ID === action.data.ID) isExist = true;
          i++;
        }
        if (isExist) {
          documents = [
            ...documents.slice(0, i - 1),
            action.data,
            ...documents.slice(i),
          ];
        } else {
          documents = [action.data].concat(documents);
        }
      } else {
        documents = [action.data];
      }
      return Object.assign({}, state, {
        [action.data.ownerID]: documents,
      });
    }
    case LOGOUT: {
      return {};
    }
    default:
      return state;
  }
}
