import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';

export default function TextareaCommentForm({
  comment,
  onBlurTextareaCommentForm,
}) {
  const [value, setValue] = useState(comment);
  useEffect(() => setValue(comment), [comment]);
  const alert = useAlert();

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    if (value.trim() === '' || comment === value) return;
    onBlurTextareaCommentForm(value).then(() => {
      alert.show(<span style={{ textTransform: 'none' }}>Комментарий добавлен!</span>, { type: 'success' });
    }).catch(() => {
      setTimeout(() => setValue(''), 400);
      alert.show('Комментарий не добавлен!', { type: 'error' });
    });
  };

  return (
    <textarea
      value={value}
      className="form-control"
      placeholder="Комментарий (Support)"
      id="floatingTextarea2"
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}

TextareaCommentForm.propTypes = {
  comment: PropTypes.string,
  onBlurTextareaCommentForm: PropTypes.func.isRequired,
};

TextareaCommentForm.defaultProps = {
  comment: '',
};
