import axios    from 'axios';
import signatureLib from '../../utils/signature';

const defaultConfig = {
  schema: 'http://',
  host: 'api.dev.soft-artel.com',
  port: 8080,
  ver: 'v1',
};

class BORequest {

  constructor(config = {}, device = {}, logger) {
    this.api = {};
    Object.assign(this.api, defaultConfig, config);
    this.logger = logger;
    this.device = device;
    this.api.url = `${this.api.schema}${this.api.host}:${this.api.port}/${this.api.ver}`;
    this.srvTsDelta = 0;
  }

  fileUrl(params = {}) {
    const srvTS = this.srvTS();
    const cmd = 'files';
    let url = `${this.api.url}/${cmd}`;
    const headers = {
      d: this.device.ID,
      t: srvTS,
      s: signatureLib.make(cmd, srvTS, this.device.geo, this.device.ID, this.device.devToken),
    };
    const qsObject = Object.assign({}, params, headers);

    Object.keys(qsObject).forEach((param, index) => {
      const value = qsObject[param];
      url += `${index ? '&' : '?'}${param}=${value}`;
    });

    return url;
  }

  requestUrl(cmd, params = {}) {
    const srvTS = this.srvTS();
    let url = `${this.api.url}/${cmd}`;
    const headers = {
      d: this.device.ID,
      t: srvTS,
      s: signatureLib.make(cmd, srvTS, this.device.geo, this.device.ID, this.device.devToken),
    };
    const qsObject = Object.assign({}, params, headers);

    Object.keys(qsObject).forEach((param, index) => {
      const value = qsObject[param];
      url += `${index ? '&' : '?'}${param}=${value}`;
    });

    return url;
  }

  cardUrl(params = {}) {
    const srvTS = this.srvTS();
    const cmd = 'user/vcard';
    let url = `${this.api.url}/${cmd}`;
    const headers = {
      d: this.device.ID,
      t: srvTS,
      s: signatureLib.make(cmd, srvTS, this.device.geo, this.device.ID, this.device.devToken),
    };
    const qsObject = Object.assign({}, params, headers);

    Object.keys(qsObject).forEach((param, index) => {
      const value = qsObject[param];
      url += `${index ? '&' : '?'}${param}=${value}`;
    });

    return url;
  }

  * do(method, cmd, params = {}, body = {}) {
    const logger = this.logger;
    logger.dbg(`==> ${cmd}`, params, logger.LVL.DBGPRC);

    const srvTS = this.srvTS();

    const headers = {
      d: this.device.ID,
      t: srvTS,
      s: signatureLib.make(cmd, srvTS, this.device.geo, this.device.ID, this.device.devToken),
    };

    let url = `${this.api.url}/${cmd}`;
    const qsObject = Object.assign({}, params, headers);

    Object.keys(qsObject).forEach((param, index) => {
        const value = qsObject[param];
        url += `${index ? '&' : '?'}${param}=${value}`;
    });

    const options = {
      url,
      method,
      data: JSON.stringify(body),
    };

    // FIXME: надо сделать возможность устанавливать хедеры извне
    if(method === 'POST') {
      options.headers = {
        "content-type": 'application/json',
      }
      console.log('post request');
      console.log(options);
    }

    logger.dbg(`----> ${method}' '${options.url}`, params, logger.LVL.DBG);

    if (body) {
      logger.dbg('POST BODY', body, logger.LVL.DATA);
    }

    let resp;

    try {
      resp = yield axios(options);
      logger.dbg(`<---- HTTP ${resp.status}`, null, logger.LVL.DBG);

      let body = resp.data;
      try {
        body = JSON.parse(body);
        logger.dbg('BODY', body, logger.LVL.DATA);
      } catch (e) {
        logger.warn(body, null);
      }

      if (resp.status !== 200) {
        this.processError(resp.status, body);
      }

      return { status: resp.status, body };
    } catch (err) {
      err.params = Object.assign(options, err.params);
      return err.response;
    }
  }

  processError(code, body) {
    const err = new Error(`HTTP ${code}: ${body.data || body}`);
    err.code = code;
    err.params = { status: `HTTP ${code}`, responce: body };
    throw err;
  }

  * get(cmd, params) {
    return yield this.do('GET', cmd, params);
  }

  * post(cmd, params, body) {
    return yield this.do('POST', cmd, params, body);
  }

  srvTS() {
    return (Date.now() / 1000) + this.srvTsDelta;
  }

}

export default BORequest;
