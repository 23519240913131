import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Select from '../../ui/Select';

import { languages } from '../../../utils/optionsForSelect';

export default function TextareaForModal({
  renewing,
  height,
  width,
  value,
  placeholder,
  patterns,
  selectedPattern,
  selectedLanguage,
  onChangeValue,
  onSelectLanguage,
  onSelectPattern,
}) {
  const onChange = (e) => {
    onChangeValue(e.target.value);
  };

  const onChangeLanguage = (language) => {
    onSelectLanguage(language);
  };

  const onAddPattern = (pattern) => {
    onSelectPattern(pattern);
  };

  return (
    <Wrapper width={width} renewing={renewing} height={height}>
      <textarea onChange={onChange} value={value} className="form-control" placeholder={placeholder} id="floatingTextarea2" />
      <div className="patternsBlock">
        {
          !renewing
             && (
               <div className="patternsBlock__language">
                 <Select
                   language
                   size="small"
                   options={languages}
                   selectedOption={selectedLanguage || languages[0]}
                   onSelectOption={onChangeLanguage}
                 />
               </div>
             )
        }
        <div className="patternsBlock__patterns">
          <Select
            patterns
            size="small"
            options={patterns}
            selectedOption={selectedPattern}
            onSelectOption={onAddPattern}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    .form-control {
            width: ${({ width }) => width}px;
            height: ${({ height }) => height}px;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            :focus {
              color: #000000;
            }
            ::placeholder {
                font-size: 14px;
                line-height: 17px;
                color: #C6C6C6;
            }
    }
    .patternsBlock {
        display: flex;
        justify-content: ${({ renewing }) => renewing ? 'flex-end' : 'space-between'};
        align-items: center;

    }
`;

TextareaForModal.propTypes = {
  renewing: PropTypes.bool,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  patterns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedPattern: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.oneOf(['null']),
  ]),
  selectedLanguage: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.oneOf(['null']),
  ]),
  onChangeValue: PropTypes.func.isRequired,
  onSelectLanguage: PropTypes.func,
  onSelectPattern: PropTypes.func.isRequired,
};

TextareaForModal.defaultProps = {
  renewing: false,
  value: '',
  placeholder: '',
  selectedPattern: null,
  selectedLanguage: null,
  onSelectLanguage: () => {},
};
