import React     from 'react';
import PropTypes from 'prop-types';
import IssueItem from './IssueItem';
import helpers   from '../../utils/viewHelpers';

const SprintIssues = (props) => {
  const {
    doneIssues, fixedIssues, waitIssues, setQccIssues, iterId, isAdmin,
  } = props;
  const done = doneIssues.map(issue => (
    <IssueItem key={issue.issueKey} issue={issue} setQccIssues={setQccIssues} iterId={iterId} isAdmin={isAdmin} />
  ));
  const fixed = fixedIssues.map(issue => (
    <IssueItem key={issue.issueKey} issue={issue} setQccIssues={setQccIssues} iterId={iterId} isAdmin={isAdmin} />
  ));
  const waiting = waitIssues.map(issue => (
    <IssueItem key={issue.issueKey} issue={issue} setQccIssues={setQccIssues} iterId={iterId} isAdmin={isAdmin} />
  ));
  return (
    <div className="sprint-issues">
      <div className="section waiting">
        <div className="section-header">
          <div className="badge btn-danger">{waitIssues.length}</div>
          Wait to work issues:
        </div>
        <div className="section-content">
          {waiting}
        </div>
      </div>

      <div className="section done">
        <div className="section-header">
          <div className="badge badge-primary">{doneIssues.length}</div>
          Done tasks:
        </div>
        <div className="section-content">
          {done}
        </div>
      </div>

      <div className="section fixed">
        <div className="section-header">
          <div className="badge badge-warning">{fixedIssues.length}</div>
          Fixed errors:
        </div>
        <div className="section-content">
          {fixed}
        </div>
      </div>
    </div>
  );
};

SprintIssues.propTypes = {
  doneIssues: PropTypes.arrayOf(PropTypes.object).isRequired,
  fixedIssues: PropTypes.arrayOf(PropTypes.object).isRequired,
  waitIssues: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  iterId: PropTypes.number.isRequired,
  setQccIssues: PropTypes.func.isRequired,
};

export default SprintIssues;
