import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export default function TypeMessage({
  type,
}) {
  let title;
  switch (type) {
    case 'email': {
      title = 'Email';
      break;
    }
    case 'emailSupport': {
      title = 'EmailS';
      break;
    }
    default: {
      title = type?.toUpperCase();
    }
  }
  return (
    <Wrapper type={type}>{title}</Wrapper>
  );
}

const Wrapper = styled.div`
    width: 33px;
    height: 30px;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 10px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    ${({ type }) => {
    switch (type) {
      case 'push': {
        return 'background: #2EA8E5;';
      }
      case 'sms': {
        return 'background: #4CAD00;';
      }
      case 'email':
      case 'emailSupport': {
        return `background: #818181;
                font-size: 10px;
                line-height: 20px;`;
      }
      default: {
        return 'background: #2EA8E5;';
      }
    }
  }} 
`;

TypeMessage.propTypes = {
  type: PropTypes.oneOf(['push', 'sms', 'email']).isRequired,
};
