/* eslint-disable*/
Object.defineProperties( String, {
  generateGUID : {
    value : function () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, function ( c ) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r & 0x3 | 0x8 );
        return v.toString( 16 );
      } );
    },
    writable : false,
    enumerable : false
  },
  hexToGuid : {
    value : function ( hex ) {
      let s =  hex.toString( 'hex' );
      return s.substr( 0, 8 )+'-'+s.substr( 8, 4 )+'-'+s.substr( 12, 4 )+'-'+s.substr( 16, 4 )+'-'+s.substr( 20, 12 );
    },
    enumerable : false,
    writable : false
  },
  dateFromTS : {
    value : function ( ts = -1 ) {
      let date = ts != -1?  new Date( ts <= 10000000000? ts*1000 :ts  ) : new Date();
      let str  = date.toISOString().replace('T',' ').replace('Z','');
      str = str.substr(0, 19);
      return str;
    },
    writable : false,
    enumerable : false
  },

  humanizeMsDuration : {
    value : function ( ms, short=false, timeCompKeys=['d', 'h','m','s','ms' ] ) {

      let timeComp = {};

      timeComp.s  = Math.floor( ms / 1000 );
      timeComp.m  = Math.floor( timeComp.s / 60 );
      timeComp.h  = Math.floor( timeComp.m / 60 );
      timeComp.d  = Math.floor( timeComp.h / 24 );

      timeComp.ms = ms % 1000;
      timeComp.s  = timeComp.s % 60;

      if( timeComp.m > 0) timeComp.ms = 0;

      if(timeComp.s > 0 && timeComp.ms > 0){
        timeComp.s  += (Math.floor(timeComp.ms / 10) / 100);
        timeComp.ms = 0;
      }

      timeComp.m  = timeComp.m % 60;
      timeComp.h  = timeComp.h % 24;

      let notZerroCmp = 0;
      let humStr ='';

      timeCompKeys.forEach( key => {

        if( timeComp[ key ] >0 && ( !short || notZerroCmp<2 ) ){
          notZerroCmp ++;
          humStr+=timeComp[ key ]+key+' ';
        }

      });

      humStr = humStr.slice(0, humStr.length>1? humStr.length-1: 0 );

      return humStr;

    },
    writable : false,
    enumerable : false
  },

  humanizeDuration : {
    value : function ( sec, short=false, timeCompKeys=['d', 'h','m','s' ] ) {
      return String.humanizeMsDuration( sec*1000, short, timeCompKeys );
    },
    writable : false,
    enumerable : false
  },

  humanizeTime : {
    value : function ( seconds = 0, isMs = false ) {

      seconds = isMs ? Math.floor( seconds / 1000 ) : seconds;

      let m = Math.floor( Math.floor( seconds / 30 ) / 2 ) % 60,
        h = Math.floor( seconds / 60 / 60 ) ;

      return `${ h < 10 ? '0' + h : h }:${ m < 10 ? '0' + m : m }`

    },
    writable : false,
    enumerable : false
  }


} );


Object.defineProperties( String.prototype, {
  guidToHex : {
    value : function (  ) {
      return new Buffer( this.replace( /-/gi , '').toString('hex') , "hex");
    },
    enumerable : false,
    writable : false
  },

  toNumber : {
    value : function (  ) {
      return isNaN( this * 1 )? this : this * 1;
    },
    enumerable : false,
    writable : false
  },

  replaceAll : {
    value : function ( search, replace ) {

      var target = this;
      return target.replace(new RegExp( search.escapeRegExp() , 'g'), replace);

    },
    enumerable : false,
    writable : false
  },

  escapeRegExp : {
    value : function (  ) {
      return this.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");;
    },
    enumerable : false,
    writable : false
  },


  md5 : {
    value : ( function () {
      var hex_chr = '0123456789abcdef'.split( '' );
      function md5cycle ( x, k ) {
        var a = x[ 0 ], b = x[ 1 ], c = x[ 2 ], d = x[ 3 ], a32 = add32;
        a = ff( a, b, c, d, k[ 0 ],  7,  -680876936  );
        d = ff( d, a, b, c, k[ 1 ],  12, -389564586  );
        c = ff( c, d, a, b, k[ 2 ],  17,  606105819  );
        b = ff( b, c, d, a, k[ 3 ],  22, -1044525330 );
        a = ff( a, b, c, d, k[ 4 ],  7,  -176418897  );
        d = ff( d, a, b, c, k[ 5 ],  12,  1200080426 );
        c = ff( c, d, a, b, k[ 6 ],  17, -1473231341 );
        b = ff( b, c, d, a, k[ 7 ],  22, -45705983   );
        a = ff( a, b, c, d, k[ 8 ],  7,   1770035416 );
        d = ff( d, a, b, c, k[ 9 ],  12, -1958414417 );
        c = ff( c, d, a, b, k[ 10 ], 17, -42063      );
        b = ff( b, c, d, a, k[ 11 ], 22, -1990404162 );
        a = ff( a, b, c, d, k[ 12 ], 7,   1804603682 );
        d = ff( d, a, b, c, k[ 13 ], 12, -40341101   );
        c = ff( c, d, a, b, k[ 14 ], 17, -1502002290 );
        b = ff( b, c, d, a, k[ 15 ], 22,  1236535329 );
        a = gg( a, b, c, d, k[ 1 ],  5,  -165796510  );
        d = gg( d, a, b, c, k[ 6 ],  9,  -1069501632 );
        c = gg( c, d, a, b, k[ 11 ], 14,  643717713  );
        b = gg( b, c, d, a, k[ 0 ],  20, -373897302  );
        a = gg( a, b, c, d, k[ 5 ],  5,  -701558691  );
        d = gg( d, a, b, c, k[ 10 ], 9,   38016083   );
        c = gg( c, d, a, b, k[ 15 ], 14, -660478335  );
        b = gg( b, c, d, a, k[ 4 ],  20, -405537848  );
        a = gg( a, b, c, d, k[ 9 ],  5,   568446438  );
        d = gg( d, a, b, c, k[ 14 ], 9,  -1019803690 );
        c = gg( c, d, a, b, k[ 3 ],  14, -187363961  );
        b = gg( b, c, d, a, k[ 8 ],  20,  1163531501 );
        a = gg( a, b, c, d, k[ 13 ], 5,  -1444681467 );
        d = gg( d, a, b, c, k[ 2 ],  9,  -51403784   );
        c = gg( c, d, a, b, k[ 7 ],  14,  1735328473 );
        b = gg( b, c, d, a, k[ 12 ], 20, -1926607734 );
        a = hh( a, b, c, d, k[ 5 ],  4,  -378558     );
        d = hh( d, a, b, c, k[ 8 ],  11, -2022574463 );
        c = hh( c, d, a, b, k[ 11 ], 16,  1839030562 );
        b = hh( b, c, d, a, k[ 14 ], 23, -35309556   );
        a = hh( a, b, c, d, k[ 1 ],  4,  -1530992060 );
        d = hh( d, a, b, c, k[ 4 ],  11,  1272893353 );
        c = hh( c, d, a, b, k[ 7 ],  16, -155497632  );
        b = hh( b, c, d, a, k[ 10 ], 23, -1094730640 );
        a = hh( a, b, c, d, k[ 13 ], 4,   681279174  );
        d = hh( d, a, b, c, k[ 0 ],  11, -358537222  );
        c = hh( c, d, a, b, k[ 3 ],  16, -722521979  );
        b = hh( b, c, d, a, k[ 6 ],  23,  76029189   );
        a = hh( a, b, c, d, k[ 9 ],  4,  -640364487  );
        d = hh( d, a, b, c, k[ 12 ], 11, -421815835  );
        c = hh( c, d, a, b, k[ 15 ], 16,  530742520  );
        b = hh( b, c, d, a, k[ 2 ],  23, -995338651  );
        a = ii( a, b, c, d, k[ 0 ],  6,  -198630844  );
        d = ii( d, a, b, c, k[ 7 ],  10,  1126891415 );
        c = ii( c, d, a, b, k[ 14 ], 15, -1416354905 );
        b = ii( b, c, d, a, k[ 5 ],  21, -57434055   );
        a = ii( a, b, c, d, k[ 12 ], 6,   1700485571 );
        d = ii( d, a, b, c, k[ 3 ],  10, -1894986606 );
        c = ii( c, d, a, b, k[ 10 ], 15, -1051523    );
        b = ii( b, c, d, a, k[ 1 ],  21, -2054922799 );
        a = ii( a, b, c, d, k[ 8 ],  6,   1873313359 );
        d = ii( d, a, b, c, k[ 15 ], 10, -30611744   );
        c = ii( c, d, a, b, k[ 6 ],  15, -1560198380 );
        b = ii( b, c, d, a, k[ 13 ], 21,  1309151649 );
        a = ii( a, b, c, d, k[ 4 ],  6,  -145523070  );
        d = ii( d, a, b, c, k[ 11 ], 10, -1120210379 );
        c = ii( c, d, a, b, k[ 2 ],  15,  718787259  );
        b = ii( b, c, d, a, k[ 9 ],  21, -343485551  );
        x[ 0 ] = a32( a, x[ 0 ] );
        x[ 1 ] = a32( b, x[ 1 ] );
        x[ 2 ] = a32( c, x[ 2 ] );
        x[ 3 ] = a32( d, x[ 3 ] );
      }
      function cmn ( q, a, b, x, s, t ) {
        var a32 = add32;
        a = a32( a32( a, q ), a32( x, t ) );
        return a32( ( a << s ) | ( a >>> ( 32 - s ) ), b );
      }
      function ff ( a, b, c, d, x, s, t ) {
        return cmn( ( b & c ) | ( ( ~b ) & d ), a, b, x, s, t );
      }
      function gg ( a, b, c, d, x, s, t ) {
        return cmn( ( b & d ) | ( c & ( ~d ) ), a, b, x, s, t );
      }
      function hh ( a, b, c, d, x, s, t ) {
        return cmn( b ^ c ^ d, a, b, x, s, t );
      }
      function ii ( a, b, c, d, x, s, t ) {
        return cmn( c ^ ( b | ( ~d ) ), a, b, x, s, t );
      }
      function md51 ( s ) {
        var txt = '', n = s.length, state = [ 1732584193, -271733879, -1732584194, 271733878 ], tail = [ 0,0,0,0, 0,0,0,0, 0,0,0,0, 0,0,0,0 ], md5c = md5cycle, md5b = md5blk, i;
        for ( i = 64; i <= s.length; i += 64 ) md5c( state, md5b( s.substring( i - 64, i ) ) );
        s = s.substring( i - 64 );
        for ( i = 0; i < s.length; i++ ) tail[ i >> 2 ] |= s.charCodeAt( i ) << ( ( i % 4 ) << 3 );
        tail[ i >> 2 ] |= 0x80 << ( ( i % 4 ) << 3 );
        if ( i > 55 ) {
          md5c( state, tail );
          for ( i = 0; i < 16; i++ ) tail[ i ] = 0;
        }
        tail[ 14 ] = n * 8;
        md5c( state, tail );
        return state;
      }

      /* there needs to be support for Unicode here,
       * unless we pretend that we can redefine the MD-5
       * algorithm for multi-byte characters (perhaps
       * by adding every four 16-bit characters and
       * shortening the sum to 32 bits). Otherwise
       * I suggest performing MD-5 as if every character
       * was two bytes--e.g., 0040 0025 = @%--but then
       * how will an ordinary MD-5 sum be matched?
       * There is no way to standardize text to something
       * like UTF-8 before transformation; speed cost is
       * utterly prohibitive. The JavaScript standard
       * itself needs to look at this: it should start
       * providing access to strings as preformed UTF-8
       * 8-bit unsigned value arrays.
       */
      function md5blk ( s ) { /* I figured global was faster.   */
        var md5blks = [], i; /* Andy King said do it this way. */
        for ( i = 0; i < 64; i += 4 ) md5blks[ i >> 2 ] = s.charCodeAt( i ) + ( s.charCodeAt( i + 1 ) << 8 ) + ( s.charCodeAt( i + 2 ) << 16 ) + ( s.charCodeAt( i + 3 ) << 24 );
        return md5blks;
      }

      function rhex ( n ) {
        var s = '', hc = hex_chr, j;
        for ( j = 0; j < 4; j++ ) s += hc[ ( n >> ( j * 8 + 4 ) ) & 0x0F ] + hc[ ( n >> ( j * 8 ) ) & 0x0F ];
        return s;
      }

      /* this function is much faster,
       so if possible we use it. Some IEs
       are the only ones I know of that
       need the idiotic second function,
       generated by an if clause.  */
      function add32 ( a, b ) {
        return ( a + b ) & 0xFFFFFFFF;
      }

      return function () {
        var x = md51( this), rh = rhex, i;
        for ( i = x.length; i--; ) x[ i ] = rh( x[ i ] );
        return x.join( '' );
      }
    } )(),
    enumerable : false,
    writable : false
  },
  ucFirst : {
    value : function () {
      return this.length ? this.charAt( 0 ).toUpperCase() + this.slice( 1 ) : this;
    },
    enumerable : false,
    writable : false
  },
  stripTags : {
    value : function () {
      return this.replace( /<.*?>/g, '' );
    },
    enumerable : false,
    writable : false
  },
  reverse : {
    value : function () {
      return this.split( "" ).reverse().join( "" );
    },
    enumerable : false,
    writable : false
  },
  splice : {
    value : function ( index, count, replace ) {
      return this.slice( 0, index ) + ( replace || "" ) + this.slice( index + count );
    },
    enumerable : false,
    writable : false
  },
  explode : {
    value : function ( delimeter, limit ) {
      var s = this.split( delimeter ), ret = s.slice( 0, limit - 1 );
      ret[ ret.length ] = s.slice( limit - 1 ).join( delimeter );
      return ret;
    },
    enumerable : false,
    writable : false
  }
} );

//dt, min, hour, weekDay, date, month, weekYear
Object.defineProperties( Date.prototype, {

  getWeekYear: {
    value: function () {
      // Copy date so don't modify original
      let d = new Date(+this);
      d.setHours(0,0,0,0);
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setDate(d.getDate() + 4 - (d.getDay()||7));
      // Get first day of year
      let yearStart = new Date(d.getFullYear(),0,1);
      // Calculate full weeks to nearest Thursday
      return Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    },
    enumerable : false,
    writable : false
  },

  dayOffset: {
    value: function ( ms = false ) {

      let startDay = this.toISOString().slice(0,10)+"T00:00:00.001Z";
      startDay = Date.parse( startDay );
      let offset = this - startDay;

      if( !ms ) offset = Math.ceil( offset/1000 );

      return offset;

    },
    enumerable : false,
    writable : false
  },

  dateComponents: {
    value: function () {
      return {
        datetime	: this.toISOString(),
        dayOffset	: this.dayOffset(),
        min			: this.getMinutes(),
        hour		: this.getHours(),
        weekDay		: this.getDay(),
        dateKey		: +this.toISOString().slice(0,10).split('-').join(''),
        monthKey	: +this.toISOString().slice(0,7).split('-').join(''),
        weekYear	: this.getWeekYear()
      }
    },
    enumerable : false,
    writable : false
  },


  toDtString: {
    value: function () {

      let str  = this.toISOString().replace('T',' ').replace('Z','');
      str = str.substr(0, 19);

      return str;
    },
    enumerable : false,
    writable : false
  },

  toDString: {
    value: function () {

      let str  = this.toISOString().substr(0, 10);
      str = str.substr(0, 19);

      return str;
    },
    enumerable : false,
    writable : false
  },


} );

Object.defineProperties( Number, {
  dateComponents: {
    value: function ( date ) {
      date = new Date( date );	 	//так можно будет передавать строку, unix ts, объект даты
      return date.dateComponents();
    }
  },
  randomBetween: {
    value: function ( min, max ) {
      return Math.round( Math.random() * ( max - min ) + min )
    }
  }
});

Object.defineProperties( Object, {
  copy: {
    value: function ( obj ) {
      let objCopy = {};
      for(let key of Object.keys(obj)){
        if (Object.prototype.toString.call(obj[key]) === '[object Object]') {
          objCopy[key] = Object.copy(obj[key])
        } else {
          objCopy[key] = obj[key]
        }
      }
      return objCopy;
    }
  },

  merge: {
    value: function ( sourceObj ) {

      if( arguments.length === 0 ) return {};
      if( arguments.length === 1 ) return arguments[ 0 ];

      let resObject = Object.copy( arguments[ 0 ] );

      for ( let i = 1; i < arguments.length; i++) {
        resObject.mergeFrom( arguments[ i ] );
      }

      return resObject;
    }
  }

});

Object.defineProperties( Object.prototype, {

  pickKeys: {
    value: function ( keys ) {

      if( !Array.isArray( keys) ) return {};

      let obj = {};

      keys.forEach( key => {
        obj[ key ] = this[ key ];
      });

      return obj;
    },
    enumerable : false,
    writable : true
  },

  mergeFrom: {
    value: function ( sourceObj, allKeys = true ) {

      if(!sourceObj) return undefined;
      if( typeof sourceObj !== 'object' ) return sourceObj;

      // console.log("\nMERGE");
      // console.log( sourceObj );
      // console.log("\n => \n");
      // console.log( this );

      let keys = Object.keys( this );

      let sourceKeys = Object.keys( sourceObj );

      keys.forEach( key => {

        if( sourceObj[key] !== undefined ){
          this[ key ] = sourceObj[ key ];
          sourceKeys.delete( key );
        }

      });

      if( !allKeys || sourceKeys.length === 0 ) return Object.copy( this );

      sourceKeys.forEach( key => {
        this[ key ] = sourceObj[ key ];
      });

      // console.log("\nRESULT");
      // console.log( this );

      return Object.copy( this );
    },
    enumerable : false,
    writable : true
  }

} );



Object.defineProperties( Array.prototype, {

  randomElement: {
    value: function () {
      return this[ Math.round( Math.random() * (this.length - 1) ) ]
    },
    enumerable : false,
    writable : false
  },

  delete: {
    value: function ( elm ) {

      let index = this.indexOf( elm );
      if( index == -1 ){
        return this;
      }

      this.splice(index, 1);

      return this;
    },
    enumerable : false,
    writable : false
  }

} );

