import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import gravatar from '../../../../utils/gravatar';
import withTooltip from '../../../common/withTooltip';

import DateTime from '../../../ui/DateTime';
import TypeActions from '../../../ui/TypeActions';

import getDateToString from '../../../../utils/formattingDate';
import { declOfNum } from '../../../../utils/helpers';

export default function ChangeListItem({
  item,
}) {
  const {
    ID,
    action,
    createDT,
    meta,
    supportMail,
    supportName,
    userID,
  } = item;
  let text;
  let valueInParentheses;

  switch (action) {
    case 'addingExtraEmail': {
      text = meta.newEmail;
      valueInParentheses = 'дополнительный электронный адрес';
      break;
    }
    case 'addingComment': {
      text = `“${meta.comment}”`;
      valueInParentheses = 'комментарий';
      break;
    }
    case 'resetingSecurityCode': {
      text = 'Сброс кода безопасности';
      break;
    }
    case 'recoveryEntries': {
      text = `${meta.entries} ${declOfNum(meta.entries, ['запись', 'записи', 'записей'])}`;
      valueInParentheses = `удаленные ${getDateToString(meta.from)}-${getDateToString(meta.to)}`;
      break;
    }
    case 'renewingSubscription': {
      text = meta.type && meta.type.toUpperCase();
      valueInParentheses = 'продление';
      break;
    }
    case 'acceptingIncomingContact': {
      text = meta.contactName;
      valueInParentheses = 'входящий запрос';
      break;
    }
    case 'acceptingOutgoingContact': {
      text = meta.contactName;
      valueInParentheses = 'исходящий запрос';
      break;
    }
    case 'unblockedContact':
    case 'unblockingBlockedContact': {
      text = meta.contactName;
      valueInParentheses = 'заблокированный';
      break;
    }
    case 'unblockingFromTheBlockedContact': {
      text = meta.contactName;
      valueInParentheses = 'заблокировавший';
      break;
    }
    case 'closingSession': {
      text = meta.deviceName;
      break;
    }
    case 'addingBonus': {
      if (typeof meta.entries !== 'undefined') {
        text = `${meta.entries} записей`;
      }
      if (typeof meta.storage !== 'undefined') {
        text = `${meta.storage} Мb`;
      }
      valueInParentheses = 'бонус';
      break;
    }
    default: {
      text = null;
      valueInParentheses = null;
    }
  }
  if (valueInParentheses) valueInParentheses = `(${valueInParentheses})`;
  return (
    <Wrapper>
      <div className="changeListItem">
        <div className="changeListItem__date">
          <DateTime DT={createDT} />
        </div>
        <div className="changeListItem__type">
          <TypeActions type={action !== 'addingBonus' ? action : typeof meta.entries === 'undefined' ? 'addingStorage' : 'addingEntries'} />
        </div>
        <div className="changeListItem__content">
          <span>
            {text}
            {' '}
          </span>
          <span>{valueInParentheses}</span>
        </div>
        {withTooltip(
          <Avatar
            email={supportMail}
            size={{ size: 100 }}
            className="changeListItem__avatar"
          />,
          'right',
          supportName,
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.li`
  width: 546px;
  .changeListItem {
    display: flex;
    align-items: center;
    &__date {
      color: #696969;
    }
    &__type {
      margin: 0 18px 0 20px;
    }
    &__content {
      font-size: 12px;
      line-height: 14px;
      span:nth-of-type(1) {
        color: #000000;
      }
      span:nth-of-type(2) {
        color: rgba(0,0,0,0.5);
      }
    }
    &__avatar {
      
  }
}`;

const Avatar = styled.div`
      margin: 0 10px 0 auto;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-image: url(${({ email, size }) => gravatar(email, size)});
      background-repeat: no-repeat;
      background-size: cover;
`;

ChangeListItem.propTypes = {
  item: PropTypes.shape({
    ID: PropTypes.number,
    action: PropTypes.oneOf([
      'addingExtraEmail',
      'addingComment',
      'resetingSecurityCode',
      'recoveryEntries',
      'renewingSubscription',
      'acceptingIncomingContact',
      'acceptingOutgoingContact',
      'unblockedContact',
      'unblockingBlockedContact',
      'unblockingFromTheBlockedContact',
      'closingSession',
      'addingBonus',
    ]),
    createDT: PropTypes.string,
    meta: PropTypes.shape(),
    supportMail: PropTypes.string,
    supportName: PropTypes.string,
    userID: PropTypes.string,
  }).isRequired,
};
