export const GET_ITERATION = 'GET_ITERATION';
export const GET_ITERATION_REQUEST = 'GET_ITERATION_REQUEST';
export const GET_ITERATION_SUCCESS = 'GET_ITERATION_SUCCESS';
export const GET_ITERATION_FAILURE = 'GET_ITERATION_FAILURE';

export function getIteration(id = '', date = '', userID = 'self') {
  return {
    type: GET_ITERATION,
    id,
    date,
    userID,
  };
}
export function getIterationRequest(data) {
  return {
    type: GET_ITERATION_REQUEST,
    data,
  };
}
export function getIterationSuccess(data) {
  return {
    type: GET_ITERATION_SUCCESS,
    data,
  };
}
export function getIterationFailure(err) {
  return {
    type: GET_ITERATION_FAILURE,
    err,
  };
}

export const GET_ALL_ITERATIONS = 'GET_ALL_ITERATIONS';
export const GET_ALL_ITERATIONS_REQUEST = 'GET_ALL_ITERATIONS_REQUEST';
export const GET_ALL_ITERATIONS_SUCCESS = 'GET_ALL_ITERATIONS_SUCCESS';
export const GET_ALL_ITERATIONS_FAILURE = 'GET_ALL_ITERATIONS_FAILURE';

export function getAllIterations(iterType = 'all') {
  return {
    type: GET_ALL_ITERATIONS,
    iterType,
  };
}
export function getAllIterationsRequest() {
  return {
    type: GET_ALL_ITERATIONS_REQUEST,
  };
}
export function getAllIterationsSuccess(data) {
  return {
    type: GET_ALL_ITERATIONS_SUCCESS,
    data,
  };
}
export function getAllIterationsFailure(err) {
  return {
    type: GET_ALL_ITERATIONS_FAILURE,
    err,
  };
}

export const GET_ITERATION_LIST_BY_USER = 'GET_ITERATION_LIST_BY_USER';
export const GET_ITERATION_LIST_BY_USER_REQUEST = 'GET_ITERATION_LIST_BY_USER_REQUEST';
export const GET_ITERATION_LIST_BY_USER_SUCCESS = 'GET_ITERATION_LIST_BY_USER_SUCCESS';
export const GET_ITERATION_LIST_BY_USER_FAILURE = 'GET_ITERATION_LIST_BY_USER_FAILURE';

export function getIterationList(userID = 'self') {
  return {
    type: GET_ITERATION_LIST_BY_USER,
    userID,
  };
}
export function getIterationListRequest(data) {
  return {
    type: GET_ITERATION_LIST_BY_USER_REQUEST,
    data,
  };
}
export function getIterationListSuccess(data) {
  return {
    type: GET_ITERATION_LIST_BY_USER_SUCCESS,
    data,
  };
}
export function getIterationListFailure(err) {
  return {
    type: GET_ITERATION_LIST_BY_USER_FAILURE,
    err,
  };
}

export const GET_ITERATION_FOR_ALL_USERS = 'GET_ITERATION_FOR_ALL_USERS';
export const GET_ITERATION_FOR_ALL_USERS_REQUEST = 'GET_ITERATION_FOR_ALL_USERS_REQUEST';
export const GET_ITERATION_FOR_ALL_USERS_SUCCESS = 'GET_ITERATION_FOR_ALL_USERS_SUCCESS';
export const GET_ITERATION_FOR_ALL_USERS_FAILURE = 'GET_ITERATION_FOR_ALL_USERS_FAILURE';

export function getIterationForAllUsers(id, date = '') {
  return {
    type: GET_ITERATION_FOR_ALL_USERS,
    id,
    date,
  };
}
export function getIterationForAllUsersRequest(data) {
  return {
    type: GET_ITERATION_FOR_ALL_USERS_REQUEST,
    data,
  };
}
export function getIterationForAllUsersSuccess(data) {
  return {
    type: GET_ITERATION_FOR_ALL_USERS_SUCCESS,
    data,
  };
}
export function getIterationForAllUsersFailure(err) {
  return {
    type: GET_ITERATION_FOR_ALL_USERS_FAILURE,
    err,
  };
}

export const SET_QCC_ITERATION = 'SET_QCC_ITERATION';
export const SET_QCC_ITERATION_REQUEST = 'SET_QCC_ITERATION_REQUEST';
export const SET_QCC_ITERATION_SUCCESS = 'SET_QCC_ITERATION_SUCCESS';
export const SET_QCC_ITERATION_FAILURE = 'SET_QCC_ITERATION_FAILURE';

export function setQccIteration(id, date = '', userID = 'self', qcc = 1, qcNote = '') {
  return {
    type: SET_QCC_ITERATION,
    id,
    date,
    userID,
    qcc,
    qcNote,
  };
}
export function setQccIterationRequest() {
  return {
    type: SET_QCC_ITERATION_REQUEST,
  };
}
export function setQccIterationSuccess() {
  return {
    type: SET_QCC_ITERATION_SUCCESS,
  };
}
export function setQccIterationFailure(err) {
  return {
    type: SET_QCC_ITERATION_FAILURE,
    err,
  };
}

export const RECALCULATE_ITERATION_REQUEST = 'RECALCULATE_ITERATION_REQUEST';
export const RECALCULATE_ITERATION_SUCCESS = 'RECALCULATE_ITERATION_SUCCESS';
export const RECALCULATE_ITERATION_FAILURE = 'RECALCULATE_ITERATION_FAILURE';

export const recalculateIterationRequest = id => ({
  type: RECALCULATE_ITERATION_REQUEST,
  id,
});
export const recalculateIterationSuccess = () => ({
  type: RECALCULATE_ITERATION_SUCCESS,
});
export const recalculateIterationFailure = err => ({
  type: RECALCULATE_ITERATION_FAILURE,
  err,
});
