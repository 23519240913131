import React from 'react';
import PropTypes from 'prop-types';
import WorkLogDay from '../productivity/WorkLogDay';

const Worklogs = (props) => {
  const { icons, summaries, deleteWorklog, workLogs, ids, isPending } = props;
  const workLogsList = workLogs.map(log => (
    <WorkLogDay
      key={`workLog#${log.ID}#${log.day}`}
      workedIssues={log.workLogs}
      day={log.day}
      startHr={log.fromTime}
      endHr={log.toTime}
      icons={icons}
      summaries={summaries}
      ids={ids}
      workedSec={log.workedSec}
      deleteWorklog={deleteWorklog}
      isPending={isPending}
    />
  ));
  return (
    <div className="sprint-worklogs">
      <div className="section">
        <div className="section-header">Work Logs:</div>
        <div className="section-content">{workLogsList}</div>
      </div>
    </div>
  );
};

Worklogs.propTypes = {
  deleteWorklog: PropTypes.func.isRequired,
  workLogs: PropTypes.arrayOf(PropTypes.object).isRequired,
  icons: PropTypes.shape().isRequired,
  summaries: PropTypes.shape().isRequired,
  isPending: PropTypes.objectOf(PropTypes.bool).isRequired,
};

export default Worklogs;
