import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getDateAndTimeToString } from '../../../utils/formattingDate';

const Wrapper = styled.div`
  min-width: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 10px;
  line-height: 12px;
  .time {
    font-weight: 700; 
  }
  ${({ large }) => large
    ? `width: auto;
       color: 2C3E50;
      .date {
      font-size: 12px;
      line-height: 14px;
      }
      .time {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
      }
    ` : ''
}
`;

export default function DateTime({
  large,
  DT,
}) {
  const { date, time } = getDateAndTimeToString(DT);
  return (
    <Wrapper large={large}>
      <div className="date">{date}</div>
      <div className="time">{time}</div>
    </Wrapper>
  );
}

DateTime.propTypes = {
  large: PropTypes.bool,
  DT: PropTypes.string.isRequired,
};

DateTime.defaultProps = {
  large: false,
};
