import React                from 'react';
import PropTypes            from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }          from 'react-redux';
import { getEmployee }      from '../redux/reducers/employees';
import HospitalsComponent   from '../components/vacations/Hospitals';
import VacationsComponent   from '../components/vacations/Vacations';
import EmployeeItem         from '../components/adminVacations/EmployeeItem';
import { getVacationsById, getStatByID } from '../redux/reducers/vacations';
import {
  getHospitalsById,
  getStatByID as getHospitalStatByID,
} from '../redux/reducers/hospitals';
import {
  updateHospital,
  updateVacation,
} from '../redux/actions/hospitalsActions';

const noop = () => {};

const AdminPersonalVacation = (props) => {
  const {
    email, name, surname, position, ID, stat, hospitalStat, vacations, hospitals, device,
  } = props;
  return (
    <div className="container vacations">
      <EmployeeItem
        email={email}
        name={name}
        surname={surname}
        position={position}
        ID={ID}
        stat={stat}
        hospitalStat={hospitalStat}
        styles={{ width: '100%', margin: '20px 0' }}
      />
      <div className="row">
        <VacationsComponent
          userID={ID}
          getVacationList={noop}
          updateVacation={props.updateVacation}
          removeVacation={noop}
          vacations={vacations}
          vacationDays={stat.vacationDays}
          device={device}
          editable={false}
        />
        <HospitalsComponent
          userID={ID}
          getHospitalList={noop}
          updateHospital={props.updateHospital}
          removeHospital={noop}
          uploadHospital={noop}
          switchHospital={noop}
          hospitals={hospitals}
          hospitalDocID=""
          editable={false}
        />
      </div>
    </div>
  );
};

AdminPersonalVacation.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  ID: PropTypes.string.isRequired,
  stat: PropTypes.shape().isRequired,
  hospitalStat: PropTypes.shape().isRequired,
  hospitals: PropTypes.shape().isRequired,
  vacations: PropTypes.shape().isRequired,
  updateHospital: PropTypes.func.isRequired,
  updateVacation: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  const emplID = props.match.params.id;
  const user = getEmployee(state.employees.byId, emplID);
  return {
    device: state.device,
    ID: user.ID,
    email: user.email,
    name: user.name,
    surname: user.surname,
    position: user.position,
    stat: getStatByID(state.vacations, user.ID),
    hospitalStat: getHospitalStatByID(state.hospitals.byUserId, user.ID),
    vacations: getVacationsById(state.vacations, emplID),
    hospitals: getHospitalsById(state.hospitals.byUserId, emplID),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateHospital: bindActionCreators(updateHospital, dispatch),
    updateVacation: bindActionCreators(updateVacation, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminPersonalVacation);
