import { combineReducers } from 'redux';
import {
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_FAILURE,
  GET_USER_LIST_SUCCESS,
  GET_USER_INFO_SUCCESS,
  UPDATE_USER_SUCCESS,
} from '../actions/userActions';
import { LOGOUT } from '../actions/deviceActions';

const byId = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_LIST_SUCCESS: {
      const newState = {};
      action.data.forEach((empl) => {
        newState[empl.ID] = empl;
      });
      return newState;
    }
    case LOGOUT: {
      return {};
    }
    case GET_USER_INFO_SUCCESS:
    case UPDATE_USER_SUCCESS: {
      const empl = Object.assign({}, state[action.data.ID], action.data);
      return Object.assign({}, state, { [action.data.ID]: empl });
    }
    default:
      return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case GET_USER_LIST_SUCCESS: {
      const newState = [];
      action.data.forEach((empl) => {
        newState.push(empl.ID);
      });
      return newState;
    }
    case LOGOUT: {
      return [];
    }
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case LOGOUT:
    case GET_USER_LIST_SUCCESS:
    case GET_USER_LIST_FAILURE: {
      return false;
    }
    case GET_USER_LIST_REQUEST: {
      return true;
    }
    default:
      return state;
  }
};

export const getActiveEmployeesCount = (employees, workstates) => {
  let count = 0;
  for (const id in employees.byId) {
    if (workstates[id] && workstates[id].state === 10) {
      count++;
    }
  }
  return count;
};

export const getIds = state => state.ids;
export const getIsFetching = state => state.isFetching;
export const getEmployee = (state, id) => state[id];
export const getAllEmployees = (state) => {
  const emplIds = getIds(state);
  return emplIds.map(id => getEmployee(state.byId, id));
};

const byDepartment = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_LIST_SUCCESS: {
      const nextState = {};
      action.data.forEach((empl) => {
        let department;
        switch (empl.department) {
          case 'Developers Team': {
            department = 'Developers';
            break;
          }
          case 'QA And Support Team': {
            department = 'QA, Admins, UIX';
            break;
          }
          default: {
            department = 'QA, Admins, UIX';
          }
        }
        if (nextState[department]) {
          if (nextState[department].ids.indexOf(empl.ID) === -1) {
            nextState[department].ids.push(empl.ID);
          }
        } else {
          nextState[department] = {
            title: department,
            ids: [empl.ID],
          };
        }
      });
      return nextState;
    }
    case LOGOUT: {
      return {};
    }
    default:
      return state;
  }
};

export const getDepartment = (state, department, workstates, visible = 'visible') => {
  const emplIds = state.byDepartment[department] ? state.byDepartment[department].ids : [];
  const active  = [];
  const employees = [];
  emplIds.forEach((id) => {
    const empl = getEmployee(state.byId, id);
    if (visible === 'all') {
      if (workstates[empl.ID] && workstates[empl.ID].state === 10) {
        active.push(empl.ID);
      }
      employees.push(empl);
    } else {
      if (empl.visible === 1) {
        if (workstates[empl.ID] && workstates[empl.ID].state === 10) {
          active.push(empl.ID);
        }
        employees.push(empl);
      }
    }
  });
  return {
    title: department,
    employees,
    active,
  };
};

export default combineReducers({
  byId,
  ids,
  byDepartment,
  isFetching,
});
