import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import icons from '../../../utils/icons';

const Wrapper = styled.div`
    position: relative;
    font-size: 10px;
    line-height: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
    ${({ status }) => {
    switch (status) {
      case '4001':
      case '4002':
      case '4003': {
        return 'color: #B6B6B6;';
      }
      case '4005': {
        return 'color: #FF4545;';
      }
      case '4006': {
        return 'color: #FF9501;';
      }
      default: {
        return 'color: #4CAD00';
      }
    }
  }} 
`;

export default function StatusForHistoryMessage({
  status,
}) {
  let icon = null;
  let title;
  if (status === '4005') {
    icon = icons.status4005;
    title = status;
  } else if (status === '4006') {
    icon = icons.status4006;
    title = status;
  } else if (status == null || status === '') {
    title = 'ОТПРАВЛЕНО';
  } else {
    title = status;
  }
  return (
    <Wrapper status={status}>
      {icon}
      <span>{title}</span>
    </Wrapper>
  );
}

StatusForHistoryMessage.propTypes = {
  status: PropTypes.string.isRequired,
};
