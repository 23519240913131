import React from 'react';
import { Switch, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UserCard from './UserCard';

import ArrowBackIcon from '../../../images/arrow-back.svg';

import Preloader from '../../common/Preloader';
import renderRoutingModalWindows from '../../common/RoutingModalWindows';

export default function MainPage({
  selectedUser,
  loadingUserCard,
  updateExtraEmail,
  addComment,
  resetCode,
  addBonus,
}) {
  const history = useHistory();

  const onClickBackWrapper = () => {
    history.push('/support');
  };
  return (
    <>
      { loadingUserCard
        ? <Preloader />
        : (
          <Wrapper>
            <BackWrapper onClick={onClickBackWrapper}>
              <img src={ArrowBackIcon} alt="back" />
              <span>Назад</span>
            </BackWrapper>
            <UserCard
              infoAboutUser={selectedUser}
              updateExtraEmail={updateExtraEmail}
              addComment={addComment}
              resetCode={resetCode}
              addBonus={addBonus}
            />
            <Switch>
              {renderRoutingModalWindows(selectedUser)}
            </Switch>
          </Wrapper>
        )}
    </>
  );
}

const Wrapper = styled.div`
    margin-top: 28px;
`;

const BackWrapper = styled.div`
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 36px;
    display: inline-block;
    cursor: pointer;
    img {
        vertical-align: unset;
        margin-right: 8px;
    }
`;

MainPage.propTypes = {
  selectedUser: PropTypes.shape().isRequired,
  loadingUserCard: PropTypes.bool.isRequired,
  updateExtraEmail: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired,
  resetCode: PropTypes.func.isRequired,
  addBonus: PropTypes.func.isRequired,
};
