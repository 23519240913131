export const RESET_JUST_LOGIN = 'RESET_JUST_LOGIN';

export function resetJustLogin() {
  return {
    type: RESET_JUST_LOGIN,
  };
}

export const REDIRECT = 'REDIRECT';

export function redirect() {
  return {
    type: REDIRECT,
  };
}

export const SET_URI = 'SET_URI';

export function setUri() {
  return {
    type: SET_URI,
  };
}
