import { take, fork, all, put, select, call } from 'redux-saga/effects';
import {
  fetchIteration,
  fetchIterationForAllUsers,
  fetchAllIterations,
  fetchIterationListByUser,
  setQccIteration,
  recalculateIteration,
} from '../api/iterationApi';
import {
  GET_ITERATION,
  getIterationRequest,
  getIterationSuccess,
  getIterationFailure,
  GET_ITERATION_FOR_ALL_USERS,
  getIterationForAllUsersRequest,
  getIterationForAllUsersSuccess,
  getIterationForAllUsersFailure,
  GET_ALL_ITERATIONS,
  getAllIterationsRequest,
  getAllIterationsSuccess,
  getAllIterationsFailure,
  GET_ITERATION_LIST_BY_USER,
  getIterationList,
  getIterationListRequest,
  getIterationListSuccess,
  getIterationListFailure,
  SET_QCC_ITERATION,
  setQccIterationRequest,
  setQccIterationSuccess,
  setQccIterationFailure,
  RECALCULATE_ITERATION_REQUEST,
  recalculateIterationFailure,
  recalculateIterationSuccess,
} from '../../redux/actions/iterationActions';
import { getDevice } from '../../redux/reducers/device';

export function* getIterationApiRequest(id, date, userID) {
  yield put(getIterationRequest({ id, userID }));
  const device = yield select(getDevice);
  const response = yield call(fetchIteration, device.device, id, date, userID);
  if (response.status === 200) {
    yield put(getIterationSuccess(response.body.data));
    yield put(getIterationList(response.body.data.ID));
  } else {
    yield put(getIterationFailure());
  }
}

function* watchGetIteration() {
  while (true) {
    const { id, date, userID } = yield take(GET_ITERATION);
    yield fork(getIterationApiRequest, id, date, userID);
  }
}

export function* getIterationForAllUsersApiRequest(id, date, userID) {
  yield put(getIterationForAllUsersRequest([{ iterId: id }]));
  const device = yield select(getDevice);
  const response = yield call(fetchIterationForAllUsers, device.device, id, date, userID);
  if (response.status === 200) {
    yield put(getIterationForAllUsersSuccess(response.body.data));
  } else {
    yield put(getIterationForAllUsersFailure());
  }
}

function* watchGetIterationForAllUsers() {
  while (true) {
    const { id, date, userID } = yield take(GET_ITERATION_FOR_ALL_USERS);
    yield fork(getIterationForAllUsersApiRequest, id, date, userID);
  }
}

export function* getAllIterationsApiRequest(iterType) {
  yield put(getAllIterationsRequest());
  const device = yield select(getDevice);
  const response = yield call(fetchAllIterations, device.device, iterType);
  if (response.status === 200) {
    yield put(getAllIterationsSuccess(response.body.data));
  } else {
    yield put(getAllIterationsFailure());
  }
}

function* watchGetAllIterations() {
  while (true) {
    const { iterType } = yield take(GET_ALL_ITERATIONS);
    yield fork(getAllIterationsApiRequest, iterType);
  }
}

export function* getIterationListApiRequest(userID) {
  yield put(getIterationListRequest({ userID }));
  const device = yield select(getDevice);
  const response = yield call(fetchIterationListByUser, device.device, userID);
  if (response.status === 200) {
    yield put(getIterationListSuccess(response.body.data));
  } else {
    yield put(getIterationListFailure());
  }
}

function* watchGetIterationList() {
  while (true) {
    const { userID } = yield take(GET_ITERATION_LIST_BY_USER);
    yield fork(getIterationListApiRequest, userID);
  }
}

export function* setQccIterationApiRequest(id, date, userID, qcc, qcNote) {
  yield put(setQccIterationRequest());
  const device = yield select(getDevice);
  const response = yield call(setQccIteration, device.device, id, date, userID, qcc, qcNote);
  if (response.status === 200) {
    yield put(setQccIterationSuccess(response.body.data));
  } else {
    yield put(setQccIterationFailure());
  }
}

function* watchSetQccIteration() {
  while (true) {
    const { id, date, userID, qcc, qcNote } = yield take(SET_QCC_ITERATION);
    yield fork(setQccIterationApiRequest, id, date, userID, qcc, qcNote);
  }
}

export function* recalculateIterationApiRequest(id) {
  const device = yield select(getDevice);
  const response = yield call(recalculateIteration, device.device, id);
  if (response.status === 200) {
    yield put(recalculateIterationSuccess(response.body.data));
  } else {
    yield put(recalculateIterationFailure());
  }
}

function* watchRecalculateIteration() {
  while (true) {
    const { id } = yield take(RECALCULATE_ITERATION_REQUEST);
    yield fork(recalculateIterationApiRequest, id);
  }
}

export default function* iterationSaga() {
  yield all([
    fork(watchGetIteration),
    fork(watchGetIterationForAllUsers),
    fork(watchGetAllIterations),
    fork(watchGetIterationList),
    fork(watchSetQccIteration),
    fork(watchRecalculateIteration),
  ]);
}
