import { take, fork, all, put, select, call } from 'redux-saga/effects';
import { getDocumentsList, removeDocument, updateDocument, uploadDocument } from '../api/documentsApi';
import {
  GET_DOCUMENTS_LIST,
  getDocumentsListRequest,
  getDocumentsListSuccess,
  getDocumentsListFailure,
  UPLOAD_DOCUMENT,
  uploadDocumentRequest,
  uploadDocumentSuccess,
  uploadDocumentFailure,
  REMOVE_DOCUMENT,
  removeDocumentRequest,
  removeDocumentSuccess,
  removeDocumentFailure,
  UPDATE_DOCUMENT,
  updateDocumentRequest,
  updateDocumentSuccess,
  updateDocumentFailure,
} from '../../redux/actions/documentsActions';
import { getDevice } from '../../redux/reducers/device';

export function* getDocumentsListApiRequest(user, search, type) {
  yield put(getDocumentsListRequest());
  const device = yield select(getDevice);
  const response = yield call(getDocumentsList, device.device, user, search, type);
  if (response.status === 200) {
    yield put(getDocumentsListSuccess(response.body.data));
  } else {
    yield put(getDocumentsListFailure());
  }
}

export function* uploadDocumentApiRequest(file, type, desc, ID, isNew) {
  yield put(uploadDocumentRequest());
  const device = yield select(getDevice);
  const response = yield call(uploadDocument, device.device, file, type, desc, ID);
  if (response.status === 201) {
    yield put(uploadDocumentSuccess(response.data.data, ID, isNew));
  } else {
    yield put(uploadDocumentFailure(null, ID, isNew));
  }
}

export function* removeDocumentApiRequest(ID) {
  yield put(removeDocumentRequest());
  const device = yield select(getDevice);
  const response = yield call(removeDocument, device.device, ID);
  if (response.status === 200) {
    yield put(removeDocumentSuccess(response.body.data, ID));
  } else {
    yield put(removeDocumentFailure(null, ID));
  }
}

export function* updateDocumentApiRequest(doc) {
  yield put(updateDocumentRequest());
  const device = yield select(getDevice);
  const response = yield call(updateDocument, device.device, doc);
  if (response.status === 200) {
    yield put(updateDocumentSuccess(response.body.data, doc.ID));
  } else {
    yield put(updateDocumentFailure(null, doc.ID));
  }
}

function* watchGetDocumentsList() {
  while (true) {
    const { user, search, docType } = yield take(GET_DOCUMENTS_LIST);
    yield fork(getDocumentsListApiRequest, user, search, docType);
  }
}

function* watchUploadDocument() {
  while (true) {
    const { file, docType, desc, ID, isNew } = yield take(UPLOAD_DOCUMENT);
    yield fork(uploadDocumentApiRequest, file, docType, desc, ID, isNew);
  }
}

function* watchRemoveDocument() {
  while (true) {
    const { ID } = yield take(REMOVE_DOCUMENT);
    yield fork(removeDocumentApiRequest, ID);
  }
}

function* watchUpdateDocument() {
  while (true) {
    const { doc } = yield take(UPDATE_DOCUMENT);
    yield fork(updateDocumentApiRequest, doc);
  }
}

export default function* documentsSaga() {
  yield all([
    fork(watchGetDocumentsList),
    fork(watchUploadDocument),
    fork(watchRemoveDocument),
    fork(watchUpdateDocument),
  ]);
}
