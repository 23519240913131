import React                  from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon }               from 'react-fa';
import PropTypes              from 'prop-types';
import logo                   from '../images/soft-artel.png';
import CountrySelect          from '../components/CountrySelect';
import { signInDevice, codeDevice } from '../redux/actions/deviceActions';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      code: '',
      selectedCountry: 'Россия (+7)',
      countryCode: '7',
    };

    this.renderCodeInput  = this.renderCodeInput.bind(this);
    this.renderPhoneInput = this.renderPhoneInput.bind(this);
    this.renderError      = this.renderError.bind(this);
    this.onCountryChange  = this.onCountryChange.bind(this);
  }

  onCountryChange(selectedCountry) {
    const countryCodeNumberRegex = /(\d+)/;
    const countryCode = selectedCountry.match(countryCodeNumberRegex)[0];
    this.setState({ selectedCountry, countryCode });
  }

  changeState(ev, field) {
    this.setState({
      [field]: ev.target.value,
    });
    if (field === 'phone' && ev.target.value.length === 10) {
      this.props.codeDevice(this.state.countryCode + ev.target.value);
    }
    if (field === 'code' && ev.target.value.length === 4) {
      this.props.signInDevice(this.state.countryCode + this.state.phone, ev.target.value);
    }
  }

  handleKeyPress(ev, field) {
    if (ev.key === 'Enter') {
      if (field === 'phone') {
        if (this.state.phone.length) {
          this.props.codeDevice(this.state.countryCode + this.state.phone);
        }
      } else if (field === 'code') {
        if (this.state.phone.length && this.state.code.length) {
          this.props.signInDevice(this.state.countryCode + this.state.phone, this.state.code);
        }
      }
    }
  }

  renderPhoneInput() {
    return (
      <div className="phone-input input-group">
        <div className="phone-number">
          <div className="country-select">
            <span className="country-selected accentLabel">{this.state.selectedCountry}</span>
            <span className="chevron accentLabel">▾</span>
            <CountrySelect onCountryChange={this.onCountryChange} />
          </div>
          <span className="input-group-addon" >
            <Icon name="phone" />
          </span>
          <div className="phone-input">
            <input
              type="number"
              placeholder="Phone"
              className="form-control"
              value={this.state.phone}
              onKeyPress={ev => this.handleKeyPress(ev, 'phone')}
              onChange={ev => this.changeState(ev, 'phone')}
            />
          </div>
        </div>
        { this.props.login.isPending
        &&
        <span className="input-after" >
          <div className="spinner center">
            <div className="spinner-blade" />
            <div className="spinner-blade" />
            <div className="spinner-blade" />
            <div className="spinner-blade" />
            <div className="spinner-blade" />
            <div className="spinner-blade" />
            <div className="spinner-blade" />
            <div className="spinner-blade" />
            <div className="spinner-blade" />
            <div className="spinner-blade" />
            <div className="spinner-blade" />
            <div className="spinner-blade" />
          </div>
        </span>
        }
      </div>
    );
  }

  renderCodeInput() {
    if (this.props.login.state === 'code') {
      return (
        <div className="code-input input-group">
          <span className="input-group-addon" >
            <Icon name="asterisk" />
          </span>
          <input
            type="number"
            className="form-control"
            placeholder="SMS-Code"
            value={this.state.code}
            onKeyPress={ev => this.handleKeyPress(ev, 'code')}
            onChange={ev => this.changeState(ev, 'code')}
            autoFocus
          />
          <span className="input-after" >
            <Icon name="refresh" />
          </span>
        </div>
      );
    }
    return '';
  }

  renderError() {
    if (this.props.login.err) {
      return (
        <div className="error-message alert alert-danger" role="alert">
          { this.props.login.err }
        </div>
      );
    }
    return '';
  }

  render() {
    const isError = this.props.login.err ? 'errored' : '';
    const isCode  = this.props.login.state === 'code' ? 'code' : '';

    return (
      <div className="login-page-wrap">
        <div className={`login-page d-flex flex-row ${isCode} ${isError}`}>
          <div className="d-flex flex-column align-items-center p-2">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div>
              <div className="software-development">
                <div>Software</div>
                <div>Development</div>
              </div>
              <div className="link">
                <a href="http://soft-artel.com/">www.soft-artel.com</a>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column p-2">
            <div className="soft-artel">
              Soft-Artel
            </div>
            <div className="backoffice">
              BackOffice
            </div>

            { this.renderPhoneInput() }
            { this.renderCodeInput() }
            { this.renderError() }

          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.shape({
    state: PropTypes.string,
    err: PropTypes.string,
    isPending: PropTypes.bool,
  }).isRequired,
  codeDevice: PropTypes.func.isRequired,
  signInDevice: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    login: state.app.login,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signInDevice: bindActionCreators(signInDevice, dispatch),
    codeDevice: bindActionCreators(codeDevice, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
