import styled from 'styled-components';
import controlsImage from '../../images/control.png';

export const AttachControlsWrapper = styled.ul`
  display: none;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  background-color: rgba(0, 0, 0, 0.66);
  z-index: 10;
  margin: 0;
  padding: 0;
`;

export const AttachControl = styled.li`
  display: inline-block;
  margin: 50px 10px;
  width: ${props => (props['data-action'] === 'full' ? 20 : 15)}px;
  height: 20px;
  background-image: url(${controlsImage});
  background-repeat: no-repeat;
  background-size: 50px auto;
  cursor: pointer;
  opacity: 0.5;
  :hover {
    opacity: 0.9;
  }
  background-position: ${props => {
  switch (props['data-action']) {
    case 'export': {
      return 'left';
    }
    case 'trash': {
      return 'right';
    }
    case 'full': {
      return 'center';
    }
    default: {
      return 'center';
    }
  }
}};
`;

export const Thumbnail = styled.div`
  box-sizing: border-box;
  height: 15vh;
  position: relative;
  line-height: 0;
  overflow: hidden;
  :hover {
    ${AttachControlsWrapper} {
      display: flex;
    }
  }
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  box-shadow: inset 0 0 0 0.5px white;
`;
