import { take, fork, all, put, select, call } from 'redux-saga/effects';
import { fetchUserListWithWorkState, postUser, fetchUserInfo, signUpUser } from '../api/userApi';
import {
  GET_USER_LIST,
  GET_USER_INFO,
  UPDATE_USER_REQUEST,
  SIGNUP_USER_REQUEST,
  getUserListRequest,
  getUserListSuccess,
  getUserListFailure,
  getUserInfoRequest,
  getUserInfoSuccess,
  getUserInfoFailure,
  updateUserSuccess,
  updateUserFailure,
  signupUserSuccess,
  signupUserFailure,
} from '../../redux/actions/userActions';
import { getAllWorkstate } from '../../redux/actions/workstatesActions';
import { getDevice } from '../../redux/reducers/device';
import { getUser, getUserForm, getPersonalInfoForm, getNewUserForm } from '../../redux/reducers/user';

export function* getUserListWithWorkstate() {
  yield put(getUserListRequest());
  const device = yield select(getDevice);
  yield device.update();
  const response = yield call(fetchUserListWithWorkState, device.device);
  if (response.status === 200) {
    yield put(getUserListSuccess(response.body.data));
    yield put(getAllWorkstate());
  } else {
    yield put(getUserListFailure());
  }
}

export function* getUserInfo(userID) {
  yield put(getUserInfoRequest(userID));
  const device = yield select(getDevice);
  yield device.update();
  const response = yield call(fetchUserInfo, device.device, userID);
  if (response.status === 200) {
    yield put(getUserInfoSuccess(response.body.data, userID));
  } else {
    yield put(getUserInfoFailure());
  }
}

export function* updateUser() {
  const device = yield select(getDevice);
  const user = yield select(getUser);
  const userForm = yield select(getUserForm);
  const personalInfoForm = yield select(getPersonalInfoForm);
  personalInfoForm.kids = personalInfoForm.kids ? '1' : '';
  personalInfoForm.personalCar = personalInfoForm.personalCar ? '1' : '';
  const personalInfoFormForServer = {};
  for (const name in personalInfoForm) {
    if (name === 'personalCar' || name === 'kids') {
      personalInfoFormForServer[name] = personalInfoForm[name] ? '1' : '0';
    } else {
      personalInfoFormForServer[name] = personalInfoForm[name];
    }
  }
  const newUser = yield call(Object.assign, {}, user.__initFields, userForm, {
    personalJSON: JSON.stringify(personalInfoFormForServer),
  });
  const response = yield call(postUser, device.device, newUser);
  if (response.status === 200) {
    yield put(updateUserSuccess(response.body.data));
  } else {
    yield put(updateUserFailure());
  }
}

export function* signupUser() {
  const device = yield select(getDevice);
  const userForm = yield select(getNewUserForm);
  userForm.award = +userForm.award;
  userForm.salary = +userForm.salary;
  const response = yield call(signUpUser, device.device, userForm);
  if (response.status === 200) {
    yield put(signupUserSuccess(response.body.data));
  } else {
    yield put(signupUserFailure());
  }
}

function* watchGetUserList() {
  while (true) {
    yield take(GET_USER_LIST);
    yield fork(getUserListWithWorkstate);
  }
}

function* watchGetUserInfo() {
  while (true) {
    const { userID } = yield take(GET_USER_INFO);
    yield fork(getUserInfo, userID);
  }
}

function* watchUpdateUser() {
  while (true) {
    yield take(UPDATE_USER_REQUEST);
    yield fork(updateUser);
  }
}

function* watchSignupUser() {
  while (true) {
    yield take(SIGNUP_USER_REQUEST);
    yield fork(signupUser);
  }
}

export default function* userSaga() {
  yield all([fork(watchGetUserList), fork(watchGetUserInfo), fork(watchUpdateUser), fork(watchSignupUser)]);
}
