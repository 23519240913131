/* eslint-disable no-mixed-operators */
import {
  GET_FEATURES_SUCCESS,
} from '../actions/employeesActions';
import {
  GET_WAITING_ISSUES_SUCCESS,
} from '../actions/issuesActions';

export const getIssue = (state, key) => state.byKey[key];

export const getFeatures = (state, login) => {
  const issuesKeys = state[login] && state[login].features || [];
  return issuesKeys.map(key => getIssue(state[login], key));
};

export const getWaitingIssues = (state, login) => {
  const issuesKeys = state[login] && state[login].waiting || [];
  return issuesKeys.map(key => getIssue(state[login], key));
};

export default function issuesReducer(state = {}, action = {}) {
  switch (action.type) {
    case GET_FEATURES_SUCCESS: {
      const newState = { ...state };
      for (const emplID in action.data) {
        if (emplID !== 'undefined' && action.data.hasOwnProperty(emplID)) {
          if (typeof newState[emplID] === 'undefined') {
            newState[emplID] = {
              waiting: [],
              features: [],
              byKey: {},
            };
          }
          const features = action.data[emplID];
          const featuresKeys = features.map((issue) => {
            newState[emplID].byKey[issue.issueKey] = issue;
            return issue.issueKey;
          });
          newState[emplID].features = featuresKeys;
        }
      }
      return newState;
    }
    case 'LOGOUT': {
      return {};
    }
    case GET_WAITING_ISSUES_SUCCESS: {
      const newState = { ...state };
      for (const emplID in action.data) {
        if (emplID !== 'undefined' && action.data.hasOwnProperty(emplID)) {
          if (typeof newState[emplID] === 'undefined') {
            newState[emplID] = {
              waiting: [],
              features: [],
              byKey: {},
            };
          }
          const waiting = action.data[emplID];
          const waitingKeys = waiting.map((issue) => {
            newState[emplID].byKey[issue.issueKey] = issue;
            return issue.issueKey;
          });
          newState[emplID].waiting = waitingKeys;
        }
      }
      return newState;
    }
    default:
      return state;
  }
}
