import React, { useReducer, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useAlert } from 'react-alert';
import Spinner from 'react-bootstrap/Spinner';
import * as fromStyles from '../../styles';
import Context from '../../common/Context';
import { ReactComponent as CalendarIcon } from '../../../images/calendarIcon.svg';
import { formattingDateToUTC } from '../../../utils/formattingDate';

import DateFilter from '../../ui/DateFilter/DateFilter';

const spinner = <Spinner animation="border" size="sm" />;

const SET_IS_LOADING = 'SET_IS_LOADING';
const SET_IS_LOADING_TRASH_INFO_PERIOD = 'SET_IS_LOADING_TRASH_INFO_PERIOD';
const SET_IS_LOADING_TRASH_INFO = 'SET_IS_LOADING_TRASH_INFO';
const SET_IS_FORM_VALIDATED = 'SET_IS_FORM_VALIDATED';
const IS_SELECT_RADIO_BUTTON_DATES = 'IS_SELECT_RADIO_BUTTON_DATES';
const SELECT_DAYS = 'SELECT_DAYS';
const CHANGE_DATE_RANGE = 'CHANGE_DATE_RANGE';
const TRASH_INFO = 'TRASH_INFO';

const initialState = {
  isLoadingTrashInfo: true,
  isLoadingTrashInfoPeriod: false,
  isLoading: false,
  isFormValidate: true,
  isSelectRadioButtonDates: false,
  selectedDays: 0,
  dateRange: {
    from: undefined,
    to: undefined,
  },
  trashInfo: {
    deletedToday: null,
    deletedInAMonth: null,
    deletedInAWeek: null,
    forSpecifiedPeriod: null,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case SET_IS_LOADING_TRASH_INFO:
      return {
        ...state,
        isLoadingTrashInfo: action.value,
      };
    case SET_IS_LOADING_TRASH_INFO_PERIOD:
      return {
        ...state,
        isLoadingTrashInfoPeriod: action.value,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.value,
      };
    case SET_IS_FORM_VALIDATED:
      return {
        ...state,
        isFormValidate: action.value,
      };
    case CHANGE_DATE_RANGE: {
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          [action.toOrFrom]: action.value,
        },
      };
    }
    case SELECT_DAYS:
      return {
        ...state,
        selectedDays: Number(action.value),
        isSelectRadioButtonDates: action.isSelectRadioButtonDates,
        dateRange: {
          from: undefined,
          to: undefined,
        },
      };
    case TRASH_INFO:
      return {
        ...state,
        trashInfo: { ...action.data },
      };
    default:
      return state;
  }
}

export default function DeletedEntries({
  propsFromModal: { ID },
  setIsVisibleModal,
}) {
  const { restoreTrashbin, getTrashInfo, restoreTrashbinForDateRange } = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    isLoadingTrashInfo,
    isLoadingTrashInfoPeriod,
    isLoading,
    dateRange,
    isFormValidate,
    isSelectRadioButtonDates,
    selectedDays,
    trashInfo: {
      deletedToday,
      deletedInAMonth,
      deletedInAWeek,
      forSpecifiedPeriod,
    },
  } = state;

  const alert = useAlert();

  const setIsLoadingTrashInfo = (value) => {
    dispatch({ type: SET_IS_LOADING_TRASH_INFO, value });
  };

  const setIsLoadingTrashInfoPeriod = (value) => {
    dispatch({ type: SET_IS_LOADING_TRASH_INFO_PERIOD, value });
  };

  useEffect(() => {
    getTrashInfo(ID).then(({ data }) => {
      dispatch({ type: TRASH_INFO, data: { ...data, forSpecifiedPeriod: null } });
      setIsLoadingTrashInfo(false);
    });
  }, []);

  useEffect(() => {
    if (!isSelectRadioButtonDates) {
      dispatch({ type: TRASH_INFO,
        data: {
          deletedToday,
          deletedInAMonth,
          deletedInAWeek,
          forSpecifiedPeriod: null } });
    }
  }, [isSelectRadioButtonDates]);

  const setIsFormValidated = (value) => {
    dispatch({ type: SET_IS_FORM_VALIDATED, value });
  };

  useEffect(() => setIsFormValidated(selectedDays !== 0), [selectedDays]);

  useEffect(() => {
    if (dateRange.from && dateRange.to) {
      setIsLoadingTrashInfoPeriod(true);
      getTrashInfo(ID,
        formattingDateToUTC(dateRange.from),
        formattingDateToUTC(dateRange.to)).then(({ data }) => {
        dispatch({ type: TRASH_INFO, data });
        setIsLoadingTrashInfoPeriod(false);
      });
      setIsFormValidated(true);
    }
  }, [dateRange.from, dateRange.to]);

  const setIsLoading = (value) => {
    dispatch({ type: SET_IS_LOADING, value });
  };

  const onRangeChange = (toOrFrom, value) => {
    dispatch({ type: CHANGE_DATE_RANGE, toOrFrom, value });
  };

  const selectDays = ({ target }) => {
    dispatch({ type: SELECT_DAYS, value: target.value, isSelectRadioButtonDates: target.id === 'radioButton-dates' });
  };

  const onSubmit = () => {
    setIsLoading(true);
    if (isSelectRadioButtonDates) {
      restoreTrashbinForDateRange(ID,
        formattingDateToUTC(dateRange.from),
        formattingDateToUTC(dateRange.to))
        .then(() => {
          alert.show(<span style={{ textTransform: 'none' }}>Записи пользователя восстановлены</span>, { type: 'success' });
          setIsLoading(false);
          setIsVisibleModal(false);
        })
        .catch(() => {
          alert.show(<span style={{ textTransform: 'none' }}>Записи пользователя не восстановлены</span>, { type: 'error' });
          setIsLoading(false);
          setIsVisibleModal(false);
        });
      return;
    }
    restoreTrashbin(ID, selectedDays)
      .then(() => {
        alert.show(<span style={{ textTransform: 'none' }}>Записи пользователя восстановлены</span>, { type: 'success' });
        setIsLoading(false);
        setIsVisibleModal(false);
      })
      .catch(() => {
        alert.show(<span style={{ textTransform: 'none' }}>Записи пользователя не восстановлены</span>, { type: 'error' });
        setIsLoading(false);
        setIsVisibleModal(false);
      });
  };

  return (
    <Wrapper>
      <div className="deletedEntries">
        <div className="deletedEntries__radioButtons">
          <section>
            <input value={1} type="radio" id="radioButton-oneDay" name="renewal" onChange={selectDays} />
            <label htmlFor="radioButton-oneDay">
              <span>1 день </span>
              <span>{isLoadingTrashInfo ? spinner : `(${deletedToday})`}</span>
            </label>
          </section>
          <section>
            <input value={7} type="radio" id="radioButton-sevenDay" name="renewal" onChange={selectDays} />
            <label htmlFor="radioButton-sevenDay">
              <span>7 дней </span>
              <span>{isLoadingTrashInfo ? spinner : `(${deletedInAWeek})`}</span>
            </label>
          </section>
          <section>
            <input value={30} type="radio" id="radioButton-oneMonth" name="renewal" onChange={selectDays} />
            <label htmlFor="radioButton-oneMonth">
              <span>Месяц </span>
              <span>{isLoadingTrashInfo ? spinner : `(${deletedInAMonth})`}</span>
            </label>
          </section>
          <section>
            <input value={0} type="radio" id="radioButton-dates" name="renewal" onChange={selectDays} />
            <label htmlFor="radioButton-dates">Даты</label>
            {
          isSelectRadioButtonDates && (
            <div className="deletedEntries__dateFilter">
              <DateFilter
                to={dateRange.to}
                from={dateRange.from}
                onChange={onRangeChange}
              />
              <CalendarIcon className="calendarIcon" />
              <span className="daysCount">
                {isLoadingTrashInfoPeriod
                  ?                spinner
                  : (forSpecifiedPeriod !== null && `(${forSpecifiedPeriod})`)}
              </span>
            </div>
          )
        }
          </section>
        </div>
        <div className="deletedEntries__button">
          <fromStyles.ButtonForModal
            disabled={!isFormValidate}
            onClick={onSubmit}
            style={{ width: '111px' }}
          >
            {
              isLoading ? (
                <Spinner
                  as="span"
                  size="sm"
                  animation="border"
                  variant="light"
                />
              ) : 'Восстановить'
            }
          </fromStyles.ButtonForModal>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    .deletedEntries {
      padding: 22px 30px 25px 31px;
        &__radioButtons {
            display: flex;
            flex-direction: column;
            label {
                font-size: 16px;
                line-height: 24px;     
                color: #212529;
                margin: 0;
                margin-left: 7px;
                span:nth-of-type(2) {
                  color: #CED4DA;
                }
            }
            section {
                display: flex;
                align-items: center;
                width: fit-content;
                :not(:last-of-type) {
                  margin-bottom: 25px;
                }
                :last-of-type {
                  min-width: 307px;
                  height: 30px;
                }
            }
            section, label, input {
                cursor: pointer;
            }
        }
    &__button {
        display: flex;
        justify-content: flex-end;
        margin-top: 32px;
    }
    &__dateFilter {
    display: flex;
    margin-left: 7px;
      svg.calendarIcon {
        margin-left: 11px;
      }
      span.daysCount {
        color: #CED4DA;
        margin-left: 8px;
      }
    }
}`;

DeletedEntries.propTypes = {
  propsFromModal: PropTypes.shape({
    ID: PropTypes.string,
  }).isRequired,
  setIsVisibleModal: PropTypes.func.isRequired,
};
