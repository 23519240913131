import { adminID } from '../../../shared/config';
import {
  CODE_DEVICE_SUCCESS,
  CODE_DEVICE_REQUEST,
  CODE_DEVICE_FAILURE,
  SIGN_IN_DEVICE_REQUEST,
  SIGN_IN_DEVICE_SUCCESS,
  SIGN_IN_DEVICE_FAILURE,
  LOGOUT,
} from '../actions/deviceActions';
import {
  GET_ALL_WORKSTATE_SUCCESS,
  GET_USER_WORKSTATE_SUCCESS,
  SWITCH_WORKSTATE_REQUEST,
  SWITCH_WORKSTATE_SUCCESS,
  SWITCH_WORKSTATE_FAILURE,
} from '../actions/workstatesActions';
import { TRANSIT_ISSUE, TRANSIT_ISSUE_SUCCESS, TRANSIT_ISSUE_FAILURE } from '../actions/employeesActions';
import { DELETE_WORKLOG, DELETE_WORKLOG_SUCCESS, DELETE_WORKLOG_FAILURE } from '../actions/issuesActions';
import {
  UPDATE_HOSPITAL,
  UPDATE_HOSPITAL_SUCCESS,
  UPDATE_HOSPITAL_FAILURE,
  UPDATE_VACATION,
  UPDATE_VACATION_SUCCESS,
  UPDATE_VACATION_FAILURE,
} from '../actions/hospitalsActions';
import {
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  REMOVE_DOCUMENT,
  REMOVE_DOCUMENT_SUCCESS,
  REMOVE_DOCUMENT_FAILURE,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAILURE,
} from '../actions/documentsActions';
import { GET_ITERATION_SUCCESS } from '../actions/iterationActions';
import {
  GET_USER_LIST_SUCCESS,
  GET_USER_INFO_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  SIGNUP_USER_REQUEST,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
} from '../actions/userActions';

import { getCookie } from '../../utils/cookie';

export const UPDATE_TIME_EVERY_1_MIN = 'UPDATE_TIME_EVERY_1_MIN';
export const REDUX_STORAGE_LOAD = 'REDUX_STORAGE_LOAD';

export function updateTimeEvery1min() {
  return {
    type: UPDATE_TIME_EVERY_1_MIN,
  };
}

export function getUri(state) {
  return state.uri;
}

export function isLoaded(state, userID) {
  return userID === adminID || state.userInfo && state.userList && state.iteration && state.workstate && state.workstates;
}
const initialState = {
  login: {
    state: 'exit',
    err: '',
    isPending: false,
    justLogin: false,
  },
  uri: {
    path: '/',
    isRedirected: false,
  },
  dataLoaded: {
    userInfo: false,
    userList: false,
    iteration: false,
    workstate: false,
    workstates: false,
  },
  isPending: {
    callInSick: false,
    addVacation: false,
    updateAboutMe: false,
    switchWorkstate: {},
    transitIssue: {},
    deleteWorkLog: {},
    addDocument: false,
    editDocument: {},
    newEmployee: false,
  },
};

const getPathBasedOnCookie = () => {
  const validPath = [
    '/team',
    '/workstate',
    '/employee',
    '/vacations',
    '/admin/vacations',
    '/admin/newEmployee',
    '/sprints',
  ];
  const uri = getCookie('uri');
  if (uri && validPath.indexOf(uri) !== -1) {
    return uri;
  }
  return '/';
};

function getObjectWithoutProperty(object, property) {
  const { [property]: value, ...withoutProperty } = object;
  return withoutProperty;
}

export default function app(state = initialState, action = {}) {
  switch (action.type) {
    case CODE_DEVICE_REQUEST: {
      return { ...state, login: { ...state.login, isPending: true, err: '' } };
    }
    case CODE_DEVICE_SUCCESS: {
      return { ...state, login: { ...state.login, isPending: false, err: '', state: 'code' } };
    }
    case CODE_DEVICE_FAILURE: {
      return { ...state, login: { ...state.login, isPending: false, err: action.err.data } };
    }
    case GET_USER_INFO_SUCCESS: {
      if (action.userID === 'self') {
        return { ...state, dataLoaded: { ...state.dataLoaded, userInfo: true } };
      }
      return state;
    }
    case GET_ITERATION_SUCCESS:
    case GET_USER_LIST_SUCCESS:
    case GET_USER_WORKSTATE_SUCCESS:
    case GET_ALL_WORKSTATE_SUCCESS: {
      let data = '';
      switch (action.type) {
        case GET_ITERATION_SUCCESS:
          data = 'iteration';
          break;
        case GET_USER_LIST_SUCCESS:
          data = 'userList';
          break;
        case GET_USER_WORKSTATE_SUCCESS:
          data = 'workstate';
          break;
        case GET_ALL_WORKSTATE_SUCCESS:
          data = 'workstates';
          break;
        default: {
          break;
        }
      }
      return { ...state, dataLoaded: { ...state.dataLoaded, [data]: true } };
    }
    case SIGN_IN_DEVICE_REQUEST: {
      return { ...state, login: { ...state.login, isPending: true, err: '' } };
    }
    case 'LOGIN': {
      return { ...state, login: { ...state.login, justLogin: true } };
    }
    case 'RESET_JUST_LOGIN': {
      return { ...state, login: { ...state.login, justLogin: false } };
    }
    case 'REDIRECT': {
      return { ...state, uri: { ...state.uri, isRedirected: true } };
    }
    case 'SET_URI': {
      return { ...state, uri: { ...state.uri, path: getPathBasedOnCookie(), isRedirected: false } };
    }
    case SIGN_IN_DEVICE_SUCCESS: {
      return { ...state, login: { ...state.login, isPending: false, err: '', state: 'logged' } };
    }
    case SIGN_IN_DEVICE_FAILURE: {
      return { ...state, login: { ...state.login, isPending: false, err: action.err.data } };
    }
    case LOGOUT: {
      return initialState;
    }
    case SWITCH_WORKSTATE_REQUEST: {
      return {
        ...state,
        isPending: { ...state.isPending, switchWorkstate: { ...state.isPending.switchWorkstate, [action.ID]: true } },
      };
    }
    case SWITCH_WORKSTATE_SUCCESS:
    case SWITCH_WORKSTATE_FAILURE: {
      return {
        ...state,
        isPending: {
          ...state.isPending,
          switchWorkstate: getObjectWithoutProperty(state.isPending.switchWorkstate, action.ID),
        },
      };
    }
    case TRANSIT_ISSUE: {
      return {
        ...state,
        isPending: { ...state.isPending, transitIssue: { ...state.isPending.transitIssue, [action.key]: true } },
      };
    }
    case TRANSIT_ISSUE_SUCCESS:
    case TRANSIT_ISSUE_FAILURE: {
      return {
        ...state,
        isPending: {
          ...state.isPending,
          transitIssue: getObjectWithoutProperty(state.isPending.transitIssue, action.key),
        },
      };
    }
    case DELETE_WORKLOG: {
      return {
        ...state,
        isPending: {
          ...state.isPending,
          deleteWorkLog: { ...state.isPending.deleteWorkLog, [action.worklogId]: true },
        },
      };
    }
    case DELETE_WORKLOG_SUCCESS:
    case DELETE_WORKLOG_FAILURE: {
      /**/
      return {
        ...state,
        isPending: {
          ...state.isPending,
          deleteWorkLog: getObjectWithoutProperty(state.isPending.deleteWorkLog, action.worklogId),
        },
      };
    }
    case UPDATE_HOSPITAL: {
      return { ...state, isPending: { ...state.isPending, callInSick: true } };
    }
    case UPDATE_HOSPITAL_SUCCESS:
    case UPDATE_HOSPITAL_FAILURE: {
      return { ...state, isPending: { ...state.isPending, callInSick: false } };
    }
    case UPDATE_VACATION: {
      return { ...state, isPending: { ...state.isPending, addVacation: true } };
    }
    case UPDATE_VACATION_SUCCESS:
    case UPDATE_VACATION_FAILURE: {
      return { ...state, isPending: { ...state.isPending, addVacation: false } };
    }
    case UPLOAD_DOCUMENT: {
      if (action.isNew) {
        return { ...state, isPending: { ...state.isPending, addDocument: true } };
      }
      return {
        ...state,
        isPending: {
          ...state.isPending,
          editDocument: { ...state.isPending.editDocument, [action.ID]: true },
        },
      };
    }
    case UPLOAD_DOCUMENT_SUCCESS:
    case UPLOAD_DOCUMENT_FAILURE: {
      if (action.isNew) {
        return { ...state, isPending: { ...state.isPending, addDocument: false } };
      }
      return {
        ...state,
        isPending: {
          ...state.isPending,
          editDocument: getObjectWithoutProperty(state.isPending.editDocument, action.ID),
        },
      };
    }
    case UPDATE_DOCUMENT:
    case REMOVE_DOCUMENT: {
      return {
        ...state,
        isPending: {
          ...state.isPending,
          editDocument: { ...state.isPending.editDocument, [action.ID || action.doc.ID]: true },
        },
      };
    }
    case UPDATE_DOCUMENT_SUCCESS:
    case UPDATE_DOCUMENT_FAILURE:
    case REMOVE_DOCUMENT_SUCCESS:
    case REMOVE_DOCUMENT_FAILURE: {
      return {
        ...state,
        isPending: {
          ...state.isPending,
          editDocument: getObjectWithoutProperty(state.isPending.editDocument, action.ID),
        },
      };
    }
    case UPDATE_USER_REQUEST: {
      return { ...state, isPending: { ...state.isPending, updateAboutMe: true } };
    }
    case UPDATE_USER_SUCCESS:
    case UPDATE_USER_FAILURE: {
      return { ...state, isPending: { ...state.isPending, updateAboutMe: false } };
    }
    case SIGNUP_USER_REQUEST: {
      return { ...state, isPending: { ...state.isPending, newEmployee: true } };
    }
    case SIGNUP_USER_SUCCESS:
    case SIGNUP_USER_FAILURE: {
      return { ...state, isPending: { ...state.isPending, newEmployee: false } };
    }
    default:
      return state;
  }
}
