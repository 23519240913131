import React     from 'react';
import PropTypes from 'prop-types';
import pure      from 'recompose/pure';
import { Link }  from 'react-router-dom';
import helpers   from '../../utils/viewHelpers';
import gravatar  from '../../utils/gravatar';

const SprintInfoCard = (props) => {
  const {
    email, name, surname, position, workedSec, planedSec,
    vacationSec, issuesDone, issuesFixed, issuesWaiting, moneyAward,
    moneySalary, moneyTotal, userID, sprintId,
  } = props;
  return (
    <div className="sprint-employees-item sprint active">
      <Link to={`/personalSprint/${userID}/${sprintId}`} style={{ textDecoration: 'none' }}>
        <div className="sprint-employees-item-info d-flex">
          <div className="sprint-employees-item-image">
            <img className="rounded-circle" src={gravatar(email, { size: 70 })} alt="icon" />
          </div>
          <div className="sprint-employees-item-user">
            <div className="sprint-employees-item-user__name">{`${name} ${surname}`}</div>
            <div className="sprint-employees-item-user__position">{position}</div>
          </div>
        </div>
        <div className="d-flex">
          <div className="sprint-tasks-info d-flex flex-column">
            <div className="sprint-info-line">
              <div className="sprint-info-line__label">Logged</div>
              <div className="sprint-info-line__value danger large bold">{helpers.HMfromSec(workedSec, ':')}</div>
            </div>
            <div className="d-flex">
              <div className="col-md-6 sprint-info-line">
                <div className="sprint-info-line__label">Planned</div>
                <div className="sprint-info-line__value primary">{helpers.HMfromSec(planedSec, ':')}</div>
              </div>
              <div className="col-md-6 sprint-info-line">
                <div className="sprint-info-line__label">Vacation</div>
                <div className="sprint-info-line__value">{helpers.HMfromSec(vacationSec, ':')}</div>
              </div>
            </div>
            <div className="d-flex sprint-issues-hours">
              <div className="sprint-info-line">
                <div className="sprint-info-line__label">Done:</div>
                <div className="sprint-info-line__value primary">{issuesDone}</div>
              </div>
              <div className="sprint-info-line">
                <div className="sprint-info-line__label">Errors:</div>
                <div className="sprint-info-line__value warning">{issuesFixed}</div>
              </div>
              <div className="sprint-info-line">
                <div className="sprint-info-line__label">Wait:</div>
                <div className="sprint-info-line__value danger">{issuesWaiting}</div>
              </div>
            </div>
          </div>
          <div className="vertical-line" />
          <div className="sprint-salary-info  d-flex flex-column">
            <div className="sprint-info-line">
              <div className="sprint-info-line__label">Salary</div>
              <div className="sprint-info-line__value">{`${helpers.formatMoney(moneySalary)}`}<span className="ruble ruble-small" /></div>
            </div>
            <div className="sprint-info-line">
              <div className="sprint-info-line__label">Award</div>
              <div className="sprint-info-line__value">{`${helpers.formatMoney(moneyAward)}`}<span className="ruble ruble-small" /></div>
            </div>
            <div className="sprint-info-line">
              <div className="sprint-info-line__label">Total</div>
              <div className="sprint-info-line__value large black">{`${helpers.formatMoney(moneyTotal)}`}<span className="ruble" /></div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

SprintInfoCard.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  userID: PropTypes.string.isRequired,
  workedSec: PropTypes.number.isRequired,
  planedSec: PropTypes.number.isRequired,
  vacationSec: PropTypes.number.isRequired,
  issuesDone: PropTypes.number.isRequired,
  issuesFixed: PropTypes.number.isRequired,
  issuesWaiting: PropTypes.number.isRequired,
  moneyAward: PropTypes.number.isRequired,
  moneySalary: PropTypes.number.isRequired,
  moneyTotal: PropTypes.number.isRequired,
  sprintId: PropTypes.number.isRequired,
};

export default pure(SprintInfoCard);
