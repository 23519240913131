import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as fromStyled from '../styles';

import SubscribeList from './SubscribeList';

import SelectForFilter from '../../../ui/Select/SelectForFilter';
import DateFilter from '../../../ui/DateFilter/DateFilter';

import { formattingDateToUTC } from '../../../../utils/formattingDate';

const options = {
  subscriptions: {
    free: 'Free',
    lite: 'Lite',
    base: 'Base',
    plus: 'Plus',
  },
  sources: {
    Support: 'Support',
    AppStore: 'AppStore',
  },
};

const SET_FILTERED_HISTORY_SUBSCRIBES = 'SET_FILTERED_HISTORY_SUBSCRIBES';
const CHANGE_SUBCRIPTION_ON_FILTER = 'CHANGE_SUBCRIPTION_ON_FILTER';
const CHANGE_SOURCE_ON_FILTER = 'CHANGE_SOURCE_ON_FILTER';
const CHANGE_DATE_RANGE_ON_FILTER = 'CHANGE_DATE_RANGE_ON_FILTER';

const CHANGE_FILTER = 'CHANGE_FILTER';

const SET_SOURCES_LIST = 'SET_SOURCES_LIST';

const initialState = {
  filteredHistorySubscribes: null,
  sourcesList: {},
  filter: {
    subscription: null,
    source: null,
    dateRange: {
      to: undefined,
      from: undefined,
    },
  },
};

function init(propsFromModal) {
  return {
    ...initialState,
    historySubscribes: propsFromModal.history,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case SET_SOURCES_LIST:
      return {
        ...state,
        sourcesList: action.sourcesList,
      };
    case SET_FILTERED_HISTORY_SUBSCRIBES:
      return {
        ...state,
        filteredHistorySubscribes: action.data,
      };
    case CHANGE_SUBCRIPTION_ON_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          subscription: action.value,
        },
      };
    case CHANGE_SOURCE_ON_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          source: action.value,
        },
      };
    case CHANGE_DATE_RANGE_ON_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateRange: {
            ...state.filter.dateRange,
            [action.toOrFrom]: action.value,
          },
        },
      };
    case CHANGE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.filterKey]: action.filterValue,
        },
      };
    default:
      return state;
  }
}

export default function HistorySubscribes({
  propsFromModal,
}) {
  const [state, dispatch] = useReducer(reducer, propsFromModal, init);

  const {
    sourcesList,
    filteredHistorySubscribes,
    historySubscribes,
    filter,
  } = state;

  useEffect(() => {
    const list = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in historySubscribes) {
      const sr = historySubscribes[key].source;
      list[sr] = sr;
    }
    dispatch({ type: SET_SOURCES_LIST, sourcesList: list });
  }, []);

  const filteredWithDR = (list, DRfrom, DRto) => {
    const from = formattingDateToUTC(DRfrom.setHours(0, 0, 0, 0));
    const to = formattingDateToUTC(DRto.setHours(23, 59, 59, 59));
    return list.filter(({ purchaseDT }) => {
      const first = purchaseDT >= from;
      const second = purchaseDT <= to;
      return first && second;
    });
  };

  const filteredWithSubscription = (list, subscription) => list
    .filter(({ subscrType }) => subscrType === subscription);

  const filteredWithSource = (list, SR) => list
    .filter(({ source }) => source === SR);

  const setFilteredHistorySubscribes = (data) => {
    dispatch({ type: SET_FILTERED_HISTORY_SUBSCRIBES, data });
  };

  const changeFilter = (filterKey, filterValue) => {
    dispatch({ type: CHANGE_FILTER, filterKey, filterValue });
  };

  const onChangeDateRangeOnFilter = (toOrFrom, value) => {
    dispatch({ type: CHANGE_DATE_RANGE_ON_FILTER, toOrFrom, value });
  };

  const onChangeSubscriptionOnFilter = (value) => {
    changeFilter('subscription', value);
    // dispatch({ type: CHANGE_SUBCRIPTION_ON_FILTER, value });
  };

  const onChangeSourceOnFilter = (value) => {
    changeFilter('source', value);
    // dispatch({ type: CHANGE_SOURCE_ON_FILTER, value });
  };
  useEffect(() => {
    const prevHistory = historySubscribes;
    const { subscription, source, dateRange } = filter;
    let DR = null;
    let SR = null;
    let SR_DR = null;
    let SB = null;
    let SB_DR = null;
    let SB_SR = null;
    let SB_SR_DR = null;
    if (dateRange.from && dateRange.to) {
      DR = filteredWithDR(prevHistory, dateRange.from, dateRange.to);
      if (source) {
        SR_DR = filteredWithSource(DR, source);
        if (subscription) {
          SB_SR_DR = filteredWithSubscription(SR_DR, subscription);
          setFilteredHistorySubscribes(SB_SR_DR);
        } else setFilteredHistorySubscribes(SR_DR);
      } else if (subscription) {
        SB_DR = filteredWithSubscription(DR, subscription);
        setFilteredHistorySubscribes(SB_DR);
      } else setFilteredHistorySubscribes(DR);
    } else if (source) {
      SR = filteredWithSource(prevHistory, source);
      if (subscription) {
        SB_SR = filteredWithSubscription(SR, subscription);
        setFilteredHistorySubscribes(SB_SR);
      } else setFilteredHistorySubscribes(SR);
    } else if (subscription) {
      SB = filteredWithSubscription(prevHistory, subscription);
      setFilteredHistorySubscribes(SB);
    } else setFilteredHistorySubscribes(null);
  }, [filter.subscription, filter.source, filter.dateRange]);

  return (
    <Wrapper>
      <FilterWrapper>
        <SelectForFilter
          options={options.subscriptions}
          defaultValue={{ value: 'subscription', label: 'Подписка' }}
          selectedOption={filter.subscription}
          onSelectOption={onChangeSubscriptionOnFilter}
          width={170}
        />
        <section>
          <SelectForFilter
            options={sourcesList}
            defaultValue={{ value: 'source', label: 'Источник' }}
            selectedOption={filter.source}
            onSelectOption={onChangeSourceOnFilter}
            width={170}
          />
        </section>
        <section>
          <DateFilter
            to={filter.dateRange.to}
            from={filter.dateRange.from}
            isForFilter
            onChange={onChangeDateRangeOnFilter}
          />
        </section>
      </FilterWrapper>
      <SubscribeList
        list={filteredHistorySubscribes !== null
          ? filteredHistorySubscribes
          : historySubscribes}
      />
    </Wrapper>
  );
}

const Wrapper = styled(fromStyled.WrapperForHistoryModal)`
padding: 0px 20px 13px 30px;
`;

const FilterWrapper = styled(fromStyled.WrapperForFilter)`
width: 681px;
`;

HistorySubscribes.propTypes = {
  propsFromModal: PropTypes.shape({
    history: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};
