import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { Loader, Overlay } from '../Loader';

class AddHospital extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startD: moment().format('YYYY-MM-DD'),
      days: 1,
      comment: '',
    };
    this.onStartDateChange = (ev) => {
      this.setState({ startD: moment(ev).format('YYYY-MM-DD') });
    };
    this.onCommentChange = (ev) => {
      this.setState({ comment: ev.target.value });
    };
    this.onDaysChange = (ev) => {
      if (+ev.target.value > 0) {
        const days = ev.target.value;
        this.setState({
          days,
        });
      }
    };
    this.onSubmit = (ev) => {
      ev.preventDefault();
      const { startD, comment, days } = this.state;
      this.props.updateHospital({ startD, comment, days }, this.props.userID);
      this.setState({
        startD: moment().format('YYYY-MM-DD'),
        days: 1,
        comment: '',
      });
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { state, props } = this;
    const update =
      nextState.startD !== state.startD ||
      nextState.days !== state.days ||
      nextState.comment !== state.comment ||
      nextProps.isPending !== props.isPending;
    return update;
  }

  render() {
    return (
      <div className="section hospital-section">
        <div className="section-header">Больничные</div>
        <div className="section-content">
          <p>Заявление на предоставление больничного:</p>
          <form>
            <div className="form-group add-hospital-start-date">
              <label htmlFor="from" className=" col-form-label">
                С
              </label>
              <DatePicker
                className="data-picker"
                value={new Date(this.state.startD)}
                onChange={this.onStartDateChange}
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                rows="3"
                placeholder="Комментарий"
                value={this.state.comment}
                onChange={this.onCommentChange}
              />
            </div>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-warning hospital-submit-button" onClick={this.onSubmit}>
                Открыть больничный
              </button>
            </div>
          </form>
          {this.props.isPending && (
            <Overlay>
              <Loader show scale={0.8} />
            </Overlay>
          )}
        </div>
      </div>
    );
  }
}

AddHospital.propTypes = {
  userID: PropTypes.string.isRequired,
  updateHospital: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default AddHospital;
