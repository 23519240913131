import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useHistory, useParams } from 'react-router-dom';

import icons from '../../../utils/icons';
import ConfirmationModal from '../../ui/ConfirmationModal';

export default function Modal({ confirmation, title, Component, props }) {
  const [isVisible, setIsVisible] = useState(true);
  const history = useHistory();
  const { id } = useParams();
  const hide = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (!isVisible) {
      // удаляем адресную строку для модального окна
      const pathname = history.location.pathname.split('/')[2];
      if (pathname === 'userCard') {
        history.push(`/support/userCard/${id}`);
      } else history.push('/support');
    }
  }, [isVisible]);

  const onClickOnContentWrapper = (e) => {
    e.stopPropagation();
  };

  return isVisible && (
  <Wrapper onClick={hide}>
    <ContentWrapper onClick={onClickOnContentWrapper}>
      {
        !confirmation ? (
          <>
            <div className="headerModal">
              <div className="headerModal__title">{title}</div>
              <div className="headerModal__closeIcon">
                <span onClick={hide}>{icons.close}</span>
              </div>
            </div>
            <Component
              propsFromModal={props}
              isVisibleModal={isVisible}
              setIsVisibleModal={setIsVisible}
            />
          </>
        ) : (
          <ConfirmationModal
            // eslint-disable-next-line react/prop-types
            onConfirm={props.onConfirm}
            hide={hide}
          />
        )
      }

    </ContentWrapper>
  </Wrapper>
  );
}

const Wrapper = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.75);
    position: fixed;
    top: 0;
    left: 0;
`;

const ContentWrapper = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .headerModal {
        display: flex;
        justify-content: space-between;
        margin: 15px 10px 0 30px;
        &__title {
            font-weight: 500;
            font-size: 18px;
            line-height: 35px;
            color: #000000;
        }
        &__closeIcon {
          position: relative;
          top: -7px;
          svg {
            cursor: pointer;
            &:hover {
                line {
                    stroke: #737373;
                }
            }
        }

        }
    }
`;

Modal.propTypes = {
  confirmation: PropTypes.bool,
  title: PropTypes.string,
  Component: PropTypes.func,
  props: PropTypes.shape(),
};

Modal.defaultProps = {
  confirmation: false,
  title: '',
  Component: () => {},
  props: {},
};
