import React from 'react';
import { Route } from 'react-router-dom';
import { MODAL_WINDOWS } from '../../../constants';
import Modal from '../../view/Modal';

export default (selectedUser) => {
  const modalWindows = Object.keys(MODAL_WINDOWS).map((modal) => {
    const { title } = MODAL_WINDOWS[modal];
    const { component } = MODAL_WINDOWS[modal];
    let props = {
      ID: selectedUser.userID,
    };
    switch (modal) {
      case 'sendMessage': {
        props = {
          ...props,
          email: selectedUser.personal.email,
          extraEmail: selectedUser.personal.extraEmail,
          phone: selectedUser.personal.phone,
        };
        break;
      }
      case 'renewingSubscribe': {
        props = {
          ...props,
          comment: selectedUser.subscription.comment,
          startDT: selectedUser.subscription.startDT,
          endDT: selectedUser.subscription.endDT,
          type: selectedUser.subscription.type,
          subscrEndDays: selectedUser.personal.subscrEndDays,
        };
        break;
      }
      case 'deletedEntries': {
        props = {
          ...props,
        };
        break;
      }
      case 'historyChanges': {
        props = {
          ...props,
        };
        break;
      }
      case 'historySubscribes': {
        props = {
          ...props,
          history: selectedUser.subscription.history,
        };
        break;
      }
      case 'historyMessages': {
        props = {
          ...props,
        };
        break;
      }
      default: {
        props = {};
      }
    }
    return (
      <Route key={modal} path={`/support/userCard/modal/${modal}/:id`}>
        <Modal
          title={title}
          Component={component}
          props={props}
        />
      </Route>
    );
  });
  return modalWindows;
};
