import styled from 'styled-components';
import { getBackgroundForAvatar } from '../utils/helpers';

// Кнопка, которая используется в модальных окнах
export const ButtonForModal = styled.button`
    border: none;
    padding: 4px 10px;
    font-size: 14px;
    line-height: 21px;
    text-align: center;  
    color: #FFFFFF;
    border-radius: 4px;    
    transition: background 0.2s;
    ${({ disabled }) => disabled ? `
      background: #818181;` : `
      background: #2EA8E5;
      `}
`;

export const List = styled.ul`
  width: 100%;
  height: 300px;
  padding-left: 0px;
  margin-left: 0px;
  overflow: auto;
  ::-webkit-scrollbar { width: 3px; height: 3px;}
  ::-webkit-scrollbar-button {  background-color: #666; }
  ::-webkit-scrollbar-track {  background: #EDEDED;}
  ::-webkit-scrollbar-track-piece { background-color: #ffffff;}
  ::-webkit-scrollbar-thumb { height: 3px; background-color: #B6B6B6;}
  ::-webkit-scrollbar-corner { background-color: #999;}}
  ::-webkit-resizer { background-color: #666;}
  li {
    list-style-type: none;
  }
`;

export const Avatar = styled.div`
  width: ${({ height }) => height}px;
  height: ${({ height }) => height}px;
  background:  ${({ base64 }) => getBackgroundForAvatar(base64)};
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
`;
