export const TRANSIT_ISSUE = 'TRANSIT_ISSUE';
export const TRANSIT_ISSUE_REQUEST = 'TRANSIT_ISSUE_REQUEST';
export const TRANSIT_ISSUE_SUCCESS = 'TRANSIT_ISSUE_SUCCESS';
export const TRANSIT_ISSUE_FAILURE = 'TRANSIT_ISSUE_FAILURE';

export function transitIssue(key, to) {
  return {
    type: TRANSIT_ISSUE,
    key,
    to,
  };
}
export function transitIssueRequest() {
  return {
    type: TRANSIT_ISSUE_REQUEST,
  };
}
export function transitIssueSuccess(data, key) {
  return {
    type: TRANSIT_ISSUE_SUCCESS,
    data,
    key,
  };
}
export function transitIssueFailure(err, key) {
  return {
    type: TRANSIT_ISSUE_FAILURE,
    err,
    key,
  };
}

export const GET_FEATURES = 'GET_FEATURES';
export const GET_FEATURES_REQUEST = 'GET_FEATURES_REQUEST';
export const GET_FEATURES_SUCCESS = 'GET_FEATURES_SUCCESS';
export const GET_FEATURES_FAILURE = 'GET_FEATURES_FAILURE';

export function getFeatures() {
  return {
    type: GET_FEATURES,
  };
}
export function getFeaturesRequest() {
  return {
    type: GET_FEATURES_REQUEST,
  };
}
export function getFeaturesSuccess(data) {
  return {
    type: GET_FEATURES_SUCCESS,
    data,
  };
}
export function getFeaturesFailure(err) {
  return {
    type: GET_FEATURES_FAILURE,
    err,
  };
}

export const GET_WORKLOGS = 'GET_WORKLOGS';
export const GET_WORKLOGS_REQUEST = 'GET_WORKLOGS_REQUEST';
export const GET_WORKLOGS_SUCCESS = 'GET_WORKLOGS_SUCCESS';
export const GET_WORKLOGS_FAILURE = 'GET_WORKLOGS_FAILURE';

export function getWorklogs(user = 'all', iteration) {
  return {
    type: GET_WORKLOGS,
    user,
    iteration,
  };
}
export function getWorklogsRequest() {
  return {
    type: GET_WORKLOGS_REQUEST,
  };
}
export function getWorklogsSuccess(data) {
  return {
    type: GET_WORKLOGS_SUCCESS,
    data,
  };
}
export function getWorklogsFailure(err) {
  return {
    type: GET_WORKLOGS_FAILURE,
    err,
  };
}

export const GET_ISSUE_BY_KEY = 'GET_ISSUE_BY_KEY';
export const GET_ISSUE_BY_KEY_REQUEST = 'GET_ISSUE_BY_KEY_REQUEST';
export const GET_ISSUE_BY_KEY_SUCCESS = 'GET_ISSUE_BY_KEY_SUCCESS';
export const GET_ISSUE_BY_KEY_FAILURE = 'GET_ISSUE_BY_KEY_FAILURE';

export function getIssueByKey(key) {
  return {
    type: GET_ISSUE_BY_KEY,
    key,
  };
}
export function getIssueByKeyRequest() {
  return {
    type: GET_ISSUE_BY_KEY_REQUEST,
  };
}
export function getIssueByKeySuccess(data) {
  return {
    type: GET_ISSUE_BY_KEY_SUCCESS,
    data,
  };
}
export function getIssueByKeyFailure(err) {
  return {
    type: GET_ISSUE_BY_KEY_FAILURE,
    err,
  };
}

export const GET_ISSUE_BY_JQL = 'GET_ISSUE_BY_JQL';
export const GET_ISSUE_BY_JQL_REQUEST = 'GET_ISSUE_BY_JQL_REQUEST';
export const GET_ISSUE_BY_JQL_SUCCESS = 'GET_ISSUE_BY_JQL_SUCCESS';
export const GET_ISSUE_BY_JQL_FAILURE = 'GET_ISSUE_BY_JQL_FAILURE';

export function getIssueByJql(jql) {
  return {
    type: GET_ISSUE_BY_JQL,
    jql,
  };
}
export function getIssueByJqlRequest() {
  return {
    type: GET_ISSUE_BY_JQL_REQUEST,
  };
}
export function getIssueByJqlSuccess(data) {
  return {
    type: GET_ISSUE_BY_JQL_SUCCESS,
    data,
  };
}
export function getIssueByJqlFailure(err) {
  return {
    type: GET_ISSUE_BY_JQL_FAILURE,
    err,
  };
}

export const GET_ISSUE_BY_STATUS = 'GET_ISSUE_BY_STATUS';
export const GET_ISSUE_BY_STATUS_REQUEST = 'GET_ISSUE_BY_STATUS_REQUEST';
export const GET_ISSUE_BY_STATUS_SUCCESS = 'GET_ISSUE_BY_STATUS_SUCCESS';
export const GET_ISSUE_BY_STATUS_FAILURE = 'GET_ISSUE_BY_STATUS_FAILURE';

export function getIssueByStatus(user = 'all', status = 'all', iteration) {
  return {
    type: GET_ISSUE_BY_STATUS,
    user,
    status,
    iteration,
  };
}
export function getIssueByStatusRequest() {
  return {
    type: GET_ISSUE_BY_STATUS_REQUEST,
  };
}
export function getIssueByStatusSuccess(data) {
  return {
    type: GET_ISSUE_BY_STATUS_SUCCESS,
    data,
  };
}
export function getIssueByStatusFailure(err) {
  return {
    type: GET_ISSUE_BY_STATUS_FAILURE,
    err,
  };
}

export const GET_ISSUE_BY_STATUS_CATEGORY = 'GET_ISSUE_BY_STATUS_CATEGORY';
export const GET_ISSUE_BY_STATUS_CATEGORY_REQUEST = 'GET_ISSUE_BY_STATUS_CATEGORY_REQUEST';
export const GET_ISSUE_BY_STATUS_CATEGORY_SUCCESS = 'GET_ISSUE_BY_STATUS_CATEGORY_SUCCESS';
export const GET_ISSUE_BY_STATUS_CATEGORY_FAILURE = 'GET_ISSUE_BY_STATUS_CATEGORY_FAILURE';

export function getIssueByStatusCategory(user = 'all', category = 'all') {
  return {
    type: GET_ISSUE_BY_STATUS_CATEGORY,
    user,
    category,
  };
}
export function getIssueByStatusCategoryRequest() {
  return {
    type: GET_ISSUE_BY_STATUS_CATEGORY_REQUEST,
  };
}
export function getIssueByStatusCategorySuccess(data) {
  return {
    type: GET_ISSUE_BY_STATUS_CATEGORY_SUCCESS,
    data,
  };
}
export function getIssueByStatusCategoryFailure(err) {
  return {
    type: GET_ISSUE_BY_STATUS_CATEGORY_FAILURE,
    err,
  };
}
