import React from 'react';
import PropTypes from 'prop-types';
import AttachmentPicker from '../AttachmentPicker';
import DropdownMenu from '../DropdownMenu';
import { Loader, Overlay } from '../Loader';

import { docTypes } from '../../../constants';

class AddDocumentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      desc: '',
      base64: '',
      type: '0',
      file: null,
    };
    this.descriptionChangeHandler = (ev) => {
      this.setState({ desc: ev.target.value });
    };
    this.typeChangeHandler = (ev) => {
      this.setState({ type: ev.target.value });
    };
    this.onDocumentAttach = (file) => {
      this.setState({ file });
    };
    this.onRemoveAttach = () => {
      this.setState({ file: null, base64: '' });
    };
    this.onSubmit = () => {
      const { desc, type, file } = this.state;
      if (file) {
        this.props.uploadDocument(file, Object.keys(docTypes)[+type], desc, String.generateGUID(), true);
        this.setState({
          desc: '',
          base64: '',
          type: '0',
          file: null,
        });
      }
    };
    this.getBase64 = (file) => {
      const self = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        self.setState({ base64: reader.result });
      };
    };
  }

  componentDidMount() {
    const textAreas = document.getElementsByTagName('textarea');

    Array.prototype.forEach.call(textAreas, (elem) => {
      elem.placeholder = elem.placeholder.replace(/\\n/g, '\n');
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { state, props } = this;
    const update =
      nextState.base64 !== state.base64 ||
      nextState.file !== state.file ||
      nextState.type !== state.type ||
      nextState.desc !== state.desc ||
      nextProps.isPending !== props.isPending;
    return update;
  }

  render() {
    let base64 = '';
    if (this.state.file) {
      this.getBase64(this.state.file);
      base64 = this.state.base64;
    }

    return (
      <div className="section new-document">
        <div className="section-header">Добавить документ</div>
        <div className="section-content">
          <div className="form-group">
            <textarea
              className="form-control"
              rows="5"
              placeholder="Название, ФИО\nсерия и номер документа\nДругие данные"
              value={this.state.desc}
              onChange={this.descriptionChangeHandler}
            />
          </div>
          <AttachmentPicker
            className="attachment-curtain"
            text="Перетащите сюда документ для загрузки"
            onDocumentAttach={this.onDocumentAttach}
          />
          {base64 !== '' && (
            <div className="attachment-preview-wrapper">
              <div className="attachment-preview-screencover" />
              <img className="attachment-preview" src={base64} alt="" />
              <div className="attachment-preview__remove" onClick={this.onRemoveAttach}>
                <i className="fa-trash-o" />
              </div>
            </div>
          )}
          <div className="d-flex justify-content-between">
            <DropdownMenu defaultValueNumber={0} values={Object.values(docTypes)} changeElement={this.typeChangeHandler} />
            <button type="submit" className="btn btn-success" onClick={this.onSubmit}>
              Загрузить документ
            </button>
          </div>
          {this.props.isPending && (
            <Overlay>
              <Loader show />
            </Overlay>
          )}
        </div>
      </div>
    );
  }
}

AddDocumentForm.propTypes = {
  uploadDocument: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default AddDocumentForm;
