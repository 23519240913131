import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import UserListItem from '../UserListItem';

const Wrapper = styled.div`
    margin: 8px 0 50px 0;
`;

export default function UserList({
  foundUsers,
  onSelectUser,
}) {
  return (
    <Wrapper>
      {foundUsers.map((item) => (
        <UserListItem
          key={item.ID}
          data={item}
          onSelectUser={onSelectUser}
        />
      ))}
    </Wrapper>
  );
}

UserList.propTypes = {
  foundUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelectUser: PropTypes.func.isRequired,
};
