export const GET_USER_WORKSTATE = 'GET_USER_WORKSTATE';
export const GET_USER_WORKSTATE_REQUEST = 'GET_USER_WORKSTATE_REQUEST';
export const GET_USER_WORKSTATE_SUCCESS = 'GET_USER_WORKSTATE_SUCCESS';
export const GET_USER_WORKSTATE_FAILURE = 'GET_USER_WORKSTATE_FAILURE';

export function getUserWorkstate() {
  return {
    type: GET_USER_WORKSTATE,
  };
}
export function getUserWorkstateRequest() {
  return {
    type: GET_USER_WORKSTATE_REQUEST,
  };
}
export function getUserWorkstateSuccess(data) {
  return {
    type: GET_USER_WORKSTATE_SUCCESS,
    data,
  };
}
export function getUserWorkstateFailure(err) {
  return {
    type: GET_USER_WORKSTATE_FAILURE,
    err,
  };
}

export const GET_ALL_WORKSTATE = 'GET_ALL_WORKSTATE';
export const GET_ALL_WORKSTATE_REQUEST = 'GET_ALL_WORKSTATE_REQUEST';
export const GET_ALL_WORKSTATE_SUCCESS = 'GET_ALL_WORKSTATE_SUCCESS';
export const GET_ALL_WORKSTATE_FAILURE = 'GET_ALL_WORKSTATE_FAILURE';

export function getAllWorkstate() {
  return {
    type: GET_ALL_WORKSTATE,
  };
}
export function getAllWorkstateRequest() {
  return {
    type: GET_ALL_WORKSTATE_REQUEST,
  };
}
export function getAllWorkstateSuccess(data) {
  return {
    type: GET_ALL_WORKSTATE_SUCCESS,
    data,
  };
}
export function getAllWorkstateFailure(err) {
  return {
    type: GET_ALL_WORKSTATE_FAILURE,
    err,
  };
}

export const SWITCH_WORKSTATE_REQUEST = 'SWITCH_WORKSTATE_REQUEST';
export const SWITCH_WORKSTATE_SUCCESS = 'SWITCH_WORKSTATE_SUCCESS';
export const SWITCH_WORKSTATE_FAILURE = 'SWITCH_WORKSTATE_FAILURE';

export function switchWorkstateRequest(ID, state = '') {
  return {
    type: SWITCH_WORKSTATE_REQUEST,
    ID,
    state,
  };
}
export function switchWorkstateSuccess(ID, data) {
  return {
    type: SWITCH_WORKSTATE_SUCCESS,
    ID,
    data,
  };
}
export function switchWorkstateFailure(err, ID) {
  return {
    type: SWITCH_WORKSTATE_FAILURE,
    err,
    ID,
  };
}
