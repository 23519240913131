import BORequest from './request';
import Device    from './deviceEntity';
import defines   from '../../utils/defines';
import { apiConfig, defaultlogger } from '../../../shared/config';

const defaultDevice = {
  ID: defines.guest.devID,
  devToken: defines.guest.devToken,
  ownerID: defines.guest.ID,
  ownerPhone: defines.guest.phone,
  devPlatform: 'Srv',
  devOS: 'MacOS',
  devModel: 'SDK',
  devName: 'CLIDevice',
  appName: 'BackOffice',
  appVer: '1.0',
  appBuild: 1,
  timeZone: 'Europe/Moscow',
  locale: 'ru_RU',
  geo: '15.789,53.346,10',
};

class BODevice extends BORequest {

  static* init(devFieds, config, logger, debug = 3) {
    devFieds = Object.merge(defaultDevice, devFieds);

    const device =  Device.byFields(devFieds, { db: {}, cache: {}, logger }, debug);

    logger.rowStart = `Device [ ${device.ID} ]`;

    const dev = new BORequest(config, device, logger, debug);

    yield dev.update();

    return dev;
  }

  static byFields(devFields) {
    const device = Device.byFields(devFields, { logger: defaultlogger });
    return new BODevice(apiConfig, device, defaultlogger);
  }

  * code(phone) {
    let resp;
    try {
      resp = yield this.post('device/code', { phone }, this.device.fields());
    } catch (err) {
      if (err.code !== 422) {
        console.error(err);
      } else {
        resp = {
          message: 'Code already sended!',
          status: 422,
        };
      }
    }

    return resp;
  }

  * signin(phone, code) {
    let resp;
    try {
      resp = yield this.post('device/signin', { phone, code }, this.device.fields());
    } catch (err) {
      if (err.code !== 403) {
        console.error(err);
      } else {
        resp = {
          message: 'Invalid code!',
          status: 403,
        };
      }
    }

    return resp;
  }

  * update() {
    return yield this.post('device/update', {}, this.device.fields());
  }
}

export default BODevice;
