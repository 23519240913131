import React     from 'react';
import PropTypes from 'prop-types';
import pure      from 'recompose/pure';
import Iteration from './Iteration';

const IterationsList = (props) => {
  const { iterations, sprintId, setQccIteration, isAdmin } = props;
  const sprints = iterations.list.indexes.map((index) => {
    const iteration = iterations.list.DS[index];
    return (
      <Iteration
        key={iteration.iterId}
        isAdmin={isAdmin}
        iterId={iteration.iterId}
        sprintId={sprintId}
        ID={iteration.ID}
        startD={iteration.startD}
        endD={iteration.endD}
        reqSec={iteration.reqSec}
        workedSec={iteration.workedSec}
        planedSec={iteration.planedSec}
        vacationSec={iteration.vacationSec}
        issuesDone={iteration.issuesDone}
        issuesFixed={iteration.issuesFixed}
        issuesWaiting={iteration.issuesWaiting}
        moneySalary={iteration.moneySalary}
        moneyAward={iteration.moneyAward}
        moneyTotal={iteration.moneyTotal}
        qcC={iteration.qcC}
        qcNote={iteration.qcNote}
        setQccIteration={setQccIteration}
      />
    );
  });
  return (
    <div className="sprint-list">
      {sprints}
    </div>
  );
};

IterationsList.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  sprintId: PropTypes.number.isRequired,
  setQccIteration: PropTypes.func.isRequired,
  iterations: PropTypes.shape().isRequired,
};

export default pure(IterationsList);
