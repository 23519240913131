import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import entriesIcon from '../../../images/entries.svg';
import storageIcon from '../../../images/storage.svg';

export default function ProgressBar({
  type,
  filledCount,
  totalCount,
}) {
  let icon;
  let title;
  let color = '#2EA8E5';
  let width;
  if (type === 'entries') {
    icon = entriesIcon;
    title = 'Записи';
    width = 380;
  } else {
    icon = storageIcon;
    title = 'Хранилище';
    width = 420;
  }
  const fullnessWidth = (100 * filledCount) / totalCount;
  if (fullnessWidth > 90) color = '#D4454D';
  let filledCountTitle;
  let totalCountTitle;
  switch (type) {
    case 'entries': {
      filledCountTitle = filledCount;
      totalCountTitle = totalCount;
      break;
    }
    case 'storage': {
      const formattedFilledCount = (filledCount.toFixed(1) / 1000).toFixed(4);
      const formattedTotalCount = totalCount / 1000;
      filledCountTitle = `${formattedFilledCount}Mb`;
      totalCountTitle = `${formattedTotalCount}Gb`;
      if (formattedFilledCount < 1) {
        filledCountTitle = `${(formattedFilledCount * 1000).toFixed(1)}Mb`;
      }
      if (formattedTotalCount < 1) {
        totalCountTitle = `${(formattedTotalCount * 1000).toFixed(1)}Mb`;
      }
      break;
    }
    default: {
      filledCountTitle = 0;
      totalCountTitle = 0;
    }
  }
  return (
    <Wrapper width={width}>
      <div className="overProgressBar">
        <div className="iconTitle">
          <img src={icon} alt={type === 'entries' ? 'entries' : 'attachments'} />
          <span className="title">{title}</span>
        </div>
        <div className="counts">
          <span className="filledCount">
            {filledCountTitle}
          </span>
          <span className="totalCount">
            {totalCountTitle}
          </span>
        </div>
      </div>
      <ProgressBarWrapper>
        <FullnesWrapper color={color} fullnessWidth={fullnessWidth} />
      </ProgressBarWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: Roboto;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  color: #6B6B6B;
  width: ${({ width }) => width}px;
  .overProgressBar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .iconTitle {
      display: flex;
      align-items: center;
      span {
        margin-left: 12px;
      }
    }
  }
  .filledCount {
    font-weight: 300;
    &::after {
      content: "/";
      margin: 0 5px;
    }
  }

`;

const ProgressBarWrapper = styled.div`
    width: 100%;
    height: 3px;
    background: #E4E4E4;
    border-radius: 3px;
`;

const FullnesWrapper = styled.div`
    width: ${({ fullnessWidth }) => fullnessWidth <= 100 ? fullnessWidth : 100}%;
    height: 100%;
    background: ${({ color }) => color};
    border-radius: 3px 0px 0px 3px;
`;

ProgressBar.propTypes = {
  type: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  filledCount: PropTypes.number.isRequired,
};
