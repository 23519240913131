import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SubscribePillow from '../../../ui/Pillows/SubscribePillow';
import SubscribeInfo from '../../../ui/SubscribeInfo';
import DateTime from '../../../ui/DateTime';
import icons from '../../../../utils/icons';

export default function SubscribeListItem({
  addedDays,
  type,
  source,
  startDT,
  endDT,
  comment,
}) {
  return (
    <Wrapper>
      <div className="subscribeListItem">
        <div className="subscribeListItem__type">
          <SubscribePillow type={type} size="small" />
        </div>
        <div className="subscribeListItem__source">{source}</div>
        <div className="subscribeListItem__dates">
          <DateTime DT={startDT} />
          {icons.longArrowRight}
          <DateTime DT={endDT} />
        </div>
        <div className="subscribeListItem__daysCount">
          <SubscribeInfo type={type} daysCount={addedDays} />
        </div>
        <div className="subscribeListItem__comment">{comment}</div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.li`
   width: 682px;
  .subscribeListItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 10px;
      padding-left: 20px;
      &__type {
      }
      &__source {
        width: 77px;
        font-size: 12px;
        line-height: 14px;  
        color: #000000;
        margin-left: -25px;
        text-align: center;
      }
      &__dates {
        display: flex;
        color: #696969;
        align-items: center;
        svg {
            margin: 0 11px;
        }
      }
      &__daysCount {

      }
      &__comment {
        width: 190px;
        font-size: 12px;
        line-height: 14px;
        color: #007BFF;
      }
  }
`;

SubscribeListItem.propTypes = {
  addedDays: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  startDT: PropTypes.string.isRequired,
  endDT: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};
