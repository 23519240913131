import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function IdField({
  size,
  content,
}) {
  return (
    <Wrapper data-field="id" size={size}>{content}</Wrapper>
  );
}

const Wrapper = styled.span`
    color: #AAAAAA;
    ${({ size }) => size === 'large'
    ? `font-size: 12px;
    line-height: 14px;`
    : `font-size: 10px;
    line-height: 12px;`}
`;

IdField.propTypes = {
  size: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
