import { Field, reduxForm } from 'redux-form';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import User from '../../redux/boModels/user';
import PersonalInfoForm, * as fromPersonalInfoForm from './PersonalInfoForm';
import { Loader, Overlay } from '../Loader';

import '../../styles/employee-form.css';

const LoaderPositioner = styled.div`
  position: relative;
  right: 50%;
`;

const userModelFields = User.model().fields;
// eslint-disable-next-line
let EmployeeForm = props => {
  const { onSubmit, user, isPending } = props;
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <div className="employee-form">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="phone">Номер телефона</label>
          <Field
            name="phone"
            component="input"
            type="text"
            placeholder="Номер телефона"
            className="form-control"
            disabled={userModelFields.phone.allow > 1}
          />
        </div>
        <div className="form-group">
          <label htmlFor="login">Логин</label>
          <Field
            name="login"
            component="input"
            type="text"
            placeholder="Логин"
            className="form-control"
            disabled={userModelFields.login.allow > 1}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <Field
            name="email"
            component="input"
            type="email"
            placeholder="Email"
            className="form-control"
            disabled={userModelFields.email.allow > 1}
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Имя</label>
          <Field
            name="name"
            component="input"
            type="text"
            placeholder="Имя"
            className="form-control"
            disabled={userModelFields.name.allow > 1}
          />
        </div>
        <div className="form-group">
          <label htmlFor="surname">Фамилия</label>
          <Field
            name="surname"
            component="input"
            type="text"
            placeholder="Фамилия"
            className="form-control"
            disabled={userModelFields.surname.allow > 1}
          />
        </div>
        <div className="form-group">
          <label htmlFor="patronymic">Отчество</label>
          <Field
            name="patronymic"
            component="input"
            type="text"
            placeholder="Отчество"
            className="form-control"
            disabled={userModelFields.patronymic.allow > 1}
          />
        </div>
        <div className="form-group">
          <label htmlFor="beginD">Дата начала</label>
          <Field
            name="beginD"
            component="input"
            type="date"
            className="form-control"
            disabled={userModelFields.beginD.allow > 1}
          />
        </div>
        <div className="form-group">
          <label htmlFor="birthdayD">Дата рождения</label>
          <Field
            name="birthdayD"
            component="input"
            type="date"
            className="form-control"
            disabled={userModelFields.birthdayD.allow > 1}
          />
        </div>
        <div className="form-group">
          <label htmlFor="trialExpD">Конец испытательного срока</label>
          <Field
            name="trialExpD"
            component="input"
            type="date"
            placeholder="Конец испытательного срока"
            className="form-control"
            disabled={userModelFields.trialExpD.allow > 1}
          />
        </div>
        {user.personalJSON && (
          <PersonalInfoForm
            onSubmit={onSubmit}
            initialValues={fromPersonalInfoForm.getObjectByValues(JSON.parse(user.personalJSON))}
          />
        )}

        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">
            Обновить
          </button>
          {isPending && (
            <LoaderPositioner>
              <Loader show scale={0.25} />
            </LoaderPositioner>
          )}
        </div>
      </form>
      {isPending && <Overlay />}
    </div>
  );
};

EmployeeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

EmployeeForm = reduxForm({
  form: 'user',
})(EmployeeForm);

const getObjectByValues = (user, values) => {
  const result = {};
  for (let i = 0; i < values.length; i++) {
    if (user[values[i]]) result[values[i]] = user[values[i]];
  }
  return result;
};

const userValues = [
  'phone',
  'login',
  'name',
  'surname',
  'patronymic',
  'phone',
  'email',
  'birthdayD',
  'beginD',
  'endD',
  'trialExpD',
];

EmployeeForm = connect((state, props) => ({
  initialValues: getObjectByValues(props.user, userValues),
}))(EmployeeForm);

export default EmployeeForm;
