// const env = 'dev';
const env = 'alpha';

const defaultApiConfig = {
  schema: 'https://',
  host: env === 'dev' ? 'api-local.soft-artel.com' : 'api.soft-artel.com',
  port: env === 'dev' ? 8443 : 443,
  ver: 'v1',
};

function getApiConfig() {
  const regExp = new RegExp(/^https:\/\/(\w+)/);
  const origin = window.location.origin;
  const replace = origin.replace(regExp, 'api');
  if (origin !== 'http://localhost:3000') {
    const apiConfig = {
      schema: 'https://',
      host: `${replace}`,
      port: 443,
      ver: 'v1',
    };
    return apiConfig;
  }
  return defaultApiConfig;
}

const apiConfig = getApiConfig();

function noop() {}

const nologger = {
  dbg: noop,
  info: noop,
  error: noop,
  warn: noop,
  LVL: {},
  state: noop,
};
// const logger = {
//   dbg: console.log,
//   info: console.log,
//   error: console.error,
//   warn: console.log,
//   LVL: {},
//   state: noop,
// };

module.exports = {
  apiConfig,
  defaultlogger: env === 'dev' ? nologger : nologger,
  jiraUrl: 'http://jira.soft-artel.com',
  adminID: 'f90db39c-37a8-4082-b2f0-1b193d423767',
};
