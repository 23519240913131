/* eslint-disable no-mixed-operators */
import moment from 'moment';
import {
  UPDATE_VACATION_SUCCESS,
  GET_VACATION_LIST_SUCCESS,
  GET_VACATION_STAT_SUCCESS,
  REMOVE_VACATION_SUCCESS,
  SWITCH_VACATION_SUCCESS,
} from '../actions/hospitalsActions';

export const getVacationsById = (state, ID) => {
  const allVacations = state[ID] && state[ID].vacations || [];
  const byYear = {};
  const newVacations = [];
  const accepted = [];
  allVacations.forEach((vacation) => {
    const year = new Date(vacation.startD).getFullYear();
    if (vacation.status === 'review') {
      newVacations.push(vacation);
    }
    if (vacation.status === 'accepted') {
      if (moment(vacation.startD) > moment()) {
        accepted.push(vacation);
      } else {
        if (byYear[year]) {
          byYear[year] = byYear[year].concat([vacation]);
        } else {
          byYear[year] = [vacation];
        }
      }
    }
  });
  return { newVacations, byYear, accepted } || { newVacations: [], byYear: {}, accepted: [] };
};

export const getAllVacationsByStatus = (vacations, status) => {
  const result = [];
  for (const emplID in vacations) {
    const emplVacations = vacations[emplID].vacations;
    for (let i = 0; i < emplVacations.length; i++) {
      const vacation = emplVacations[i];
      if (vacation.status === status) result.push(vacation);
    }
  }
  return result;
};

export const getStatByID = (vacations, ID) => vacations[ID] && vacations[ID].stat || {};

const getVacationObject = (emplVacation, obj, param) => {
  if (param === 'stat') {
    return {
      stat: obj,
      vacations: typeof emplVacation === 'undefined' ? [] : emplVacation.vacations,
    };
  }
  return {
    stat: typeof emplVacation === 'undefined' ? {} : emplVacation.stat,
    vacations: obj,
  };
};

export default function vacationsReducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_VACATION_SUCCESS: {
      let employeeVacations = [];
      if (state[action.data.empID]) {
        employeeVacations = state[action.data.empID].vacations.concat([action.data]);
      } else {
        employeeVacations = [action.data];
      }
      return Object.assign(
        {},
        state,
        {
          [action.data.empID]: getVacationObject(state[action.data.empID], employeeVacations, 'vacations'),
        },
      );
    }
    case REMOVE_VACATION_SUCCESS: {
      const employeeVacations = state[action.data.userID].vacations.filter(vacation => (
        vacation.id !== action.data.id
      ));
      return {
        ...state,
        [action.data.userID]: getVacationObject(state[action.data.userID], employeeVacations, 'vacations'),
      };
    }
    case SWITCH_VACATION_SUCCESS: {
      let userVacations = state[action.data.empID].vacations;
      let index = 0;
      let isExist = false;
      while (index < userVacations.length && !isExist) {
        if (userVacations[index].id === action.data.id) isExist = true;
        index++;
      }
      if (isExist) {
        const editedVacation = Object.assign({}, userVacations[index - 1], {
          status: 1,
        });
        userVacations = [
          ...userVacations.slice(0, index - 1),
          editedVacation,
          ...userVacations.slice(index),
        ];
      }
      return {
        ...state,
        [action.data.empID]: getVacationObject(state[action.data.empID], userVacations, 'vacations'),
      };
    }
    case GET_VACATION_LIST_SUCCESS: {
      let newState = { ...state };
      Object.keys(action.data).forEach((ID) => {
        const vacations = action.data[ID];
        newState = Object.assign({}, newState, {
          [ID]: getVacationObject(state[ID], vacations, 'vacations'),
        });
      });
      return newState;
    }
    case GET_VACATION_STAT_SUCCESS: {
      let newState = { ...state };
      Object.keys(action.data).forEach((ID) => {
        const stat = action.data[ID];
        newState = Object.assign({}, newState, {
          [ID]: getVacationObject(state[ID], stat, 'stat'),
        });
      });
      return newState;
    }
    case 'LOGOUT': {
      return {};
    }
    default:
      return state;
  }
}
