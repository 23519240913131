/*
eslint react/no-string-refs : 0
 */

/* eslint-disable no-mixed-operators */

import React     from 'react';
import { Link }  from 'react-router-dom';
import PropTypes from 'prop-types';
import moment    from 'moment';
import logo      from '../images/logo.png';
import gravatar  from '../utils/gravatar';
import helpers   from '../utils/viewHelpers';
// import '../styles/Menu.css';

moment.locale('ru');


class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = {
      isOpen: false,
      rightDropdownIsOpen: false,
      leftDropdownIsOpen: false,
    };
    this.getDropdownMenuRef = this.getDropdownMenuRef.bind(this);
    document.addEventListener('touchend', (event) => {
      if (event.target.className !== 'dropdown-item') {
        this.setState({ rightDropdownIsOpen: false, leftDropdownIsOpen: false });
      }
      if (event.target.tagName.toUpperCase() === 'A') {
        if (event.target.className.indexOf('logout-btn') !== -1) {
          this.props.logout();
        }
      }
    });
    document.addEventListener('mousedown', (event) => {
      if (event.target.className !== 'dropdown-item') {
        this.setState({ rightDropdownIsOpen: false, leftDropdownIsOpen: false });
      }
      if (event.target.tagName.toUpperCase() === 'A') {
        if (event.target.className.indexOf('logout-btn') !== -1) {
          this.props.logout();
        }
      }
    });
  }

  componentDidMount() {
    const self = this;
    window.onresize = (event) => {
      if (self.state.isOpen) {
        self.setState({ isOpen: event.target.innerWidth < 992 });
      }
    };
  }

  getDropdownMenuRef(node) {
    this._dropdownMenu = node;
  }
  toggleMenu(force) {
    if (force) {
      this.setState({ isOpen: false });
    } else {
      this.setState({ isOpen: !this.state.isOpen });
    }
  }

  toggleDropdown(event) {
    const { position } = event.currentTarget.dataset;
    if (event.target.className.indexOf('dropdown-item') !== 0) {
      if (position === 'right') {
        this.setState({ rightDropdownIsOpen: true });
      } else if (position === 'left') {
        this.setState({ leftDropdownIsOpen: true });
      }
    }
    else {
      this.setState({ rightDropdownIsOpen: false, leftDropdownIsOpen: false });
    }
  }
  render() {
    const { pathname, user, workstate, activeCount, getUserList } = this.props;
    const iteration = workstate && workstate.iteration ? workstate.iteration : null;
    const workHours = workstate && workstate.workedSec || 0;
    const sprintWorkHours = iteration && iteration.workedSec || 0;
    const status = workstate && workstate.state === 10 ? 'online' : 'offline';
    const sprintInfo = iteration ? `${moment(iteration.startD).format('D')} - ${moment(iteration.endD).format('D')} ${moment(iteration.startD).format('MMMM')} [${iteration.reqHrs}]` : '1 - 15 August [80]';
    const { done, undone } = helpers.getDoneUndoneTasksCount(iteration);
    return (
        <div>
        <div className="blueline" />
        <nav className="navbar navbar-toggleable navbar-light navbarMenu">
          <div className="navbar-elements">
            <span data-position="left" className="toggler-icon" onClick={this.toggleDropdown} />
            <div className="dropdown-menu-wrap left-dropdown-menu">
              <div ref="leftMenu" className={`dropdown-menu ${this.state.leftDropdownIsOpen ? 'show' : ''}`}>
                <a className="dropdown-item logout-btn">Logout</a>
                {
                   user.allows === 100 &&
                  <div>
                    <div className="dropdown-divider" />
                    <h6 className="dropdown-header">Administrate</h6>
                    <Link to="/admin/vacations" className="dropdown-item">Отпуска и больничные</Link>
                    <Link to="/" className="dropdown-item">Начисления зп</Link>
                    <Link to="/sprints" className="dropdown-item">Выработка по спринтам</Link>
                  </div>
                }
              </div>
            </div>

            <div ref="menu" className="menu">
              <div>
                <Link to="/" className="navbar-brand divider-right">
                  <img
                    src={logo}
                    width="30"
                    height="30"
                    className="d-inline-block"
                    alt=""
                    onClick={() => this.toggleMenu(true)}
                  />
                  <div onClick={() => this.toggleMenu(true)}>
                    <p>Soft-Artel</p>
                    <div>BackOffice</div>
                  </div>
                </Link>
                <ul className="navbar-nav">
                  <li className={`nav-item ${pathname === '/workstate' ? 'active' : ''} `}>
                    <Link to="/workstate" className="nav-link">
                      <div onClick={() => {
                        this.toggleMenu(true);
                        getUserList();
                      }}
                      >
                        Work State
                        <div className="badge badge-pill badge-success">{activeCount}</div>
                      </div>
                    </Link>
                  </li>
                  {user.isSupport === 1 && (
                   <li className={`team-link nav-item divider-right ${pathname.split('/')[1] === 'support' ? 'active' : ''} `}>
                   <Link to="/support" className="nav-link">
                     <div>
                       Support
                     </div>
                   </Link>
                   </li>
                  )}
                  <li className={`team-link nav-item divider-right ${pathname === '/team' ? 'active' : ''} `}>
                    <Link to="/team" className="nav-link">
                      <div onClick={() => {
                        this.toggleMenu(true);
                      }}
                      >
                        Команда
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex flex-row current-sprint-wrap ml-auto divider-left">
              <div className="d-flex flex-column current-sprint">
                <Link to={`/personalSprint/${user.ID}/${iteration && iteration.id || ''}`}>
                  <div className="current-sprint-title">Current sprint:</div>
                  <div className="current-sprint-date">{sprintInfo}</div>
                </Link>
              </div>

              <div className="d-flex flex-column current-sprint-numbers divider-left">
                <div>
                  <span className="current-sprint-hours">{helpers.HMfromSec(sprintWorkHours)}</span>
                </div>
                <div className="">
                  <span className="current-sprint-tasks__done">{done} </span>
                  <span className="current-sprint-tasks__undone">{undone}</span>
                </div>
              </div>
              <div data-position="right" className="d-flex flex-row current-user-info" onClick={this.toggleDropdown}>
                <img
                  className="rounded-circle"
                  src={gravatar(user.email, { size: 100 })}
                  alt=""
                />
                <span className="toggler-icon" />
                <div ref={this.getDropdownMenuRef} className={`d-flex flex-column align-items-end ${this.state.rightDropdownIsOpen ? 'show' : ''}`}>
                  <div className="user-name">{user.name} {user.surname}</div>
                  <div className="d-flex flex-row align-items-center current-user-status">
                    <div className={`rounded-circle ${status}`} />
                    <div className={`${status}`}>{status}</div>
                    <div>{helpers.HMfromSec(workHours)}</div>
                  </div>
                  <div className="dropdown-menu">
                    <h6 className="dropdown-header">Personal</h6>
                    <Link to="/employee" className="dropdown-item">Обо мне</Link>
                    <Link to="/vacations" className="dropdown-item">Отпуск и больничный</Link>
                    <Link to="/" className="dropdown-item">Начисления зп</Link>
                    <Link to="/team" className="dropdown-item">Команда</Link>
                    <Link to={`/personalSprint/${user.ID}/${iteration && iteration.id || ''}`} className="dropdown-item">
                      Выработка
                    </Link>
                    <a className="dropdown-item logout-btn">Logout</a>
                    {
                      user.allows === 100 &&
                      <div>
                        <div className="dropdown-divider" />
                        <h6 className="dropdown-header">Administrate</h6>
                        <Link to="/admin/vacations" className="dropdown-item">Отпуска и больничные</Link>
                        <Link to="/admin/newEmployee" className="dropdown-item">Создать нового пользователя</Link>
                        <Link to="/" className="dropdown-item">Начисления зп</Link>
                        <Link to="/sprints" className="dropdown-item">Выработка по спринтам</Link>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Menu;

Menu.propTypes = {
  pathname: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  getUserList: PropTypes.func.isRequired,
  workstate: PropTypes.shape().isRequired,
  activeCount: PropTypes.number.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    workstate: PropTypes.object,
    isSupport: PropTypes.number,
  }).isRequired,
};
