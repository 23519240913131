export const UPDATE_HOSPITAL = 'UPDATE_HOSPITAL';
export const UPDATE_HOSPITAL_REQUEST = 'UPDATE_HOSPITAL_REQUEST';
export const UPDATE_HOSPITAL_SUCCESS = 'UPDATE_HOSPITAL_SUCCESS';
export const UPDATE_HOSPITAL_FAILURE = 'UPDATE_HOSPITAL_FAILURE';

export function updateHospital(hospital, userID) {
  return {
    type: UPDATE_HOSPITAL,
    hospital,
    userID,
  };
}

export function updateHospitalRequest() {
  return {
    type: UPDATE_HOSPITAL_REQUEST,
  };
}

export function updateHospitalSuccess(data) {
  return {
    type: UPDATE_HOSPITAL_SUCCESS,
    data,
  };
}

export function updateHospitalFailure(err) {
  return {
    type: UPDATE_HOSPITAL_FAILURE,
    err,
  };
}

export const GET_HOSPITAL_LIST = 'GET_HOSPITAL_LIST';
export const GET_HOSPITAL_LIST_REQUEST = 'GET_HOSPITAL_LIST_REQUEST';
export const GET_HOSPITAL_LIST_SUCCESS = 'GET_HOSPITAL_LIST_SUCCESS';
export const GET_HOSPITAL_LIST_FAILURE = 'GET_HOSPITAL_LIST_FAILURE';

export function getHospitalList(userID, date, status) {
  return {
    type: GET_HOSPITAL_LIST,
    userID,
    date,
    status,
  };
}

export function getHospitalListRequest() {
  return {
    type: GET_HOSPITAL_LIST_REQUEST,
  };
}

export function getHospitalListSuccess(data) {
  return {
    type: GET_HOSPITAL_LIST_SUCCESS,
    data,
  };
}

export function getHospitalListFailure(err) {
  return {
    type: GET_HOSPITAL_LIST_FAILURE,
    err,
  };
}

export const UPDATE_VACATION = 'UPDATE_VACATION';
export const UPDATE_VACATION_REQUEST = 'UPDATE_VACATION_REQUEST';
export const UPDATE_VACATION_SUCCESS = 'UPDATE_VACATION_SUCCESS';
export const UPDATE_VACATION_FAILURE = 'UPDATE_VACATION_FAILURE';

export function updateVacation(vacation, userID) {
  return {
    type: UPDATE_VACATION,
    vacation,
    userID,
  };
}

export function updateVacationRequest() {
  return {
    type: UPDATE_VACATION_REQUEST,
  };
}

export function updateVacationSuccess(data) {
  return {
    type: UPDATE_VACATION_SUCCESS,
    data,
  };
}

export function updateVacationFailure(err) {
  return {
    type: UPDATE_VACATION_FAILURE,
    err,
  };
}

export const SWITCH_VACATION = 'SWITCH_VACATION';
export const SWITCH_VACATION_REQUEST = 'SWITCH_VACATION_REQUEST';
export const SWITCH_VACATION_SUCCESS = 'SWITCH_VACATION_SUCCESS';
export const SWITCH_VACATION_FAILURE = 'SWITCH_VACATION_FAILURE';

export function switchVacation(id, userID) {
  return {
    type: SWITCH_VACATION,
    id,
    userID,
  };
}

export function switchVacationRequest() {
  return {
    type: SWITCH_VACATION_REQUEST,
  };
}

export function switchVacationSuccess(data) {
  return {
    type: SWITCH_VACATION_SUCCESS,
    data,
  };
}

export function switchVacationFailure(err) {
  return {
    type: SWITCH_VACATION_FAILURE,
    err,
  };
}

export const GET_VACATION_LIST = 'GET_VACATION_LIST';
export const GET_VACATION_LIST_REQUEST = 'GET_VACATION_LIST_REQUEST';
export const GET_VACATION_LIST_SUCCESS = 'GET_VACATION_LIST_SUCCESS';
export const GET_VACATION_LIST_FAILURE = 'GET_VACATION_LIST_FAILURE';

export function getVacationList(login, date, status) {
  return {
    type: GET_VACATION_LIST,
    login,
    date,
    status,
  };
}

export function getVacationListRequest() {
  return {
    type: GET_VACATION_LIST_REQUEST,
  };
}

export function getVacationListSuccess(data) {
  return {
    type: GET_VACATION_LIST_SUCCESS,
    data,
  };
}

export function getVacationListFailure(err) {
  return {
    type: GET_VACATION_LIST_FAILURE,
    err,
  };
}

export const REMOVE_VACATION = 'REMOVE_VACATION';
export const REMOVE_VACATION_REQUEST = 'REMOVE_VACATION_REQUEST';
export const REMOVE_VACATION_SUCCESS = 'REMOVE_VACATION_SUCCESS';
export const REMOVE_VACATION_FAILURE = 'REMOVE_VACATION_FAILURE';

export function removeVacation(id, userID) {
  return {
    type: REMOVE_VACATION,
    id,
    userID,
  };
}

export function removeVacationRequest() {
  return {
    type: REMOVE_VACATION_REQUEST,
  };
}

export function removeVacationSuccess(data) {
  return {
    type: REMOVE_VACATION_SUCCESS,
    data,
  };
}

export function removeVacationFailure(err) {
  return {
    type: REMOVE_VACATION_FAILURE,
    err,
  };
}

export const REMOVE_HOSPITAL = 'REMOVE_HOSPITAL';
export const REMOVE_HOSPITAL_REQUEST = 'REMOVE_HOSPITAL_REQUEST';
export const REMOVE_HOSPITAL_SUCCESS = 'REMOVE_HOSPITAL_SUCCESS';
export const REMOVE_HOSPITAL_FAILURE = 'REMOVE_HOSPITAL_FAILURE';

export function removeHospital(id) {
  return {
    type: REMOVE_HOSPITAL,
    id,
  };
}

export function removeHospitalRequest() {
  return {
    type: REMOVE_HOSPITAL_REQUEST,
  };
}

export function removeHospitalSuccess(data) {
  return {
    type: REMOVE_HOSPITAL_SUCCESS,
    data,
  };
}

export function removeHospitalFailure(err) {
  return {
    type: REMOVE_HOSPITAL_FAILURE,
    err,
  };
}

export const UPLOAD_HOSPITAL = 'UPLOAD_HOSPITAL';
export const UPLOAD_HOSPITAL_REQUEST = 'UPLOAD_HOSPITAL_REQUEST';
export const UPLOAD_HOSPITAL_SUCCESS = 'UPLOAD_HOSPITAL_SUCCESS';
export const UPLOAD_HOSPITAL_FAILURE = 'UPLOAD_HOSPITAL_FAILURE';

export function uploadHospital(file, docType, desc, ID) {
  return {
    type: UPLOAD_HOSPITAL,
    file,
    docType,
    desc,
    ID,
  };
}

export function uploadHospitalRequest() {
  return {
    type: UPLOAD_HOSPITAL_REQUEST,
  };
}

export function uploadHospitalSuccess(data) {
  return {
    type: UPLOAD_HOSPITAL_SUCCESS,
    data,
  };
}

export function uploadHospitalFailure(err) {
  return {
    type: UPLOAD_HOSPITAL_FAILURE,
    err,
  };
}

export const SWITCH_HOSPITAL = 'SWITCH_HOSPITAL';
export const SWITCH_HOSPITAL_REQUEST = 'SWITCH_HOSPITAL_REQUEST';
export const SWITCH_HOSPITAL_SUCCESS = 'SWITCH_HOSPITAL_SUCCESS';
export const SWITCH_HOSPITAL_FAILURE = 'SWITCH_HOSPITAL_FAILURE';

export function switchHospital(id, docID, endD) {
  return {
    type: SWITCH_HOSPITAL,
    id,
    docID,
    endD,
  };
}

export function switchHospitalRequest() {
  return {
    type: SWITCH_HOSPITAL_REQUEST,
  };
}

export function switchHospitalSuccess(data) {
  return {
    type: SWITCH_HOSPITAL_SUCCESS,
    data,
  };
}

export function switchHospitalFailure(err) {
  return {
    type: SWITCH_HOSPITAL_FAILURE,
    err,
  };
}

export const GET_VACATION_STAT = 'GET_VACATION_STAT';
export const GET_VACATION_STAT_REQUEST = 'GET_VACATION_STAT_REQUEST';
export const GET_VACATION_STAT_SUCCESS = 'GET_VACATION_STAT_SUCCESS';
export const GET_VACATION_STAT_FAILURE = 'GET_VACATION_STAT_FAILURE';

export function getVacationStat(userID) {
  return {
    type: GET_VACATION_STAT,
    userID,
  };
}

export function getVacationStatRequest() {
  return {
    type: GET_VACATION_STAT_REQUEST,
  };
}

export function getVacationStatSuccess(data) {
  return {
    type: GET_VACATION_STAT_SUCCESS,
    data,
  };
}

export function getVacationStatFailure(err) {
  return {
    type: GET_VACATION_STAT_FAILURE,
    err,
  };
}

export const GET_HOSPITAL_STAT = 'GET_HOSPITAL_STAT';
export const GET_HOSPITAL_STAT_REQUEST = 'GET_HOSPITAL_STAT_REQUEST';
export const GET_HOSPITAL_STAT_SUCCESS = 'GET_HOSPITAL_STAT_SUCCESS';
export const GET_HOSPITAL_STAT_FAILURE = 'GET_HOSPITAL_STAT_FAILURE';

export function getHospitalStat(userID) {
  return {
    type: GET_HOSPITAL_STAT,
    userID,
  };
}

export function getHospitalStatRequest() {
  return {
    type: GET_HOSPITAL_STAT_REQUEST,
  };
}

export function getHospitalStatSuccess(data) {
  return {
    type: GET_HOSPITAL_STAT_SUCCESS,
    data,
  };
}

export function getHospitalStatFailure(err) {
  return {
    type: GET_HOSPITAL_STAT_FAILURE,
    err,
  };
}
