import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getAvatarBase64ByPhoneAndID } from '../../../utils/helpers';
import * as fromStyled from '../../styles';

export default function Avatar({
  height,
  ID,
  phone,
}) {
  const [base64, setBase64] = useState();
  getAvatarBase64ByPhoneAndID(ID, phone).then((data) => {
    setBase64(data);
  });
  return (
    <div>
      <fromStyled.Avatar height={height} base64={base64} />
    </div>
  );
}

Avatar.propTypes = {
  height: PropTypes.number.isRequired,
  ID: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};
