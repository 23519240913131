import React from 'react';
import PropTypes from 'prop-types';
import { Route, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';

import styled from 'styled-components';
import supportIcon from '../../../images/supportIcon.svg';

import AvatarWithIcon from '../../ui/AvatarWithIcon';
import Pillow from '../../ui/Pillows';
import SubscribeInfo from '../../ui/SubscribeInfo';
import Field from '../../ui/Fields';
import NameSurnameField from '../../ui/Fields/NameSurnameField';
import ServiceField from '../../ui/Fields/ServiceField';
import IdField from '../../ui/Fields/IdField';
import RegistrationDate from '../../ui/RegistrationDate';
import DateTime from '../../ui/DateTime';
import SendMessage from '../Modal/SendMessage';
import Modal from '../Modal';

export default function UserListItem({
  data,
  onSelectUser,
}) {
  const history = useHistory();
  const alert = useAlert();

  const { ID, appVersion, comment, createDT, email, emailConfirmed,
    extraEmail, lang, locale, modifyDT, name, phone,
    phoneConfirmed, registeredDays, surname, usertype, subscrEndDays } = data;

  const language = lang.toUpperCase();

  const onClick = (e) => {
    const { target: { dataset } } = e;
    if (dataset.field === 'phone' || dataset.field === 'email') {
      history.push(`support/modal/sendMessage/${ID}`);
      console.log('Отправка сообщения');
    } else if (dataset.field === 'id') {
      // Добавление ID в буфер обмена
      alert.show(<span style={{ textTransform: 'none' }}>Cкопировано.</span>);

      navigator.clipboard.writeText(ID);
    } else {
      onSelectUser(ID);
      history.push(`support/userCard/${ID}`);
    }
  };

  return (
    <>
      <Wrapper onClick={onClick}>
        <LeftInfo>
          <AvatarWithIcon ID={ID} phone={phone} size="small" userType={usertype} />
          <MainInfo>
            <div className="nameSubascribes">
              <NameSurnameField size="middle" name={name} surname={surname} />
              <SubscribesCount>
                <SubscribeInfo type="" daysCount={subscrEndDays} size="small" />
              </SubscribesCount>
            </div>
            <div className="numberEmail">
              <Field active={phoneConfirmed || true} size="small" content={phone} type="phone" onClick={onClick} />
              <Field active={emailConfirmed} size="small" content={email} type="email" onClick={onClick} />
            </div>
          </MainInfo>
          <div className="localMail">
            <div className="local">
              <span>{locale}</span>
              <Pillow content={language} size="middle" color="#ADADAD" />
            </div>
            <div className="extraEmail">
              <span>{extraEmail}</span>
            </div>
          </div>

        </LeftInfo>
        <RightInfo>
          <RightInfoWrapper>
            <div className="firstBlock">
              <div className="firstBlock-header">
                <RegistrationDate size="small" createDT={createDT} registeredDays={registeredDays} />
              </div>
              <div className="firstBlock-footer">
                <ServiceField userType={appVersion} />
              </div>
            </div>
            <div className="middleBlock">
              <IdField onClick={onClick} size="small" content={ID} />
              <span className="middleBlock__comments">{comment}</span>
            </div>
            <div className="lastBlock">
              <DateTime large DT={modifyDT} />
              <img src={supportIcon} alt="supportIcon" />
            </div>
          </RightInfoWrapper>
        </RightInfo>
        <Route
          path="/support/modal/sendMessage/:id"
          render={() => (
            <Modal
              title="Отправить сообщение"
              Component={SendMessage}
              props={{
                ID,
                email,
                extraEmail,
                phone,
              }}
            />
          )}
        />
      </Wrapper>

    </>
  );
}

const Wrapper = styled.div`
    display: flex;
    width: 1110px;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(167, 167, 167, 0.1);
    border-radius: 6px;
    margin-top: 4px;
    cursor: pointer;
`;

const LeftInfo = styled.div`
    display: flex; 
    width: 633px;
    height: 100%;
    align-items: center;
    padding: 12px 7px 10px 10px;
    .localMail {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        .local {
            margin-left: auto;
            font-size: 12px;
            line-height: 14px;
            color: #ADADAD;
            & :last-child {
              margin-left: 4px;
            }
        }
        .extraEmail {
            font-size: 11px;
            line-height: 13px;
            color: #CCCCCC;
        }
    }
`;

const RightInfo = styled.div`
    width: 477px;
    height: 64px;
    background: #FAFAFA;
    border-radius: 0px 6px 6px 0px;
    margin: 0 0 0 auto;
`;

const MainInfo = styled.div`
    margin-left: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .nameSubascribes {
        display: flex;
        align-items: center;
    }
    .numberEmail {
        & :not(:first-child) {
            margin-left: 47px;
        }
    }
`;

const SubscribesCount = styled.div`
    margin-left: 10px;
    display: flex;
`;

const RightInfoWrapper = styled.div`
    padding: 11px 15px 10px 14px;
    width: 100%;
    height: 100%;
    display: flex;
    .firstBlock {
        height: 100%;
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;      
    }
    .middleBlock {
        display: flex;
        margin-left: auto;       
        flex-direction: column;
        justify-content: space-between;
        span[data-field='id'] {
          &:hover {
            color: #525252;
          }
        }
        &__comments {
          max-height: 24px;
          font-size: 10px;
          line-height: 12px;  
          color: #007BFF;
          width: 186px;
          overflow: hidden; /* Обрезаем содержимое */
          text-overflow: ellipsis; /* Добавляем три точки */
        }
    }
    .lastBlock {
        display: flex;
        align-items: center;
        margin-left: auto;
        img {
            margin-left: 22px;
        }
    }
`;

UserListItem.propTypes = {
  data: PropTypes.shape({
    ID: PropTypes.string,
    appVersion: PropTypes.string,
    comment: PropTypes.string,
    createDT: PropTypes.string,
    deviceVersion: PropTypes.string,
    email: PropTypes.string,
    emailConfirmed: PropTypes.bool,
    extraEmail: PropTypes.string,
    lang: PropTypes.string,
    locale: PropTypes.string,
    modifyDT: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    phoneConfirmed: PropTypes.bool,
    registeredDays: PropTypes.number,
    subscrEndDays: PropTypes.number,
    surname: PropTypes.string,
    usertype: PropTypes.string,
  }),
  onSelectUser: PropTypes.func.isRequired,
};

UserListItem.defaultProps = {
  data: {},
};
