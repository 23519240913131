import React from 'react';
import styled from 'styled-components';
import withTooltip from '../../common/withTooltip';

const Wrapper = styled.span`
{
    color: #FFFFFF;
    text-align: center;
    padding: 2px 3px;
    opacity: ${({ isExist }) => isExist ? '1' : '0'};
    background: ${({ color }) => color || '#C1C1C1'};
    border-radius: 4px;
    ${({ size }) => {
    switch (size) {
      case 'small':
      {
        return (
          `font-size: 9px;
                     line-height: 11px;
                    `
        );
      }
      case 'middle':
      {
        return (
          `font-size: 12px;
                     line-height: 14px;
                     padding: 2px 4px;
                    `
        );
      }
      case 'large':
      {
        return (
          `font-size: 14px;
                     line-height: 17px;
                     padding: 2px 4px;

                    `
        );
      }
      default: {
        return (
          `font-size: 9px;
                     line-height: 11px;
                    `
        );
      }
    }
  }

}`;

export default function Pillow({
  tooltip,
  content,
  color,
  size,
}) {
  const component = <Wrapper color={color} size={size} isExist={content}>{content}</Wrapper>;
  if (tooltip) {
    return withTooltip(component, tooltip.placement, tooltip.text);
  }
  return component;
}
