import React      from 'react';
import PropTypes  from 'prop-types';
import ReactModal from 'react-modal';
import helpers    from '../../utils/viewHelpers';

class IssueItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qcc: this.props.issue.qcc,
      comment: '',
      edit: false,
    };
    this.coefChangeHandler = (ev) => {
      const value = +ev.target.value;
      if (value >= 0 && value <= 2) {
        this.setState({ qcc: ev.target.value });
      }
    };
    this.commentChangeHandler = (ev) => {
      const { value } = ev.target;
      this.setState({ comment: value });
    };
    this.toggle = (state) => {
      this.setState({ edit: state });
    };
    this.cancelModal = () => {
      this.setState({ qcc: this.props.issue.qcc });
    };
    this.saveCoef = () => {
      const { issue, setQccIssues, iterId } = this.props;
      setQccIssues(issue.issueKey, issue.emplID, iterId, this.state.qcc, this.state.comment);
    };
  }
  render() {
    const { issue, isAdmin } = this.props;
    const moneyColor = +issue.qcc > 1 ? 'success' : issue.qcc < 1 ? 'failure' : '';
    return (
      <div className="issue d-flex flex-row">
        <div className="c1 d-flex flex-column">
          <div className="issue-info d-flex flex-row align-items-center">
            <img src={issue.iconType} alt="" />
            <a target="_blank" href={`http://jira.soft-artel.com/browse/${issue.issueKey}`}>{issue.issueKey}</a>
            <div className="badge badge-primary">{issue.status}</div>
          </div>
          <div className="issue-title">{issue.summary}</div>
          <div className="components-label">Components</div>
        </div>
        <div className="c2 d-flex flex-column ml-auto">{helpers.HMfromSec(issue.timeEstimate, 'h')}</div>
        <div className="c3 d-flex flex-column">
          <div className="issue-hours">{helpers.HMfromSec(issue.workedSec, 'hm')}</div>
          <div className={`issue-money ${moneyColor}`}>
            {issue.moneyTotal ? `${helpers.formatMoney(issue.moneyTotal)} \u20bd` : ''}
          </div>
          {
            isAdmin &&
            <ReactModal
              isOpen={this.state.edit}
              contentLabel="Inline Styles Modal Example"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0,0,0,0.3)',
                },
                content: {
                  color: 'lightsteelblue',
                  top: 'calc(35%)',
                  left: 'calc(35%)',
                  height: '260px',
                  width: '400px',
                  border: '1px solid silver',
                },
              }}
            >
              <div className="form-group">
                <label>Введите новый коэффициент</label>
                <input
                  className="form-control"
                  value={this.state.qcc}
                  onChange={this.coefChangeHandler}
                  type="number"
                  step="0.25"
                  min="0"
                  max="2"
                />
              </div>
              <div className="form-group">
                <label>Введите комментарий</label>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.comment}
                  onChange={this.commentChangeHandler}
                />
              </div>
              <div className="d-flex justify-content-around">
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => { this.toggle(false); this.cancelModal(); }}
                >
                  Отменить
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  onClick={() => { this.toggle(false); this.saveCoef(); }}
                >
                  Сохранить
                </button>
              </div>
            </ReactModal>
          }
          <div>
            <div
              className={`issue-qcoefficient-value ${this.state.qcc === 1 ? '' : 'bold'}`}
              onClick={() => { this.toggle(true); }}
              title={this.state.qcc === 1 ? '' : issue.qcNote}
            >
              qC: <span>{this.state.qcc}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

IssueItem.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  setQccIssues: PropTypes.func.isRequired,
  iterId: PropTypes.number.isRequired,
  issue: PropTypes.shape().isRequired,
};

export default IssueItem;
