import BORequest from '../boModels/request';
import { apiConfig, defaultlogger } from '../../../shared/config';

const WORKSTATE_LOCATION = 'workstate';

function* fetchUserWorkState(device) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${WORKSTATE_LOCATION}/self`);
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* fetchAllWorkState(device) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${WORKSTATE_LOCATION}/team`);
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* switchWorkstate(device, ID = 'self', state = '') {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${WORKSTATE_LOCATION}/switch`, {
      ID,
      state,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* getWorkLogs(device, user = 'all', iteration) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${WORKSTATE_LOCATION}/iteration`, {
      user,
      iteration,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

export { fetchUserWorkState, fetchAllWorkState, switchWorkstate, getWorkLogs };
