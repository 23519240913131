import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Nav from 'react-bootstrap/Nav';

import * as fromStyled from '../../../styles';

import ContactItem from './ContactItem';

const ContentWrapper = styled(fromStyled.List)`
      li {
          padding-top: 15px;
          padding-bottom: 13px;
          &:first-child {
            padding-top: 0;
          }
          &:not(:last-child) {
            border-bottom: 1px solid #ECECEC;
          }
      }
`;

export default function Contacts({
  contacts,
}) {
  const [key, setKey] = useState('incomingRequests');

  const { incomingRequests, userWaits, userBlocked, blockedUser } = contacts;
  const changeKey = (k) => {
    setKey(k);
  };

  const renderContactsListItems = (activeKey) => activeKey.map((contact) => (
    <ContactItem
      key={contact.ID}
      contact={contact}
      incomingRequests={activeKey === incomingRequests}
      userWaits={activeKey === userWaits}
      userBlocked={activeKey === userBlocked}
      blockedUser={activeKey === blockedUser}
    />
  ));

  const renderContactsList = () => {
    switch (key) {
      case 'userWaits': {
        const userWaitsContactList = renderContactsListItems(userWaits);
        return userWaitsContactList;
      }
      case 'userBlocked': {
        const userBlockedContactList = renderContactsListItems(userBlocked);
        return userBlockedContactList;
      }
      case 'blockedUser': {
        const blockedUserContactList = renderContactsListItems(blockedUser);
        return blockedUserContactList;
      }
      case 'incomingRequests':
      default: {
        const incomingRequestsContactList = renderContactsListItems(incomingRequests);
        return incomingRequestsContactList;
      }
    }
  };

  const incomingRequestsCount = incomingRequests.length;
  const userWaitsCount = userWaits.length;
  const userBlockedCount = userBlocked.length;
  const blockedUserCount = blockedUser.length;

  return (
    <Wrapper>
      <Nav
        fill
        variant="tabs"
        defaultActiveKey={key}
        onSelect={changeKey}
      >
        <Nav.Item>
          <Nav.Link as="button" eventKey="incomingRequests">
            Входящие
            <span>{incomingRequestsCount}</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as="button" eventKey="userWaits">
            Исходящие
            <span>{userWaitsCount}</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as="button" eventKey="userBlocked">
            Заблокированные
            <span>{userBlockedCount}</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as="button" eventKey="blockedUser">
            Заблокировавшие
            <span>{blockedUserCount}</span>
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <ContentWrapper>
        {renderContactsList()}
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    height: 100%;
    overflow: hidden;
    .nav {
      margin-bottom: 12px;
    }
    .nav-link {
        font-size: 14px;
        line-height: 24px;
        color: #C9C9C9;
        width: -webkit-fill-available;
        background-color: #fff;
        border-bottom: 1px solid #dee2e6;
        padding: 8px 0;
        span {
          margin-left: 5px;
          display: inline-block;
          font-size: 10px;
          line-height: 12px;
          width: 14px;
          height: 14px;  
          background: #C4C4C4;
          color: #ffffff;
          border-radius: 50%;
          text-align: center;
        }
        &.active {
          color: #000000;
          span {
            background: #000000;
            color: #ffffff;
          }
        }
      }
`;

Contacts.propTypes = {
  contacts: PropTypes.shape({
    blockedUser: PropTypes.arrayOf(PropTypes.shape()),
    deletedUser: PropTypes.arrayOf(PropTypes.shape()),
    incomingRequests: PropTypes.arrayOf(PropTypes.shape()),
    userBlocked: PropTypes.arrayOf(PropTypes.shape()),
    userWaits: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};
