/* eslint-disable no-mixed-operators */
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import slug from '../../utils/slug';
import '../../styles/employee-form.css';
import { Loader, Overlay } from '../Loader';

const developersOptions = [
  <option value="Junior Developer">Junior Developer</option>,
  <option value="Middle Developer">Middle Developer</option>,
  <option value="Senior Developer">Senior Developer</option>,
];

const qaOptions = [
  <option value="Junior QA Engineer">Junior QA Engineer</option>,
  <option value="Middle QA Engineer">Middle QA Engineer</option>,
  <option value="Senior QA Engineer">Senior QA Engineer</option>,
  <option value="QA Lead">QA Lead</option>,
];

const qaTeam = 'QA And Support Team';
const developersTeam = 'Developers Team';

const trim = value => value && value.trim();

class NewEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = (e) => {
      e.preventDefault();
      this.props.signupUserRequest();
    };
    this.onChange = (e) => {
      const fieldName = e.target.getAttribute('name');
      let login = '';
      if (fieldName === 'name') {
        login = `${e.target.value}${this.props.surname ? `.${this.props.surname[0]}` : ''}`;
      } else if (fieldName === 'surname') {
        login = `${this.props.name ? `${this.props.name}.` : ''}${(e.target.value && e.target.value[0]) || ''}`;
      }
      this.props.dispatch(change('newUser', 'login', slug(login, { lower: true, remove: null })));
    };
  }
  render() {
    const { department, isPending } = this.props;
    return (
      <div className="employee-form container">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Имя</label>
            <Field
              name="name"
              component="input"
              type="text"
              placeholder="Имя"
              className="form-control"
              onChange={this.onChange}
              normalize={trim}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="surname">Фамилия</label>
            <Field
              name="surname"
              component="input"
              type="text"
              placeholder="Фамилия"
              className="form-control"
              onChange={this.onChange}
              normalize={trim}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Номер телефона</label>
            <Field
              name="phone"
              component="input"
              type="text"
              placeholder="Номер телефона"
              className="form-control"
              normalize={trim}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="login">Логин</label>
            <Field
              name="login"
              component="input"
              type="text"
              placeholder="Логин"
              className="form-control"
              normalize={trim}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Пароль</label>
            <Field
              name="password"
              component="input"
              type="password"
              placeholder="Пароль"
              className="form-control"
              normalize={trim}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <Field
              name="email"
              component="input"
              type="email"
              placeholder="Email"
              className="form-control"
              normalize={trim}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="office">Офис</label>
            <Field name="office" component="select" className="form-control" required>
              <option />
              <option value="Kazan">Казань</option>
              <option value="Limassol">Лимассол</option>
            </Field>
          </div>
          <div className="form-group">
            <label htmlFor="department">Отдел</label>
            <Field name="department" component="select" className="form-control" required>
              <option />
              <option value="Developers Team">Developers Team</option>
              <option value="QA And Support Team">QA And Support Team</option>
            </Field>
          </div>
          <div className="form-group">
            <label htmlFor="position">Должность</label>
            <Field name="position" component="select" className="form-control" required>
              <option />
              {department === qaTeam ? qaOptions : department === developersTeam ? developersOptions : []}
            </Field>
          </div>
          <div className="form-group">
            <label htmlFor="salary">Оклад</label>
            <Field
              name="salary"
              component="input"
              type="number"
              placeholder="Оклад"
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="award">Премия</label>
            <Field name="award" component="input" type="number" placeholder="Оклад" className="form-control" required />
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              Обновить
            </button>
          </div>
        </form>
        {isPending && (
          <Overlay>
            <Loader show />
          </Overlay>
        )}
      </div>
    );
  }
}

NewEmployee.propTypes = {
  signupUserRequest: PropTypes.func.isRequired,
  department: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
};

NewEmployee = reduxForm({
  form: 'newUser',
})(NewEmployee);

const selector = formValueSelector('newUser');
NewEmployee = connect((state) => {
  const department = selector(state, 'department');
  const name = selector(state, 'name');
  const surname = selector(state, 'surname');
  return {
    department,
    name,
    surname,
  };
})(NewEmployee);

export default NewEmployee;
