import React        from 'react';
import PropTypes    from 'prop-types';
import { connect }  from 'react-redux';
import { bindActionCreators } from 'redux';
import VacationItem from '../components/adminVacations/VacationItem';
import EmployeeItem from '../components/adminVacations/EmployeeItem';
import { getEmployee, getAllEmployees } from '../redux/reducers/employees';
import {
  getAllVacationsByStatus,
  getStatByID,
} from '../redux/reducers/vacations';

import {
  getStatByID as getHospitalStatByID,
} from '../redux/reducers/hospitals';

import {
  getVacationList,
  getVacationStat,
  getHospitalList,
  getHospitalStat,
  switchVacation,
  removeVacation,
}  from '../redux/actions/hospitalsActions';

import '../styles/adminVacations.css';

class AdminVacations extends React.Component {
  componentWillMount() {
    this.props.getVacationList('all', '', 'all');
    this.props.getHospitalList('all', '', 'all');
    this.props.getVacationStat('all');
    this.props.getHospitalStat('all');
  }
  render() {
    const {
      vacations, employeeById, employees,
    } = this.props;
    const vacationsList = vacations.map((vacation) => {
      const employee = employeeById(vacation.empID);
      return (
        <VacationItem
          key={`vacation#${vacation.id}`}
          email={employee.email}
          name={employee.name}
          surname={employee.surname}
          startD={vacation.startD}
          ID={vacation.id}
          endD={vacation.exitD}
          userID={employee.ID}
          comment={vacation.comment}
          available={vacation.available}
          days={vacation.days}
          onAccept={this.props.switchVacation}
          onReject={this.props.removeVacation}
        />
      );
    });
    const employeesVacations = employees.map(empl => (
      <EmployeeItem
        key={`employee#${empl.ID}`}
        ID={empl.ID}
        email={empl.email}
        name={empl.name}
        surname={empl.surname}
        position={empl.position}
        stat={this.props.getStatByID(empl.ID)}
        hospitalStat={this.props.getHospitalStatByID(empl.ID)}
      />
    ));
    return (
      <div className="">
        <div className="admin-vacations-list">
          {vacationsList}
        </div>
        <div className="admin-employees-list d-flex flex-wrap">
          {employeesVacations}
        </div>
      </div>
    );
  }
}

AdminVacations.propTypes = {
  switchVacation: PropTypes.func.isRequired,
  removeVacation: PropTypes.func.isRequired,
  getVacationList: PropTypes.func.isRequired,
  getHospitalList: PropTypes.func.isRequired,
  getVacationStat: PropTypes.func.isRequired,
  getHospitalStat: PropTypes.func.isRequired,
  getStatByID: PropTypes.func.isRequired,
  getHospitalStatByID: PropTypes.func.isRequired,
  employeeById: PropTypes.func.isRequired,
  vacations: PropTypes.arrayOf(PropTypes.object).isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function mapStateToProps(state) {
  return {
    vacations: getAllVacationsByStatus(state.vacations, 'review'),
    employeeById: ID => getEmployee(state.employees.byId, ID),
    employees: getAllEmployees(state.employees),
    getStatByID: ID => getStatByID(state.vacations, ID),
    getHospitalStatByID: ID => getHospitalStatByID(state.hospitals.byUserId, ID),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getVacationList: bindActionCreators(getVacationList, dispatch),
    getVacationStat: bindActionCreators(getVacationStat, dispatch),
    getHospitalList: bindActionCreators(getHospitalList, dispatch),
    getHospitalStat: bindActionCreators(getHospitalStat, dispatch),
    switchVacation: bindActionCreators(switchVacation, dispatch),
    removeVacation: bindActionCreators(removeVacation, dispatch),
  };
}

const AdminVacationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminVacations);

export default AdminVacationsContainer;
