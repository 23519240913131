import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function SubscribePillow({
  type,
  size,
}) {
  return (
    <Wrapper disabled={size === 'small'} size={size} type={type}>{type}</Wrapper>
  );
}

const Wrapper = styled.button.attrs(({ size }) => ({ disabled: size === 'small' }))`
    background: ${({ type }) => type === 'basic' ? '#2EA8E5' : type === 'premium' ? '#E4A500' : '#949494'} ;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: bold;
    ${({ size }) => {
    switch (size) {
      case 'small': {
        return `font-size: 8px;
                line-height: 20px;
                border-radius: 4px;
                width: 48px;
                height: 22px;
                display: inline-block;
                padding: 0;
               `;
      }
      case 'large': {
        return `font-size: 22px;
                line-height: 20px;
                border-radius: 6px;
                padding: 2px 10px;
                height: 33px;
                `;
      }
      default: {
        return `font-size: 8px;
                line-height: 20px;
                border-radius: 4px;
                width: 48px;
                height: 22px;
                `;
      }
    }
  }}`;

SubscribePillow.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};
