import { connect } from 'react-redux';
import Team from '../components/team';
import { getDepartment } from '../redux/reducers/employees';
import { getDevice } from '../redux/reducers/device';
import { getUserCard } from '../redux/api/userApi';

function mapStateToProps(state) {
  return {
    currentUserID: state.user.ID,
    allows: state.user.allows,
    developers: getDepartment(state.employees, 'Developers', state.workstates, 'all'),
    other: getDepartment(state.employees, 'QA, Admins, UIX', state.workstates, 'all'),
    getUserCard: ID => getUserCard(getDevice(state).device, ID),
  };
}

function mapDispatchToProps() {
  return {};
}

const TeamContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Team);

export default TeamContainer;
