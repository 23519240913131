/* eslint-disable no-mixed-operators */
import { combineReducers } from 'redux';
import moment              from 'moment';
import {
  GET_ITERATION_SUCCESS,
  GET_ALL_ITERATIONS_SUCCESS,
  GET_ITERATION_FOR_ALL_USERS_SUCCESS,
  GET_ITERATION_FOR_ALL_USERS_REQUEST,
  GET_ITERATION_FOR_ALL_USERS_FAILURE,
  GET_ITERATION_LIST_BY_USER_SUCCESS,
  GET_ITERATION_REQUEST,
  GET_ITERATION_FAILURE,
} from '../actions/iterationActions';

import {
  LOGOUT,
} from '../actions/deviceActions';

export const getIterationForUser = (state, userID, iterID) => (
  state[userID] && state[userID].byID[iterID] || {}
);

export const getIterationsByID = (state, iterID) => {
  const result = [];
  Object.keys(state).forEach((userID) => {
    const { indexes, DS } = state[userID].list;
    let i = 0;
    let isExist = false;
    while (i < indexes.length && !isExist) {
      if (DS[indexes[i]].iterId === iterID) {
        isExist = true;
        result.push({
          userID,
          iteration: DS[indexes[i]],
        });
      }
      i++;
    }
  });
  return result;
};

function splitArrayOfIterations(array, currentObject) {
  const indexes = [];
  const DS = Object.assign({}, currentObject && currentObject.DS || {});
  for (let i = 0; i < array.length; i++) {
    indexes.push(array[i].iterId);
    DS[array[i].iterId] = array[i];
  }
  return {
    indexes,
    DS,
  };
}

function sortedIndex(array, value) {
  let low = 0;
  let high = array.length;

  while (low < high) {
    const mid = low + high >>> 1;
    if (array[mid] > value) low = mid + 1;
    else high = mid;
  }
  return low;
}

function addIteration(iteration, listOfIterations) {
  if (listOfIterations.indexes.indexOf(iteration.iterId) === -1) {
    const arr = listOfIterations.indexes.map(index => (+moment(listOfIterations.DS[index].startD)));
    const location = sortedIndex(arr, +moment(iteration.startD));
    listOfIterations.indexes.splice(location, 0, iteration.iterId);
  }
  listOfIterations.DS[iteration.iterId] = iteration;
}

function byUserID(state = {}, action = {}) {
  switch (action.type) {
    case GET_ITERATION_SUCCESS: {
      const newState = { ...state };
      if (newState[action.data.ID]) {
        newState[action.data.ID].byID[action.data.iteration.id] = action.data;
        newState[action.data.ID].loading = false;
      } else {
        newState[action.data.ID] = {
          byID: {
            [action.data.iteration.id]: action.data,
          },
          list: {
            indexes: [],
            DS: {},
          },
          loading: false,
        };
      }
      return newState;
    }
    case GET_ITERATION_LIST_BY_USER_SUCCESS: {
      if (action.data.length) {
        const newState = Object.assign({}, state);
        const listOfIterations = splitArrayOfIterations(
          action.data,
          newState[action.data[0].ID] && newState[action.data[0].ID].list || undefined,
        );
        return Object.assign({}, newState, {
          [action.data[0].ID]: Object.assign({}, newState[action.data[0].ID], {
            list: listOfIterations,
          }),
        });
      }
      return state;
    }
    case LOGOUT: {
      return {};
    }
    case GET_ITERATION_REQUEST: {
      const newState = { ...state };
      if (action.data && action.data.userID && action.data.userID.length === 36) {
        if (newState[action.data.userID]) {
          newState[action.data.userID].loading = true;
        } else {
          newState[action.data.userID] = {
            byID: {},
            list: {
              indexes: [],
              DS: {},
            },
            loading: true,
          };
        }
      }
      return newState;
    }
    case GET_ITERATION_FAILURE: {
      const newState = { ...state };
      if (action.data && newState[action.data.userID]) {
        newState[action.data.userID].loading = false;
      }
      return newState;
    }
    case GET_ITERATION_FOR_ALL_USERS_SUCCESS: {
      const newState = { ...state };
      action.data.forEach((iter) => {
        if (newState[iter.ID]) {
          addIteration(iter, newState[iter.ID].list);
        } else {
          newState[iter.ID] = {
            byID: {},
            list: splitArrayOfIterations([iter]),
            loading: false,
          };
        }
      });
      return newState;
    }
    default:
      return state;
  }
}

function splitArrayToIndexesAndById(array) {
  const indexes = [];
  const byId = {};
  array.forEach((iteration) => {
    indexes.push(iteration.id);
    byId[iteration.id] = { ...iteration, loading: false };
  });
  return {
    indexes,
    byId,
  };
}

function list(state = { indexes: [], byId: {} }, action = {}) {
  switch (action.type) {
    case GET_ALL_ITERATIONS_SUCCESS: {
      return splitArrayToIndexesAndById(action.data);
    }
    case GET_ITERATION_FOR_ALL_USERS_REQUEST:
    case GET_ITERATION_FOR_ALL_USERS_FAILURE:
    case GET_ITERATION_FOR_ALL_USERS_SUCCESS: {
      if (action.data && action.data.length) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [action.data[0].iterId]: {
              ...state.byId[action.data[0].iterId],
              loading: action.type === GET_ITERATION_FOR_ALL_USERS_REQUEST,
            },
          },
        };
      }
      return state;
    }
    case LOGOUT: {
      return { indexes: [], byId: {} };
    }
    default:
      return state;
  }
}

export default combineReducers({
  byUserID,
  list,
});
