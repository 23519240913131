/* eslint-disable no-mixed-operators */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { transitIssue } from '../redux/actions/employeesActions';
import { resetJustLogin } from '../redux/actions/appActions';
import { deleteWorklog } from '../redux/actions/issuesActions';
import { getEmployee } from '../redux/reducers/employees';
import { getIterationForUser } from '../redux/reducers/iterations';
import { getFeatures, getWaitingIssues } from '../redux/reducers/issues';
import { switchWorkstateRequest, getUserWorkstate } from '../redux/actions/workstatesActions';
import { getUserList, getUserInfo } from '../redux/actions/userActions';
import Productivity from '../components/productivity';

function getPausedIssuesWithoutLastIssue(issues, workstate) {
  if (workstate.issues && workstate.issues.length) {
    return issues.filter(key => key !== workstate.issues[workstate.issues.length - 1].issueKey);
  }
  return issues;
}

function mapStateToProps(state) {
  let paused = [];
  let worklogs = [];
  let userIteration = {};
  const icons = {};
  const summaries = {};
  const ids = {};
  const currentIterationId = (state.workstates[state.user.ID] && state.workstates[state.user.ID].iteration.id) || null;
  if (currentIterationId) {
    userIteration = getIterationForUser(state.iterations.byUserID, state.user.ID, currentIterationId);
    if (userIteration.ID) {
      paused = getPausedIssuesWithoutLastIssue(userIteration.issuesPaused, state.workstates[state.user.ID]).map(key => userIteration.issues[key]);
      worklogs = userIteration.daysOrder.map(day => userIteration.days[day]);
      Object.keys(userIteration.issues).forEach((key) => {
        icons[key] = userIteration.issues[key].iconType;
        summaries[key] = userIteration.issues[key].summary;
        ids[key] = userIteration.issues[key].id;
      });
    }
  }
  return {
    user: getEmployee(state.employees.byId, state.user.ID) || state.user,
    pauseIssues: paused,
    worklogs,
    icons,
    summaries,
    ids,
    features: getFeatures(state.issues, state.user.ID),
    waitingIssues: getWaitingIssues(state.issues, state.user.ID),
    userIteration,
    workstate: state.workstates[state.user.ID] || {},
    justLogin: state.app.login.justLogin,
    isPendingTransitIssue: state.app.isPending.transitIssue,
    isPendingDeleteWorkLog: state.app.isPending.deleteWorkLog,
    isPendingSwitchWorkstate: state.app.isPending.switchWorkstate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSwitchWorkstate: bindActionCreators(switchWorkstateRequest, dispatch),
    onTransitIssue: bindActionCreators(transitIssue, dispatch),
    onResetJustLogin: bindActionCreators(resetJustLogin, dispatch),
    onDeleteWorklog: bindActionCreators(deleteWorklog, dispatch),
    getUserList: bindActionCreators(getUserList, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
    getUserWorkstate: bindActionCreators(getUserWorkstate, dispatch),
  };
}

const ProductivityContainer = connect(mapStateToProps, mapDispatchToProps)(Productivity);

export default ProductivityContainer;
