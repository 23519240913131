import { take, fork, all, put, select, call } from 'redux-saga/effects';
import { fetchUserWorkState, fetchAllWorkState, switchWorkstate } from '../api/workstateApi';
import {
  GET_USER_WORKSTATE,
  getUserWorkstate,
  getUserWorkstateRequest,
  getUserWorkstateSuccess,
  getUserWorkstateFailure,
  GET_ALL_WORKSTATE,
  getAllWorkstateRequest,
  getAllWorkstateSuccess,
  getAllWorkstateFailure,
  SWITCH_WORKSTATE_REQUEST,
  switchWorkstateSuccess,
  switchWorkstateFailure,
} from '../../redux/actions/workstatesActions';
import { getIteration } from '../../redux/actions/iterationActions';
import { getWaitingIssues } from '../../redux/actions/issuesActions';
import { getDocumentsList } from '../../redux/actions/documentsActions';
import { getDevice } from '../../redux/reducers/device';

export function* getUserWorkState() {
  yield put(getUserWorkstateRequest());
  const device = yield select(getDevice);
  const response = yield call(fetchUserWorkState, device.device);
  if (response.status === 200) {
    yield put(getUserWorkstateSuccess(response.body.data));
    yield put(getDocumentsList(response.body.data.ID));
    yield put(getWaitingIssues());
    if (response.body.data.iteration) {
      yield put(getIteration(response.body.data.iteration.id));
      yield put({
        type: 'GET_FEATURES',
        user: response.body.data.login,
        project: 'all',
        iteration: response.body.data.iteration.id,
      });
    }
  } else {
    yield put(getUserWorkstateFailure());
  }
}

export function* getAllWorkstate() {
  yield put(getAllWorkstateRequest());
  const device = yield select(getDevice);
  const response = yield call(fetchAllWorkState, device.device);
  if (response.status === 200) {
    yield put(getAllWorkstateSuccess(response.body.data));
  } else {
    yield put(getAllWorkstateFailure());
  }
}

export function* switchWorkstateApiRequest(ID, state) {
  const device = yield select(getDevice);
  const response = yield call(switchWorkstate, device.device, ID, state);
  if (response.status === 200) {
    yield put(switchWorkstateSuccess(ID, response.body.data));
    yield put(getUserWorkstate());
  } else {
    yield put(switchWorkstateFailure(null, ID));
  }
}

function* watchGetUserWorkstate() {
  while (true) {
    yield take(GET_USER_WORKSTATE);
    yield fork(getUserWorkState);
  }
}

function* watchGetAllWorkstate() {
  while (true) {
    yield take(GET_ALL_WORKSTATE);
    yield fork(getAllWorkstate);
  }
}

function* watchSwitchWorkstateRequest() {
  while (true) {
    const { ID, state } = yield take(SWITCH_WORKSTATE_REQUEST);
    yield fork(switchWorkstateApiRequest, ID, state);
  }
}

export default function* workstatesSaga() {
  yield all([fork(watchGetUserWorkstate), fork(watchGetAllWorkstate), fork(watchSwitchWorkstateRequest)]);
}
