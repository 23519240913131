import BORequest from '../boModels/request';
import { apiConfig, defaultlogger } from '../../../shared/config';

const USER_LOCATION = 'user';

function* fetchUserListWithWorkState(device) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${USER_LOCATION}/list`);
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* fetchUserInfo(device, userID) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${USER_LOCATION}/info`, { userID });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* postUser(device, user) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${USER_LOCATION}/update`, {}, user);
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function getUserCard(device, userID) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  return request.cardUrl({ userID });
}

function* signUpUser(device, user) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${USER_LOCATION}/signup`, {}, user);
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

export { fetchUserListWithWorkState, fetchUserInfo, postUser, signUpUser, getUserCard };
