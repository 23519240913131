import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getNameUserType, getAvatarBase64ByPhoneAndID } from '../../../utils/helpers';
import * as fromStyled from '../../styles';

import bossnoteIcon from '../../../images/bossnoteLogo.svg';
import simplanumIcon from '../../../images/simplanumLogo.svg';

export default function AvatarWithIcon({
  size,
  userType,
  ID,
  phone,
}) {
  const [base64, setBase64] = useState();
  const height = size === 'small' ? 42 : 136;

  useEffect(() => {
    getAvatarBase64ByPhoneAndID(ID, phone).then((data) => {
      setBase64(data);
    });
  }, []);

  return (
    <div>
      <Image
        height={height}
        ID={ID}
        phone={phone}
        base64={base64}
      >
        <ServiceIcon size={size} userType={userType} />
      </Image>
    </div>
  );
}

const Image = styled(fromStyled.Avatar)`
  position: relative;
  flex-shrink: 0;
  @media print {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
`;

const ServiceIcon = styled.div`
  position: absolute;
  background-color: white;
  background-image: url(${({ userType }) => getNameUserType(userType) === 'bossnote' ? bossnoteIcon : simplanumIcon});
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  border-radius: 50%;
  ${({ size }) => {
    switch (size) {
      case 'small': {
        return (
          `width: 20px;
                 height: 20px;
                 left: 24px;
                 bottom: 24px;
                 background-size: 59%;
                `
        );
      }
      case 'large': {
        return (
          `width: 29px;
                 height: 29px;
                 left: 97px;
                 bottom: 113px;
                 background-size: 69%;
                `
        );
      }
      default: {
        return (
          `width: 20px;
                 height: 20px;
                 left: 24px;
                 bottom: 24px;
                 background-size: 59%;
                `
        );
      }
    }
  }}
}`;

AvatarWithIcon.propTypes = {
  size: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  ID: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};
