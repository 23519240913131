import React       from 'react';
import PropTypes   from 'prop-types';
import moment      from 'moment';
import pure        from 'recompose/pure';
import healthImage from '../../images/stethoscope_medical_health_care.png';

moment.locale('ru');

const HospitalItem = (props) => {
  const { days, startDate, endDate, comment } = props;
  return (
    <div className="section-content section-content__inactive">
      <div className="d-flex flex-row">
        <div className="content-icon">
          <img src={healthImage} alt="icon" />
        </div>
        <div className="vacation-info">
          <div className="vacation-dates">
            С <b>{moment(startDate).format('DD MMMM YYYY, dddd')}</b> на <b>{days} дней</b>
            <p className="light">
              по {(endDate ? moment(endDate) : moment(startDate).add(days, 'day')).format('DD MMMM YYYY, dddd')}
            </p>
          </div>
          <div className="sick-leave-info-reason">{comment}</div>
        </div>
      </div>
    </div>
  );
};

HospitalItem.propTypes = {
  days: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};

export default pure(HospitalItem);
