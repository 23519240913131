import React, { useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useAlert } from 'react-alert';
import Spinner from 'react-bootstrap/Spinner';

import TextareaForModal from './TextareaForModal';

import * as fromStyles from '../../styles';

import { patternsForSendMessage } from '../../../utils/optionsForSelect';
import Context from '../../common/Context';

const initialState = {
  isLoading: false,
  valueTextaria: '',
  isFormValidate: false,
  selectedPattern: null,
  selectedLanguage: null,
  activeTypeButtons: 'push',
};

function init(propsFromModal) {
  return {
    ...initialState,
    ID: propsFromModal.ID,
    typeMessage: {
      email: !!propsFromModal.email,
      extraEmail: !!propsFromModal.extraEmail,
      phone: !!propsFromModal.phone,
    },
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.value,
      };
    case 'SET_VALUE_TEXTARIA':
      return {
        ...state,
        valueTextaria: action.value,
      };
    case 'SELECT_PATTERN':
      return {
        ...state,
        selectedPattern: action.pattern,
      };
    case 'SELECT_LANGUAGE':
      return {
        ...state,
        selectedLanguage: action.language,
      };
    case 'CHANGE_ACTIVE_TYPE_BUTTON':
      return {
        ...state,
        activeTypeButtons: action.value,
      };
    case 'CHANGE_IS_FORM_VALIDATE': {
      return {
        ...state,
        isFormValidate: action.value,
      };
    }
    default:
      return state;
  }
}

export default function SendMessage({
  propsFromModal,
  setIsVisibleModal,
}) {
  const alert = useAlert();

  const { sendMessage } = useContext(Context);

  const [state, dispatch] = useReducer(reducer, propsFromModal, init);
  const {
    isLoading,
    valueTextaria,
    isFormValidate,
    selectedPattern,
    selectedLanguage,
    activeTypeButtons,
    ID,
    typeMessage,
  } = state;

  const changeIsFormValidate = (value) => {
    dispatch({ type: 'CHANGE_IS_FORM_VALIDATE', value });
  };

  /* changeIsFormValidate примет параметры: false - когда значение текстового поля пустое,
  true - когда значение поля не пустое */
  useEffect(() => changeIsFormValidate(valueTextaria.trim() !== ''), [valueTextaria]);

  const setIsLoading = (value) => {
    dispatch({ type: 'SET_IS_LOADING', value });
  };

  const setValueTextarea = (value) => {
    dispatch({ type: 'SET_VALUE_TEXTARIA', value });
  };

  const selectPattern = (pattern) => {
    dispatch({ type: 'SELECT_PATTERN', pattern });
    setValueTextarea(pattern.label);
  };

  const selectLanguage = (language) => {
    dispatch({ type: 'SELECT_LANGUAGE', language });
  };

  const onClickTypeButton = ({ target: { value } }) => {
    dispatch({ type: 'CHANGE_ACTIVE_TYPE_BUTTON', value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    sendMessage(ID, valueTextaria, activeTypeButtons)
      .then(() => {
        alert.show(<span style={{ textTransform: 'none' }}>Отправлено</span>, { type: 'success' });
        setIsLoading(false);
        setIsVisibleModal(false);
      })
      .catch(() => {
        alert.show('Не отправлено', { type: 'error' });
        setIsLoading(false);
      });
  };

  const renderTypeButtons = () => (
    <>
      <TypeButton value="push" active={activeTypeButtons === 'push'} onClick={onClickTypeButton}>PUSH</TypeButton>
      <TypeButton value="sms" active={activeTypeButtons === 'sms'} onClick={onClickTypeButton}>SMS</TypeButton>
      {typeMessage.email && <TypeButton value="email" active={activeTypeButtons === 'email'} onClick={onClickTypeButton}>Email</TypeButton>}
      {typeMessage.extraEmail &&  <TypeButton value="emailSupport" active={activeTypeButtons === 'emailSupport'} onClick={onClickTypeButton}>Email Support</TypeButton>}
    </>
  );

  return (
    <Wrapper>
      <div className="sendMessage">
        <div className="sendMessage__header">
          {renderTypeButtons()}
        </div>
        <div className="sendMessage__main">
          <TextareaForModal
            className="sendMessage__main-textaria"
            placeholder="Текст сообщения..."
            height={186}
            width={371}
            value={valueTextaria}
            onChangeValue={setValueTextarea}
            onSelectLanguage={selectLanguage}
            onSelectPattern={selectPattern}
            selectedPattern={selectedPattern}
            selectedLanguage={selectedLanguage}
            patterns={patternsForSendMessage}
          />
        </div>
        <div className="sendMessage__footer">
          <fromStyles.ButtonForModal
            disabled={!isFormValidate || isLoading}
            className="sendMessage__footer-button"
            onClick={onSubmit}
            style={{ width: '90px' }}
          >
            {
              isLoading ? (
                <Spinner
                  as="span"
                  size="sm"
                  animation="border"
                  variant="light"
                />
              ) : 'Отправить'
            }
          </fromStyles.ButtonForModal>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    .sendMessage {
      width: auto;
      margin: 0px 30px 20px 30px;
        &__header {
            margin-top: 15px;
            button:not(:first-of-type) {
                margin-left: 10px;
            }
        }
        &__main {
            margin-top: 18px;            
        }
        &__footer {
            display: flex;
            justify-content: flex-end;
            &-button {
                margin-top: 36px;
            }
        }
    }
`;

const TypeButton = styled.button.attrs(() => ({ type: 'button' }))`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #FFFFFF;
  padding: 6px 0;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  transition: opacity 0.1s ease-in-out;
  opacity: ${({ active }) => active ? '1' : '0.4'};
    ${({ value }) => {
    switch (value) {
      case 'push': {
        return `
        background: #2EA8E5;
        width: 54px;
        `;
      }
      case 'sms': {
        return `
        background: #4CAD00;
        width: 56px;
        `;
      }
      case 'email': {
        return `
        width: 76px;
        background: #818181;

        `;
      }
      case 'emailSupport': {
        return `
        width: 112px;
        background: #818181;

        `;
      }
      default: {
        return `
        `;
      }
    }
  }}
`;

SendMessage.propTypes = {
  propsFromModal: PropTypes.shape({
    ID: PropTypes.string,
    email: PropTypes.string,
    extraEmail: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  setIsVisibleModal: PropTypes.func.isRequired,
};
