import React from 'react';
import PropTypes from 'prop-types';
import { Loader, RoundedOverlay } from '../Loader';

class EmployeeCard extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { props } = this;
    return (
      nextProps.position !== props.position ||
      nextProps.fullName !== props.fullName ||
      nextProps.workPosition !== props.workPosition ||
      nextProps.avatar !== props.avatar ||
      nextProps.workHours !== props.workHours ||
      nextProps.doneCount !== props.doneCount ||
      nextProps.undoneCount !== props.undoneCount ||
      nextProps.startTime !== props.startTime ||
      nextProps.currentTime !== props.currentTime ||
      nextProps.endTime !== props.endTime ||
      nextProps.active !== props.active ||
      nextProps.isPending !== props.isPending
    );
  }

  render() {
    const {
      position,
      fullName,
      workPosition,
      state,
      avatar,
      issues,
      workHours,
      doneCount,
      undoneCount,
      startTime,
      currentTime,
      endTime,
      onSwitchWorkstate,
      isPending,
    } = this.props;
    const issuesList = issues.map(issue => (
      <div key={issue.issueKey} className="issue-item">
        <div className="d-flex flex-row">
          <div className="issue-image-wrap">
            <img src={issue.iconType} alt="" />
          </div>
          <div className="issue-link">
            <a target="_blank" href={`http://jira.soft-artel.com/browse/${issue.issueKey}`}>
              {issue.issueKey}
            </a>
          </div>
        </div>
        <div className="p-1 issue-title">{issue.summary}</div>
      </div>
    ));
    return (
      <div
        className={`user-card d-flex ${position === 'right' ? 'user-right-column' : ''} ${
          state === 10 ? 'user-active' : state === 2 ? 'user-vacation' : state === 3 ? 'user-hospital' : ''
        } ${currentTime === '00:00' ? 'user-notworked' : ''}`}
      >
        <div className="user-avatar-wrap">
          <img className="avatar rounded-circle" src={avatar} alt="avatar" />
        </div>

        <div className="user-info d-flex flex-column p-2">
          <div className={`user-info-wrapper ${position === 'right' ? 'user-info-wrapper__right' : ''}`}>
            <div className={`user-info-header ${position === 'right' ? 'user-info-header__right' : ''}`}>
              <div className="user-name">{fullName}</div>
              <div className="user-position">{workPosition}</div>
            </div>

            <div
              className={`d-flex align-items-start flex-column ${
                position === 'right' ? 'mr-auto' : 'ml-auto'
              } user-sprint-hours`}
            >
              <div>
                <i className="fa fa-hourglass-start" aria-hidden="true" />
                <span>{workHours}</span>
              </div>
              <div>
                <i className="fa fa-file-text-o" aria-hidden="true" />
                <span>{doneCount}</span> <span>{undoneCount}</span>
              </div>
            </div>
          </div>

          {issuesList}
        </div>

        <div
          className="d-flex user-work-status rounded-circle flex-column justify-content-center align-items-center"
          onClick={onSwitchWorkstate}
        >
          <div className="start-time">{startTime}</div>
          <div className="current-time">{currentTime}</div>
          <div className="end-time">{endTime}</div>
          {isPending && (
            <RoundedOverlay>
              <Loader show scale={0.7} />
            </RoundedOverlay>
          )}
        </div>
      </div>
    );
  }
}

EmployeeCard.propTypes = {
  position: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  fullName: PropTypes.string.isRequired,
  workPosition: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  issues: PropTypes.arrayOf(PropTypes.object).isRequired,
  workHours: PropTypes.string.isRequired,
  doneCount: PropTypes.number.isRequired,
  state: PropTypes.number.isRequired,
  undoneCount: PropTypes.number.isRequired,
  onSwitchWorkstate: PropTypes.func.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  currentTime: PropTypes.string.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default EmployeeCard;
