import React            from 'react';
import PropTypes        from 'prop-types';
import moment           from 'moment';
import DatePicker       from 'react-date-picker';
import AttachmentPicker from '../AttachmentPicker';
import healthImage      from '../../images/stethoscope_medical_health_care.png';

moment.locale('ru');

class ActiveHospital extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      base64: '',
      disabled: true,
      endD: moment().format('YYYY-MM-DD'),
    };
    this.onEndDateChange = (ev) => {
      this.setState({ endD: moment(ev).format('YYYY-MM-DD') });
    };
    this.onDocumentAttach = (file) => {
      this.setState({
        file,
      });
      this.props.uploadHospital(file, 'bolnich');
    };
    this.onRemoveAttach = () => {
      this.setState({
        file: null,
        base64: '',
        disabled: true,
      });
    };
    this.getBase64 = (file) => {
      const self = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        self.setState({ base64: reader.result });
      };
    };
    this.onSubmit = (ev) => {
      ev.preventDefault();
      const { ID, docID, switchHospital } = this.props;
      if (ID && docID && this.state.file && this.state.endD) {
        switchHospital(ID, docID, this.state.endD);
        this.setState({
          file: null,
          base64: '',
          disabled: true,
        });
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.docID && this.state.file) {
      this.setState({
        disabled: false,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { props, state } = this;
    return (
      nextProps.docID !== props.docID ||
      nextProps.startDate !== props.startDate ||
      nextProps.days !== props.days ||
      nextProps.comment !== props.comment ||
      nextState.base64 !== state.base64 ||
      nextState.endD !== state.endD ||
      nextState.file !== state.file
    );
  }

  render() {
    const { startDate, days, comment, editable } = this.props;
    let base64 = '';
    if (this.state.file) {
      this.getBase64(this.state.file);
      base64 = this.state.base64;
    }
    return (
      <div className="section-content sick-leave">
        <div className="d-flex flex-row">
          <div className="content-icon">
            <img src={healthImage} alt="icon" />
          </div>
          <div className="sick-leave-info">
            <div className="sick-leave-info-field">С {moment(startDate).format('DD MMMM YYYY, dddd')}</div>
            <div className="sick-leave-info-field"><b>{days} дня</b></div>
            <div className="form-group row">
              <label htmlFor="from" className="col-1 col-form-label">До</label>
              <DatePicker
                className="col-12"
                value={new Date(this.state.endD)}
                onChange={this.onEndDateChange}
              />
            </div>
            <div className="light">{comment}</div>
            {
              editable && <AttachmentPicker
                className="sick-leave-attachment-curtain"
                text="Перетащите сюда фото больничного листа или справки"
                onDocumentAttach={this.onDocumentAttach}
              />
            }
            {
              base64 !== '' &&
              <div className="attachment-preview-wrapper">
                <div className="attachment-preview-screencover" />
                <img className="attachment-preview" src={base64} alt="" />
                <div className="attachment-preview__remove" onClick={this.onRemoveAttach}>
                  <i className="fa-trash-o" />
                </div>
              </div>
            }
          </div>
        </div>
        {
          editable &&
          <div className="d-flex justify-content-end">
            <button
              disabled={this.state.disabled}
              className="btn btn-success close-hospital-button"
              onClick={this.onSubmit}
            >
              Закрыть больничный
            </button>
          </div>
        }
      </div>
    );
  }
}

ActiveHospital.propTypes = {
  days: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  docID: PropTypes.string.isRequired,
  ID: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  uploadHospital: PropTypes.func.isRequired,
  switchHospital: PropTypes.func.isRequired,
};

export default ActiveHospital;
