import { fork, all } from 'redux-saga/effects';
import deviceSaga    from './deviceSaga';
import userSaga      from './userSaga';
import employeesSaga from './employeesSaga';
import hospitalsSaga from './hospitalsSaga';
import documentsSaga from './documentsSaga';
import workstateSaga from './workstateSaga';
import iterationSaga from './iterationSaga';
import issuesSaga    from './issuesSaga';

export default function* root() {
  yield all([
    fork(deviceSaga),
    fork(userSaga),
    fork(workstateSaga),
    fork(iterationSaga),
    fork(employeesSaga),
    fork(hospitalsSaga),
    fork(documentsSaga),
    fork(issuesSaga),
  ]);
}
