import axios from 'axios';
import { buildUrl } from '../utils/request';

// export const baseURL = 'https://api-test-simplanum.stage.soft-artel.com/v1/support';
const baseURL = 'https://api-dev-backoffice.stage.soft-artel.com';
const baseURL2 = 'https://api.alpha.simplanum.com/v1/support';

const headers = {
  'Content-Type': 'application/json; charset=utf-8',
};

const instance = axios.create({
  baseURL,
  headers,
});

const instance2 = axios.create({
  baseURL,
  responseType: 'arraybuffer',
  headers: {
    'Content-Type': 'image/png',
  },
});

const md5Hash = '55ae4491f6f151c1db79f90832602f7d';

const stage = 'alpha';

const getParamsForRequest = (command, options) => ({
  command,
  stage,
  params: Object.keys(options).map((i) => `${i}=${options[i]}`),
});

const url = buildUrl('support/send', {}, baseURL);

const requestUser = (cmd, options) => instance.post(url, getParamsForRequest(`user/${cmd}`, options))
  .then((response) => response.data);

const requestDevice = (cmd, options) => instance.post(url, getParamsForRequest(`device/${cmd}`, options))
  .then((response) => response.data);

const requestContacts = (cmd, options) => instance.post(url, getParamsForRequest(`contacts/${cmd}`, options))
  .then((response) => response.data);

/* export const getFoundUsers = (searchParam) => requestUser('list',
{ command: 'user/list', stage: 'test', params: [`searchParam=${searchParam}`] }); */
export const getFoundUsers = (searchParam) => requestUser('list', { searchParam });

export const getUserCard = (ID) => requestUser('get', { ID });

// export const getAvatar = (ID, phone) => instance2.post(url, {
//   stage: 'alpha',
//   params: {
//     command: 'user/get_avatar',
//     ID,
//     phone,
//   },
// });

export const getAvatar = (ID, phone) => axios.get(`${baseURL2}/user/get_avatar?ID=${ID}&phone=${phone}&pass=${md5Hash}`,
  { responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'image/png',
    },
  });

export const actionsWithUserCard = {
  // обновление дополнительного email
  updateSupportEmail: (ID, email) => requestUser('update_supportEmail', { ID, email }),
  // добавление комментария
  addComment: (ID, comment) => requestUser('comment', { ID, comment }),
  // сброс кода безопасности
  resetCode: (ID) => requestUser('reset_code', { ID }),
  // отправка сообщения
  messageSend: (ID, message, type) => requestUser('message_send', { ID, message, type }),
  /* продление подписки
    Входные параметры:
    type - тип подписки, которую нужно продлить (typeof: string)
    day - количество дней (typeof: string, between: [0, 36500])
    comment - комментарий (typeof: string)
  */
  renewingSubscribe: (ID, type, days, comment) => requestUser('subscription', { ID, type, days, comment }),
  /* добавление бонуса
    Входные параметры:
    ID - id пользователя
    type - параметр укзывающий на то, чему именно нужно добавить бонус (entries или storage)
    value -  typeof: 'number', between: [0, 9999]
   */
  addBonus: (ID, type, value, comment) => requestUser('bonus', { ID, type, value, comment }),
  // закрытие активной сессии
  finishSession: (ID) => requestDevice('session_finish', { ID }),
  // принятие входящего контакта
  acceptContact: (ID, userPhone, type) => requestContacts('accept', { ID, userPhone, type }),
  // разблокировка заблокированного контакта
  unblockContact: (ID, userPhone = null) => requestContacts('unblock', userPhone === null ? { ID } : { ID, userPhone }),
  // восстановление удалённых записей
  restoreTrashbin: (ID, days) => requestUser('restore_trashbin', { ID, days }),
  // восстановление за определенные даты
  restoreTrashbinForDateRange: (ID, from, to) => requestUser('restore_trashbin', { ID, from, to }),
  trashInfo: (ID, from = null, to = null) => requestUser('trash_info', from === null ? { ID } : { ID, from, to }),
  // получение истории изменений
  getSupportHistory: (ID) => requestUser('get_support_history', { ID }),
  // получение истории сообщений
  getMessageList: (ID) => requestUser('message_list', { ID }),
};

const apiDoc = {
  commands: {
    user: {
      list: {
        desc: 'Returns list of users by search params',
        reqMethod: 'GET',
        reqParams: {
          searchParam: { typeOf: 'string' },
          pass: { typeOf: 'string', values: [md5Hash] },
        },
      },

      get: {
        desc: 'Returns user info by ID or phone',
        reqMethod: 'GET',
        reqParams: {
          ID: { typeOf: 'guid', allowNull: true },
          phone: { typeOf: 'string', allowNull: true },
          pass: { typeOf: 'string', values: [md5Hash] },
        },
      },

      get_avatar: {
        desc: 'Returns user info by ID or phone',
        reqMethod: 'GET',
        reqParams: {
          ID: { typeOf: 'guid' },
          phone: { typeOf: 'string' },
          pass: { typeOf: 'string', values: [md5Hash] },
        },
      },

      update_supportEmail: {
        desc: 'Updates user extra email',
        reqMethod: 'GET',
        reqParams: {
          ID: { typeOf: 'guid' },
          email: { typeOf: 'email' },
          comment: { typeOf: 'string', allowNull: true },
          pass: { typeOf: 'string', values: [md5Hash] },
        },
      },

      reset_code: {
        desc: 'Resets user security code',
        reqMethod: 'GET',
        reqParams: {
          ID: { typeOf: 'guid' },
          pass: { typeOf: 'string', values: [md5Hash] },
        },
      },

      change_phone: {
        desc: 'Resets user security code',
        reqMethod: 'GET',
        reqParams: {
          ID: { typeOf: 'guid' },
          phone: { typeOf: 'string' },
          updateContacts: { typeOf: 'number', values: [0, 1] },
          pass: { typeOf: 'string', values: [md5Hash] },
        },
      },

      message_send: {
        desc: 'Sends message to user',
        reqMethod: 'GET',
        reqParams: {
          ID: { typeOf: 'guid' },
          message: { typeOf: 'string' },
          type: { typeOf: 'string', values: ['push', 'sms', 'email', 'emailSupport'] },
          pass: { typeOf: 'string', values: [md5Hash] },
        },
      },

      message_list: {
        desc: 'Returns user notifications info',
        reqMethod: 'GET',
        reqParams: {
          ID: { typeOf: 'guid' },
          pass: { typeOf: 'string', values: [md5Hash] },
        },
      },

      subscription: {
        desc: 'Updates user subscription',
        reqMethod: 'GET',
        reqParams: {
          ID: { typeOf: 'guid' },
          type: { typeOf: 'string', allowNull: true },
          days: { typeOf: 'number', between: [0, 36500] },
          comment: { typeOf: 'string', allowNull: true },
          pass: { typeOf: 'string', values: [md5Hash] },
        },
      },

      trash_info: {
        desc: 'Restores info abaout user\'s deleted entries',
        reqMethod: 'GET',
        reqParams: {
          ID: { typeOf: 'guid' },
          pass: { typeOf: 'string', values: [md5Hash] },
          from: { typeOf: 'string', allowNull: true },
          to: { typeOf: 'string', allowNull: true },
        },
      },

      restore_trashbin: {
        desc: 'Restores deleted entries and notebooks',
        reqMethod: 'GET',
        reqParams: {
          ID: { typeOf: 'guid' },
          days: { typeOf: 'number', between: [0, 365] },
          pass: { typeOf: 'string', values: [md5Hash] },
        },
      },

      bonus: {
        desc: 'Add bonuses to user',
        reqMethod: 'GET',
        reqParams: {
          ID: { typeOf: 'guid' },
          type: { typeOf: 'string', values: ['storage', 'entries'] },
          value: { typeOf: 'number', between: [0, 9999] },
          comment: { typeOf: 'string', allowNull: true },
          pass: { typeOf: 'string', values: [md5Hash] },
        },
      },
    },

    device: {
      session_finish: {
        desc: 'Finish device session for user',
        reqMethod: 'GET',
        reqParams: {
          ID: { typeOf: 'guid' },
          pass: { typeOf: 'string', values: [md5Hash] },
        },
      },
    },

    contacts: {
      accept: {
        desc: 'Accepts another users requests with status wait',
        reqMethod: 'GET',
        reqParams: {
          ID: { typeOf: 'guid' },
          userPhone: { typeOf: 'string' },
          type: { typeOf: 'string', values: ['incoming', 'outgoing'] },
        },
      },
      unblock: {
        desc: 'Unblocks blocked contact',
        reqMethod: 'GET',
        reqParams: {
          ID: { typeOf: 'guid' },
          pass: { typeOf: 'string', values: [md5Hash] },
        },
      },
    },

  },
};
