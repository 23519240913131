import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tab, Tabs, Nav } from 'react-bootstrap';

import * as fromStyled from '../../../styles';

import SessionItem from './SessionItem';

const CHANGE_KEY = 'CHANGE_KEY';

function init({ active, inactive }) {
  return {
    active,
    inactive,
    activeKey: 'active',
  };
}

function reducer(state, action) {
  switch (action.type) {
    case CHANGE_KEY: {
      return {
        ...state,
        activeKey: action.key,
      };
    }
    default:
      return state;
  }
}

export default function Sessions({ sessions }) {
  const { active, inactive } = sessions;

  const [state, dispatch] = useReducer(
    reducer,
    {
      active,
      inactive,
    },
    init,
  );

  const {
    activeKey,
  } = state;

  const changeKey = (key) => {
    dispatch({ type: CHANGE_KEY, key });
  };

  const activeSessionCount = sessions.active.length;
  const inactiveSessionCount = sessions.inactive.length;

  const renderSessionsList = () => {
    if (activeKey === 'active') {
      const activeSession = active.map((session) => (
        <SessionItem
          key={session.ID}
          session={session}
        />
      ));
      return activeSession;
    }
    const inActiveSession = inactive.map((session) => (
      <SessionItem
        key={session.ID}
        session={session}
      />
    ));
    return inActiveSession;
  };

  return (
    <Wrapper>
      <Nav
        fill
        variant="tabs"
        defaultActiveKey={activeKey}
        onSelect={changeKey}
      >
        <Nav.Item>
          <Nav.Link as="button" eventKey="active">
            Активные сессии
            <span>{activeSessionCount}</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as="button" eventKey="inactive">
            Неактивные сессии
            <span>{inactiveSessionCount}</span>
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <ContentWrapper>
        {renderSessionsList()}
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    height: 100%;
    overflow: hidden;
    .nav {
        margin-bottom: 12px;
    }
    .nav-link {
        font-size: 14px;
        line-height: 24px;
        color: #C9C9C9;
        width: -webkit-fill-available;
        background-color: #fff;
        border-bottom: 1px solid #dee2e6;
        span {
          margin-left: 5px;
          display: inline-block;
          font-size: 10px;
          line-height: 12px;
          width: 14px;
          height: 14px;  
          background: #C4C4C4;
          color: #ffffff;
          border-radius: 50%;
          text-align: center;
        }
        &.active {
          color: #000000;
          span {
            background: #000000;
            color: #ffffff;
          }
        }
      }
`;

const ContentWrapper = styled(fromStyled.List)`
      li {
          padding-top: 15px;
          padding-bottom: 13px;
          &:first-child {
              padding-top: 0;
          }
          &:not(:last-child) {
            border-bottom: 1px solid #ECECEC;
          }
      }
`;

Sessions.propTypes = {
  sessions: PropTypes.shape({
    active: PropTypes.arrayOf(PropTypes.shape()),
    inactive: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};
