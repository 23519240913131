import React     from 'react';
import PropTypes from 'prop-types';

const countriesPhoneCode = {
  AC: 'о-в Вознесения (+247)',
  AD: 'Андорра (+376)',
  AE: 'ОАЭ (+971)',
  AF: 'Афганистан (+93)',
  AG: 'Антигуа и Барбуда (+1)',
  AI: 'Ангилья (+1)',
  AL: 'Албания (+355)',
  AM: 'Армения (+374)',
  AO: 'Ангола (+244)',
  AR: 'Аргентина (+54)',
  AS: 'Американское Самоа (+1)',
  AT: 'Австрия (+43)',
  AU: 'Австралия (+61)',
  AW: 'Аруба (+297)',
  AX: 'Аландские о-ва (+358)',
  AZ: 'Азербайджан (+994)',
  BA: 'Босния и Герцеговина (+387)',
  BB: 'Барбадос (+1)',
  BD: 'Бангладеш (+880)',
  BE: 'Бельгия (+32)',
  BF: 'Буркина-Фасо (+226)',
  BG: 'Болгария (+359)',
  BH: 'Бахрейн (+973)',
  BI: 'Бурунди (+257)',
  BJ: 'Бенин (+229)',
  BL: 'Сен-Бартельми (+590)',
  BM: 'Бермудские о-ва (+1)',
  BN: 'Бруней-Даруссалам (+673)',
  BO: 'Боливия (+591)',
  BQ: 'Бонэйр, Синт-Эстатиус и Саба (+599)',
  BR: 'Бразилия (+55)',
  BS: 'Багамские о-ва (+1)',
  BT: 'Бутан (+975)',
  BW: 'Ботсвана (+267)',
  BY: 'Беларусь (+375)',
  BZ: 'Белиз (+501)',
  CA: 'Канада (+1)',
  CC: 'Кокосовые о-ва (+61)',
  CD: 'Конго - Киншаса (+243)',
  CF: 'ЦАР (+236)',
  CG: 'Конго - Браззавиль (+242)',
  CH: 'Швейцария (+41)',
  CI: 'Кот-д’Ивуар (+225)',
  CK: 'о-ва Кука (+682)',
  CL: 'Чили (+56)',
  CM: 'Камерун (+237)',
  CN: 'Китай (+86)',
  CO: 'Колумбия (+57)',
  CR: 'Коста-Рика (+506)',
  CU: 'Куба (+53)',
  CV: 'Кабо-Верде (+238)',
  CW: 'Кюрасао (+599)',
  CX: 'о-в Рождества (+61)',
  CY: 'Кипр (+357)',
  CZ: 'Чехия (+420)',
  DE: 'Германия (+49)',
  DJ: 'Джибути (+253)',
  DK: 'Дания (+45)',
  DM: 'Доминика (+1)',
  DO: 'Доминиканская Республика (+1)',
  DZ: 'Алжир (+213)',
  EC: 'Эквадор (+593)',
  EE: 'Эстония (+372)',
  EG: 'Египет (+20)',
  EH: 'Западная Сахара (+212)',
  ER: 'Эритрея (+291)',
  ES: 'Испания (+34)',
  ET: 'Эфиопия (+251)',
  FI: 'Финляндия (+358)',
  FJ: 'Фиджи (+679)',
  FK: 'Фолклендские о-ва (+500)',
  FM: 'Федеративные Штаты Микронезии (+691)',
  FO: 'Фарерские о-ва (+298)',
  FR: 'Франция (+33)',
  GA: 'Габон (+241)',
  GB: 'Великобритания (+44)',
  GD: 'Гренада (+1)',
  GE: 'Грузия (+995)',
  GF: 'Французская Гвиана (+594)',
  GG: 'Гернси (+44)',
  GH: 'Гана (+233)',
  GI: 'Гибралтар (+350)',
  GL: 'Гренландия (+299)',
  GM: 'Гамбия (+220)',
  GN: 'Гвинея (+224)',
  GP: 'Гваделупа (+590)',
  GQ: 'Экваториальная Гвинея (+240)',
  GR: 'Греция (+30)',
  GS: 'Южная Георгия и Южные Сандвичевы о-ва (+500)',
  GT: 'Гватемала (+502)',
  GU: 'Гуам (+1)',
  GW: 'Гвинея-Бисау (+245)',
  GY: 'Гайана (+592)',
  HK: 'Гонконг (специальный административный район) (+852)',
  HM: 'о-ва Херд и Макдональд (+672)',
  HN: 'Гондурас (+504)',
  HR: 'Хорватия (+385)',
  HT: 'Гаити (+509)',
  HU: 'Венгрия (+36)',
  ID: 'Индонезия (+62)',
  IE: 'Ирландия (+353)',
  IL: 'Израиль (+972)',
  IM: 'о-в Мэн (+44)',
  IN: 'Индия (+91)',
  IO: 'Британская территория в Индийском океане (+246)',
  IQ: 'Ирак (+964)',
  IR: 'Иран (+98)',
  IS: 'Исландия (+354)',
  IT: 'Италия (+39)',
  JE: 'Джерси (+44)',
  JM: 'Ямайка (+1)',
  JO: 'Иордания (+962)',
  JP: 'Япония (+81)',
  KE: 'Кения (+254)',
  KG: 'Киргизия (+996)',
  KH: 'Камбоджа (+855)',
  KI: 'Кирибати (+686)',
  KM: 'Коморские о-ва (+269)',
  KN: 'Сент-Китс и Невис (+1)',
  KP: 'КНДР (+850)',
  KR: 'Республика Корея (+82)',
  KW: 'Кувейт (+965)',
  KY: 'Каймановы о-ва (+1)',
  KZ: 'Казахстан (+7)',
  LA: 'Лаос (+856)',
  LB: 'Ливан (+961)',
  LC: 'Сент-Люсия (+1)',
  LI: 'Лихтенштейн (+423)',
  LK: 'Шри-Ланка (+94)',
  LR: 'Либерия (+231)',
  LS: 'Лесото (+266)',
  LT: 'Литва (+370)',
  LU: 'Люксембург (+352)',
  LV: 'Латвия (+371)',
  LY: 'Ливия (+218)',
  MA: 'Марокко (+212)',
  MC: 'Монако (+377)',
  MD: 'Молдова (+373)',
  ME: 'Черногория (+382)',
  MF: 'Сен-Мартен (+590)',
  MG: 'Мадагаскар (+261)',
  MH: 'Маршалловы о-ва (+692)',
  MK: 'Македония (+389)',
  ML: 'Мали (+223)',
  MM: 'Мьянма (Бирма) (+95)',
  MN: 'Монголия (+976)',
  MO: 'Макао (специальный административный район) (+853)',
  MP: 'Северные Марианские о-ва (+1)',
  MQ: 'Мартиника (+596)',
  MR: 'Мавритания (+222)',
  MS: 'Монтсеррат (+1)',
  MT: 'Мальта (+356)',
  MU: 'Маврикий (+230)',
  MV: 'Мальдивы (+960)',
  MW: 'Малави (+265)',
  MX: 'Мексика (+52)',
  MY: 'Малайзия (+60)',
  MZ: 'Мозамбик (+258)',
  NA: 'Намибия (+264)',
  NC: 'Новая Каледония (+687)',
  NE: 'Нигер (+227)',
  NF: 'о-в Норфолк (+672)',
  NG: 'Нигерия (+234)',
  NI: 'Никарагуа (+505)',
  NL: 'Нидерланды (+31)',
  NO: 'Норвегия (+47)',
  NP: 'Непал (+977)',
  NR: 'Науру (+674)',
  NU: 'Ниуэ (+683)',
  NZ: 'Новая Зеландия (+64)',
  OM: 'Оман (+968)',
  PA: 'Панама (+507)',
  PE: 'Перу (+51)',
  PF: 'Французская Полинезия (+689)',
  PG: 'Папуа – Новая Гвинея (+675)',
  PH: 'Филиппины (+63)',
  PK: 'Пакистан (+92)',
  PL: 'Польша (+48)',
  PM: 'Сен-Пьер и Микелон (+508)',
  PR: 'Пуэрто-Рико (+1)',
  PS: 'Палестинские территории (+970)',
  PT: 'Португалия (+351)',
  PW: 'Палау (+680)',
  PY: 'Парагвай (+595)',
  QA: 'Катар (+974)',
  RE: 'Реюньон (+262)',
  RO: 'Румыния (+40)',
  RS: 'Сербия (+381)',
  RU: 'Россия (+7)',
  RW: 'Руанда (+250)',
  SA: 'Саудовская Аравия (+966)',
  SB: 'Соломоновы о-ва (+677)',
  SC: 'Сейшельские о-ва (+248)',
  SD: 'Судан (+249)',
  SE: 'Швеция (+46)',
  SG: 'Сингапур (+65)',
  SH: 'о-в Св. Елены (+290)',
  SI: 'Словения (+386)',
  SJ: 'Шпицберген и Ян-Майен (+47)',
  SK: 'Словакия (+421)',
  SL: 'Сьерра-Леоне (+232)',
  SM: 'Сан-Марино (+378)',
  SN: 'Сенегал (+221)',
  SO: 'Сомали (+252)',
  SR: 'Суринам (+597)',
  SS: 'Южный Судан (+211)',
  ST: 'Сан-Томе и Принсипи (+239)',
  SV: 'Сальвадор (+503)',
  SX: 'Синт-Мартен (+1)',
  SY: 'Сирия (+963)',
  SZ: 'Свазиленд (+268)',
  TC: 'о-ва Тёркс и Кайкос (+1)',
  TD: 'Чад (+235)',
  TG: 'Того (+228)',
  TH: 'Таиланд (+66)',
  TJ: 'Таджикистан (+992)',
  TK: 'Токелау (+690)',
  TL: 'Восточный Тимор (+670)',
  TM: 'Туркменистан (+993)',
  TN: 'Тунис (+216)',
  TO: 'Тонга (+676)',
  TR: 'Турция (+90)',
  TT: 'Тринидад и Тобаго (+1)',
  TV: 'Тувалу (+688)',
  TW: 'Тайвань (+886)',
  TZ: 'Танзания (+255)',
  UA: 'Украина (+380)',
  UG: 'Уганда (+256)',
  US: 'Соединенные Штаты (+1)',
  UY: 'Уругвай (+598)',
  UZ: 'Узбекистан (+998)',
  VA: 'Ватикан (+379)',
  VC: 'Сент-Винсент и Гренадины (+1)',
  VE: 'Венесуэла (+58)',
  VG: 'Виргинские о-ва (Британские) (+1)',
  VI: 'Виргинские о-ва (США) (+1)',
  VN: 'Вьетнам (+84)',
  VU: 'Вануату (+678)',
  WF: 'Уоллис и Футуна (+681)',
  WS: 'Самоа (+685)',
  XK: 'Косово (+386)',
  YE: 'Йемен (+967)',
  YT: 'Майотта (+262)',
  ZA: 'ЮАР (+27)',
  ZM: 'Замбия (+260)',
  ZW: 'Зимбабве (+263)',
};

const CountrySelect = (props) => {
  const options = Object.keys(countriesPhoneCode).map(code => (
    <option key={code} value={code}>{countriesPhoneCode[code]}</option>
  ));
  return (
    <select onChange={(ev) => { props.onCountryChange(countriesPhoneCode[ev.target.value]); }}>
      {options}
    </select>
  );
};

CountrySelect.propTypes = {
  onCountryChange: PropTypes.func.isRequired,
};

export default CountrySelect;
