import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NewEmployee from '../components/newEmployee/NewEmployee';
import { signupUserRequest } from '../redux/actions/userActions';

function mapStateToProps(state) {
  return {
    isPending: state.app.isPending.newEmployee,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signupUserRequest: bindActionCreators(signupUserRequest, dispatch),
  };
}

const NewEmployeeContainer = connect(mapStateToProps, mapDispatchToProps)(NewEmployee);

export default NewEmployeeContainer;
