import React     from 'react';
import moment    from 'moment';
import PropTypes from 'prop-types';
import pure      from 'recompose/pure';

moment.locale('ru');

const Feature = ({ iconType, issueKey, SUMMARY }) => (
  <div className="d-flex flex-column sprint-feature">
    <div className="d-flex flex-row sprint-feature-info align-items-center">
      <img src={iconType} alt="" />
      <a target="_blank" href={`http://jira.soft-artel.com/browse/${issueKey}`}>{issueKey}</a>
      <div className="badge badge-primary">3</div>
      <div className="add-subtask">Add Subtask</div>
    </div>
    <div className="sprint-feature-title">{SUMMARY}</div>
    <div className="sprint-feature-components-label"><i>Components</i></div>
  </div>
);

Feature.propTypes = {
  issueKey: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  SUMMARY: PropTypes.string.isRequired,
};

export default pure(Feature);
