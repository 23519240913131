export const UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS';
export const UPDATE_DEVICE_REQUEST = 'UPDATE_DEVICE_REQUEST';
export const UPDATE_DEVICE_FAILURE = 'UPDATE_DEVICE_FAILURE';
export const SIGN_IN_DEVICE_SUCCESS = 'SIGN_IN_DEVICE_SUCCESS';
export const SIGN_IN_DEVICE_REQUEST = 'SIGN_IN_DEVICE_REQUEST';
export const SIGN_IN_DEVICE_FAILURE = 'SIGN_IN_DEVICE_FAILURE';
export const CODE_DEVICE_SUCCESS = 'CODE_DEVICE_SUCCESS';
export const CODE_DEVICE_REQUEST = 'CODE_DEVICE_REQUEST';
export const CODE_DEVICE_FAILURE = 'CODE_DEVICE_FAILURE';
export const LOGOUT = 'LOGOUT';
export const SET_GEO = 'SET_GEO';

export function setGeoposition(position) {
  return {
    type: SET_GEO,
    position,
  };
}

export function updateDeviceSuccess(device) {
  return {
    type: UPDATE_DEVICE_SUCCESS,
    device,
  };
}

export function signInSuccess(device, user) {
  return {
    type: SIGN_IN_DEVICE_SUCCESS,
    device,
    user,
  };
}

export function updateDevice() {
  return {
    type: UPDATE_DEVICE_REQUEST,
  };
}

export function signInDevice(phone, code) {
  return {
    type: SIGN_IN_DEVICE_REQUEST,
    phone,
    code,
  };
}

export function codeDevice(phone) {
  return {
    type: CODE_DEVICE_REQUEST,
    phone,
  };
}

export function codeDeviceSuccess() {
  return {
    type: CODE_DEVICE_SUCCESS,
  };
}

export function codeDeviceFailure(err) {
  return {
    type: CODE_DEVICE_FAILURE,
    err,
  };
}

export function signInFailure(err) {
  return {
    type: SIGN_IN_DEVICE_FAILURE,
    err,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}
