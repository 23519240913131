import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import EmployeeSprintInfoCard from './EmployeeSprintInfoCard';
import { Loader } from '../Loader';

class SprintInfo extends React.Component {
  constructor(props) {
    super(props);
    this.props.getAllIterations('past');
    this.props.getIterationForAllUsers(this.props.sprintId);
    this.state = {
      sprintId: this.props.sprintId,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (typeof this.state.sprintId === 'undefined' && nextProps.sprintId) {
      this.setState({ sprintId: nextProps.sprintId });
    }
  }
  onSprintChange = (ev) => {
    const sprintId = +ev.currentTarget.getAttribute('data-id');
    this.props.getIterationForAllUsers(sprintId);
    this.setState({ sprintId });
  };
  recalculateIteration = () => {
    this.props.recalculateIteration(this.state.sprintId);
  };
  render() {
    const sprintEmployees = this.props.employeesIterations(this.state.sprintId).map((item) => {
      const { userID, iteration } = item;
      const user = this.props.getEmployeeByID(userID);
      if (!user) return null;
      return (
        <EmployeeSprintInfoCard
          key={userID}
          userID={userID}
          sprintId={this.state.sprintId}
          email={user.email}
          name={user.name}
          surname={user.surname}
          position={user.position}
          workedSec={iteration.workedSec}
          planedSec={iteration.planedSec}
          vacationSec={iteration.vacationSec}
          issuesDone={iteration.issuesDone}
          issuesFixed={iteration.issuesFixed}
          issuesWaiting={iteration.issuesWaiting}
          moneyAward={iteration.moneyAward}
          moneySalary={iteration.moneySalary}
          moneyTotal={iteration.moneyTotal}
        />
      );
    });
    const { byId, indexes } = this.props.iterations;
    const loaded = (byId[this.state.sprintId] && !byId[this.state.sprintId].loading) || false;
    const sprints = indexes.map((id) => {
      const iteration = byId[id];
      const startD = moment(iteration.startD).format('D');
      const endD = moment(iteration.endD).format('D');
      const month = moment(iteration.startD)
        .format('MMM')
        .replace('.', '');
      const year = moment(iteration.startD).format('YYYY');
      const sprintInfo = `${startD} - ${endD} ${month} ${year}`;
      return (
        <div
          className={`sprints-list-item ${iteration.id === this.state.sprintId ? 'active' : ''}`}
          key={`iteration#${iteration.id}`}
          data-id={iteration.id}
          onClick={this.onSprintChange}
        >
          <span>{sprintInfo}</span> [{iteration.reqHrs}]
        </div>
      );
    });
    return (
      <div style={{ opacity: loaded ? 1 : 0.05 }}>
        <Loader show={!loaded} />
        <div className="sprints-list d-flex">{sprints}</div>
        <div className="line" />
        <button onClick={this.recalculateIteration}>Пересчитать</button>
        <div className="sprint-employees">{sprintEmployees}</div>
      </div>
    );
  }
}

SprintInfo.propTypes = {
  getEmployeeByID: PropTypes.func.isRequired,
  recalculateIteration: PropTypes.func.isRequired,
  getAllIterations: PropTypes.func.isRequired,
  getIterationForAllUsers: PropTypes.func.isRequired,
  sprintId: PropTypes.number.isRequired,
  employeesIterations: PropTypes.func.isRequired,
  iterations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SprintInfo;
