/* eslint-disable*/
let errorsMsg = "";
let errorsCnt = 0;
let validateErrors = {};
let validateErrorsMaxLevel = 0; // 0 - Pass, 1 - Warn, 100 - Error

let validObject = {};

const locales = {
  "sq" : "sq_AL",
  "ar" : "ar_AE",
  "be" : "be_BY",
  "bg" : "bg_BG",
  "ca" : "ca_ES",
  "zh" : "zh_CN",
  "hr" : "hr_HR",
  "cs" : "cs_CZ",
  "da" : "da_DK",
  "nl" : "nl_NL",
  "en" : "en_US",
  "et" : "et_EE",
  "fi" : "fi_FI",
  "fr" : "fr_FR",
  "de" : "de_DE",
  "el" : "el_GR",
  "iw" : "iw_IL",
  "hi" : "hi_IN",
  "hu" : "hu_HU",
  "is" : "is_IS",
  "in" : "in_ID",
  "ga" : "ga_IE",
  "it" : "it_IT",
  "ja" : "ja_JP",
  "ko" : "ko_KR",
  "lv" : "lv_LV",
  "lt" : "lt_LT",
  "mk" : "mk_MK",
  "ms" : "ms_MY",
  "mt" : "mt_MT",
  "no" : "no_NO",
  "pl" : "pl_PL",
  "pt" : "pt_PT",
  "ro" : "ro_RO",
  "ru" : "ru_RU",
  "sr" : "sr_BA",
  "sk" : "sk_SK",
  "sl" : "sl_SI",
  "es" : "es_US",
  "sv" : "sv_SE",
  "th" : "th_TH",
  "tr" : "tr_TR",
  "uk" : "uk_UA",
  "vi" : "vi_VN"
};


function validate( object, rules, throwError = true ) {

  errorsMsg = '';
  errorsCnt = 0;
  validateErrors = {};
  validateErrorsMaxLevel = 0; // 0 - Pass, 1 - Warn, 100 - Error


  validObject = validateProcess( object, rules, '' );

  if( throwError ){

    if( errorsCnt > 0 ){
      let err = new Error( "VALIDATE ERRORS ["+errorsCnt+"] \n"+errorsMsg );
      err.code = err.status = err.statusCode = 400;
      throw( err );
    }

    return validObject;

  }
  else {
    return {
      object:         validObject,
      errorsCnt:      errorsCnt,
      errors:         validateErrors,
      errorsMaxLevel: validateErrorsMaxLevel
    };
  }




};



function validateError( path, message, field, rule ) {
  errorsCnt ++;

  message= path +': '+message+'  '+JSON.stringify( rule )+' val:'+JSON.stringify( field );

  let errLevel = rule['errLevel']!==undefined? rule['errLevel']: 100;

  if( !validateErrors[ errLevel ]) validateErrors[ errLevel ] = {};
  validateErrors[ errLevel ][ path ] = message;

  errorsMsg += message+"  \n";

  if( errLevel > validateErrorsMaxLevel ){
    validateErrorsMaxLevel = errLevel;
  }

}



function validateProcess( field, rule, path ) {

  //0. Валидно ли правило
  if( !rule ||  typeof rule !== 'object' ){
    //Сранная ситуация - правило не объект
    return field;
  }

  //1. Правило конечно или это вложенный объект
  if( typeof rule === 'object' && !rule["typeOf"]  ){

    if( field == undefined ){
      return {};
    }

    if( typeof rule !== typeof field ){
      validateError( path, "typeof rule and field missmatch: "+typeof rule+" != "+typeof field, field, rule );
      return ;
    }

    let rules  = rule;
    let res    = {};

    for( var key in rules ) {

      let nextPath = path.length > 0 ? path+'.'+key : key;

      if( typeof field === 'object' && rules[ key  ] ){
        let val = validateProcess( field[ key  ], rules[ key  ], nextPath );

        if( val !== undefined ){
          res[ key ] = val;
        }

      }

    }

    return res;
  }


  //2. Проверяем есть ли вообще поле или дефолт для него
  if( field === undefined ){
    // Объекта нет, смотрим есть ли дефаулт
    let value    = ( rule.default !== undefined )?rule.default : undefined ;

    if( rule.errLevel != undefined ||  rule.default == undefined  ){
      validateError( path, "required", field, rule );
    }

    return rule.default;

  }
  //если требуется число, но пришла строка, привести к типу
  if(rule['typeOf'] === 'number' && typeof field === 'string') {
    field = isNaN( field * 1 )? field : field * 1;
  }

  //Пляски с локалями

  if( rule['typeOf'] === 'locale' ) {

    if( field.indexOf('@') !== -1 ){
      field = field.split("@")[0];
    }

    if( field.length < 2 || field.length >=200 ) {
      validateError( path, "Wrong size of Locale value", field, rule );
      return
    }

    if( field.indexOf( '-' ) !== -1 ) { //ru-RU
      field = field.replace('-','_');
    }

    if( field.indexOf( '_' ) === -1 ) { //ru
      field = field.toLowerCase();
      field = locales[ field ]?locales[ field ]:field+'_UNDF';
    }


    if( field.indexOf( '_' ) === -1 ) {
      validateError( path, "Wrong Locale value", field, rule );
      return
    }

    let locPath = field.split('_');

    field = locPath[0].toLowerCase()+'_'+locPath[1].toUpperCase();

    return field;
  }


  //3. Проверяем тип поля
  if( !isValidType( rule["typeOf"] , field ) ){
    validateError( path, "Invalid typeOf ", field, rule );
    return ;
  }

  //4. values
  if( rule.values ){

    if( rule.values.indexOf( field ) !== -1 ){
      return field;
    }
    else {
      validateError( path, "Invalid value ", field, rule );
      return ;
    }

  }

  //5. between
  if( rule.between ){

    let min = rule.between[0]?rule.between[0]:0;
    let max = rule.between[1]?rule.between[1]:min+1;

    let val = ( rule["typeOf"] === 'number' )? field * 1 : string( field ).length;

    if( val >= min && val <= max ){
      return field;
    }
    else {
      validateError( path, "Invalid between ("+val+")", field, rule );
      return ;
    }

  }

  //6. ???

  return field;
}

function isValidType( type, value ) {

  let jsTypes = ["number", "string", "boolean", "object" ];

  if( jsTypes.indexOf( type ) !== -1 ){
    // Тип дефалтовый

    if( typeof value ===  type ){
      return true;
    }
    else {
      return false;
    }

  }

  if( type === 'guid' ){
    return match( value.toLowerCase() ,  /^[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$/ );
  }

  if( type === 'timeZone' ){
    return match( value,  /^(?:[a-z\d\-]+\/[a-z\d\-\+_]+[\/a-z\d\-\+_ ]*|GMT|UTC|)$/i );
  }

  if( type === 'locale' ){

    if( value.indexOf('@') !== -1 ){
      value = value.split("@")[0];
    }

    return match( value,  /^[a-z]{2,}_[A-Z_]{2,}$/ );
  }

  if( type === 'iso8601dt' ){
    return match( value, /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:Z|-0[1-9]|-1\d|-2[0-3]|-00:?(?:0[1-9]|[1-5]\d)|\+[01]\d|\+2[0-3])(?:|:?[0-5]\d)$/  );
  }

  if( type === 'dt' ){
    return match( value, /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/ );
  }

  if( type === 'date' ){
    return match( value, /^(?:19|20|25|26)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])$/ );
  }

  if( type === 'float' ){
    return match( value, /^-?\d+(?:\.\d+|)|$/ );
  }

  if( type === 'json' ){
    var v = string( value );
    if ( v ) {
      try {
        JSON.parse( v );
        return true;
      } catch ( e ) {
        return false;
      }
    } else {
      return false;
    }
  }

}


function match( val, re ) {
  var ret = string( val ).match( re );
  return ret? true : false;
}


function string ( val ) {
  return val === 0 ? '0' : '' + ( val || '' );
}



const PARAM_TYPES   = [ 'number', 'string', 'guid', 'timeZone', 'locale', 'iso8601dt', 'date', 'float', 'json' ];
const TIMEZONES     = [ 'Europe/Moscow', 'Africa/Tunis', 'America/Aruba', 'Asia/Almaty', 'Asia/Dubai', 'Australia/Sydney' ];

function generate( rule = {}, valid = 1 ) {

  let { between, values, typeOf } = rule;

  if( !typeOf )
    return;

  if( valid === -1 ) {
    let invalidType;
    do {
      invalidType = PARAM_TYPES.randomElement();
    } while ( invalidType === typeOf );
    return generate({ typeOf: invalidType });
  }

  values  = Array.isArray( values ) ? values : [];
  if( valid === 1 && values.length ) {
    return values.randomElement()
  }

  /*if( valid === -2 ) {
   return fs.readFileSync('someHugeFile')
   }*/

  switch ( typeOf ) {

    case 'number':
      between = between || [ 0, 5 ];

      if( valid === 0 ) {
        // сдвинуть промежуток за пределы заданного
        let shiftBy = between[1] - between[0];
        between[0] += shiftBy;
        between[1] += shiftBy
      }
      return Number.randomBetween.apply(null, between);

    case 'string':
      between = between || [ 0, 10 ];
      if( valid === 0 ) {
        // увеличить заданную длину строки в полтора раза
        between[1] += between[1] / 2;
      }

      let str = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for( let i = between[0]; i < between[1]; i++ )
        str += possible.charAt(Math.floor(Math.random() * possible.length));    //TODO mb add method on sa-prototype String.prototype.randomSubstring( length = 1 )

      return str;

    case 'guid':
      let guid = String.generateGUID();
      return valid === 0 ? guid.replace(/-/g, '_') : guid;

    case 'timeZone':
      let tz = TIMEZONES.randomElement();
      return valid === 0 ? tz.replace('/', '\\') : tz;

    case 'locale':
      let locale      = locales[ Object.keys( locales ).randomElement() ];
      return valid === 0 ? locale.replace(/-|_/, '/') : locale;

    case 'iso8601dt':
      let date = new Date();
      // если есть промежуток, невалидная дата - это просто за промежутком
      // иначе ломаю формат строки с датой
      if( between ) {
        let minDT = new Date(between[0]);
        let maxDT = new Date(between[1]);

        let shiftMS = valid === 0 ? maxDT - minDT : 0;

        date.setTime( Number.randomBetween(minDT.getTime() + shiftMS, maxDT.getTime() + shiftMS ) );

        return date.toISOString();
      }

      return valid === 0 ? date.toISOString().replace(/-/g, '&') : date.toISOString();

    case 'date':

      rule.typeOf = 'iso8601dt';

      let dateStr = generate( rule, valid );
      return dateStr.slice(0, 10);

    case 'float':

      rule.typeOf = 'number';

      let num =  generate( rule, valid );
      num /= [10, 100, 1000].randomElement() ;
      return num;

    case 'json':
      //тут особо не разгуляешься, только хардкод
      let json = JSON.stringify({ a: 1, b: 'string', c: null, d: { e: [ 1, 'str', { a: 10 } ] } });

      if( valid == 0 ){
        // и тут
        json = json.slice(0, json.length / 2);
        // json = json.replace(/"/g, '');
      }
      return json;

  }
}

module.exports = { validate, generate };
