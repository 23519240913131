/* eslint-disable no-mixed-operators */
import { GET_WORKLOGS_SUCCESS } from '../actions/employeesActions';

export const getWorkLogsByIteration = (state, login, iteration) => (
  state[login] && state[login][iteration] || []
);

export default function workLogsReducer(state = {}, action = {}) {
  switch (action.type) {
    case GET_WORKLOGS_SUCCESS: {
      const newState = { ...state };
      for (const login in action.data) {
        if (action.data.hasOwnProperty(login)) {
          if (typeof newState[login] === 'undefined') {
            newState[login] = {};
          }
          const workLogs = action.data[login].filter(dailyWorkState => (
            dailyWorkState.ID !== '' && dailyWorkState.iteration.id !== 0 // todo
          ));
          const sprintID = workLogs[0] && workLogs[0].iteration && workLogs[0].iteration.id;
          if (typeof sprintID !== 'undefined') {
            newState[login][sprintID] = workLogs;
          }
        }
      }
      return newState;
    }
    case 'LOGOUT': {
      return {};
    }
    default:
      return state;
  }
}
