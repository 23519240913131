import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { useAlert } from 'react-alert';

export default function ExtraEmailForm({
  extraEmail,
  onBlurExtraEmail,
}) {
  const [value, setValue] = useState(extraEmail);
  useEffect(() => setValue(value), [extraEmail]);
  const alert = useAlert();

  // Регулярное выражение для email
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const onChangeInput = (e) => {
    setValue(e.target.value);
  };

  const onBlurInput = () => {
    if (value.trim() === '' || value === extraEmail) return;
    if (!regex.test(value.trim())) {
      alert.show(<span style={{ textTransform: 'none' }}>Некорректный формат</span>, { type: 'error' });
      return;
    }
    onBlurExtraEmail(value);
  };

  return (
    <>
      <Label htmlFor="alternativeMail">Альтернативный Email (Support)</Label>
      <Input value={value} id="alternativeMail" onChange={onChangeInput} onBlur={onBlurInput} />
    </>
  );
}

const Label = styled.label`
  font-size: 11px;
  line-height: 13px;                   
  color: #9E9E9E;
  margin-bottom: 0px;
`;

const Input = styled.input`
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  border: none;
  border-bottom: 1px solid #BBBBBB;
`;

ExtraEmailForm.propTypes = {
  extraEmail: PropTypes.string.isRequired,
  onBlurExtraEmail: PropTypes.func.isRequired,
};
