export const docTypes = {
  passport: 'Паспорт РФ',
  zagran: 'Загран паспорт',
  inn: 'ИНН',
  snils: 'СНИЛС',
  pens: 'Пенсионное',
  trud: 'Трудовая',
  bolnich: 'Больничный',
  spravka: 'Справка',
  zayav: 'Заявление',
  other: 'Другой',
};
