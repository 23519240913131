import React from 'react';
import PropTypes from 'prop-types';
import EmployeeCard from './EmployeeCard';
import gravatar from '../../utils/gravatar';

const Department = ({ title, activeCount, count, position, employees, workstates, onSwitchWorkstate, isPending }) => {
  const getTodayInfo = (workstate) => {
    if (workstate && workstate.ID) {
      if (workstate.state === 2 || workstate.state === 3) {
        return {
          startTime: '',
          endTime: '',
          currentTime: '08:00',
        };
      }
      if (workstate.workedTime === '' || workstate.workedTime === '00:00') {
        return {
          startTime: '',
          endTime: '',
          currentTime: '00:00',
        };
      }
      return {
        startTime: workstate.fromTime || '00:00',
        endTime: workstate.toTime || '00:00',
        currentTime: workstate.workedTime || '00:00',
      };
    }
    return {
      startTime: '00:00',
      endTime: '00:00',
      currentTime: '00:00',
    };
  };
  const getSprintInfo = (iteration) => {
    if (iteration) {
      return {
        workHours: iteration.workedTime || '00:00',
        doneCount: iteration.done.length || 0,
        undoneCount: iteration.wait.length || 0,
      };
    }
    return {
      workHours: '00:00',
      doneCount: 0,
      undoneCount: 0,
    };
  };
  const employeesList = employees.map((employee) => {
    const workstate = workstates[employee.ID];
    if (!workstate || employee.visible === 0) {
      return null;
    }
    const iteration = workstate && workstate.iteration ? workstate.iteration : null;
    const sprintInfo = getSprintInfo(iteration);
    const todayInfo = getTodayInfo(workstate);
    return (
      <EmployeeCard
        key={`${employee.ID}`}
        position={position}
        active={workstate.state === 10}
        state={workstate.state}
        fullName={`${employee.name} ${employee.surname}`}
        workPosition={employee.position || 'Unknown'}
        avatar={gravatar(employee.email, { size: 100 })}
        issues={workstate.issues || []}
        workHours={sprintInfo.workHours}
        doneCount={sprintInfo.doneCount}
        undoneCount={sprintInfo.undoneCount}
        startTime={todayInfo.startTime}
        endTime={todayInfo.endTime}
        currentTime={todayInfo.currentTime}
        onSwitchWorkstate={onSwitchWorkstate.bind(this, employee.ID, workstate.state === 10 ? 'off' : 'on')}
        isPending={employee.ID in isPending}
      />
    );
  });
  return (
    <div className="offset-1 col-11 offset-lg-0 offset-md-0 col-md-12 col-lg-6 col-xl-6">
      <div
        className={`department-header d-flex ${
          position === 'right' ? 'flex-row-reverse' : 'flex-row'
        } align-items-center`}
      >
        <div className="department-title">{title}</div>
        <div className="badge badge-success department-employees-count__active">{activeCount}</div>
        <div className="badge badge-default department-employees-count">{count}</div>
      </div>
      {employeesList}
    </div>
  );
};

Department.propTypes = {
  title: PropTypes.string.isRequired,
  activeCount: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  position: PropTypes.string.isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSwitchWorkstate: PropTypes.func.isRequired,
  workstates: PropTypes.shape().isRequired,
  isPending: PropTypes.objectOf(PropTypes.bool).isRequired,
};

export default Department;
