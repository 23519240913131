import { take, fork, all, put, select, call } from 'redux-saga/effects';
import {
  transitIssue,
  getIssueByKey,
  getFeatures,
  getIssueByJql,
  getIssueByStatus,
  getIssueByStatusCategory,
} from '../api/issuesApi';
import { getWorkLogs } from '../api/workstateApi';
import {
  TRANSIT_ISSUE,
  transitIssueRequest,
  transitIssueSuccess,
  transitIssueFailure,
  GET_ISSUE_BY_KEY,
  getIssueByKeyRequest,
  getIssueByKeySuccess,
  getIssueByKeyFailure,
  GET_ISSUE_BY_JQL,
  getIssueByJqlRequest,
  getIssueByJqlSuccess,
  getIssueByJqlFailure,
  GET_ISSUE_BY_STATUS,
  getIssueByStatusRequest,
  getIssueByStatusSuccess,
  getIssueByStatusFailure,
  GET_ISSUE_BY_STATUS_CATEGORY,
  getIssueByStatusCategoryRequest,
  getIssueByStatusCategorySuccess,
  getIssueByStatusCategoryFailure,
  GET_FEATURES,
  getFeaturesRequest,
  getFeaturesSuccess,
  getFeaturesFailure,
  GET_WORKLOGS,
  getWorklogsRequest,
  getWorklogsSuccess,
  getWorklogsFailure,
} from '../../redux/actions/employeesActions';
import { getIteration } from '../../redux/actions/iterationActions';
import { getWaitingIssues } from '../../redux/actions/issuesActions';
import { getDevice } from '../../redux/reducers/device';

export function* transitIssueApiRequest(key, to) {
  yield put(transitIssueRequest());
  const device = yield select(getDevice);
  const response = yield call(transitIssue, device.device, key, to);
  if (response.status === 200) {
    yield put(transitIssueSuccess(response.body.data, key));
    yield put(getIteration(response.body.data.iteration.id));
    yield put(getWaitingIssues());
  } else {
    yield put(transitIssueFailure(null, key));
  }
}

export function* getIssueByKeyApiRequest(key) {
  yield put(getIssueByKeyRequest());
  const device = yield select(getDevice);
  const response = yield call(getIssueByKey, device.device, key);
  if (response.status === 200) {
    yield put(getIssueByKeySuccess(response.body.data));
  } else {
    yield put(getIssueByKeyFailure());
  }
}

export function* getIssueByJqlApiRequest(jql) {
  yield put(getIssueByJqlRequest());
  const device = yield select(getDevice);
  const response = yield call(getIssueByJql, device.device, jql);
  if (response.status === 200) {
    yield put(getIssueByJqlSuccess(response.body.data));
  } else {
    yield put(getIssueByJqlFailure());
  }
}

export function* getIssueByStatusApiRequest(user, status, iteration) {
  yield put(getIssueByStatusRequest());
  const device = yield select(getDevice);
  const response = yield call(getIssueByStatus, device.device, user, status, iteration);
  if (response.status === 200) {
    yield put(getIssueByStatusSuccess(response.body.data));
  } else {
    yield put(getIssueByStatusFailure());
  }
}

export function* getIssueByStatusCategoryApiRequest(user, category) {
  yield put(getIssueByStatusCategoryRequest());
  const device = yield select(getDevice);
  const response = yield call(getIssueByStatusCategory, device.device, user, category);
  if (response.status === 200) {
    yield put(getIssueByStatusCategorySuccess(response.body.data));
  } else {
    yield put(getIssueByStatusCategoryFailure());
  }
}

export function* getFeaturesApiRequest() {
  yield put(getFeaturesRequest());
  const device = yield select(getDevice);
  const response = yield call(getFeatures, device.device);
  if (response.status === 200) {
    yield put(getFeaturesSuccess(response.body.data));
  } else {
    yield put(getFeaturesFailure());
  }
}

export function* getWorklogsApiRequest(user, iteration) {
  yield put(getWorklogsRequest());
  const device = yield select(getDevice);
  const response = yield call(getWorkLogs, device.device, user, iteration);
  if (response.status === 200) {
    yield put(getWorklogsSuccess(response.body.data));
  } else {
    yield put(getWorklogsFailure());
  }
}

function* watchTransitIssue() {
  while (true) {
    const { key, to } = yield take(TRANSIT_ISSUE);
    yield fork(transitIssueApiRequest, key, to);
  }
}

function* watchGetIssueByKey() {
  while (true) {
    const { key } = yield take(GET_ISSUE_BY_KEY);
    yield fork(getIssueByKeyApiRequest, key);
  }
}

function* watchGetIssueByJql() {
  while (true) {
    const { jql } = yield take(GET_ISSUE_BY_JQL);
    yield fork(getIssueByJqlApiRequest, jql);
  }
}

function* watchGetIssueByStatus() {
  while (true) {
    const { user, status, iteration } = yield take(GET_ISSUE_BY_STATUS);
    yield fork(getIssueByStatusApiRequest, user, status, iteration);
  }
}

function* watchGetIssueByStatusCategory() {
  while (true) {
    const { user, category } = yield take(GET_ISSUE_BY_STATUS_CATEGORY);
    yield fork(getIssueByStatusCategoryApiRequest, user, category);
  }
}

function* watchGetFeatures() {
  while (true) {
    yield take(GET_FEATURES);
    yield fork(getFeaturesApiRequest);
  }
}

function* watchGetWorklogs() {
  while (true) {
    const { user, iteration } = yield take(GET_WORKLOGS);
    yield fork(getWorklogsApiRequest, user, iteration);
  }
}

export default function* employeesSaga() {
  yield all([
    fork(watchTransitIssue),
    fork(watchGetIssueByKey),
    fork(watchGetIssueByJql),
    fork(watchGetIssueByStatus),
    fork(watchGetIssueByStatusCategory),
    fork(watchGetFeatures),
    fork(watchGetWorklogs),
  ]);
}
