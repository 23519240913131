export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';
export const SIGNUP_USER_REQUEST = 'SIGNUP_USER_REQUEST';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';

export function getUserList() {
  return {
    type: GET_USER_LIST,
  };
}

export function getUserListRequest() {
  return {
    type: GET_USER_LIST_REQUEST,
  };
}

export function getUserListSuccess(data) {
  return {
    type: GET_USER_LIST_SUCCESS,
    data,
  };
}

export function getUserListFailure(err) {
  return {
    type: GET_USER_LIST_FAILURE,
    err,
  };
}

export function getUserInfo(userID = 'self') {
  return {
    type: GET_USER_INFO,
    userID,
  };
}

export function getUserInfoRequest(userID) {
  return {
    type: GET_USER_INFO_REQUEST,
    userID,
  };
}

export function getUserInfoSuccess(data, userID) {
  return {
    type: GET_USER_INFO_SUCCESS,
    data,
    userID,
  };
}

export function getUserInfoFailure(err, userID) {
  return {
    type: GET_USER_INFO_FAILURE,
    err,
    userID,
  };
}

export function updateUserRequest() {
  return {
    type: UPDATE_USER_REQUEST,
  };
}

export function updateUserSuccess(data) {
  return {
    type: UPDATE_USER_SUCCESS,
    data,
  };
}

export function updateUserFailure(err) {
  return {
    type: UPDATE_USER_FAILURE,
    err,
  };
}

export function signupUserRequest() {
  return {
    type: SIGNUP_USER_REQUEST,
  };
}

export function signupUserSuccess(data) {
  return {
    type: SIGNUP_USER_SUCCESS,
    data,
  };
}

export function signupUserFailure(err) {
  return {
    type: SIGNUP_USER_FAILURE,
    err,
  };
}
