import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LoaderWrapper = styled.div`
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
  z-index: 2;
  display: ${props => (props.show ? 'block' : 'none')};
  ${props => props.scale && `transform: scale( ${props.scale});`};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #9a969685;
  cursor: not-allowed;
`;

export const RoundedOverlay = Overlay.extend`
  border-radius: 50%;
`;

export const Loader = ({ show, scale }) => {
  const loaderUrl = 'https://web.simplanum.com/images/splash-loader.svg';
  return (
    <LoaderWrapper show={show} scale={scale}>
      <img src={loaderUrl} alt="" />
    </LoaderWrapper>
  );
};

Loader.defaultProps = {
  scale: 0,
};

Loader.propTypes = {
  show: PropTypes.bool.isRequired,
  scale: PropTypes.number,
};
