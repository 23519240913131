import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import activeIcon from '../../../images/mark.svg';

export default function Field({
  content,
  active,
  size,
  type,
  onClick,
}) {
  let text = content;
  if (type === 'phone' && !content) {
    text = type;
  } else if (type === 'email' && !content) {
    text = type;
  }
  return (
    <Wrapper isExist={content} data-field={type} size={size} active={active} onClick={onClick}>
      {type === 'phone' ? formatPhoneNumberIntl(`+${content}`) : text}
    </Wrapper>
  );
}

const Wrapper = styled.span`
    ${({ isExist }) => !isExist ? 'color: #CDCDCD;' : ''}
    ${({ size, active }) => {
    switch (size) {
      case 'small': {
        return (
          `font-size: 14px;
           line-height: 17px;
           ${active ? `
              color: #48B3E8;
              background-image: url(${activeIcon});
              background-repeat: no-repeat;
              background-position-y: center;
              padding-left: 15px;
           ` : ''}
          `
        );
      }
      case 'large': {
        return (
          `font-size: 20px;
           line-height: 24px;
           ${active ? `
           color: #48B3E8;
           background-image: url(${activeIcon});
           background-repeat: no-repeat;
           background-position-y: center;
           padding-left: 30px;
           background-size: 8%;
           ` : ''}
          `
        );
      }
      default: {
        return (
          `font-size: 14px;
           line-height: 17px;
          `
        );
      }
    }
  }}
`;

Field.propTypes = {
  content: PropTypes.string.isRequired,
  active: PropTypes.bool,
  size: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Field.defaultProps = {
  active: false,
  onClick: () => {},
};
