import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function ConfirmationModal({
  onConfirm,
  hide,
}) {
  const onClick = (e) => {
    if (e.target.dataset.value === 'confirm') {
      onConfirm();
      // функция hide используется для закрытия модалки
      hide();
      return;
    }
    hide();
  };
  return (
    <ConfirmationModalWrapper>
      <h3>Подтвердить сброс кода</h3>
      <Buttons>
        {/* <div>
          {info}
        </div> */}
        <div>
          <button
            type="button"
            className="confirmButton actionButton"
            data-value="confirm"
            onClick={onClick}
          >
            Да
          </button>
        </div>
        <div>
          <button
            type="button"
            className="cancelButton"
            data-value="cancel"
            onClick={onClick}
          >
            Отмена
          </button>
        </div>
      </Buttons>
    </ConfirmationModalWrapper>
  );
}

const MAIN_COLOR = '#2EA8E5';

const ConfirmationModalWrapper = styled.div`
  display: flex;
  justify-content: center;    
  flex-direction: column;
  align-items: center;
  min-height: 154px;
  width: initial;
  max-width: 350px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #fefefe;
  box-shadow: -1px 3px 7px rgba(0, 0, 0, 0.5);
  padding: 25px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  z-index: 99;

  .deleteText{
    justify-content: center;
    text-align: center;
    padding-top: 10px;
    font-weight: 400;
    font-size: 16px;
    color: #838383;
  }

  > h3 {
    max-width: 250px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    color: #2b2b2b;
    padding-top: 10px;
    margin: 0;
    text-align: center;
  }
  > p {
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    color: #838383;
  }
  div {
    // width: 100%;
    > button {
      width: 100%;
      height: 40px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      outline: none;
      border: none;
      border-radius: 5px;
      box-sizing: border-box;
      background: transparent;
      padding: 10px 15px;
      cursor: pointer;
    }

    /* .closeButton {
      color: #9b9b9b;
      border: 1px solid #9B9B9B;
      :hover {
        color: #7c7c7c;
        border-color: #7c7c7c;
      }
      :focus {
        color: #6c6c6c;
        border-color: #6c6c6c;
      }
      :active {
        color: #6c6c6c;
        border-color: #6c6c6c;
      }
      :disabled {
        color: #b9b9b9;
        border-color: #b9b9b9;
      }
    } */
    
    .cancelButton {
      height: 40px;
      font-weight: 300;
      color: #c4c4c4 !important; 
      border: 1px solid #c4c4c4;
      padding: 10px;
      margin-top: 20px;
      cursor: pointer;

      :hover {
        background: #EF5362;
        box-shadow: 0px 7px 14px rgba(239, 83, 98, 0.54);
        color: #fff;
        border: 1px solid #EF5362;
        opacity: 1;
      }
      :focus,
      :active {
        background: #EF5362;
        box-shadow: '0px 7px 14px rgba(239, 83, 98, 0.54)' : '0px 7px 14px rgba(35, 163, 255, 0.54)'};
        color: #fff;
        border: 1px solid #EF5362
        opacity: 1;
      }
      :disabled {
        color: #b9b9b9;
      }
    }
    .confirmButton {
      height: 40px;
      color: ${MAIN_COLOR};
      border: 1px solid ${MAIN_COLOR};
      margin-top: 20px;
      :hover {
        background: ${MAIN_COLOR};
        box-shadow: 0px 7px 14px rgba(35, 163, 255, 0.54);
        color: #fff;
        opacity: 1;
      }
      :focus,
      :active {
        background: ${MAIN_COLOR};
        box-shadow: 0px 7px 14px rgba(35, 163, 255, 0.54);
        color: #fff;
        opacity: 1;
      }
      :disabled {
        opacity: 0.6;
      }
    }
    .deleteButton {
      height: 40px;
      color: #EF5362;
      border: 1px solid #EF5362;
      :hover {
        background: #EF5362;
        box-shadow: 0px 7px 14px rgba(239, 83, 98, 0.54);
        color: #fff;
        opacity: 1;
      }
      :focus,
      :active {
        background: #EF5362;
        box-shadow: 0px 7px 14px rgba(239, 83, 98, 0.54);
        color: #fff;
        opacity: 1;
      }
      :disabled {
        opacity: 0.6;
      }
    }
    .actionButton {
      background-color: transparent;
      color: #EF5362;
      border: 1px solid #EF5362;
      margin-top: 20px;

      :hover {
        background: #EF5362;
        box-shadow: 0px 7px 14px rgba(239, 83, 98, 0.54);
        color: #fff;
        opacity: 1;
      }
      :focus,
      :active {
        background: #EF5362;
        box-shadow: 0px 7px 14px rgba(239, 83, 98, 0.54);
        color: #fff;
        opacity: 1;
      }
      :disabled {
        opacity: 0.6;
      }
    }
  }

  > .noEmptyNotebook {
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 3px;
    > button {
      max-width: 350px;
      margin-top: 15px;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  // max-width: calc(100% + 10px);
  width: calc(100% + 10px);
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

ConfirmationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
};
