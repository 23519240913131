import ClientJS from 'clientjs';
import { guest } from '../../utils/defines';
import BODevice  from '../boModels/device';
import { REDUX_STORAGE_LOAD, UPDATE_TIME_EVERY_1_MIN } from './app';
import { UPDATE_DEVICE_SUCCESS, SIGN_IN_DEVICE_SUCCESS, LOGOUT, SET_GEO } from '../actions/deviceActions';

const getLanguage = () => {
  if (typeof navigator.languages !== 'undefined') {
    return navigator.languages[0];
  }
  return navigator.language;
};

const client = new ClientJS();

const getDeviceInfo = () => {
  const deviceInfo = {
    devOS: `${client.getOS().name} ${client.getOS().version}`,
    devModel: `${client.getBrowser().name} ${client.getBrowser().major}`,
    devName: `${client.getBrowser().name} (web)`,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    locale: getLanguage(),
    onlineDT: new Date().toDtString(),
  };
  try {
    const appInfo = require('../../../app-info.json');
    deviceInfo.appVer =  appInfo.ver;
    deviceInfo.appBuild =  +appInfo.build;
  } catch (e) {
    deviceInfo.appVer =  'v0.0.1';
    deviceInfo.appBuild =  0;
  }
  return deviceInfo;
};

export const getGuestDevice = () => (
  Object.assign(
    {},
    {
      ID: guest.devID,
      devToken: guest.devToken,
      ownerID: guest.ID,
      ownerPhone: guest.phone,
      devPlatform: 'Web',
      appName: 'BackOffice',
      geo: '0,0,0',
      pushToken: 'Nan',
      ver: '1.0',
      isGuest: true,
    },
    getDeviceInfo(),
  )
);

export function getDevice(state) {
  return state.device;
}

export default function deviceReducer(state = BODevice.byFields(getGuestDevice()), action = {}) {
  switch (action.type) {
    case SIGN_IN_DEVICE_SUCCESS:
    case UPDATE_DEVICE_SUCCESS: {
      return BODevice.byFields(action.device);
    }
    case LOGOUT: {
      return BODevice.byFields(getGuestDevice());
    }
    case SET_GEO: {
      const device = { ...state.device.__initFields,
        geo: `${action.position.latitude},${action.position.longitude},${action.position.accuracy}`,
      };
      return BODevice.byFields(device);
    }
    case UPDATE_TIME_EVERY_1_MIN: {
      return BODevice.byFields(Object.assign({}, state.device, getDeviceInfo()));
    }
    case REDUX_STORAGE_LOAD: {
      if (action.payload.device) {
        const dev = action.payload.device.device;
        if (dev && dev.ID && !(dev instanceof BODevice)) {
          return BODevice.byFields(dev);
        }
      }
      return state;
    }
    default:
      return state;
  }
}
