import React, { useReducer, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useAlert } from 'react-alert';

import TextareaForModal from './TextareaForModal';

import SubscribePillow from '../../ui/Pillows/SubscribePillow';
import Select from '../../ui/Select';

import * as fromStyles from '../../styles';

import { renewalPeriod, patternForRenewingSubscribe } from '../../../utils/optionsForSelect';
import getDateToString from '../../../utils/formattingDate';
import Context from '../../common/Context';

const initialState = {
  isFormValidate: false,
  selectedPeriod: { value: null, label: null },
  selectedPattern: null,
};

function init({ ID, comment, startDT, endDT, type, subscrEndDays }) {
  return {
    ...initialState,
    valueTextaria: comment,
    ID,
    comment,
    startDT,
    endDT,
    type,
    subscrEndDays,
    localStartDT: startDT,
    localEndDT: endDT,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_VALUE_TEXTARIA':
      return {
        ...state,
        valueTextaria: action.value,
      };
    case 'SELECT_PERIOD':
      return {
        ...state,
        selectedPeriod: action.value,
        localStartDT: new Date(),
        localEndDT: action.currentSubscrEndDT,
      };
    case 'SELECT_PATTERN':
      return {
        ...state,
        selectedPattern: action.pattern,
      };
    case 'CHANGE_IS_FORM_VALIDATE': {
      return {
        ...state,
        isFormValidate: action.value,
      };
    }
    default:
      return state;
  }
}

export default function RenewingSubscribe({
  propsFromModal,
  setIsVisibleModal,
}) {
  const { renewingSubscribe } = useContext(Context);
  const alert = useAlert();

  const [state, dispatch] = useReducer(reducer, propsFromModal, init);
  const {
    valueTextaria,
    isFormValidate,
    selectedPeriod,
    selectedPattern,
    ID,
    comment,
    startDT,
    endDT,
    type,
    subscrEndDays,
    localStartDT,
    localEndDT,
  } = state;

  const changeIsFormValidate = (value) => {
    dispatch({ type: 'CHANGE_IS_FORM_VALIDATE', value });
  };

  /* changeIsFormValidate примет параметры:
  true - когда текстовое поле не пустое, период продления был выбран
  false - когда значение текстового поля пустое и период продления не был выбран */
  useEffect(() => changeIsFormValidate(valueTextaria.trim() !== '' && selectedPeriod.value !== null), [valueTextaria, selectedPeriod]);

  const selectPeriod = (value) => {
    const DAY_IN_MSEC = 86400000;
    let currentSubscrEndDT = new Date(endDT);

    if (currentSubscrEndDT >= new Date()) {
      currentSubscrEndDT = new Date(currentSubscrEndDT.getTime() + value.value * DAY_IN_MSEC);
      dispatch({ type: 'SELECT_PERIOD', value, currentSubscrEndDT });
    } else {
      currentSubscrEndDT = new Date(Date.now() + value.value * DAY_IN_MSEC);
    }
    dispatch({ type: 'SELECT_PERIOD', value, currentSubscrEndDT });
  };

  const setValueTextarea = (value) => {
    dispatch({ type: 'SET_VALUE_TEXTARIA', value });
  };

  const selectPattern = (pattern) => {
    dispatch({ type: 'SELECT_PATTERN', pattern });
    setValueTextarea(pattern.label);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsVisibleModal(false);
    // alert.show(<span style={{ textTransform: 'none' }}>
    // Подписка продлена (ИМИТАЦИЯ)</span>, { type: 'success' });

    renewingSubscribe(ID, type, selectedPeriod.value, valueTextaria).then(() => {
      alert.show(
        <span
          style={{ textTransform: 'none' }}
        >
          Подписка продлена
        </span>,
        { type: 'success' },
      );
      setIsVisibleModal(false);
    }).catch(() => {
      alert.show('Ошибка! Подписка не продлена!', { type: 'error' });
    });
  };

  return (
    <Wrapper>
      <div className="renewingSubscribe">
        <div className="renewingSubscribe__header">
          <SubscribePillow type={type} size="large" />
        </div>
        <div className="renewingSubscribe__main">
          <div className="renewingSubscribe__main-select">
            <label>Срок продления</label>
            <Select
              renewing
              selectedOption={selectedPeriod}
              label="Сроки продления"
              size="large"
              options={renewalPeriod}
              onSelectOption={selectPeriod}
            />
          </div>
          <div className="renewingSubscribe__main-dates">
            <span className="renewingSubscribe__main-dates-start">{getDateToString(localStartDT)}</span>
            <span className="renewingSubscribe__main-dates-end">{getDateToString(localEndDT)}</span>
          </div>
          <TextareaForModal
            renewing
            className="renewingSubscribe__main-textaria"
            placeholder="Комментарий (Support)"
            height={87}
            width={304}
            value={valueTextaria || comment}
            onChangeValue={setValueTextarea}
            onSelectPattern={selectPattern}
            selectedPattern={selectedPattern}
            patterns={patternForRenewingSubscribe}
          />
        </div>
        <div className="renewingSubscribe__footer">
          <fromStyles.ButtonForModal
            disabled={!isFormValidate}
            className="renewingSubscribe__footer-button"
            onClick={onSubmit}
          >
            Продлить
          </fromStyles.ButtonForModal>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    .renewingSubscribe {
      margin: 17px 30px 25px 30px;
        &__header {
            margin-top: 18px;
            button:not(:first-of-type) {
                margin-left: 20px;
            }
        }
        &__main {
            margin-top: 18px;
            &-select {
            label {
              font-size: 14px;
              line-height: 17px;
              color: #9E9E9E;
              margin: 0;
            }
            }
            &-dates {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                line-height: 14px;         
                color: #9B9B9B;
                margin: 11px 0 13px 0;
                &-start {

                }
                &-end {
                }
            }
        }
        &__footer {
            display: flex;
            justify-content: flex-end;
            &-button {
                margin-top: 25px;
            }
        }
    }
`;

RenewingSubscribe.propTypes = {
  propsFromModal: PropTypes.shape({
    ID: PropTypes.string,
    comment: PropTypes.string,
    startDT: PropTypes.string,
    endDT: PropTypes.string,
    type: PropTypes.string,
    subscrEndDays: PropTypes.number,
  }).isRequired,
  setIsVisibleModal: PropTypes.func.isRequired,
};
