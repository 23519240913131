// периоды для модального окна "Продление подписки"
export const renewalPeriod = [
  { value: 30, label: '30 дней' },
  { value: 60, label: '60 дней' },
  { value: 365, label: '1 год' },
  { value: 730, label: '2 года' },
  { value: 36500, label: '100 лет' },
];

export const patternForRenewingSubscribe = [
  { value: 'pattern1', label: 'Друзья и партнёры' },
  { value: 'pattern2', label: 'В качестве поощрения' },
  { value: 'pattern3', label: 'Продление по внешним источникам не выполнена корректно' },
];

export const languages = [
  { value: 'ru', label: 'Ru' },
  { value: 'en', label: 'En' },
];

export const patternsForSendMessage = [
  { value: 'pattern1', label: 'Спасибо за обращение в службу поддержки!' },
  { value: 'pattern2', label: 'Возникшая ранее проблема исправлена!' },
  { value: 'pattern3', label: 'Ваше обращение взято на контроль разработчиками!' },
  { value: 'pattern4', label: 'Приятно было помочь, если будут вопросы обязательно задавайте, я помогу!' },
  { value: 'pattern5', label: 'Данная ошибка зафиксирована, приношу наши извинения. Нам есть куда расти, спасибо за Ваше обращение!' },
];
