import React     from 'react';
import PropTypes from 'prop-types';

class AttachmentPicker extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onDrag = this.onDrag.bind(this);
  }

  componentDidMount() {
    if (this.props.multiple) {
      this.fileInput.setAttribute('multiple', '');
    }
    this.fileInput.addEventListener('change', this.onChange);
    this.dragZone.addEventListener('dragstart', this.onDrag);
    this.dragZone.addEventListener('dragend', this.onDrag);
    this.dragZone.addEventListener('dragover', this.onDrag);
    this.dragZone.addEventListener('dragleave', this.onDrag);
    this.dragZone.addEventListener('drop', this.onDrag);
  }

  onChange(ev) {
    const files = this.fileInput.files.length ? this.fileInput.files : ev.detail;
    for (let i = 0; i < files.length; i++) {
      this.props.onDocumentAttach(files[i]);
    }
  }

  onDrag(ev) {
    ev.preventDefault();
    const { files } = ev.dataTransfer;
    const event = new CustomEvent('change', { detail: files });
    if (ev.type === 'drop') {
      this.dragZone.classList.remove('is-dragover');
      this.dragZone.querySelector('input[type="file"]').dispatchEvent(event);
    }
    if (ev.type === 'dragleave' || ev.type === 'dragend') {
      this.dragZone.classList.remove('is-dragover');
    }
    if (ev.type === 'dragover' || ev.type === 'dragenter') {
      this.dragZone.classList.add('is-dragover');
    }
  }

  render() {
    const { className, text } = this.props;
    return (
      <div
        ref={(dragZone) => { this.dragZone = dragZone; }}
        className={className}
      >
        {text}
        <input
          ref={(input) => { this.fileInput = input; }}
          type="file"
        />
      </div>
    );
  }
}

AttachmentPicker.defaultProps = {
  multiple: false,
};

AttachmentPicker.propTypes = {
  onDocumentAttach: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
};

export default AttachmentPicker;
