import { take, fork, all, put, select, call } from 'redux-saga/effects';
import {
  updateHospital,
  getHospitalList,
  updateVacation,
  switchVacation,
  getVacationList,
  getVacationStats,
  getHospitalStats,
  removeVacation,
  removeHospital,
  switchHospital,
} from '../api/hospitalsVacationsApi';
import { uploadDocument } from '../api/documentsApi';
import {
  UPDATE_HOSPITAL,
  updateHospitalRequest,
  updateHospitalSuccess,
  updateHospitalFailure,
  GET_HOSPITAL_LIST,
  getHospitalListRequest,
  getHospitalListSuccess,
  getHospitalListFailure,
  UPDATE_VACATION,
  updateVacationRequest,
  updateVacationSuccess,
  updateVacationFailure,
  GET_VACATION_LIST,
  getVacationListRequest,
  getVacationListSuccess,
  getVacationListFailure,
  GET_VACATION_STAT,
  getVacationStatRequest,
  getVacationStatSuccess,
  getVacationStatFailure,
  GET_HOSPITAL_STAT,
  getHospitalStatRequest,
  getHospitalStatSuccess,
  getHospitalStatFailure,
  REMOVE_VACATION,
  removeVacationRequest,
  removeVacationSuccess,
  removeVacationFailure,
  SWITCH_VACATION,
  switchVacationRequest,
  switchVacationSuccess,
  switchVacationFailure,
  REMOVE_HOSPITAL,
  removeHospitalRequest,
  removeHospitalSuccess,
  removeHospitalFailure,
  UPLOAD_HOSPITAL,
  uploadHospitalRequest,
  uploadHospitalSuccess,
  uploadHospitalFailure,
  SWITCH_HOSPITAL,
  switchHospitalRequest,
  switchHospitalSuccess,
  switchHospitalFailure,
} from '../../redux/actions/hospitalsActions';
import { getDevice } from '../../redux/reducers/device';

export function* updateHospitalApiRequest(hospital, userID) {
  yield put(updateHospitalRequest());
  const device = yield select(getDevice);
  const response = yield call(updateHospital, device.device, hospital, userID);
  if (response.status === 200) {
    yield put(updateHospitalSuccess(response.body.data));
  } else {
    yield put(updateHospitalFailure());
  }
}

export function* getHospitalListApiRequest(userID, date, status) {
  yield put(getHospitalListRequest());
  const device = yield select(getDevice);
  const response = yield call(getHospitalList, device.device, userID, date, status);
  if (response.status === 200) {
    yield put(getHospitalListSuccess(response.body.data));
  } else {
    yield put(getHospitalListFailure());
  }
}

export function* updateVacationApiRequest(vacation, userID) {
  yield put(updateVacationRequest());
  const device = yield select(getDevice);
  const response = yield call(updateVacation, device.device, vacation, userID);
  if (response.status === 200) {
    yield put(updateVacationSuccess(response.body.data));
  } else {
    yield put(updateVacationFailure());
  }
}

export function* getVacationListApiRequest(user, date, status) {
  yield put(getVacationListRequest());
  const device = yield select(getDevice);
  const response = yield call(getVacationList, device.device, user, date, status);
  if (response.status === 200) {
    yield put(getVacationListSuccess(response.body.data));
  } else {
    yield put(getVacationListFailure());
  }
}

export function* getVacationStatApiRequest(userID) {
  yield put(getVacationStatRequest());
  const device = yield select(getDevice);
  const response = yield call(getVacationStats, device.device, userID);
  if (response.status === 200) {
    yield put(getVacationStatSuccess(response.body.data));
  } else {
    yield put(getVacationStatFailure());
  }
}

export function* getHospitalStatApiRequest(userID) {
  yield put(getHospitalStatRequest());
  const device = yield select(getDevice);
  const response = yield call(getHospitalStats, device.device, userID);
  if (response.status === 200) {
    yield put(getHospitalStatSuccess(response.body.data));
  } else {
    yield put(getHospitalStatFailure());
  }
}

export function* removeVacationApiRequest(id, userID) {
  yield put(removeVacationRequest());
  const device = yield select(getDevice);
  const response = yield call(removeVacation, device.device, id, userID);
  if (response.status === 200) {
    yield put(removeVacationSuccess(response.body.data));
  } else {
    yield put(removeVacationFailure());
  }
}

export function* switchVacationApiRequest(id, userID) {
  yield put(switchVacationRequest());
  const device = yield select(getDevice);
  const response = yield call(switchVacation, device.device, id, userID);
  if (response.status === 200) {
    yield put(switchVacationSuccess(response.body.data));
  } else {
    yield put(switchVacationFailure());
  }
}

export function* removeHospitalApiRequest(id) {
  yield put(removeHospitalRequest());
  const device = yield select(getDevice);
  const response = yield call(removeHospital, device.device, id);
  if (response.status === 200) {
    yield put(removeHospitalSuccess(response.body.data));
  } else {
    yield put(removeHospitalFailure());
  }
}

export function* uploadHospitalApiRequest(file, type, desc, ID) {
  yield put(uploadHospitalRequest());
  const device = yield select(getDevice);
  const response = yield call(uploadDocument, device.device, file, type, desc, ID);
  if (response.status === 201) {
    yield put(uploadHospitalSuccess(response.data.data));
  } else {
    yield put(uploadHospitalFailure());
  }
}

export function* switchHospitalApiRequest(id, docID, endD) {
  yield put(switchHospitalRequest());
  const device = yield select(getDevice);
  const response = yield call(switchHospital, device.device, id, docID, endD);
  if (response.status === 200) {
    yield put(switchHospitalSuccess(response.body.data));
  } else {
    yield put(switchHospitalFailure());
  }
}

function* watchUpdateHospital() {
  while (true) {
    const { hospital, userID } = yield take(UPDATE_HOSPITAL);
    yield fork(updateHospitalApiRequest, hospital, userID);
  }
}

function* watchGetHospitalList() {
  while (true) {
    const { userID, date, status } = yield take(GET_HOSPITAL_LIST);
    yield fork(getHospitalListApiRequest, userID, date, status);
  }
}

function* watchUpdateVacation() {
  while (true) {
    const { vacation, userID } = yield take(UPDATE_VACATION);
    yield fork(updateVacationApiRequest, vacation, userID);
  }
}

function* watchGetVacationList() {
  while (true) {
    const { login, date, status } = yield take(GET_VACATION_LIST);
    yield fork(getVacationListApiRequest, login, date, status);
  }
}

function* watchGetVacationStat() {
  while (true) {
    const { userID } = yield take(GET_VACATION_STAT);
    yield fork(getVacationStatApiRequest, userID);
  }
}

function* watchGetHospitalStat() {
  while (true) {
    const { userID } = yield take(GET_HOSPITAL_STAT);
    yield fork(getHospitalStatApiRequest, userID);
  }
}

function* watchRemoveVacation() {
  while (true) {
    const { id, userID } = yield take(REMOVE_VACATION);
    yield fork(removeVacationApiRequest, id, userID);
  }
}

function* watchSwitchVacation() {
  while (true) {
    const { id, userID } = yield take(SWITCH_VACATION);
    yield fork(switchVacationApiRequest, id, userID);
  }
}

function* watchRemoveHospital() {
  while (true) {
    const { id } = yield take(REMOVE_HOSPITAL);
    yield fork(removeHospitalApiRequest, id);
  }
}

function* watchUploadHospital() {
  while (true) {
    const { file, docType, desc, ID } = yield take(UPLOAD_HOSPITAL);
    yield fork(uploadHospitalApiRequest, file, docType, desc, ID);
  }
}

function* watchSwitchHospital() {
  while (true) {
    const { id, docID, endD } = yield take(SWITCH_HOSPITAL);
    yield fork(switchHospitalApiRequest, id, docID, endD);
  }
}

export default function* hospitalSaga() {
  yield all([
    fork(watchUpdateHospital),
    fork(watchGetHospitalList),
    fork(watchUpdateVacation),
    fork(watchGetVacationList),
    fork(watchGetVacationStat),
    fork(watchGetHospitalStat),
    fork(watchRemoveVacation),
    fork(watchSwitchVacation),
    fork(watchRemoveHospital),
    fork(watchUploadHospital),
    fork(watchSwitchHospital),
  ]);
}
