import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import gravatar from '../../utils/gravatar';

const EmployeeWrapper = styled.div`
  position: relative;
  background: #fff;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
  width: 100%;
`;

const EmployeeInfoWrapper = styled.div`
  font-size: 22px;
  color: #000;
  line-height: 24px;
  margin-left: 10px;
`;

const EmployeeName = styled.div`
  font-family: 'HelveticaNeueMedium';
  margin-top: 10px;
`;

const EmployeeFieldBold = styled.span`
  font-size: 14px;
  font-family: 'HelveticaNeueMedium';
  color: #000;
  font-weight: 900;
`;

const EmployeeField = styled.div`
  font-size: 14px;
  font-family: 'HelveticaNeueRegular';
  color: #a6a6a6;
`;

const EmployeeCard = (props) => {
  const {
    name,
    ID,
    surname,
    email,
    position,
    department,
    office,
    phone,
    birthdayD,
    beginD,
    visible,
    getUserCard,
    currentUserID,
    allows,
  } = props;
  return (
    <EmployeeWrapper className="d-flex flex-row">
      <div
        onClick={() => {
          window.open(getUserCard(ID), '_blank');
        }}
      >
        <img className="rounded-circle" src={gravatar(email, { size: 70 })} alt="icon" />
      </div>
      <EmployeeInfoWrapper>
        <EmployeeName>
          {allows === 100 ? (
            <Link to={ID === currentUserID ? '/employee' : `/team/${ID}`}>{`${name} ${surname}`}</Link>
          ) : (
            `${name} ${surname}`
          )}
        </EmployeeName>
        <EmployeeField>
          <EmployeeFieldBold>{position}</EmployeeFieldBold>, {visible ? 'В штате' : 'Удаленно'}
        </EmployeeField>
        <EmployeeField>{`${department}, ${office}`}</EmployeeField>
        <EmployeeField>{`Работает с ${beginD}`}</EmployeeField>
        <EmployeeField>{`+${phone}`}</EmployeeField>
        <EmployeeField>
          <a href={`mailto:${email}`}>{email}</a>
        </EmployeeField>

        <EmployeeField>{`День рождения: ${birthdayD}`}</EmployeeField>
      </EmployeeInfoWrapper>
    </EmployeeWrapper>
  );
};

EmployeeCard.propTypes = {
  name: PropTypes.string.isRequired,
  ID: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  department: PropTypes.string.isRequired,
  office: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  birthdayD: PropTypes.string.isRequired,
  beginD: PropTypes.string.isRequired,
  currentUserID: PropTypes.string.isRequired,
  visible: PropTypes.number.isRequired,
  getUserCard: PropTypes.func.isRequired,
  allows: PropTypes.number.isRequired,
};

export default EmployeeCard;
