import React from 'react';

const icons = {
  close: (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1.70711" y1="1.29289" x2="11.7071" y2="11.2929" stroke="#CECECE" strokeWidth="2" />
      <line x1="1.29289" y1="11.2929" x2="11.2929" y2="1.29289" stroke="#CECECE" strokeWidth="2" />
    </svg>),
  closureIcon: (
    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.6784 0H6.49976V1.07141H1.6784C1.53632 1.07141 1.40006 1.12785 1.29959 1.22832C1.19913 1.32878
              1.14269 1.46504 1.14269 1.60712V13.3927C1.14269 13.5347 1.19913 13.671 1.29959 13.7715C1.40006
              13.8719 1.53632 13.9284 1.6784 13.9284H6.49976V14.9998H1.6784C1.25216 14.9998 0.843385 14.8305
              0.541991 14.5291C0.240598 14.2277 0.0712767 13.8189 0.0712767 13.3927V1.60712C0.0712771 1.18088
              0.240598 0.772108 0.541991 0.470714C0.843385 0.169321 1.25216 0 1.6784 0Z"
        fill="#505050"
      />
      <path
        d="M3.51064 8.03545V6.96403H11.3212L9.17842 4.82121L9.93912 4.04443L12.6177 6.72297C12.7173
              6.82247 12.7963 6.94064 12.8502 7.0707C12.9041 7.20077 12.9319 7.34019 12.9319 7.48099C12.9319
              7.62179 12.9041 7.76121 12.8502 7.89128C12.7963 8.02135 12.7173 8.13951 12.6177 8.23901L9.93912
              10.9175L9.17842 10.1783L11.3212 8.03545H3.51064Z"
        fill="#505050"
      />
    </svg>),
  arrowDown: (
    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.112982 0.104897C0.148705 0.0716462 0.191143 0.0452651
      0.237865 0.0272651C0.284586 0.00926505 0.334673 0 0.385258 0C0.435842 0 0.485929 0.00926505 0.532651 0.0272651C0.579373
      0.0452651 0.62181 0.0716462 0.657534 0.104897L5.0001 4.13744L9.34267 0.104897C9.37842 0.0717 9.42087 0.0453664 9.46759
      0.0274002C9.51431 0.00943405 9.56438 0.000186941 9.61494 0.000186941C9.66551 0.000186941 9.71558 0.00943405 9.7623
      0.0274002C9.80902 0.0453664 9.85146 0.0717 9.88722 0.104897C9.92298 0.138094 9.95134 0.177505 9.97069 0.220879C9.99004
      0.264254 10 0.310742 10 0.35769C10 0.404638 9.99004 0.451126 9.97069 0.4945C9.95134 0.537875 9.92298 0.577285 9.88722
      0.610483L5.27238 4.8951C5.23665 4.92835 5.19422 4.95473 5.14749 4.97273C5.10077 4.99073 5.05068 5 5.0001 5C4.94952
      5 4.89943 4.99073 4.85271 4.97273C4.80599 4.95473 4.76355 4.92835 4.72783 4.8951L0.112982 0.610483C0.0771683 0.577315
      0.0487541 0.537914 0.0293668 0.494536C0.00997949 0.451158 0 0.404654 0 0.35769C0 0.310725 0.00997949 0.264222 0.0293668
      0.220844C0.0487541 0.177465 0.0771683 0.138064 0.112982 0.104897Z"
        fill="#909090"
      />
    </svg>
  ),
  longArrowRight: (
    <svg width="34" height="6" viewBox="0 0 34 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.2121 3.21213C33.3293 3.09497 33.3293 2.90502 33.2121 2.78787L31.3029 0.878677C31.1858
        0.76152 30.9958 0.76152 30.8787 0.878677C30.7615 0.995834 30.7615 1.18578 30.8787 1.30294L32.5757
        3L30.8787 4.69705C30.7615 4.81421 30.7615 5.00416 30.8787 5.12132C30.9958 5.23847 31.1858 5.23847
        31.3029 5.12132L33.2121 3.21213ZM2.62268e-08 3.3L33 3.3L33 2.7L-2.62268e-08 2.7L2.62268e-08 3.3Z"
        fill="#B4B4B4"
      />
    </svg>
  ),
  status4005: (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.9502 10.6504C8.78711 10.6504 11.1211 8.32129 11.1211 5.47949C11.1211 2.6377 8.78711 0.303711 5.94531
      0.303711C3.10352 0.303711 0.774414 2.6377 0.774414 5.47949C0.774414 8.32129 3.1084 10.6504 5.9502 10.6504ZM5.9502
      6.11426C5.6084 6.11426 5.41309 5.92383 5.39355 5.58203L5.32031 3.64844C5.30078 3.28711 5.56445 3.03809 5.94531
      3.03809C6.32617 3.03809 6.58984 3.29199 6.57031 3.65332L6.49219 5.57715C6.47266 5.92871 6.28223 6.11426 5.9502
      6.11426ZM5.9502 7.99902C5.54492 7.99902 5.2666 7.75977 5.2666 7.38867C5.2666 7.02246 5.54004 6.78809 5.9502 6.78809C6.3457
      6.78809 6.62402 7.02246 6.62402 7.38867C6.62402 7.76465 6.3457 7.99902 5.9502 7.99902Z"
        fill="#FF4545"
      />
    </svg>
  ),
  status4006: (
    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.01465 9.96191H9.66113C10.457 9.96191 10.999 9.3418 10.999 8.61914C10.999 8.39453 10.9453 8.16992 10.8232
      7.95508L6.99512 1.09961C6.74121 0.650391 6.29199 0.425781 5.83789 0.425781C5.38379 0.425781 4.91992 0.650391 4.67578
      1.09961L0.847656 7.95996C0.725586 8.16504 0.671875 8.39453 0.671875 8.61914C0.671875 9.3418 1.21387 9.96191 2.01465
      9.96191ZM5.84277 6.51953C5.50098 6.51953 5.30566 6.3291 5.28613 5.9873L5.20801 4.05371C5.19336 3.69238 5.45215 3.44336
      5.83301 3.44336C6.21875 3.44336 6.48242 3.69727 6.46289 4.05859L6.38477 5.98242C6.36523 6.33398 6.1748 6.51953 5.84277
      6.51953ZM5.84277 8.4043C5.4375 8.4043 5.15918 8.16504 5.15918 7.79395C5.15918 7.42773 5.43262 7.19336 5.84277 7.19336C6.2334
      7.19336 6.5166 7.42773 6.5166 7.79395C6.5166 8.16992 6.23828 8.4043 5.84277 8.4043Z"
        fill="#FF9501"
      />
    </svg>
  ),
  typeActions: {
    addingExtraEmail: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M15.7964 17.6362C18.0317 17.6362 19.4468 15.6982 19.4468 12.9297V12.4272C19.4468 8.75635 16.7192 5.40332 11.7974
          5.40332H11.7153C7.01904 5.40332 3.67627 8.54102 3.67627 13.3809V13.9141C3.67627 19.0308 7.09082 21.7173 11.5 21.7173H11.6025C13.0996
          21.7173 14.1353 21.5225 14.6685 21.2764V19.7178C14.0327 20.0049 13.0176 20.1792 11.6846 20.1792H11.5923C8.35205 20.1792 5.4502 18.231
          5.4502 13.8525V13.4424C5.4502 9.47412 8.04443 6.96191 11.7153 6.96191H11.7974C15.5093 6.96191 17.7139 9.49463 17.7139 12.499V12.8066C17.7139
          15.1445 16.8936 16.1597 16.0527 16.1597C15.4375 16.1597 14.9658 15.7905 14.9658 15.083V9.99707H13.0996V11.2583H13.0278C12.833 10.6123 12.1665
          9.85352 10.8745 9.85352C9.05957 9.85352 7.98291 11.1763 7.98291 13.145V14.2627C7.98291 16.3237 9.06982 17.6362 10.8335 17.6362C11.9819 17.6362
          12.9355 17.0107 13.1714 16.1597H13.2739C13.4893 17.021 14.5044 17.6362 15.7964 17.6362ZM9.99268 13.9756V13.4014C9.99268 12.0376 10.6079 11.4224
          11.4487 11.4224C12.2485 11.4224 12.9253 11.9761 12.9253 13.2476V14.0884C12.9253 15.5034 12.2896 15.9854 11.4692 15.9854C10.5874 15.9854 9.99268
          15.4316 9.99268 13.9756Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    addingComment: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M7 21.0762H16C17.8369 21.0762 18.8477 20.0566 18.8477 18.2109V5.72168C18.8477 3.87598 17.8369 2.85645 16 2.85645H7C5.16309
          2.85645 4.14355 3.87598 4.14355 5.72168V18.2109C4.14355 20.0566 5.16309 21.0762 7 21.0762ZM7.24609 18.9844C6.56055 18.9844 6.24414
          18.6328 6.24414 17.9824V5.94141C6.24414 5.29102 6.56055 4.94824 7.24609 4.94824H15.7451C16.4307 4.94824 16.7559 5.29102 16.7559
          5.94141V17.9824C16.7559 18.6328 16.4307 18.9844 15.7539 18.9844H7.24609ZM13.4512 11.2764C14.3652 11.2764 15.0332 10.6436 15.0332
          9.71191C15.0332 8.82422 14.4619 8.22656 13.6709 8.22656C13.2666 8.22656 12.9414 8.3584 12.7129 8.63965H12.5459C12.6074 8.38477 12.7744
          8.12988 13.0293 7.91895C13.2666 7.70801 13.5654 7.56738 13.917 7.49707C14.1719 7.44434 14.2598 7.34766 14.2598 7.16309C14.2598 7.00488
          14.1367 6.88184 13.9258 6.88184C13.4072 6.88184 12.792 7.19824 12.3701 7.6377C11.9307 8.09473 11.7285 8.70117 11.7285 9.34277C11.7285 10.5645
          12.4844 11.2764 13.4512 11.2764ZM9.54883 11.2852C10.4629 11.2852 11.1309 10.6523 11.1309 9.7207C11.1309 8.82422 10.5596 8.23535 9.76855
          8.23535C9.36426 8.23535 9.03906 8.36719 8.81055 8.63965H8.64355C8.70508 8.39355 8.86328 8.12988 9.10938 7.92773C9.34668 7.7168 9.6543
          7.55859 10.0146 7.50586C10.2432 7.46191 10.3574 7.36523 10.3574 7.17188C10.3574 7.00488 10.2344 6.88184 10.0234 6.88184C9.51367 6.88184 8.90723
          7.18066 8.46777 7.6377C8.02832 8.09473 7.82617 8.70117 7.82617 9.34277C7.82617 10.5645 8.58203 11.2852 9.54883 11.2852ZM8.57324 14.0977H14.3037C14.6201
          14.0977 14.8574 13.8516 14.8574 13.5439C14.8574 13.2451 14.6201 13.0078 14.3037 13.0078H8.57324C8.24805 13.0078 8.01074 13.2451 8.01074 13.5439C8.01074
          13.8516 8.24805 14.0977 8.57324 14.0977ZM8.57324 16.708H11.4473C11.7637 16.708 12.001 16.4707 12.001 16.1719C12.001 15.8555 11.7637 15.6094 11.4473
          15.6094H8.57324C8.24805 15.6094 8.01074 15.8555 8.01074 16.1719C8.01074 16.4707 8.24805 16.708 8.57324 16.708Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    resetingSecurityCode: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M7.36914 20.1885H15.6309C16.8174 20.1885 17.3887 19.6084 17.3887 18.3164V11.9355C17.3887 10.7842 16.9141 10.1865 15.9385
          10.0898V8.10352C15.9385 4.91309 13.7588 3.36621 11.5 3.36621C9.24121 3.36621 7.06152 4.91309 7.06152 8.10352V10.1074C6.13867 10.2305
          5.61133 10.8193 5.61133 11.9355V18.3164C5.61133 19.6084 6.19141 20.1885 7.36914 20.1885ZM9.02148 7.98047C9.02148 6.24902 10.1113 5.25586
          11.5 5.25586C12.8887 5.25586 13.9785 6.24902 13.9785 7.98047V10.0811H9.02148V7.98047Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>

    ),
    recoveryEntries: (
      <svg width="26" height="25" viewBox="-1 -1 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path fillRule="evenodd" clipRule="evenodd" d="M13.1822 3.51556L17.4756 7.80889C17.8133 8.14667 18 8.6 18 9.07111V14.225C15.8476 14.7962 14.238 16.7003 14.1085 19H3.77778C2.8 19 2 18.2 2 17.2222V4.77778C2 3.8 2.8 3 3.77778 3H11.9289C12.4 3 12.8533 3.18667 13.1822 3.51556ZM11.7778 6.55556H5.55556V8.33333H11.7778V6.55556ZM5.55556 15.4444H14.4444V13.6667H5.55556V15.4444ZM5.55556 11.8889H14.4444V10.1111H5.55556V11.8889Z" fill="white" />
        </g>
        <path
          d="M19.5054 23.6528C21.8345 23.6528 23.7637 21.7192 23.7637 19.3901C23.7637 17.061 21.8301 15.1318 19.501 15.1318C17.1719
          15.1318 15.2427 17.061 15.2427 19.3901C15.2427 21.7192 17.1763 23.6528 19.5054 23.6528ZM19.0264 21.7148C17.9453 21.7148
          17.2642 20.9282 17.2642 19.8735V19.2495C17.2642 19.0386 17.3872 18.8892 17.5981 18.8892C17.8135 18.8892 17.9321 19.0386
          17.9321 19.2407V19.8735C17.9321 20.5459 18.3672 21.0293 19.0615 21.0293C19.7471 21.0293 20.125 20.5283 20.125 19.8779V18.5332L20.147
          18.0146L19.9492 18.2783L19.4263 18.8364C19.3647 18.9023 19.2725 18.9375 19.189 18.9375C19 18.9375 18.8506 18.7881 18.8506 18.6079C18.8506
          18.5156 18.8857 18.4365 18.9473 18.375L20.1777 17.1665C20.2524 17.0918 20.3447 17.0479 20.4502 17.0479C20.5601 17.0479 20.6523 17.0918
          20.7314 17.1665L21.9487 18.3706C22.0146 18.4321 22.0498 18.5068 22.0498 18.6035C22.0498 18.7881 21.9004 18.9375 21.7114 18.9375C21.6191
          18.9375 21.5312 18.8936 21.4741 18.832L21.0303 18.3662L20.7578 18.0146L20.771 18.5332V19.8779C20.771 20.9326 20.1118 21.7148 19.0264 21.7148Z"
          fill="white"
        />
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    acceptingIncomingContact: (
      <svg width="24" height="24" viewBox="2 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M12.4141 11.918C10.6123 11.918 9.07422 10.3096 9.07422 8.23535C9.07422 6.1875 10.6211 4.64941 12.4141 4.64941C14.1982 4.64941 15.7451
          6.22266 15.7451 8.25293C15.7451 10.3096 14.207 11.918 12.4141 11.918ZM12.4141 13.4033C13.8115 13.4033 15.0332 13.7285 16.0439 14.2207C14.8486
          15.2139 14.084 16.6992 14.084 18.3691C14.084 18.7998 14.1279 19.2217 14.2422 19.626H7.20215C5.875 19.626 5.40918 19.2393 5.40918 18.501C5.40918
          16.3564 8.10742 13.4033 12.4141 13.4033ZM19.4805 22.6143C17.1777 22.6143 15.2354 20.6895 15.2354 18.3691C15.2354 16.04 17.1602 14.124 19.4805
          14.124C21.8096 14.124 23.7256 16.04 23.7256 18.3691C23.7256 20.707 21.8096 22.6143 19.4805 22.6143ZM19.4805 21.1289C19.7793 21.1289 20.043 20.918
          20.043 20.6016V18.8965H21.6162C21.9062 18.8965 22.1523 18.6504 22.1523 18.3691C22.1523 18.0791 21.9062 17.833 21.6162 17.833H20.043V16.1367C20.043
          15.8115 19.7793 15.6094 19.4805 15.6094C19.1816 15.6094 18.918 15.8115 18.918 16.1367V17.833H17.3447C17.0547 17.833 16.8174 18.0791 16.8174 18.3691C16.8174
          18.6504 17.0547 18.8965 17.3447 18.8965H18.918V20.6016C18.918 20.918 19.1816 21.1289 19.4805 21.1289Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    acceptingOutgoingContact: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.3877 12.1553C9.40137 12.1553 7.77539 10.3887 7.77539 8.23535C7.77539 6.09961 9.39258 4.4209 11.3877 4.4209C13.374 4.4209 15 6.12598 14.9912
          8.25293C14.9912 10.3975 13.374 12.1553 11.3877 12.1553ZM11.3877 10.8369C12.583 10.8369 13.5938 9.69434 13.5938 8.25293C13.6025 6.8291 12.5918 5.73926
          11.3877 5.73926C10.1748 5.73926 9.18164 6.81152 9.18164 8.23535C9.18164 9.67676 10.1836 10.8369 11.3877 10.8369ZM11.3877 13.166C12.8818 13.166 14.1738
          13.5088 15.2373 14.0625C14.8682 14.3525 14.543 14.6865 14.2617 15.0732C13.4619 14.7129 12.4951 14.4844 11.3877 14.4844C7.62598 14.4844 5.53418 16.9805
          5.53418 18.3164C5.53418 18.4834 5.62207 18.5449 5.83301 18.5449H13.084C13.1016 19.002 13.1631 19.4326 13.3037 19.8633H6.18457C4.77832 19.8633 4.11035
          19.4326 4.11035 18.501C4.11035 16.2861 6.89648 13.166 11.3877 13.166ZM18.4805 22.6143C16.1777 22.6143 14.2354 20.6895 14.2354 18.3691C14.2354 16.04
          16.1602 14.124 18.4805 14.124C20.8096 14.124 22.7256 16.04 22.7256 18.3691C22.7256 20.707 20.8096 22.6143 18.4805 22.6143ZM18.4805 21.1289C18.7793
          21.1289 19.043 20.918 19.043 20.6016V18.8965H20.6162C20.9062 18.8965 21.1523 18.6504 21.1523 18.3691C21.1523 18.0791 20.9062 17.833 20.6162
          17.833H19.043V16.1367C19.043 15.8115 18.7793 15.6094 18.4805 15.6094C18.1816 15.6094 17.918 15.8115 17.918 16.1367V17.833H16.3447C16.0547 17.833
          15.8174 18.0791 15.8174 18.3691C15.8174 18.6504 16.0547 18.8965 16.3447 18.8965H17.918V20.6016C17.918 20.918 18.1816 21.1289 18.4805 21.1289Z"
          fill="white"
        />
      </svg>
    ),
    unblockingBlockedContact: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.4141 11.918C8.6123 11.918 7.07422 10.3096 7.07422 8.23535C7.07422 6.1875 8.62109 4.64941 10.4141
          4.64941C12.1982 4.64941 13.7451 6.22266 13.7451 8.25293C13.7451 10.3096 12.207 11.918 10.4141 11.918ZM10.4141
          13.4033C11.8115 13.4033 13.0332 13.7285 14.0439 14.2207C12.8486 15.2139 12.084 16.6992 12.084 18.3691C12.084 18.7998
          12.1279 19.2217 12.2422 19.626H5.20215C3.875 19.626 3.40918 19.2393 3.40918 18.501C3.40918 16.3564 6.10742 13.4033 10.4141 13.4033Z"
          fill="white"
        />
        <path
          d="M17.5054 22.6528C19.8345 22.6528 21.7637 20.7192 21.7637 18.3901C21.7637 16.061 19.8301 14.1318 17.501
          14.1318C15.1719 14.1318 13.2427 16.061 13.2427 18.3901C13.2427 20.7192 15.1763 22.6528 17.5054 22.6528ZM17.0264
          20.7148C15.9453 20.7148 15.2642 19.9282 15.2642 18.8735V18.2495C15.2642 18.0386 15.3872 17.8892 15.5981 17.8892C15.8135
          17.8892 15.9321 18.0386 15.9321 18.2407V18.8735C15.9321 19.5459 16.3672 20.0293 17.0615 20.0293C17.7471 20.0293 18.125 19.5283 18.125
          18.8779V17.5332L18.147 17.0146L17.9492 17.2783L17.4263 17.8364C17.3647 17.9023 17.2725 17.9375 17.189 17.9375C17 17.9375 16.8506 17.7881
          16.8506 17.6079C16.8506 17.5156 16.8857 17.4365 16.9473 17.375L18.1777 16.1665C18.2524 16.0918 18.3447 16.0479 18.4502 16.0479C18.5601
          16.0479 18.6523 16.0918 18.7314 16.1665L19.9487 17.3706C20.0146 17.4321 20.0498 17.5068 20.0498 17.6035C20.0498 17.7881 19.9004 17.9375 19.7114
          17.9375C19.6191 17.9375 19.5312 17.8936 19.4741 17.832L19.0303 17.3662L18.7578 17.0146L18.771 17.5332V18.8779C18.771 19.9326 18.1118 20.7148 17.0264 20.7148Z"
          fill="white"
        />
      </svg>
    ),
    unblockedContact: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.4141 11.918C8.6123 11.918 7.07422 10.3096 7.07422 8.23535C7.07422 6.1875 8.62109 4.64941 10.4141
        4.64941C12.1982 4.64941 13.7451 6.22266 13.7451 8.25293C13.7451 10.3096 12.207 11.918 10.4141 11.918ZM10.4141
        13.4033C11.8115 13.4033 13.0332 13.7285 14.0439 14.2207C12.8486 15.2139 12.084 16.6992 12.084 18.3691C12.084 18.7998
        12.1279 19.2217 12.2422 19.626H5.20215C3.875 19.626 3.40918 19.2393 3.40918 18.501C3.40918 16.3564 6.10742 13.4033 10.4141 13.4033Z"
          fill="white"
        />
        <path
          d="M17.5054 22.6528C19.8345 22.6528 21.7637 20.7192 21.7637 18.3901C21.7637 16.061 19.8301 14.1318 17.501
        14.1318C15.1719 14.1318 13.2427 16.061 13.2427 18.3901C13.2427 20.7192 15.1763 22.6528 17.5054 22.6528ZM17.0264
        20.7148C15.9453 20.7148 15.2642 19.9282 15.2642 18.8735V18.2495C15.2642 18.0386 15.3872 17.8892 15.5981 17.8892C15.8135
        17.8892 15.9321 18.0386 15.9321 18.2407V18.8735C15.9321 19.5459 16.3672 20.0293 17.0615 20.0293C17.7471 20.0293 18.125 19.5283 18.125
        18.8779V17.5332L18.147 17.0146L17.9492 17.2783L17.4263 17.8364C17.3647 17.9023 17.2725 17.9375 17.189 17.9375C17 17.9375 16.8506 17.7881
        16.8506 17.6079C16.8506 17.5156 16.8857 17.4365 16.9473 17.375L18.1777 16.1665C18.2524 16.0918 18.3447 16.0479 18.4502 16.0479C18.5601
        16.0479 18.6523 16.0918 18.7314 16.1665L19.9487 17.3706C20.0146 17.4321 20.0498 17.5068 20.0498 17.6035C20.0498 17.7881 19.9004 17.9375 19.7114
        17.9375C19.6191 17.9375 19.5312 17.8936 19.4741 17.832L19.0303 17.3662L18.7578 17.0146L18.771 17.5332V18.8779C18.771 19.9326 18.1118 20.7148 17.0264 20.7148Z"
          fill="white"
        />
      </svg>
    ),
    unblockingFromTheBlockedContact: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M11.3877 12.1553C9.40137 12.1553 7.77539 10.3887 7.77539 8.23535C7.77539 6.09961 9.39258
          4.4209 11.3877 4.4209C13.374 4.4209 15 6.12598 14.9912 8.25293C14.9912 10.3975 13.374 12.1553 11.3877 12.1553ZM11.3877
          10.8369C12.583 10.8369 13.5938 9.69434 13.5938 8.25293C13.6025 6.8291 12.5918 5.73926 11.3877 5.73926C10.1748 5.73926
          9.18164 6.81152 9.18164 8.23535C9.18164 9.67676 10.1836 10.8369 11.3877 10.8369ZM11.3877 13.166C12.8818 13.166 14.1738 13.5088
          15.2373 14.0625C14.8682 14.3525 14.543 14.6865 14.2617 15.0732C13.4619 14.7129 12.4951 14.4844 11.3877 14.4844C7.62598 14.4844 5.53418 16.9805
          5.53418 18.3164C5.53418 18.4834 5.62207 18.5449 5.83301 18.5449H13.084C13.1016 19.002 13.1631 19.4326 13.3037 19.8633H6.18457C4.77832 19.8633
          4.11035 19.4326 4.11035 18.501C4.11035 16.2861 6.89648 13.166 11.3877 13.166Z"
            fill="white"
          />
          <path
            d="M18.5054 22.6528C20.8345 22.6528 22.7637 20.7192 22.7637 18.3901C22.7637 16.061 20.8301
          14.1318 18.501 14.1318C16.1719 14.1318 14.2427 16.061 14.2427 18.3901C14.2427 20.7192 16.1763 22.6528 18.5054
          22.6528ZM18.0264 20.7148C16.9453 20.7148 16.2642 19.9282 16.2642 18.8735V18.2495C16.2642 18.0386 16.3872 17.8892 16.5981
          17.8892C16.8135 17.8892 16.9321 18.0386 16.9321 18.2407V18.8735C16.9321 19.5459 17.3672 20.0293 18.0615 20.0293C18.7471 20.0293
          19.125 19.5283 19.125 18.8779V17.5332L19.147 17.0146L18.9492 17.2783L18.4263 17.8364C18.3647 17.9023 18.2725 17.9375 18.189 17.9375C18
          17.9375 17.8506 17.7881 17.8506 17.6079C17.8506 17.5156 17.8857 17.4365 17.9473 17.375L19.1777 16.1665C19.2524 16.0918 19.3447 16.0479 19.4502
          16.0479C19.5601 16.0479 19.6523 16.0918 19.7314 16.1665L20.9487 17.3706C21.0146 17.4321 21.0498 17.5068 21.0498 17.6035C21.0498 17.7881 20.9004
          17.9375 20.7114 17.9375C20.6191 17.9375 20.5312 17.8936 20.4741 17.832L20.0303 17.3662L19.7578 17.0146L19.771 17.5332V18.8779C19.771 19.9326
          19.1118 20.7148 18.0264 20.7148Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="24"
              height="24"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    closingSession: (
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.352 7.99053C13.352 7.58343 13.6876 7.2426 14.0793 7.2426H16.6713L17.986 7.29941L17.352
        6.71243L15.972 5.38698C15.8228 5.25444 15.7483 5.06509 15.7483 4.88521C15.7483 4.49704 16.0186 4.19408
        16.4009 4.19408C16.5967 4.19408 16.7459 4.27929 16.8765 4.4213L19.7483 7.43195C19.9347 7.63077 20 7.80118 20 7.99053C20
        8.18935 19.9347 8.35976 19.7483 8.54911L16.8765 11.5692C16.7459 11.7112 16.5967 11.7965 16.4009 11.7965C16.0186 11.7965
        15.7483 11.503 15.7483 11.1148C15.7483 10.9349 15.8228 10.7266 15.972 10.5941L17.352 9.27811L17.986 8.69112L16.6713 8.74793H14.0793C13.6876
        8.74793 13.352 8.4071 13.352 7.99053ZM0 13.1408V2.85917C0 0.956213 0.932401 0 2.77855 0H11.338C13.1841 0 14.1165 0.956213 14.1165
        2.85917V7.02485H7.66434C7.15152 7.02485 6.72261 7.46982 6.72261 7.99053C6.72261 8.52071 7.15152 8.95621 7.66434 8.95621H14.1165V13.1408C14.1165
        15.0343 13.1841 16 11.338 16H2.77855C0.932401 16 0 15.0343 0 13.1408Z"
          fill="white"
        />
      </svg>
    ),
    addingEntries: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M19.4805 23.6143C17.1777 23.6143 15.2354 21.6895 15.2354 19.3691C15.2354 17.04 17.1602 15.124
          19.4805 15.124C21.8096 15.124 23.7256 17.04 23.7256 19.3691C23.7256 21.707 21.8096 23.6143 19.4805
          23.6143ZM19.4805 22.1289C19.7793 22.1289 20.043 21.918 20.043 21.6016V19.8965H21.6162C21.9062 19.8965 22.1523 19.6504 22.1523
          19.3691C22.1523 19.0791 21.9062 18.833 21.6162 18.833H20.043V17.1367C20.043 16.8115 19.7793 16.6094 19.4805 16.6094C19.1816
          16.6094 18.918 16.8115 18.918 17.1367V18.833H17.3447C17.0547 18.833 16.8174 19.0791 16.8174 19.3691C16.8174 19.6504 17.0547 19.8965 17.3447
          19.8965H18.918V21.6016C18.918 21.918 19.1816 22.1289 19.4805 22.1289Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1822 3.51556L17.4756 7.80889C17.8133 8.14667 18 8.6
          18 9.07111V14.225C15.8476 14.7962 14.238 16.7003 14.1085 19H3.77778C2.8 19 2 18.2 2 17.2222V4.77778C2
          3.8 2.8 3 3.77778 3H11.9289C12.4 3 12.8533 3.18667 13.1822 3.51556ZM11.7778 6.55556H5.55556V8.33333H11.7778V6.55556ZM5.55556
          15.4444H14.4444V13.6667H5.55556V15.4444ZM5.55556 11.8889H14.4444V10.1111H5.55556V11.8889Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    renewingSubscription: (
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.5 18.2686C14.3076 18.2686 18.2627 14.3135 18.2627 9.50586C18.2627 4.69824 14.3076 0.743164 9.5
        0.743164C4.69238 0.743164 0.737305 4.69824 0.737305 9.50586C0.737305 14.3135 4.69238 18.2686 9.5 18.2686ZM6.39746
        13.9355C6.18652 13.7773 6.14258 13.5225 6.26562 13.1709L7.18848 10.4199L4.82422 8.72363C4.5166 8.50391 4.40234 8.27539 4.49023
        8.0293C4.56055 7.79199 4.79785 7.66895 5.17578 7.66895L8.06738 7.69531L8.95508 4.91797C9.06934 4.56641 9.24512 4.38184 9.5 4.38184C9.75488
        4.38184 9.93066 4.56641 10.0449 4.91797L10.9326 7.69531L13.833 7.66895C14.2109 7.66895 14.4395 7.79199 14.5186 8.0293C14.6064 8.2666
        14.4834 8.50391 14.167 8.72363L11.8115 10.4111L12.7344 13.1709C12.8486 13.5225 12.8135 13.7773 12.6113 13.9355C12.4092 14.085 12.1631 14.041 11.8467
        13.8125L9.5 12.0986L7.15332 13.8125C6.83691 14.041 6.59082 14.0938 6.39746 13.9355Z"
          fill="white"
        />
      </svg>
    ),
    addingStorage: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.4204 22.0361C17.1177 22.0361 15.1753 20.1113 15.1753 17.791C15.1753 15.4619 17.1001 13.5459 19.4204
        13.5459C21.7495 13.5459 23.6655 15.4619 23.6655 17.791C23.6655 20.1289 21.7495 22.0361 19.4204 22.0361ZM19.4204
        20.5508C19.7192 20.5508 19.9829 20.3398 19.9829 20.0234V18.3184H21.5562C21.8462 18.3184 22.0923 18.0723 22.0923 17.791C22.0923
        17.501 21.8462 17.2549 21.5562 17.2549H19.9829V15.5586C19.9829 15.2334 19.7192 15.0312 19.4204 15.0312C19.1216 15.0312 18.8579 15.2334 18.8579
        15.5586V17.2549H17.2847C16.9946 17.2549 16.7573 17.501 16.7573 17.791C16.7573 18.0723 16.9946 18.3184 17.2847 18.3184H18.8579V20.0234C18.8579 20.3398
        19.1216 20.5508 19.4204 20.5508Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1171 16.8203H5.15234C2.98584 16.8203 1.5 15.4673 1.5 13.4917C1.5
        11.9312 2.49609 10.6445 4.02344 10.2129C4.26416 7.28271 6.71289 5 9.62646 5C11.8179 5 13.6689 6.16211 14.6733
        8.1875C17.3408 8.17093 19.3634 10.0145 19.3716 12.4723C19.3444 12.4719 19.3173 12.4717 19.29 12.4717C16.6979 12.4717 14.5445
        14.3503 14.1171 16.8203Z"
          fill="white"
        />
      </svg>
    ),
  },
  session: {
    computer: (
      <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.2" x="2.47754" y="1.271" width="16.9714" height="11.3143" fill="#007BFF" />
        <path
          d="M3.27927 13.384H18.6479C19.3626 13.384 19.9446 12.8026 19.9446 12.0873V2.24198C19.9446 1.52665 19.3626 0.945312 18.6479
          0.945312H3.27927C2.5646 0.945312 1.98193 1.52665 1.98193 2.24198V12.0873C1.98193 12.8026 2.56393 13.384 3.27927 13.384ZM3.1346
          2.24198C3.1346 2.16265 3.1986 2.09731 3.2786 2.09731H18.6473C18.7273 2.09731 18.7919 2.16265 18.7919 2.24198V12.0873C18.7919 12.1666
          18.7266 12.232 18.6473 12.232H3.27927C3.19927 12.232 3.13527 12.1666 3.13527 12.0873V2.24198H3.1346Z"
          fill="#007BFF"
        />
        <path
          d="M21.0239 14.0122C20.6906 14.0122 17.5786 14.0122 13.8986 14.0122L13.0793 14.6122H10.9633H8.84727L8.02793 14.0122C4.34727
          14.0122 1.23593 14.0122 0.901935 14.0122C0.0599347 14.0122 0.541268 16.0542 2.34193 16.0542C4.1426 16.0542 17.7833 16.0542 19.5839
          16.0542C21.3846 16.0535 21.8659 14.0122 21.0239 14.0122Z"
          fill="#007BFF"
        />
      </svg>
    ),
    phone: (
      <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.2" x="0.690674" y="1.58936" width="11.5997" height="17.7862" rx="2" fill="#007BFF" />
        <path
          d="M12.381 18.036V2.93224C12.381 1.84477 11.505 0.96875 10.4176 0.96875H2.56359C1.47612 0.96875 0.600098
          1.84477 0.600098 2.93224V18.036C0.600098 19.1235 1.47612 19.9995 2.56359 19.9995H10.4176C11.505 19.9995 12.381
          19.1235 12.381 18.036ZM1.50632 18.036V2.93224C1.50632 2.34319 1.97454 1.87498 2.56359 1.87498H3.77189V2.32809C3.77189
          2.58485 3.96824 2.7812 4.22501 2.7812H8.75614C9.01291 2.7812 9.20926 2.58485 9.20926 2.32809V1.87498H10.4176C11.0066
          1.87498 11.4748 2.34319 11.4748 2.93224V18.036C11.4748 18.6251 11.0066 19.0933 10.4176 19.0933H2.56359C1.97454 19.0933
          1.50632 18.6251 1.50632 18.036Z"
          fill="#007BFF"
        />
      </svg>

    ),
  },
};

export default icons;

/* ОПИСАНИЕ typeActions
addingExtraEmail: 'Добавление дополнительного электронного адреса',
addingComment: 'Добавление комментария',
resetingSecurityCode: 'Сброс кода безопасности',
recoveryEntries: 'Восстановление удалённых записей',
acceptingIncomingContact: 'Принятие входящего контакта',
acceptingOutgoingContact: 'Принятие у исходящего контакта',
unblockingBlockedContact: 'Разблокирование заблокированного',
unblockingFromTheBlockedContact: 'Разблокирование у заблокировавшего',
closingSession: 'Закрытие сессии',
addingEntries: 'Добавление записей',
addingStorage: 'Добавление хранилища',
renewingPrimary: 'Продление Premium',
renewingBasic: 'Продление Basic', */
