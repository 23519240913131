/**
 * Created by Nikolay Ilin, Ilnur Yakupov.
 */

const defines = require('./defines');

let signaturePathDiv = '~';

let arrayOfCharacters = [
        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j',
        'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
        'u', 'v', 'w', 'x', 'y', 'z', '.', ',', '-'
    ]
    , arrLength = arrayOfCharacters.length
    , range1 = 10
    , offset1 = 29
    , offset2 = 5
    ;


function encodeHash ( data ) {
    let result = '', character, index;
    for ( let i = 0; i < data.length; i++ ) {
        character = data[ i ].toLowerCase();
        index = arrayOfCharacters.indexOf( character );

        if ( index > -1 ) {
            if ( index < range1 ) {
                result += arrayOfCharacters[ index + offset1 ];
            } else if ( index < arrLength - offset2 ) {
                result += arrayOfCharacters[ index + offset2 ];
            } else {
                result += arrayOfCharacters[ index + offset2 - arrLength ];
            }
        } else {
            // символа нет в массиве разрешенных символов
        }
    }
    return result;
}

function decodeHash ( hash ) {
    let result = '', character, index;
    for ( let i = 0; i < hash.length; i++ ) {
        character = hash[ i ];
        index = arrayOfCharacters.indexOf( character );

        if ( index > -1 ) {
            if ( index < range1 ) {
                result += arrayOfCharacters[ index + arrLength - offset2 ];
            } else if ( index >= offset1 ) {
                result += arrayOfCharacters[ index - offset1 ];
            } else {
                result += arrayOfCharacters[ index - offset2 ];
            }
        }
    }
    return result;
}




function signatureMake ( cmd, ts, geo=null, devID=null, token = null, debug = 0 ) {

    devID = devID || defines.guest.devID;
    token = token || defines.guest.devToken;
    geo   = geo   || '0,0,0';

    let salt = token.substring( 19, 23 )+devID.substring(9,17);

    if(debug)
        console.log( {cmd, ts, geo, devID, token } );

    let signature = salt.md5() + devID + ts + token + cmd;

    signature = signature.md5() + signaturePathDiv + encodeHash( geo );

    return signature;
}

function signatureCheck ( usrSignature, cmd, ts, devID=null, token = null  ) {

    let [signature, geoHahs] = usrSignature.split( signaturePathDiv );

    if( !signature || !geoHahs ){
        signatureError('Format mismatch', usrSignature );
    }

    let geo = decodeHash( geoHahs );

    let ctrlSignature = signatureMake( cmd, ts, geo, devID, token )

    if( ctrlSignature === usrSignature ){
         return geo;
     }
     else{
       signatureError('Not valid', {cmd, ts, geo, devID, token, ctrlSignature, usrSignature} );
     }


}


function signatureError( msg, params = "", code = 403 ) {
    let err = new Error('Signature Error: '+msg+' '+JSON.stringify( params ) );
    err.code = code;
    throw err;
}

const signatureLib = {
    decodeHash     : decodeHash,
    encodeHash     : encodeHash,
    make  : signatureMake,
    check : signatureCheck
};

export default signatureLib;
