import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import EmployeeCard from './EmployeeCard';

moment.locale('ru');

const EmployeesList = (props) => {
  const { title, employees, getUserCard, currentUserID, allows } = props;
  return (
    <div className="col-lg-6">
      <div className="department-title">{title}</div>
      {employees.map(({ ID, name, surname, email, position, department, office, phone, birthdayD, beginD, visible }) => (
        <EmployeeCard
          key={ID}
          ID={ID}
          name={name}
          surname={surname}
          email={email}
          position={position}
          department={department}
          office={office}
          phone={phone}
          birthdayD={moment(birthdayD).format('DD MMMM')}
          beginD={moment(beginD).format('DD MMMM YYYY')}
          visible={visible}
          getUserCard={getUserCard}
          currentUserID={currentUserID}
          allows={allows}
        />
        ))}
    </div>
  );
};

EmployeesList.propTypes = {
  title: PropTypes.string.isRequired,
  currentUserID: PropTypes.string.isRequired,
  allows: PropTypes.number.isRequired,
  getUserCard: PropTypes.func.isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EmployeesList;
