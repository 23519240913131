import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUserDocuments } from '../redux/reducers/documents';
import { updateUserRequest } from '../redux/actions/userActions';
import { getUser } from '../redux/reducers/user';
import { getDevice } from '../redux/reducers/device';
import { getFileUrl } from '../redux/api/documentsApi';
import { uploadDocument, removeDocument, updateDocument } from '../redux/actions/documentsActions';

import Employee from '../components/personal';

function mapStateToProps(state) {
  const currentUser = getUser(state);
  return {
    user: currentUser,
    userDocuments: getUserDocuments(state.documents, currentUser.ID),
    getFileUrl: ID => getFileUrl(getDevice(state).device, ID),
    isPendingEditDocument: state.app.isPending.editDocument,
    isPendingUpdateAboutMe: state.app.isPending.updateAboutMe,
    isPendingAddDocument: state.app.isPending.addDocument,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submit: bindActionCreators(updateUserRequest, dispatch),
    uploadDocument: bindActionCreators(uploadDocument, dispatch),
    removeDocument: bindActionCreators(removeDocument, dispatch),
    updateDocument: bindActionCreators(updateDocument, dispatch),
  };
}

const EmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Employee);

export default EmployeeContainer;
