import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ChangeListItem from './ChangeListItem';

import * as fromStyled from '../styles';
import Preloader from '../../../common/Preloader';

const ListWrapper = styled(fromStyled.ListWrapperForHistory)`
  height: 352px;
`;

const NoChangesTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #858585;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  span {
    margin-top: 117px;
  }
`;

export default function ChangeList({
  list,
}) {
  const renderChangeList = () => list.map((item) => <ChangeListItem key={item.ID} item={item} />);
  return (
    <ListWrapper>
      { list !== null ? (
        list.length !== 0 ? (
          renderChangeList()
        ) : (
          <NoChangesTitle>
            <span>
              Ничего не найдено
            </span>
          </NoChangesTitle>
        )) : <Preloader /> }
    </ListWrapper>
  );
}

ChangeList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()),
};

ChangeList.defaultProps = {
  list: null,
};
