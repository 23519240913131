import defines from '../../utils/defines';
import Entity from './entity';

const model = {
  key: 'ID',
  tbl: 'Devices',
  fields: {
    ID: { typeOf: 'guid', allow: 2, errLevel: 100 },
    devToken: { typeOf: 'string', allow: 2, errLevel: 100, between: [62, 100] },
    ownerID: { typeOf: 'guid', allow: 2, errLevel: 100 },
    ownerPhone: { typeOf: 'string', allow: 2, errLevel: 100, between: [4, 20] },
    devPlatform: { typeOf: 'string', allow: 1, errLevel: 100, values: ['iOS', 'Web', 'Ext', 'Srv', 'Nan'], default: 'Nan' },
    devOS: { typeOf: 'string', allow: 1, errLevel: 1, between: [0, 200], default: 'Nan' },
    devModel: { typeOf: 'string', allow: 1, errLevel: 1, between: [0, 100], default: 'Nan' },
    devName: { typeOf: 'string', allow: 1, errLevel: 1, between: [0, 200], default: 'Nan' },
    appName: { typeOf: 'string', allow: 1, errLevel: 100, between: [1, 50] },
    appVer: { typeOf: 'string', allow: 1, errLevel: 100, between: [1, 20] },
    appBuild: { typeOf: 'number', allow: 1, errLevel: 100 },
    timeZone: { typeOf: 'timeZone', allow: 1, errLevel: 1, default: 'UTC' },
    locale: { typeOf: 'locale', allow: 1, errLevel: 1, default: 'en_US' },
    geo: { typeOf: 'string', allow: 1, errLevel: 1, between: [0, 200], default: '0,0,0' },
    pushToken: { typeOf: 'string', allow: 1, errLevel: 0, between: [0, 250], default: 'Nan' },
    onlineDT: { typeOf: 'dt', allow: 2, errLevel: 0, default: new Date().toDtString() },
    status: { typeOf: 'number', allow: 2, errLevel: 0, default: 1 },
    ver: { typeOf: 'string', allow: 3, errLevel: 0, between: [0, 10], default: '1.0' },
    type: { typeOf: 'string', allow: 3, errLevel: 0, between: [0, 50], default: 'device' },
  },
};

class Device extends Entity {
  static model() {
    return model;
  }

  constructor(ID, resourses = {}, debug = 0) {
    super(resourses, debug);

    this._model = model;
    this.setID(ID);

    this._logger.dbg('Device created', model);
  }

  static create(fields, resources, debug = 0) {
    if (!fields) {
      resources.logger.error('Wrong Fields to Create DEVICE');
    }

    fields.ID = fields.ID || String.generateGUID();

    const entity = new Device(fields.ID,  resources, debug);

    fields = entity.validate(fields, false);

    entity.init(fields);

    return entity;
  }

  static byFields(fields, resourses, debug = 0) {
    const entity = new Device(fields.ID, resourses, debug);
    if (!entity) return undefined;
    entity.init(fields);
    return entity;
  }

  init(data) {
    data = super.init(data);
    this.isGuest = (this.ID === defines.guest.devID);
    this.info    = this.devPlatform + this.devModel;
    this.app     = `${this.appName} ${this.appVer} #${this.appBuild}`;
    try {
      this.onlineDT  = new Date(data.onlineDT);
    } catch (e) {
      this.onlineDT  = new Date();
    }
  }

  fields(allowLevel) {
    const fields = super.fields(allowLevel);
    try {
      fields.onlineDT = this.onlineDT.toDtString();
    } catch (e) {
      fields.onlineDT = new Date().toDtString();
    }
    return fields;
  }
}

export default Device;

