import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

import { adminID } from '../../../shared/config';

import EmployeeForm from '../../components/personal/EmployeeForm';
import AddDocumentForm from '../../components/personal/AddDocumentForm';
import DocumentItem from '../../components/personal/DocumentItem';
import gravatar from '../../utils/gravatar';
import helpers from '../../utils/viewHelpers';
import '../../styles/employee.css';

const Employee = (props) => {
  const { user, userDocuments, submit, isPendingEditDocument, isPendingUpdateAboutMe, isPendingAddDocument } = props;
  if (user.ID === adminID) {
    return <Redirect to={{ pathname: '/workstate' }} />;
  }
  const documents = [];
  if (userDocuments) {
    userDocuments.forEach((doc) => {
      if (doc.ID && doc.type) {
        documents.push(doc);
      }
    });
  }
  const documentsList = documents.map(doc => (
    <DocumentItem
      key={doc.ID}
      removeDocument={props.removeDocument}
      updateDocument={props.updateDocument}
      getFileUrl={props.getFileUrl}
      uploadDocument={props.uploadDocument}
      desc={doc.desc}
      type={doc.type}
      contentType={doc.contentType}
      ID={doc.ID}
      size={doc.size}
      imageUrl={props.getFileUrl(doc.ID)}
      isPending={doc.ID in isPendingEditDocument}
    />
  ));
  return (
    <div className="container personal">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-6 personal-info">
          <div className="section">
            <div className="section-header">Personal Information</div>
            <div className="section-content">
              <div className="row no-margin">
                <div className="col-4 col-sm-2 col-md-2 col-lg-3 col-xl-2 no-padding">
                  <img className="rounded-circle" src={gravatar(user.email, { size: 90 })} alt="" />
                </div>
                <div className="col-8 col-sm-6 col-lg-5 col-xl-6">
                  <div className="personal-info-position">{user.position}</div>
                  <div className="personal-info-status">{user.status}</div>
                  <div className="personal-info-date">{moment(user.beginD).format('DD MMMM YYYY')}</div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="row">
                    <div className="salary offset-4 offset-sm-0 col-8 col-sm-12">
                      <div className="salary-title">Оклад:</div>
                      <div className="salary-value">{`${helpers.formatMoney(user.salary)}  \u20bd`}</div>
                    </div>
                    <div className="salary offset-4 offset-sm-0 col-8 col-sm-12">
                      <div className="salary-title">Премия:</div>
                      <div className="salary-value">{`${helpers.formatMoney(user.award)}  \u20bd`}</div>
                    </div>
                  </div>
                </div>
              </div>
              <EmployeeForm onSubmit={submit} user={user} isPending={isPendingUpdateAboutMe} />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 personal-documents">
          <AddDocumentForm uploadDocument={props.uploadDocument} isPending={isPendingAddDocument} />
          <div className="section document">
            {documents.length > 0 && <div className="section-header">Документы</div>}
            {documentsList}
          </div>
        </div>
      </div>
    </div>
  );
};

Employee.propTypes = {
  submit: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  removeDocument: PropTypes.func.isRequired,
  updateDocument: PropTypes.func.isRequired,
  getFileUrl: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  userDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  isPendingEditDocument: PropTypes.objectOf(PropTypes.bool).isRequired,
  isPendingUpdateAboutMe: PropTypes.bool.isRequired,
  isPendingAddDocument: PropTypes.bool.isRequired,
};

export default Employee;
