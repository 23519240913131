import {
  GET_USER_WORKSTATE_SUCCESS,
  GET_ALL_WORKSTATE_SUCCESS,
  SWITCH_WORKSTATE_SUCCESS,
} from '../actions/workstatesActions';
import {
  TRANSIT_ISSUE_SUCCESS,
} from '../actions/employeesActions';
import { LOGOUT } from '../actions/deviceActions';

export default function workstatesReducer(state = {}, action = {}) {
  switch (action.type) {
    case TRANSIT_ISSUE_SUCCESS: {
      return { ...state, [action.data.ID]: action.data };
    }
    case GET_USER_WORKSTATE_SUCCESS: {
      return { ...state, [action.data.ID]: action.data };
    }
    case GET_ALL_WORKSTATE_SUCCESS: {
      return action.data;
    }
    case SWITCH_WORKSTATE_SUCCESS: {
      return { ...state, [action.data.ID]: action.data };
    }
    case LOGOUT: {
      return {};
    }
    default:
      return state;
  }
}
