import { guest } from '../../utils/defines';
import { SIGN_IN_DEVICE_SUCCESS, LOGOUT } from '../actions/deviceActions';
import { UPDATE_USER_SUCCESS, GET_USER_INFO_SUCCESS } from '../actions/userActions';
import BOUser from '../boModels/user';

const initialState = BOUser.byFields({
  ID: guest.ID,
  phone: guest.phone,
});

export function getUser(state) {
  const employee = state.employees.byId[state.user.ID];
  if (employee) {
    return Object.assign(state.user, employee);
  }
  return state.user;
}

export function getUserForm(state) {
  return state.form.user.values;
}

export function getPersonalInfoForm(state) {
  return state.form.personalInfo.values;
}

export function getNewUserForm(state) {
  return state.form.newUser.values;
}

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case SIGN_IN_DEVICE_SUCCESS: {
      return BOUser.byFields(action.user);
    }
    case UPDATE_USER_SUCCESS: {
      return BOUser.byFields(action.data);
    }
    case GET_USER_INFO_SUCCESS: {
      if (action.userID === 'self') {
        return BOUser.byFields(Object.assign({}, state.__initFields, action.data));
      }
      return state;
    }
    case LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
}
