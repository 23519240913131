import React from 'react';
import PropTypes from 'prop-types';
import AddHospital from './AddHospital';
import HospitalItem from './HospitalItem';
import ActiveHospital from './ActiveHospital';

class Hospitals extends React.PureComponent {
  render() {
    const { editable } = this.props;
    const activeHospitals = this.props.hospitals.activeHospitals.map(hospital => (
      <ActiveHospital
        key={`hospital#${hospital.id}`}
        startDate={hospital.startD}
        days={hospital.days}
        comment={hospital.comment}
        uploadHospital={this.props.uploadHospital}
        switchHospital={this.props.switchHospital}
        docID={this.props.hospitalDocID}
        ID={`${hospital.id}`}
        editable={editable}
      />
    ));

    const byYearList = Object.keys(this.props.hospitals.byYear).map((year) => {
      const byYearVacations = this.props.hospitals.byYear[year];
      let daysCount = 0;
      const hospitalList = byYearVacations.map((hospital) => {
        daysCount += hospital.days;
        return (
          <HospitalItem
            key={`hospital#${hospital.id}`}
            startDate={hospital.startD}
            endDate={hospital.endD || ''}
            days={hospital.days}
            comment={hospital.comment}
          />
        );
      });
      return (
        <div key={`hospitals#${year}`} className="section">
          <div className="section-header">
            <div className="badge badge-default">{daysCount}</div>
            {year} год
          </div>
          {hospitalList}
        </div>
      );
    });

    return (
      <div className="col-lg-6">
        <AddHospital
          updateHospital={this.props.updateHospital}
          userID={this.props.userID}
          isPending={this.props.isPending}
        />
        {activeHospitals.length > 0 && (
          <div className="section">
            <div className="section-header">Открытые больничные</div>
            {activeHospitals}
          </div>
        )}
        {byYearList}
      </div>
    );
  }
}

Hospitals.defaultProps = {
  userID: 'self',
};

Hospitals.propTypes = {
  updateHospital: PropTypes.func.isRequired,
  uploadHospital: PropTypes.func.isRequired,
  switchHospital: PropTypes.func.isRequired,
  hospitalDocID: PropTypes.string.isRequired,
  userID: PropTypes.string,
  hospitals: PropTypes.shape().isRequired,
  editable: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default Hospitals;
