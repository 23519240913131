import BORequest from '../boModels/request';
import { apiConfig, defaultlogger } from '../../../shared/config';

const HOSPITAL_LOCATION = 'hospital';
const VACATION_LOCATION = 'vacation';

function* updateHospital(device, hospital, userID = 'self') {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${HOSPITAL_LOCATION}/open`, {
      userID,
      startD: hospital.startD,
      days: hospital.days || 3,
      comment: hospital.comment,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* getHospitalList(device, userID = 'self', date = '', status = 'all') { // todo
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${HOSPITAL_LOCATION}/list`, {
      userID, status,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* removeHospital(device, id) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${HOSPITAL_LOCATION}/remove`, {
      id,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* switchHospital(device, id, docID, endD) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${HOSPITAL_LOCATION}/close`, {
      id,
      docID,
      endD,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* getHospitalStats(device, userID = 'self') {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${HOSPITAL_LOCATION}/stats`, {
      userID,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* updateVacation(device, vacation, userID = 'self') {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${VACATION_LOCATION}/open`, {
      userID,
      startD: vacation.startD,
      days: vacation.days,
      available: vacation.available,
      comment: vacation.comment,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* getVacationList(device, userID = 'self', date = '', status = 'all') {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${VACATION_LOCATION}/list`, {
      userID,
      status,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* getVacationStats(device, userID = 'self') {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${VACATION_LOCATION}/stats`, {
      userID,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* removeVacation(device, id) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${VACATION_LOCATION}/remove`, {
      id,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* switchVacation(device, id) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${VACATION_LOCATION}/accept`, {
      id,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

export {
  updateHospital,
  getHospitalList,
  updateVacation,
  getVacationList,
  getVacationStats,
  getHospitalStats,
  removeHospital,
  removeVacation,
  switchHospital,
  switchVacation,
};
