/* eslint-disable no-param-reassign */
import md5 from 'md5';

const guest = {
  userID: '33c661c8-8c72-4c5a-8bd5-8bdd8612807d',
  ID: '33c661c8-8c72-4c5a-8bd5-8bdd8612807d',
  phone: '12345678901',
  password: '33c661c8',
  locale: 'US',
  region: 'US',
  lang: 'en',
  tz: 'UTC',
  devToken: 'Z7C0iBxr3bwUcNvOh6b7RH92X4wVVjt6t8bit0odfQSyGaTdjeX6NA1hU5A5wxFH',
  devOS: 'Undef',
  devModel: 'Undef',
  devName: 'Undef',
  appName: 'Undef',
  appVer: '0.0.0',
  appBuild: 1,
  createDT: new Date().toISOString(),
  updateDT: new Date().toISOString(),
  syncDT: '1970-01-01 00:00:00',
};

const defines = {
  ID: '33c661c8-8c72-4c5a-8bd5-8bdd8612807d',
  phone: '12345678901',
  devID: '33c661c8-8c72-4c5a-8bd5-8bdd8612807d',
  devToken: 'Z7C0iBxr3bwUcNvOh6b7RH92X4wVVjt6t8bit0odfQSyGaTdjeX6NA1hU5A5wxFH',
};

const signaturePathDiv = '~';

const srvDelta = 0;

const arrayOfCharacters = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '.',
  ',',
  '-',
];

const arrLength = arrayOfCharacters.length;
const range1 = 10;
const offset1 = 29;
const offset2 = 5;

// function encodeHash(data) {
//   let result = '';
//   let character;
//   let index;
//   for (let i = 0; i < data.length; ++i) {
//     character = data[i].toLowerCase();
//     index = arrayOfCharacters.indexOf(character);

//     if (index > -1) {
//       if (index < range1) {
//         result += arrayOfCharacters[index + offset1];
//       } else if (index < arrLength - offset2) {
//         result += arrayOfCharacters[index + offset2];
//       } else {
//         result += arrayOfCharacters[index + offset2 - arrLength];
//       }
//     } else {
//       // символа нет в массиве разрешенных символов
//     }
//   }
//   return result;
// }

// export function signatureMake(cmd, ts, geo = '0,0,0', devID = null, token = null) {
//   if (typeof geo === 'object') {
//     // eslint-disable-next-line no-param-reassign
//     geo = `${geo.lat || '0'},${geo.lng || '0'},${geo.acc || '0'}`;
//   }

//   const salt = token.substring(19, 23) + devID.substring(9, 17);

//   let signature = md5(salt) + devID + ts + token + cmd;

//   signature = md5(signature) + signaturePathDiv + encodeHash(geo);

//   return signature;
// }

function encodeHash(data) {
  let result = ''; let character; let
    index;
  for (let i = 0; i < data.length; i++) {
    character = data[i].toLowerCase();
    index = arrayOfCharacters.indexOf(character);

    if (index > -1) {
      if (index < range1) {
        result += arrayOfCharacters[index + offset1];
      } else if (index < arrLength - offset2) {
        result += arrayOfCharacters[index + offset2];
      } else {
        result += arrayOfCharacters[index + offset2 - arrLength];
      }
    } else {
      // символа нет в массиве разрешенных символов
    }
  }
  return result;
}

function signatureMake(cmd, ts, geo = null, devID = null, token = null, debug = 0) {
  devID = devID || defines.guest.devID;
  token = token || defines.guest.devToken;
  geo   = geo   || '0,0,0';

  const salt = token.substring(19, 23) + devID.substring(9, 17);

  if (debug) console.log({ cmd, ts, geo, devID, token });

  let signature = md5(salt) + devID + ts + token + cmd;

  signature = md5(signature) + signaturePathDiv + encodeHash(geo);

  return signature;
}

function getSrvTS() {
  return srvDelta + (Date.now() / 1000);
}

let device = {
  ...guest,
};

const ls = localStorage.getItem('backoffice-react');
if (ls) {
  const lsStr = JSON.parse(ls).device.device;
  device = {
    ...lsStr,
  };
}

export function buildUrl(cmd, params = {}, baseURL) {
  let url = `${baseURL}/v1/${cmd}`;
  const srvTS = getSrvTS();
  const qsObject = {
    d: device.ID,
    t: srvTS,
    s: signatureMake(cmd, srvTS, device.geo, device.ID, device.devToken),
    // k: encodeURIComponent(`${srvTS}-${generateGUID()}`),
    ...params,
  };
  Object.keys(qsObject).forEach((param, index) => {
    const value = qsObject[param];
    url += `${index ? '&' : '?'}${param}=${value}`;
  });
  return url;
}
