import axios from 'axios';
import BORequest from '../boModels/request';
import { apiConfig, defaultlogger } from '../../../shared/config';

const ITERATION_LOCATION = 'iteration';

function* fetchIterationForAllUsers(device, id = '', date = '') {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ITERATION_LOCATION}/users`, {
      id,
      date,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* fetchAllIterations(device, type = 'all') {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ITERATION_LOCATION}/list`, {
      type,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* fetchIteration(device, id = '', date = '', userID = 'self') {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ITERATION_LOCATION}/info`, {
      id,
      date,
      userID,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* fetchIterationListByUser(device, userID = 'self') {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ITERATION_LOCATION}/states`, {
      userID,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* setQccIteration(device, id, date = '', userID = 'self', qcC = 1, qcNote = '') {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ITERATION_LOCATION}/qcC`, {
      id,
      date,
      userID,
      qcC,
      qcNote,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function getNextWorkDate(device, startD, endD = '', range = 0, callback) { // todo
  const request = new BORequest(apiConfig, device, defaultlogger);
  const url = request.requestUrl(`vacation/calc`, {
    startD, days: range,
  });
  axios({
    url,
    method: 'POST',
    data: '{}',
    headers: { 'content-type': 'application/json' },
  })
    .then((response) => {
      callback(response.data.data);
    })
    .catch((error) => {
      // callback(error);
    });
}

function* recalculateIteration(device, id) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ITERATION_LOCATION}/recalc`, {
      id,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

export {
  fetchIteration,
  fetchIterationListByUser,
  fetchAllIterations,
  fetchIterationForAllUsers,
  setQccIteration,
  getNextWorkDate,
  recalculateIteration,
};
