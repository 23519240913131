import React, { useState, useEffect, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as fromStyled from '../styles';

import ChangeList from './ChangeList';
import SelectForFilter from '../../../ui/Select/SelectForFilter';
import DateFilter from '../../../ui/DateFilter/DateFilter';

import Context from '../../../common/Context';

import { formattingDateToUTC } from '../../../../utils/formattingDate';

const options = {
  changeNames: {
    addingBonus: 'добавление бонусов вложения/записи',
    renewingSubscription: 'продление подписки',
    resetingSecurityCode: 'сброс кода безопасности',
    addingComment: 'комментарии',
    closingSession: 'завершение сессии',
    actionsWithContacts: 'действия с контактами пользователя',
    recoveryEntries: 'восстановление удаленных записей',
    addingExtraEmail: 'обновление альтернативного email',
  },
  supportsList: {
    'almaz.sh@soft-artel.com': 'Алмаз Шарипов',
    'ildar.n@soft-artel.com\n': 'Ильдар Низамов',
  },
};

const SET_HISTORY_CHANGES = 'SET_HISTORY_CHANGES';

const CHANGE_CHANGE_NAME_ON_FILTER = 'CHANGE_CHANGE_NAME_ON_FILTER';

const CHANGE_DATE_RANGE_ON_FILTER = 'CHANGE_DATE_RANGE_ON_FILTER';

const SET_FILTERED_HISTORY_CHANGES = 'SET_FILTERED_HISTORY_CHANGES';

// const SET_IS_FILTERED = 'SET_IS_FILTERED;'

const CHANGE_FILTER = 'CHANGE_FILTER';

const initialState = {
  isFiltered: false,
  historyChanges: null,
  filteredHistoryChanges: null,
  supportsList: {},
  filter: {
    dateRange: {
      from: undefined,
      to: undefined,
    },
    changeName: null,
    supportFullName: null,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case SET_HISTORY_CHANGES:
      return {
        ...state,
        historyChanges: action.data,
        supportsList: action.supportList,
      };
    case CHANGE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.filterKey]: action.filterValue,
        },
      };
    // case CHANGE_CHANGE_NAME_ON_FILTER:
    //   return {
    //     ...state,
    //     isFiltered: true,
    //     filter: {
    //       ...state.filter,
    //       changeName: action.value,
    //     },
    //   };
    case CHANGE_DATE_RANGE_ON_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          dateRange: {
            ...state.filter.dateRange,
            [action.toOrFrom]: action.value,
          },
        },
      };
    case SET_FILTERED_HISTORY_CHANGES:
      return {
        ...state,
        filteredHistoryChanges: action.data,
      };
    default:
      return state;
  }
}

export default function HistoryChanges({
  propsFromModal: { ID },
}) {
  const { getHistoryChanges } = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    historyChanges,
    filteredHistoryChanges,
    filter: {
      dateRange,
      changeName,
      supportFullName,
    },
    supportsList,
  } = state;

  useEffect(() => {
    getHistoryChanges(ID).then(({ data: { history } }) => {
      const supportList = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const key in history) {
        const spMail = history[key].supportMail;
        // if (typeof supportList[spMail] !== 'undefined') return;
        supportList[spMail] = history[key].supportName;
      }
      dispatch({ type: SET_HISTORY_CHANGES, data: history.reverse(), supportList });
    });
  }, []);

  const filteredWithChangeName = (list, CN) => list.filter(({ action }) => {
    switch (CN) {
      case 'addingBonus':
      case 'renewingSubscription':
      case 'addingComment':
      case 'closingSession':
      case 'recoveryEntries':
      case 'addingExtraEmail':
      case 'resetingSecurityCode': {
        return action === CN;
      }
      case 'actionsWithContacts': {
        return action === 'acceptingIncomingContact'
          || action === 'acceptingOutgoingContact'
          || action === 'unblockedContact'
          || action === 'unblockingBlockedContact'
          || action === 'unblockingFromTheBlockedContact';
      }
      default: {
        return false;
      }
    }
  });

  const filteredWithDR = (list, DRfrom, DRto) => {
    const from = formattingDateToUTC(DRfrom.setHours(0, 0, 0, 0));
    const to = formattingDateToUTC(DRto.setHours(23, 59, 59, 59));
    return list.filter(({ createDT }) => {
      const first = createDT >= from;
      const second = createDT <= to;
      return first && second;
    });
  };

  const filteredWithSupport = (list, support) => list
    .filter(({ supportMail }) => supportMail === support);

  const changeFilter = (filterKey, filterValue) => {
    dispatch({ type: CHANGE_FILTER, filterKey, filterValue });
  };

  const setFilteredHistoryChanges = (data) => {
    dispatch({ type: SET_FILTERED_HISTORY_CHANGES, data });
  };

  const onChangeChangeNameOnFilter = (value) => {
    changeFilter('changeName', value);
    // dispatch({ type: CHANGE_CHANGE_NAME_ON_FILTER, value });
  };

  const onChangeDateRangeOnFilter = (toOrFrom, value) => {
    dispatch({ type: CHANGE_DATE_RANGE_ON_FILTER, toOrFrom, value });
  };

  const onChangeSupportOnFilter = (value) => {
    changeFilter('supportFullName', value);
  };

  useEffect(() => {
    const prevHistory = historyChanges;
    const nextHistory = [];
    /* DR - dateRange
      CN - changeName
      S - supportFullName
     */
    let DR = null;
    let DR_CN = null;
    let DR_S = null;
    let DR_CN_S = null;
    let CN = null;
    let CN_S = null;
    let S = null;
    if (dateRange.from && dateRange.to) {
      // попадание сюда если указана дата
      DR = filteredWithDR(prevHistory, dateRange.from, dateRange.to);
      if (changeName) {
        DR_CN = filteredWithChangeName(DR, changeName);
        if (supportFullName) {
          DR_CN_S = filteredWithSupport(DR_CN, supportFullName);
          setFilteredHistoryChanges(DR_CN_S);
        } else {
          setFilteredHistoryChanges(DR_CN);
        }
      } else if (supportFullName) {
        DR_S = filteredWithSupport(DR, supportFullName);
        setFilteredHistoryChanges(DR_S);
      } else setFilteredHistoryChanges(DR);
    } else if (changeName) {
      CN = filteredWithChangeName(prevHistory, changeName);
      if (supportFullName) {
        CN_S = filteredWithSupport(CN, supportFullName);
        setFilteredHistoryChanges(CN_S);
      } else setFilteredHistoryChanges(CN);
    } else if (supportFullName) {
      S = filteredWithSupport(prevHistory, supportFullName);
      setFilteredHistoryChanges(S);
    } else setFilteredHistoryChanges(null);
  }, [dateRange, changeName, supportFullName]);
  return (
    <Wrapper>
      <FilterWrapper>
        <DateFilter
          isForFilter
          to={dateRange.to}
          from={dateRange.from}
          onChange={onChangeDateRangeOnFilter}
        />
        <section>
          <SelectForFilter
            options={options.changeNames}
            defaultValue={{ value: 'changeName', label: 'Изменение' }}
            selectedOption={changeName}
            onSelectOption={onChangeChangeNameOnFilter}
            width={160}
          />
        </section>
        <section>
          <SelectForFilter
            options={supportsList}
            defaultValue={{ value: 'support', label: 'Оператор' }}
            selectedOption={supportFullName}
            onSelectOption={onChangeSupportOnFilter}
            width={160}
          />
        </section>
        {/* )
        } */}
      </FilterWrapper>
      <ChangeList
        list={filteredHistoryChanges !== null
          ? filteredHistoryChanges
          : historyChanges}
      />
    </Wrapper>
  );
}

const Wrapper = styled(fromStyled.WrapperForHistoryModal)`
  padding: 0 22px 13px 30px;
`;

const FilterWrapper = styled(fromStyled.WrapperForFilter)`
  width: 564px;
`;

HistoryChanges.propTypes = {
  propsFromModal: PropTypes.shape({
    ID: PropTypes.string,
  }).isRequired,
};
