import React from 'react';
import PropTypes from 'prop-types';
import Tappable   from 'react-tappable/lib/Tappable';
import Department from './Department';
import { adminID } from '../../../shared/config';

const WorkState = (props) => {
  const { other, developers, onSwitchWorkstate, workstates, isPending, logout, userID } = props;
  return (
    <div className="container workstate">
      {
        userID === adminID && <Tappable
          className="logout-area"
          pressDelay={1500}
          onPress={logout}
        />
      }
      <div className="row">
        <Department
          title={developers.title}
          activeCount={developers.active.length}
          count={developers.employees.length}
          position="left"
          employees={developers.employees}
          workstates={workstates}
          onSwitchWorkstate={onSwitchWorkstate}
          isPending={isPending}
        />
        <Department
          title={other.title}
          activeCount={other.active.length}
          count={other.employees.length}
          position="right"
          employees={other.employees}
          workstates={workstates}
          onSwitchWorkstate={onSwitchWorkstate}
          isPending={isPending}
        />
      </div>
    </div>
  );
};

WorkState.propTypes = {
  other: PropTypes.shape({
    title: PropTypes.string,
    active: PropTypes.array,
    employees: PropTypes.array,
  }).isRequired,
  developers: PropTypes.shape({
    title: PropTypes.string,
    active: PropTypes.array,
    employees: PropTypes.array,
  }).isRequired,
  onSwitchWorkstate: PropTypes.func.isRequired,
  workstates: PropTypes.shape().isRequired,
  userID: PropTypes.string.isRequired,
  isPending: PropTypes.objectOf(PropTypes.bool).isRequired,
  logout: PropTypes.func.isRequired,
};

export default WorkState;
