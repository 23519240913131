/* eslint-disable no-mixed-operators */
import React     from 'react';
import PropTypes from 'prop-types';
import { Link }  from 'react-router-dom';
import pure      from 'recompose/pure';
import gravatar  from '../../utils/gravatar';

const EmployeeItem = (props) => {
  const {
    email, name, surname, ID, position, stat, styles, hospitalStat,
  } = props;
  let vacationYears = [];
  if (stat && stat.years) {
    vacationYears = Object.keys(stat.years).map(year => (
      <div key={`vacation#${year}`} className="admin-employees-list-item-sprints-year d-flex flex-column">
        <div>{year}</div>
        <div className="badge badge-default">{stat.years[year]}</div>
      </div>
    ));
  }
  let hospitalYears = [];
  if (hospitalStat && hospitalStat.years) {
    hospitalYears = Object.keys(hospitalStat.years).map(year => (
      <div key={`hospital#${year}`} className="admin-employees-list-item-sprints-year d-flex flex-column">
        <div>{year}</div>
        <div className="badge badge-danger">{hospitalStat.years[year]}</div>
      </div>
    ));
  }
  return (
    <Link to={`/admin/vacations/${ID}`} className="admin-employees-list-item d-flex" style={styles || {}}>
      <div className="admin-employees-list-item-image">
        <img className="rounded-circle" src={gravatar(email, { size: 90 })} alt="icon" />
      </div>
      <div className="admin-employees-list-item-user">
        <div className="admin-employees-list-item-user__name">{name}</div>
        <div className="admin-employees-list-item-user__surname">{surname}</div>
        <div className="admin-employees-list-item-user__position">{position}</div>
      </div>
      <div className="admin-employees-list-item-sprints d-flex justify-content-center align-items-center">
        <div className="badge badge-success" style={{ padding: '10px 20px', borderRadius: '15px' }}>
          {stat && stat.vacationDays || 0}
        </div>
        {vacationYears}
      </div>
      <div className="admin-employees-list-item-sprints d-flex justify-content-center align-items-center">
        {hospitalYears}
      </div>
    </Link>
  );
};

EmployeeItem.defaultProps = {
  styles: {},
};

EmployeeItem.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  ID: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  stat: PropTypes.shape().isRequired,
  hospitalStat: PropTypes.shape().isRequired,
  styles: PropTypes.shape(),
};

export default pure(EmployeeItem);
