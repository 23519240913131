import React from 'react';
import PropTypes from 'prop-types';
import AttachmentPicker from '../AttachmentPicker';
import { Loader, Overlay } from '../Loader';
import { Thumbnail, AttachControl, AttachControlsWrapper } from './styled';

import { docTypes } from '../../../constants';

const getAttachmentDefaultImage = (fileContentType) => {
  switch (true) {
    case fileContentType.indexOf('pdf') !== -1: {
      return require('../../images/pdf.png');
    }
    case fileContentType.indexOf('audio') !== -1: {
      return require('../../images/sound.png');
    }
    case fileContentType.indexOf('video') !== -1: {
      return require('../../images/video.png');
    }
    case fileContentType.indexOf('zip') !== -1: {
      return require('../../images/zip.png');
    }
    case fileContentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case fileContentType.indexOf('excel') !== -1: {
      return require('../../images/excel.png');
    }
    case fileContentType ===
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case fileContentType === 'application/msword': {
      return require('../../images/doc.png');
    }
    default: {
      return require('../../images/default.png');
    }
  }
};

class DocumentItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      desc: this.props.desc,
      file: null,
      base64: '',
      edit: false,
    };
    this.descriptionChangeHandler = (ev) => {
      this.setState({ desc: ev.target.value });
    };
    this.toggle = (state) => {
      const self = this;
      this.setState({ edit: state });
      if (state) {
        setTimeout(() => {
          self.textarea.focus();
        }, 10);
      }
    };
    this.onRemoveAttach = () => {
      this.setState({ file: null, base64: '' });
    };
    this.onDocumentAttach = (file) => {
      this.setState({ file });
    };
    this.onSubmit = () => {
      const { desc, file } = this.state;
      const { ID, type } = this.props;
      if (file) {
        this.props.uploadDocument(file, type, desc, ID, false);
        this.setState({ file: null, base64: '' });
      } else {
        this.props.updateDocument({ ID, desc });
      }
    };
    this.getBase64 = (file) => {
      const self = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        self.setState({ base64: reader.result });
      };
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { props, state } = this;
    const update =
      nextProps.type !== props.type ||
      nextProps.size !== props.size ||
      nextState.edit !== state.edit ||
      nextState.base64 !== state.base64 ||
      nextState.file !== state.file ||
      nextState.desc !== state.desc ||
      nextProps.isPending !== props.isPending;
    return update;
  }

  download = ev => {
    ev.stopPropagation();
    window.open(this.props.imageUrl, '_blank');
  };

  render() {
    const { removeDocument, ID, type, imageUrl, isPending, contentType } = this.props;
    let base64 = '';
    if (this.state.file) {
      this.getBase64(this.state.file);
      base64 = this.state.base64;
    }
    return (
      <div className="section-content">
        <div className="row no-margin">
          <div className="col-4 col-sm-3 no-padding">
            <div className="document-type">{docTypes[type]}</div>
            <div className="document-photo-wrap">
              <Thumbnail src={contentType.indexOf('image') !== -1 ? imageUrl : getAttachmentDefaultImage(contentType)}>
                <AttachControlsWrapper>
                  <AttachControl title="Загрузить оригинал" data-action="export" onClick={this.download} />
                </AttachControlsWrapper>
              </Thumbnail>
            </div>
          </div>
          <div className="col-8 col-sm-9 d-flex flex-column justify-content-between document-panel">
            <div
              className="document-content"
              style={{ display: this.state.edit ? 'none' : 'block' }}
              onClick={() => {
                this.toggle(true);
              }}
            >
              {this.state.desc}
              <span className="overlay-icon">
                <i className="fa-edit" />
              </span>
            </div>
            <div className="flex-column" style={{ display: this.state.edit ? 'flex' : 'none' }}>
              <textarea
                className="form-control"
                rows="5"
                value={this.state.desc}
                onChange={this.descriptionChangeHandler}
                onBlur={() => {
                  this.toggle(false);
                }}
                ref={(textarea) => {
                  this.textarea = textarea;
                }}
              />
              <div className="ml-auto save-options">
                <button>
                  <i className="fa-check" />
                </button>
              </div>
            </div>
            <AttachmentPicker
              className="document-attachment-curtain"
              text="Перетащите сюда файлы для обновления"
              onDocumentAttach={this.onDocumentAttach}
            />
            {base64 !== '' && (
              <div className="attachment-preview-wrapper">
                <div className="attachment-preview-screencover" />
                <img className="attachment-preview" src={base64} alt="" />
                <div className="attachment-preview__remove" onClick={this.onRemoveAttach}>
                  <i className="fa-trash-o" />
                </div>
              </div>
            )}
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-success" onClick={this.onSubmit}>
                Сохранить
              </button>
            </div>
          </div>
        </div>
        <div
          className="document-delete"
          onClick={() => {
            removeDocument(ID);
          }}
        >
          x
        </div>
        {isPending && (
          <Overlay>
            <Loader show />
          </Overlay>
        )}
      </div>
    );
  }
}

DocumentItem.propTypes = {
  removeDocument: PropTypes.func.isRequired,
  updateDocument: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  desc: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  ID: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default DocumentItem;
