import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import icons from '../../../utils/icons';

const Wrapper = styled.div`
  width: 33px;
  height: 30px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ type }) => {
    switch (type) {
      case 'acceptingIncomingContact':
      case 'acceptingOutgoingContact':
      case 'unblockingBlockedContact':
      case 'unblockedContact':
      case 'unblockingFromTheBlockedContact': {
        return '#5E85EB';
      }
      case 'renewingBasic':
      case 'addingStorage':
      case 'addingEntries':
      case 'recoveryEntries': {
        return '#2EA8E5';
      }
      case 'renewingPremium': {
        return '#E4A500';
      }
      default: {
        return '#CED4DA';
      }
    }
  }};
`;

export default function TypeActions({
  type,
}) {
  return (
    <Wrapper type={type}>
      {icons.typeActions[type]}
    </Wrapper>
  );
}

TypeActions.propTypes = {
  type: PropTypes.string.isRequired,
};
