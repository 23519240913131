export const SET_QCC_ISSUE = 'SET_QCC_ISSUE';
export const SET_QCC_ISSUE_REQUEST = 'SET_QCC_ISSUE_REQUEST';
export const SET_QCC_ISSUE_SUCCESS = 'SET_QCC_ISSUE_SUCCESS';
export const SET_QCC_ISSUE_FAILURE = 'SET_QCC_ISSUE_FAILURE';

export function setQccIssues(issueKey, userID, iterId, coefficient, comment = '') {
  return {
    type: SET_QCC_ISSUE,
    issueKey,
    userID,
    iterId,
    coefficient,
    comment,
  };
}
export function setQccIssuesRequest() {
  return {
    type: SET_QCC_ISSUE_REQUEST,
  };
}
export function setQccIssuesSuccess() {
  return {
    type: SET_QCC_ISSUE_SUCCESS,
  };
}
export function setQccIssuesFailure(err) {
  return {
    type: SET_QCC_ISSUE_FAILURE,
    err,
  };
}

export const DELETE_WORKLOG = 'DELETE_WORKLOG';
export const DELETE_WORKLOG_REQUEST = 'DELETE_WORKLOG_REQUEST';
export const DELETE_WORKLOG_SUCCESS = 'DELETE_WORKLOG_SUCCESS';
export const DELETE_WORKLOG_FAILURE = 'DELETE_WORKLOG_FAILURE';

export function deleteWorklog(issueId, worklogId) {
  return {
    type: DELETE_WORKLOG,
    issueId,
    worklogId,
  };
}
export function deleteWorklogRequest() {
  return {
    type: DELETE_WORKLOG_REQUEST,
  };
}
export function deleteWorklogSuccess(data, worklogId) {
  return {
    type: DELETE_WORKLOG_SUCCESS,
    data,
    worklogId,
  };
}
export function deleteWorklogFailure(err, worklogId) {
  return {
    type: DELETE_WORKLOG_FAILURE,
    err,
    worklogId,
  };
}

export const GET_WAITING_ISSUES = 'GET_WAITING_ISSUES';
export const GET_WAITING_ISSUES_REQUEST = 'GET_WAITING_ISSUES_REQUEST';
export const GET_WAITING_ISSUES_SUCCESS = 'GET_WAITING_ISSUES_SUCCESS';
export const GET_WAITING_ISSUES_FAILURE = 'GET_WAITING_ISSUES_FAILURE';

export function getWaitingIssues() {
  return {
    type: GET_WAITING_ISSUES,
  };
}
export function getWaitingIssuesRequest() {
  return {
    type: GET_WAITING_ISSUES_REQUEST,
  };
}
export function getWaitingIssuesSuccess(data) {
  return {
    type: GET_WAITING_ISSUES_SUCCESS,
    data,
  };
}
export function getWaitingIssuesFailure(err) {
  return {
    type: GET_WAITING_ISSUES_FAILURE,
    err,
  };
}
