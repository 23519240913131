import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MessageListItem from './MessageListItem';

import * as fromStyled from '../styles';
import Preloader from '../../../common/Preloader';

const ListWrapper = styled(fromStyled.ListWrapperForHistory)`
  height: 350px;
`;

export default function MessageList({
  list,
}) {
  const renderMessageList = () => list.map((item) => (
    <MessageListItem
      DT={item.createDT}
      type={item.typeMessage}
      text={item.content}
      status={item.status?.toString()}
    />
  ));
  return (
    <ListWrapper>
      {list !== null ? renderMessageList() : <Preloader />}
    </ListWrapper>
  );
}

MessageList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()),
};

MessageList.defaultProps = {
  list: null,
};
