import React     from 'react';
import PropTypes from 'prop-types';

import EmployeesList from './EmployeesList';


const Team = (props) => {
  const { developers, other, getUserCard, currentUserID, allows } = props;
  return (
    <div className="container workstate">
      <div className="row">
        <EmployeesList
          title={developers.title}
          employees={developers.employees}
          getUserCard={getUserCard}
          currentUserID={currentUserID}
          allows={allows}
        />
        <EmployeesList
          title={other.title}
          employees={other.employees}
          getUserCard={getUserCard}
          currentUserID={currentUserID}
          allows={allows}
        />
      </div>
    </div>
  );
};

Team.propTypes = {
  developers: PropTypes.shape().isRequired,
  other: PropTypes.shape().isRequired,
  getUserCard: PropTypes.func.isRequired,
  currentUserID: PropTypes.string.isRequired,
  allows: PropTypes.number.isRequired,
};

export default Team;
