import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TypeMessage from '../../../ui/TypeMessage';
import StatusForHistoryMessages from '../../../ui/StatusForHistoryMessages';
import DateTime from '../../../ui/DateTime';

export default function MessageListItem({
  DT,
  type,
  text,
  status,
}) {
  return (
    <Wrapper status={status}>
      <div className="messageListItem">
        <div className="messageListItem__createDT">
          <DateTime DT={DT} />
        </div>
        <div className="messageListItem__content">
          <div className="messageListItem__content-type">
            <TypeMessage type={type} />
          </div>
          <div className="messageListItem__content-message">
            <span>{text}</span>
          </div>
        </div>
        <div className="messageListItem__status">
          <StatusForHistoryMessages status={status} />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.li`
  list-style-type: none;
  width: 625px;
  .messageListItem {
    display: flex;
    align-items: center;
    ${({ status }) => {
    switch (status) {
      case '4005': {
        return 'color: #FF5151;';
      }
      case '4006': {
        return 'color: #FF9501;';
      }
      default: {
        return `color: #000000;
                &__createDT {
                    color: #696969;
                }
                `;
      }
    }
  }}
      &__createDT {
      }
      &__content {
          margin-left: 15px;
          display: flex;
          align-items: center;
          &-type {

          }
          &-message {
            width: 409px;
            font-size: 12px;
            line-height: 14px;
            margin-left: 12px;
            user-select: all;
          }
      }
      &__status {
         margin-left: auto;
         margin-right: 10px;
      }
  }
`;

MessageListItem.propTypes = {
  DT: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};
