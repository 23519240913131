import { take, fork, all, put, select, call } from 'redux-saga/effects';
import { setQccIssue, deleteWorklog, getWaitingIssue } from '../api/issuesApi';
import {
  SET_QCC_ISSUE,
  setQccIssuesRequest,
  setQccIssuesFailure,
  setQccIssuesSuccess,
  DELETE_WORKLOG,
  deleteWorklogRequest,
  deleteWorklogFailure,
  deleteWorklogSuccess,
  GET_WAITING_ISSUES,
  getWaitingIssuesRequest,
  getWaitingIssuesFailure,
  getWaitingIssuesSuccess,
} from '../../redux/actions/issuesActions';
import { getDevice } from '../../redux/reducers/device';

export function* setQccIssues(issueKey, userID, iterId, coefficient, comment) {
  yield put(setQccIssuesRequest());
  const device = yield select(getDevice);
  const response = yield call(setQccIssue, device.device, issueKey, userID, iterId, coefficient, comment);
  if (response.status === 200) {
    yield put(setQccIssuesSuccess(response.body.data));
  } else {
    yield put(setQccIssuesFailure());
  }
}

export function* deleteWorklogApiRequest(issueId, worklogId) {
  yield put(deleteWorklogRequest());
  const device = yield select(getDevice);
  if (issueId && worklogId && worklogId !== '0') {
    const response = yield call(deleteWorklog, device.device, issueId, worklogId);
    if (response.status === 200) {
      yield put(deleteWorklogSuccess(response.body.data, worklogId));
    } else {
      yield put(deleteWorklogFailure(null, worklogId));
    }
  }
}

export function* getWaitingIssuesApiRequest() {
  yield put(getWaitingIssuesRequest());
  const device = yield select(getDevice);
  const response = yield call(getWaitingIssue, device.device);
  if (response.status === 200) {
    yield put(getWaitingIssuesSuccess(response.body.data));
  } else {
    yield put(getWaitingIssuesFailure());
  }
}

function* watchSetQccIssues() {
  while (true) {
    const { issueKey, userID, iterId, coefficient, comment } = yield take(SET_QCC_ISSUE);
    yield fork(setQccIssues, issueKey, userID, iterId, coefficient, comment);
  }
}

function* watchDeleteWorklog() {
  while (true) {
    const { issueId, worklogId } = yield take(DELETE_WORKLOG);
    yield fork(deleteWorklogApiRequest, issueId, worklogId);
  }
}

function* watchGetWaitingIssues() {
  while (true) {
    yield take(GET_WAITING_ISSUES);
    yield fork(getWaitingIssuesApiRequest);
  }
}

export default function* issuesSaga() {
  yield all([fork(watchSetQccIssues), fork(watchDeleteWorklog), fork(watchGetWaitingIssues)]);
}
