const getDoneUndoneTasksCount = (iteration) => {
  if (iteration) {
    return { done: iteration.done.length, undone: iteration.wait.length };
  }
  return { done: 0, undone: 0 };
};

const getWorkHours = (wh) => {
  if (!wh) {
    return '00:00';
  }
  if (wh.indexOf('h') === -1) {
    const hmsArray = wh.split(':');
    return `${hmsArray[0]}:${hmsArray[1]}`;
  }
  const hmsArray = wh.split(' ');
  const h = hmsArray[0].substr(0, hmsArray[0].length - 1);
  const m = hmsArray[1].substr(0, hmsArray[1].length - 1);
  return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}`;
};

/*
 : - 10:50
 hm - 10h 50m
 */
const HMfromSec = (sec, format = ':') => {
  if (format !== ':' && sec <= 0) return '';
  let min = Math.floor(sec / 60);
  const hrs = Math.floor(min / 60);
  min %= 60;
  if (format === 'hm') return `${hrs < 10 ? '0' + hrs : hrs}h ${min < 10 ? '0' + min : min}m`;
  if (format === 'HM') return `${hrs}h ${min}m`;
  if (format === 'h') return `${hrs}h`;
  return `${hrs < 10 ? '0' + hrs : hrs}:${min < 10 ? '0' + min : min}`;
};

const iconUrl = (icon) => {
  if (icon[0] === '/') {
    return `http://jira.soft-artel.com${icon}`;
  }
  return icon;
};

const formatMoney = (money) => {
  const thousands = Math.floor(money / 1000) || '';
  return `${thousands} ${(`${money}`).slice(-3)}`;
};

export default {
  getDoneUndoneTasksCount,
  getWorkHours,
  HMfromSec,
  iconUrl,
  formatMoney,
};
