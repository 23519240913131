import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import helpers from '../../utils/viewHelpers';
import { Loader, Overlay } from '../Loader';

moment.locale('ru');

const WorkLogDay = ({
  workedIssues,
  day,
  startHr,
  endHr,
  icons,
  ids,
  summaries,
  workedSec,
  deleteWorklog,
  isPending,
}) => {
  const issuesList = workedIssues.map(issue => (
    <div
      key={`issue#${issue.day}#${issue.issueKey}#${issue.startTime}#${Math.random()}`}
      className="sprint-day-worklog-item d-flex flex-row align-items-start"
    >
      <div
        className="sprint-day-worklog-item__duration"
        onClick={() => {
          const isAgree = window.confirm('Удалить worklog?');
          if (isAgree) {
            deleteWorklog(ids[issue.issueKey], issue.wlId);
          }
        }}
      >
        {helpers.HMfromSec(issue.workedSec, ':')}
      </div>
      <img src={helpers.iconUrl(icons[issue.issueKey])} alt="" />
      <a
        className="sprint-day-worklog-item__link"
        target="_blank"
        href={`http://jira.soft-artel.com/browse/${issue.issueKey}`}
      >
        {issue.issueKey}
      </a>
      <div className="sprint-day-worklog-item__title">{summaries[issue.issueKey]}</div>
      {issue.wlId in isPending && (
        <Overlay>
          <Loader show scale={0.15} />
        </Overlay>
      )}
    </div>
  ));
  return (
    <div className="sprint-day d-flex flex-row">
      <div className="sprint-day-info d-flex flex-column">
        <div className="sprint-day-info__mobile_format_dow_date">
          <strong>{moment(day).format('DD.MM')},</strong> <span>{moment(day).format('dd')}</span>
        </div>
        <div className="sprint-day-info__dow">{moment(day).format('dddd')}</div>
        <div className="sprint-day-info__date">{moment(day).format('DD MMMM')}</div>
        <div className="sprint-day-info__start-time">{startHr}</div>
        <div className={`sprint-day-info__duration ${workedSec >= 28800 ? 'success' : ''}`}>
          {helpers.HMfromSec(workedSec, ':')}
        </div>
        <div className="sprint-day-info__end-time">{endHr}</div>
      </div>
      <div className="sprint-day-worklog d-flex flex-column">{issuesList}</div>
    </div>
  );
};

WorkLogDay.propTypes = {
  workedIssues: PropTypes.arrayOf(PropTypes.object).isRequired,
  day: PropTypes.string.isRequired,
  startHr: PropTypes.string.isRequired,
  endHr: PropTypes.string.isRequired,
  workedSec: PropTypes.number.isRequired,
  deleteWorklog: PropTypes.func.isRequired,
  icons: PropTypes.shape().isRequired,
  summaries: PropTypes.shape().isRequired,
  isPending: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default pure(WorkLogDay);
