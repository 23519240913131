import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import helpers from '../../utils/viewHelpers';
import { jiraUrl } from '../../../shared/config';
import { Loader, Overlay } from '../Loader';

const LastIssue = props => (
  <div className="last-issue-container col-lg-6">
    <div
      className={`issue ${
        props.status === 'In Progress' || props.status === 'Fixing' ? 'issue-in-work' : 'issue-pause'
      } eq-height d-flex flex-row`}
    >
      <div className="c1 d-flex flex-column">
        <div className="issue-info d-flex flex-row align-items-center">
          <img src={props.iconType} alt="" />
          <a target="_blank" href={`${jiraUrl}/browse/${props.issueKey}`}>
            {props.issueKey}
          </a>
          <div className="badge">{props.status}</div>
        </div>

        <div className="issue-title">{props.SUMMARY}</div>
        <div className="components-label">Components</div>
      </div>
      <div className="c2 d-flex flex-column ml-auto">{helpers.HMfromSec(props.TIMEORIGINALESTIMATE, 'HM')}</div>
      <div className="c3 d-flex flex-column">
        <div className="issue-hours">{helpers.HMfromSec(props.TIMESPENT, 'hm')}</div>
        {props.status === 'In Progress' || props.status === 'Fixing' ? (
          <div className="issue-buttons  d-flex flex-row">
            <div className="rounded-circle defaul d-flex justify-content-center align-items-center">
              <i
                className="fa fa-pause"
                aria-hidden="true"
                onClick={() => {
                  props.onTransitIssue(props.issueKey, 'pause');
                }}
              />
            </div>
            <div className="rounded-circle fill d-flex justify-content-center align-items-center">
              <i
                aria-hidden="true"
                onClick={() => {
                  props.onTransitIssue(props.issueKey, 'done');
                }}
              />
            </div>
          </div>
        ) : (
          <div className="issue-buttons mt-auto d-flex flex-row">
            <div className="rounded-circle primary d-flex justify-content-center align-items-center">
              <i className="fa fa-long-arrow-down" aria-hidden="true" />
            </div>
            <div className="rounded-circle success d-flex justify-content-center align-items-center">
              <i
                className="fa fa-play"
                aria-hidden="true"
                onClick={() => {
                  props.onTransitIssue(props.issueKey, 'start');
                }}
              />
            </div>
          </div>
        )}
      </div>
      {props.isPending && (
        <Overlay>
          <Loader show scale={0.8} />
        </Overlay>
      )}
    </div>
  </div>
);

LastIssue.defaultProps = {
  status: 'Pause',
  active: false,
  SUMMARY: '',
  issueKey: '',
  iconType: 'http://jira.soft-artel.com/secure/viewavatar?size=xsmall&avatarId=10509&avatarType=issuetype',
  TIMESPENT: 0,
  TIMEORIGINALESTIMATE: 0,
};

LastIssue.propTypes = {
  status: PropTypes.string,
  active: PropTypes.bool,
  SUMMARY: PropTypes.string,
  TIMESPENT: PropTypes.number,
  TIMEORIGINALESTIMATE: PropTypes.number,
  issueKey: PropTypes.string,
  iconType: PropTypes.string,
  onTransitIssue: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default pure(LastIssue);
