import defines from '../../utils/defines';
import Entity from './entity';

const model = {
  key: 'ID',
  tbl: 'Employees',
  fields: {
    ID: { typeOf: 'guid', allow: 2, errLevel: 100 },
    phone: { typeOf: 'string', allow: 2, errLevel: 100, between: [0, 20] },
    login: { typeOf: 'string', allow: 1, errLevel: 1, between: [0, 150], default: ' ' },
    password: { typeOf: 'string', allow: 1, errLevel: 1, between: [0, 150], default: ' ' },
    email: { typeOf: 'string', allow: 1, errLevel: 1, between: [0, 150], default: ' ' },
    name: { typeOf: 'string', allow: 1, errLevel: 0, between: [0, 100], default: ' ' },
    surname: { typeOf: 'string', allow: 1, errLevel: 0, between: [0, 100], default: ' ' },
    patronymic: { typeOf: 'string', allow: 1, errLevel: 0, between: [0, 100], default: ' ' },
    beginD: { typeOf: 'date', allow: 1, errLevel: 0, default: new Date().toDString() },
    endD: { typeOf: 'date', allow: 1, errLevel: 0, default: new Date().toDString() },
    birthdayD: { typeOf: 'date', allow: 1, errLevel: 0, default: new Date().toDString() },
    personalJSON: { typeOf: 'string', allow: 1, errLevel: 0, default: '{}' },
    trialExpD: { typeOf: 'date', allow: 1, errLevel: 0, default: new Date().toDString() },
    allows: { typeOf: 'number', allow: 2, errLevel: 0, default: 0 },
    status: { typeOf: 'string', allow: 2, errLevel: 0, default: 'active' },
    visible: { typeOf: 'number', allow: 2, errLevel: 0, default: 1 },
    salary: { typeOf: 'number', allow: 2, errLevel: 0, default: 20000 },
    award: { typeOf: 'number', allow: 2, errLevel: 0, default: 5000 },
  },
};

class User extends Entity {
  static model() {
    return model;
  }

  constructor(ID, resourses = {}, debug = 0) {
    super(resourses, debug);
    this._model = model;
    this.setID(ID);
    // this._logger.dbg('User created', model);
  }

  static create(fields, resources, debug = 0) {
    if (!fields || !fields.phone) {
      resources.logger.error('Wrong Fields to Create USER, missing PHONE! ');
    }
    fields.ID = fields.ID || String.generateGUID();
    resources.logger.dbgprc('Create new User', fields);
    const entity = new User(fields.ID, resources, debug);
    fields = entity.validate(fields, false);
    entity.init(fields);
    return entity;
  }

  static byFields(fields, resources, debug = 0) {
    const entity = new User(fields.ID, resources, debug);
    if (!entity) return undefined;
    entity.init(fields);
    return entity;
  }

  init(data) {
    const processDates = ['beginD', 'endD', 'birthdayD', 'trialExpD'];
    processDates.forEach((key) => {
      let date = data[key];

      if (typeof date === 'string') {
        try {
          date = new Date(date);
        } catch (e) {}
      }

      if (!date) date = new Date();
      data[key] = date.toDString();
    });

    data.beginD = data.beginD.substr(0, 10);
    data.endD = data.endD.substr(0, 10);
    data.birthdayD = data.birthdayD.substr(0, 10);
    data.trialExpD = data.trialExpD.substr(0, 10);

    data = super.init(data);

    this.isGuest = (this.ID === defines.guest.ID);

    try {
      this.personal = JSON.parse(this.personalJSON);
    } catch (e) {
      this.personal = {};
    }
    return data;
  }

  fields(allowLevel) {
    const fields = super.fields(allowLevel);
    fields.personalJSON = JSON.stringify(this.personal);
    return fields;
  }

  isAllow() {
    const fields = super.fields();
    fields.personalJSON = JSON.stringify(this.personal);
    return fields;
  }

}

export default User;
