import React, { useContext } from 'react';
import { useAlert } from 'react-alert';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import icons from '../../../../utils/icons';

import ServiceField from '../../../ui/Fields/ServiceField';
import Pillow from '../../../ui/Pillows';
import Context from '../../../common/Context';

import { getDateOrYesterdayOrToday } from '../../../../utils/formattingDate';

export default function SessionItem({
  session,
}) {
  const { ID, appVersion, app, appVer,
    devModel, devName, devOS,
    devPlatform, lang, locale,
    onlineDT, status, tz } = session;

  const devPlatformIcon = devPlatform === 'iOS' ? icons.session.phone : icons.session.computer;

  const { finishSession } = useContext(Context);

  const alert = useAlert();
  const onFinishSession = () => {
    finishSession(ID)
      .then(() => {
        alert.show(<span style={{ textTransform: 'none' }}>Сессия завершена</span>, { type: 'success' });
      })
      .catch(() => {
        alert.show('Сессия не завершена!', { type: 'error' });
      });
  };
  return (
    <Wrapper status={status}>
      <div className="aboutSession">
        <div className="aboutSession__devPlatformIcon">
          {devPlatformIcon}
        </div>
        <div className="aboutSession__mainInfo">
          <div className="aboutSession__left">
            <span>{devName}</span>
            <span>
              {devModel}
              ,
              {' '}
              {devOS}
            </span>
            <span>{getDateOrYesterdayOrToday(onlineDT)}</span>
          </div>
          <div className="aboutSession__right">
            <ServiceField userType={appVersion} />
            <span>
              {lang.toUpperCase()}
              {' '}
              <Pillow size="middle" content={locale} />
            </span>
            <span>{tz}</span>
          </div>
        </div>
      </div>
      {status === 10
      && (
      <button type="button" onClick={onFinishSession}>
        {icons.closureIcon}
      </button>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.li`
    font-size: 12px;
    line-height: 22px;
    text-align: right;
    color: #979797;
    display: flex;
    margin: 0 10px 0 5px;
    .aboutSession {
        display: flex;
        width: 100%;
        &__devPlatformIcon {
          min-width: 22px;
          display: flex;
          justify-content: center;
            ${({ status }) => status !== 10
    ? `svg {
        * {
            fill: #ADADAD;
        }
        }
    ` : ''
}
            
        }
        &__mainInfo {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        &__left {
            display: flex;
            flex-direction: column;
            margin-left: 11px;
            span {
                align-self: flex-start;
            }
            span:first-child {
                font-size: 15px;
                line-height: 22px;
                color: #212223;
            }
        }
        &__right {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            span {
                align-self: flex-end;
            }
        }

    }
    button {
        border: none;
        width: 28px;
        height: 28px;
        background: #F5F5F5;
        border-radius: 4px;
        margin-left: 20px;
        margin-right: 10px;
        :hover {
            background: #9C9C9C;
            svg path {
                fill: #FFFFFF;
            }
            transition: all 0.1s ease;    
        }
    }
`;

SessionItem.propTypes = {
  session: PropTypes.shape({
    ID: PropTypes.string,
    appVersion: PropTypes.string,
    app: PropTypes.string,
    appVer: PropTypes.string,
    devModel: PropTypes.string,
    devName: PropTypes.string,
    devOS: PropTypes.string,
    devPlatform: PropTypes.string,
    lang: PropTypes.string,
    locale: PropTypes.string,
    onlineDT: PropTypes.string,
    status: PropTypes.number,
    tz: PropTypes.string,
  }).isRequired,
};
