import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function withTooltip(Component, placement, text) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip style={{ fontSize: '10px', width: 'min-content' }}>{text}</Tooltip>
       }
    >
      {Component}
    </OverlayTrigger>
  );
}
