export const GET_DOCUMENTS_LIST = 'GET_DOCUMENTS_LIST';
export const GET_DOCUMENTS_LIST_REQUEST = 'GET_DOCUMENTS_LIST_REQUEST';
export const GET_DOCUMENTS_LIST_SUCCESS = 'GET_DOCUMENTS_LIST_SUCCESS';
export const GET_DOCUMENTS_LIST_FAILURE = 'GET_DOCUMENTS_LIST_FAILURE';

export function getDocumentsList(user = 'all', search = 'all', docType = 'all') {
  return {
    type: GET_DOCUMENTS_LIST,
    user,
    search,
    docType,
  };
}
export function getDocumentsListRequest() {
  return {
    type: GET_DOCUMENTS_LIST_REQUEST,
  };
}
export function getDocumentsListSuccess(data) {
  return {
    type: GET_DOCUMENTS_LIST_SUCCESS,
    data,
  };
}
export function getDocumentsListFailure(err) {
  return {
    type: GET_DOCUMENTS_LIST_FAILURE,
    err,
  };
}

export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const UPLOAD_DOCUMENT_REQUEST = 'UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';

export function uploadDocument(file, docType, desc, ID, isNew) {
  return {
    type: UPLOAD_DOCUMENT,
    file,
    docType,
    desc,
    ID,
    isNew,
  };
}
export function uploadDocumentRequest() {
  return {
    type: UPLOAD_DOCUMENT_REQUEST,
  };
}
export function uploadDocumentSuccess(data, ID, isNew) {
  return {
    type: UPLOAD_DOCUMENT_SUCCESS,
    data,
    ID,
    isNew,
  };
}
export function uploadDocumentFailure(err, ID, isNew) {
  return {
    type: UPLOAD_DOCUMENT_FAILURE,
    err,
    ID,
    isNew,
  };
}

export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';
export const REMOVE_DOCUMENT_REQUEST = 'REMOVE_DOCUMENT_REQUEST';
export const REMOVE_DOCUMENT_SUCCESS = 'REMOVE_DOCUMENT_SUCCESS';
export const REMOVE_DOCUMENT_FAILURE = 'REMOVE_DOCUMENT_FAILURE';

export function removeDocument(ID) {
  return {
    type: REMOVE_DOCUMENT,
    ID,
  };
}
export function removeDocumentRequest() {
  return {
    type: REMOVE_DOCUMENT_REQUEST,
  };
}
export function removeDocumentSuccess(data, ID) {
  return {
    type: REMOVE_DOCUMENT_SUCCESS,
    data,
    ID,
  };
}
export function removeDocumentFailure(err, ID) {
  return {
    type: REMOVE_DOCUMENT_FAILURE,
    err,
    ID,
  };
}

export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAILURE = 'UPDATE_DOCUMENT_FAILURE';

export function updateDocument(doc) {
  return {
    type: UPDATE_DOCUMENT,
    doc,
  };
}
export function updateDocumentRequest() {
  return {
    type: UPDATE_DOCUMENT_REQUEST,
  };
}
export function updateDocumentSuccess(data, ID) {
  return {
    type: UPDATE_DOCUMENT_SUCCESS,
    data,
    ID,
  };
}
export function updateDocumentFailure(err, ID) {
  return {
    type: UPDATE_DOCUMENT_FAILURE,
    err,
    ID,
  };
}
