import React        from 'react';
import { render }   from 'react-dom';
import { Provider } from 'react-redux';
import 'moment/locale/ru';
import registerServiceWorker from './registerServiceWorker';
import './utils/prototype';
import createStore  from './redux/create';
import App          from './containers/App';
import rootSaga     from './redux/sagas/index';
import './styles/fonts.css';
import './styles/index.css';
import './styles/Menu.css';
import './styles/workState.css';
import './styles/login.css';
import './styles/productivity.css';
import './styles/media.css';

if (!global.Intl) {
  require.ensure([
    'intl',
    'intl/locale-data/jsonp/en.js',
    'intl/locale-data/jsonp/ru.js',
  ], (require) => {
    require('intl');
    require('intl/locale-data/jsonp/en.js');
    require('intl/locale-data/jsonp/ru.js');
  });
}

const store = createStore();
store.runSaga(rootSaga);

render(
  <Provider store={store}>
    <App />
  </Provider>
  , document.getElementById('root'),
);

registerServiceWorker();
