import React, { useLayoutEffect, useEffect, useReducer } from 'react';
import { Route, useHistory, useRouteMatch, Switch } from 'react-router-dom';

import styled from 'styled-components';

import { getFoundUsers, getUserCard, actionsWithUserCard } from './api';
import { getAboutInfo } from './api/authAPI';

import MainPage from './components/view/MainPage';
import FirstPage from './components/view/FirstPage';

import Context from './components/common/Context';

import { getdaysCountFromDates } from './utils/helpers';

// self -  данные из backoffice
const SET_SELF_INFO = 'SET_SELF_INFO';
// searchPanelValue - значение поисковой панели (string)
const SET_VALUE_SEARCH_PANEL = 'SET_VALUE_SEARCH_PANEL';
// foundUsers - массив найденных пользователей (array)
const SET_FOUND_USERS = 'SET_FOUND_USERS';
// selectedUser - данные выбранного пользователя (object)
const SELECT_USER = 'SELECT_USER';
/* loadingUserListStatus - загрузка UserList,
  статусы:
  0 - загрузка не активна (поиск пользователей не производится)
  1 - загрузка активна (идёт поиск пользователй)
  -1 - пользователи не найдены
 */
const SET_LOADING_USER_LIST_STATUS = 'SET_LOADING_USER_LIST_STATUS';
// loadingUserCard - загрузка UserCard
const SET_LOADING_USER_CARD = 'SET_LOADING_USER_CARD';

const UPDATE_EXTRA_EMAIL = 'UPDATE_EXTRA_EMAIL';

const ADD_COMMENT = 'ADD_COMMENT';

const ACCEPT_CONTACT = 'ACCEPT_CONTACT';

const UNBLOCK_CONTACT = 'UNBLOCK_CONTACT';

const FINISH_SESSION = 'FINISH_SESSION';

const ADD_BONUS = 'ADD_BONUS';

const RENEWING_SUBSCRIBE = 'RENEWING_SUBSCRIBE';

const initialState = {
  self: {},
  foundUsers: [],
  searchPanelValue: '',
  selectedUser: {},
  loadingUserListStatus: 0,
  loadingUserCard: false,
};

function reducer(state, action) {
  switch (action.type) {
    case SET_SELF_INFO: {
      return {
        ...state,
        self: action.data,
      };
    }
    case SET_VALUE_SEARCH_PANEL:
      return {
        ...state,
        searchPanelValue: action.value,
      };
    case SET_FOUND_USERS:
      return {
        ...state,
        foundUsers: action.data,
      };
    case SELECT_USER:
      return {
        ...state,
        selectedUser: action.data,
      };
    case SET_LOADING_USER_LIST_STATUS:
      return {
        ...state,
        loadingUserListStatus: action.value,
      };
    case SET_LOADING_USER_CARD:
      return {
        ...state,
        loadingUserCard: action.value,
      };
    case UPDATE_EXTRA_EMAIL: {
      const newFoundUsers = [...state.foundUsers];
      const indexUser = newFoundUsers.findIndex((i) => i.ID === action.ID);
      const obj = { ...newFoundUsers[indexUser], extraEmail: action.extraEmail };
      newFoundUsers[indexUser] = obj;
      return {
        ...state,
        foundUsers: newFoundUsers,
        selectedUser: {
          ...state.selectedUser,
          personal: {
            ...state.selectedUser.personal,
            extraEmail: action.extraEmail,
          },
        },
      };
    }
    case ADD_COMMENT: {
      const newFoundUsers = [...state.foundUsers];
      const indexUser = newFoundUsers.findIndex((i) => i.ID === action.ID);
      const obj = { ...newFoundUsers[indexUser], comment: action.comment };
      newFoundUsers[indexUser] = obj;
      return {
        ...state,
        foundUsers: newFoundUsers,
        selectedUser: {
          ...state.selectedUser,
          personal: {
            ...state.selectedUser.personal,
            supportComment: action.comment,
          },
        },
      };
    }
    case ADD_BONUS: {
      const { selectedUser } = state;
      return {
        ...state,
        selectedUser: {
          ...selectedUser,
          userData: {
            ...selectedUser.userData,
            [action.typeBonus]: {
              ...selectedUser.userData[action.typeBonus],
              limit: Number(selectedUser.userData[action.typeBonus].limit) + Number(action.value),
            },
          },
        },

      };
    }
    case ACCEPT_CONTACT: {
      const newArr = [...state.selectedUser.userData.contacts[action.typeContact]];
      const index = newArr.findIndex((i) => i.ID === action.ID);
      newArr.splice(index, 1);
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          userData: {
            ...state.selectedUser.userData,
            contacts: {
              ...state.selectedUser.userData.contacts,
              [action.typeContact]: newArr,
            },
          },
        },
      };
    }
    case UNBLOCK_CONTACT: {
      const { selectedUser: { userData: { contacts } } } = state;
      const newUserBlocked = [...contacts[action.typeContact]];
      const index = newUserBlocked.findIndex((i) => i.ID === action.ID);
      newUserBlocked.splice(index, 1);
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          userData: {
            ...state.selectedUser.userData,
            contacts: {
              ...contacts,
              [action.typeContact]: newUserBlocked,
            },
          },
        },
      };
    }
    case FINISH_SESSION: {
      const { selectedUser: { userData: { sessions } } } = state;
      const newActiveSession = [...sessions.active];
      const index = newActiveSession.findIndex((i) => i.ID === action.ID);
      const newInactiveSession = [{ ...newActiveSession[index], status: 0 }, ...sessions.inactive];
      newActiveSession.splice(index, 1);
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          userData: {
            ...state.selectedUser.userData,
            sessions: {
              active: newActiveSession,
              inactive: newInactiveSession,
            },
          },
        },
      };
    }
    case RENEWING_SUBSCRIBE: {
      const { selectedUser } = state;
      const newFoundUsers = [...state.foundUsers];
      const indexUser = newFoundUsers.findIndex((i) => i.ID === selectedUser.userID);
      const obj = { ...newFoundUsers[indexUser], subscrEndDays: action.subscrEndDays };
      newFoundUsers[indexUser] = obj;
      return {
        ...state,
        foundUsers: newFoundUsers,
        selectedUser: {
          ...selectedUser,
          personal: {
            ...selectedUser.personal,
            subscrEndDays: action.subscrEndDays,
          },
          subscription: {
            ...selectedUser.subscription,
            type: action.subscrType,
            source: action.source,
            startDT: action.purchaseDT,
            endDT: action.subscrEndDT,
            comment: action.comment,
            history: [...selectedUser.subscription.history, {
              addedDays: action.addedDays,
              comment: action.comment,
              purchaseDT: action.purchaseDT,
              source: action.source,
              subscrEndDT: action.subscrEndDT,
              subscrType: action.subscrType,
            }],
          },
        },
      };
    }
    default:
      return state;
  }
}

function Support() {
  const history = useHistory();
  const match = useRouteMatch('/userCard/:id');
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    self,
    foundUsers,
    searchPanelValue,
    selectedUser,
    loadingUserListStatus,
    loadingUserCard,
  } = state;

  const setSelfInfo = (data) => {
    dispatch({ type: SET_SELF_INFO, data });
  };

  const setValueSearchPanel = (value) => {
    dispatch({ type: SET_VALUE_SEARCH_PANEL, value });
  };

  const setFoundUsers = (data) => {
    dispatch({ type: SET_FOUND_USERS, data });
  };

  const setLoadingUserListStatus = (value) => {
    dispatch({ type: SET_LOADING_USER_LIST_STATUS, value });
  };

  const setLoadingUserCard = (value) => {
    dispatch({ type: SET_LOADING_USER_CARD, value });
  };

  useLayoutEffect(() => {
    getAboutInfo({}).then(({ data }) => {
      setSelfInfo(data);
    }).catch(() => {
    });
  }, []);

  const selectUser = (ID) => {
    if (ID === selectedUser.userID) return;
    setLoadingUserCard(true);
    getUserCard(ID).then(({ data }) => {
      dispatch({ type: SELECT_USER, data });
      setLoadingUserCard(false);
    });
  };

  useEffect(() => {
    const { pathname } = history.location;
    const page = pathname.split('/');
    if (pathname !== '/support') {
      if ((page[1] === 'userCard') &&  match !== null && (page[2] === match.params.id)) {
        // попадание в этот блок, в случае если адресная строка имеет вид userCard/:id
        selectUser(match.params.id);
      } else history.push('/support');
    }
  }, []);

  useEffect(() => {
    console.log(self);
    if (typeof self.isSupport !== 'undefined' && self.isSupport !== 1) {
      alert('Отказано в доступе. Вы не являетесь Support.');
      window.location.href = window.origin;
    }
  }, [self]);

  useEffect(() => {
    if (searchPanelValue.trim() === '') return;
    setLoadingUserListStatus(1);
    getFoundUsers(searchPanelValue).then(({ data }) => {
      if (data.length === 0) {
        setLoadingUserListStatus(-1);
        return;
      }
      setFoundUsers(data);
      setLoadingUserListStatus(0);
    }).catch(() => setLoadingUserListStatus(-1));
  }, [searchPanelValue]);

  const updateExtraEmail = (ID, extraEmail) => actionsWithUserCard
    .updateSupportEmail(ID, extraEmail)
    .then(() => {
      dispatch({ type: UPDATE_EXTRA_EMAIL, extraEmail, ID });
    });

  const addComment = (ID, comment) => actionsWithUserCard
    .addComment(ID, comment)
    .then(() => {
      dispatch({ type: ADD_COMMENT, comment, ID });
    });

  const resetCode = (ID) => actionsWithUserCard
    .resetCode(ID)
    .then(() => {
      // Обновить состояние история изменений
    });

  const sendMessage = (ID, message, type) => new Promise((resolve, reject) => {
    actionsWithUserCard
      .messageSend(ID, message, type)
      .then(() => {
        resolve();
      // Обновить состояние: история сообщений
      })
      .catch(() => {
        reject();
      });
  });

  const getMessageList = (ID) => actionsWithUserCard.getMessageList(ID);

  const renewingSubscribe = (ID, type, days, comment) => actionsWithUserCard
    .renewingSubscribe(ID, type, days, comment)
    .then(({ data }) => {
      const { addedDays, purchaseDT, source, subscrEndDT, subscrType } = data;
      const subscrEndDays = getdaysCountFromDates(purchaseDT, subscrEndDT);
      dispatch({ type: RENEWING_SUBSCRIBE,
        subscrEndDays,
        addedDays,
        comment,
        purchaseDT,
        source,
        subscrEndDT,
        subscrType,
        days });
      // Обновить состояние: история подписок
    });

  const getHistoryChanges = (ID) => actionsWithUserCard.getSupportHistory(ID);

  const addBonus = (ID, type, value, comment = '') => actionsWithUserCard
    .addBonus(ID, type, value, comment)
    .then(() => {
      dispatch({ type: ADD_BONUS, typeBonus: type, value });
    })
    .catch(() => {
      alert(`Неудачная попытка добавить бонус типа ${type} в количестве ${value} пользователю с ID: ${ID}`);
    });

  const finishSession = (ID) => actionsWithUserCard
    .finishSession(ID)
    .then(() => {
      dispatch({ type: FINISH_SESSION, ID });
      // Обновить состояние: удалить сессию
    });

  const acceptContact = (ID, type) => actionsWithUserCard
    .acceptContact(ID, selectedUser.personal.phone, type)
    .then(() => {
      dispatch({ type: ACCEPT_CONTACT, ID, typeContact: type === 'incoming' ? 'incomingRequests' : 'userWaits' });
    });

  const unblockContact = (ID, typeContact) => {
    /* userBlocked - заблокированные контакты
    blockedUser - заблокировавшие контакты
     */
    if (typeContact === 'userBlocked') {
      return actionsWithUserCard
        .unblockContact(ID)
        .then(() => {
          dispatch({ type: UNBLOCK_CONTACT, ID,  typeContact });
        })
        .catch(() => {
          alert(`Неудачная попытка разблокировать контакт с ID: ${ID}`);
        });
    }
    if (typeContact === 'blockedUser') {
      return actionsWithUserCard
        .unblockContact(ID, selectedUser.personal.phone)
        .then(() => {
          dispatch({ type: UNBLOCK_CONTACT, ID, typeContact });
        })
        .catch(() => {
          alert(`Неудачная попытка разблокировать контакт с ID: ${ID}`);
        });
    }
    return null;
  };

  const restoreTrashbin = (ID, days) => actionsWithUserCard
    .restoreTrashbin(ID, days);

  const restoreTrashbinForDateRange = (ID, from, to) => actionsWithUserCard
    .restoreTrashbinForDateRange(ID, from, to);

  const getTrashInfo = (ID, from, to) => actionsWithUserCard
    .trashInfo(ID, from, to);

  return (
    <div style={{ backgroundColor: '#E7E7E7', minHeight: '100vh' }}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Context.Provider
        value={{
          sendMessage,
          renewingSubscribe,
          finishSession,
          acceptContact,
          unblockContact,
          restoreTrashbin,
          getTrashInfo,
          restoreTrashbinForDateRange,
          getHistoryChanges,
          getMessageList,
        }}
      >
        <Wrapper>
          <Switch>
            <Route path="/support/userCard/:id">
              <MainPage
                loadingUserCard={loadingUserCard}
                selectedUser={selectedUser}
                updateExtraEmail={updateExtraEmail}
                addComment={addComment}
                resetCode={resetCode}
                addBonus={addBonus}
              />
            </Route>
            <Route path="/support">
              <FirstPage
                loadingUserListStatus={loadingUserListStatus}
                searchPanelValue={searchPanelValue}
                foundUsers={foundUsers}
                setLoadingUserListStatus={setLoadingUserListStatus}
                setValueSearchPanel={setValueSearchPanel}
                onSelectUser={selectUser}
                setFoundUsers={setFoundUsers}
              />
            </Route>
          </Switch>
        </Wrapper>
      </Context.Provider>
    </div>
  );
}

export default Support;

const Wrapper = styled.div`
  width: 1117px;
  margin: 21px auto;
  font-family: Helvetica Neue;
  button {
    border: none; 
    &:focus {
      outline: none;
    }
  }
  textarea {
    resize: none;
  }
  .nav-item {
    height: auto;
    button:focus {
      outline: none;
    } 
  }
  @import "~bootstrap/scss/bootstrap";
  .nav-fill .nav-item, .nav-fill>.nav-link {
    flex: 1 1 auto;
    text-align: center;
  }
}
`;
