import BORequest from '../boModels/request';
import { apiConfig, defaultlogger } from '../../../shared/config';

const ISSUES_LOCATION = 'issues';

function* transitIssue(device, key, to) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ISSUES_LOCATION}/switch`, {
      key,
      to,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* getIssueByKey(device, key) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ISSUES_LOCATION}/issue`, {
      key,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* getWaitingIssue(device) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ISSUES_LOCATION}/waiting`);
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* getFeatures(device) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ISSUES_LOCATION}/features`);
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* getIssueByJql(device, jql, iteration) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ISSUES_LOCATION}/search`, {
      jql,
      iteration,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* getIssueByStatus(device, user = 'all', status = 'all', iteration) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ISSUES_LOCATION}/byStatus`, {
      user,
      status,
      iteration,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* getIssueByStatusCategory(device, user = 'all', category = 'all', iteration) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ISSUES_LOCATION}/byStatusCategory`, {
      user,
      category,
      iteration,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* setQccIssue(device, issueKey, userID, iterId, coefficient, comment = '') {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ISSUES_LOCATION}/setQcc`, {
      issueKey,
      userID,
      iterId,
      coefficient,
      comment,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* deleteWorklog(device, issueId, worklogId) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${ISSUES_LOCATION}/deleteWorklog`, {
      issueId,
      worklogId,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

export {
  transitIssue,
  getIssueByKey,
  getWaitingIssue,
  getFeatures,
  getIssueByJql,
  getIssueByStatus,
  getIssueByStatusCategory,
  setQccIssue,
  deleteWorklog,
};
