import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import thunk        from 'redux-thunk';
import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import reducers     from './reducers/index';
import {
  CODE_DEVICE_SUCCESS,
  SIGN_IN_DEVICE_SUCCESS,
  LOGOUT,
} from './actions/deviceActions';

export default function create() {
  const reducer = storage.reducer(reducers);
  const engine = createEngine('backoffice-react');
  const middleware = storage.createMiddleware(
    engine,
    [],
    [CODE_DEVICE_SUCCESS, SIGN_IN_DEVICE_SUCCESS, LOGOUT, /*UPDATE_TIME_EVERY_1_MIN*/], // todo update_time, device info
  );
  const sagaMiddleware = createSagaMiddleware();
  const createStoreWithMiddleware = applyMiddleware(thunk, sagaMiddleware, middleware)(createStore);
  const defaultState = {};
  const enhancers = compose(window.devToolsExtension ? window.devToolsExtension() : f => f);
  const store = createStoreWithMiddleware(reducer, defaultState, enhancers);
  const load = storage.createLoader(engine);
  load(store);

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return store;
}
