/* eslint-disable max-classes-per-file */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import Helmet from 'react-helmet';
import { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { ReactComponent as ArrowNavbar } from '../../../images/arrow-navbar.svg';
import { ReactComponent as ClearIcon } from '../../../images/close.svg';
import * as styled from './styles';

const WEEKDAYS_LONG = [
  'Воскресенье', 'Понедельник', 'Вторник',
  'Среда', 'Четверг', 'Пятница', 'Суббота',
];

const WEEKDAYS_SHORT = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

const MONTH_TITLE = [
  'Январь', 'Февраль', 'Март',
  'Апрель', 'Май', 'Июнь',
  'Июль', 'Август', 'Сентябрь',
  'Октябрь', 'Ноябрь', 'Декабрь',
];

const navbarElement = ({ onPreviousClick, onNextClick, month }) => {
  // debugger;
  const monthTitle = MONTH_TITLE[month.getMonth()];
  const nextMonth = new Date(month.getFullYear(), month.getMonth() + 1, 1);
  const nextMonthTitle = MONTH_TITLE[nextMonth.getMonth()];
  return (
    <styled.ControlsWrapper>
      <styled.MonthBtn onClick={() => onPreviousClick()} rotate={-90}>
        <ArrowNavbar fill="#C2C2C2" />
      </styled.MonthBtn>
      <styled.Month className="monthName">{`${monthTitle} ${month.getFullYear()}`}</styled.Month>
      <styled.Month className="monthName">{`${nextMonthTitle} ${nextMonth.getFullYear()}`}</styled.Month>
      <styled.MonthBtn onClick={() => onNextClick()} rotate={90}>
        <ArrowNavbar />
      </styled.MonthBtn>
    </styled.ControlsWrapper>
  );
};

class Input extends React.Component {
  setInput = (el) => {
    this.input = el;
  };

  focus = () => {
    this.input.focus();
  };

  onChange = (ev) => {
    const regexp = /^[0-9 /.-]+$/i;
    if (ev.target.value === '' || ev.target.value.match(regexp)) {
      if ((ev.target.value.length === 2 || ev.target.value.length === 5)) {
        // eslint-disable-next-line no-param-reassign
        ev.target.value += '.';
      }
      this.props.onChange(ev);
    }
  };

  render() {
    const { onFocus, onClick, onBlur, onKeyDown, onKeyUp, value, placeholder } = this.props;
    return (
      <input
        ref={this.setInput}
        type="text"
        value={value}
        placeholder={placeholder}
        onFocus={onFocus}
        onChange={this.onChange}
        onClick={onClick}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      />
    );
  }
}

export default class DateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      enteredTo: this.props.to,
    };
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleDayMouseEnter = (day) => {
    const { from } = this.props;
    if (from && from <= day) {
      this.setState({
        enteredTo: day,
      });
    }
  };

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
    this.props.onChange('from', from);
  }

  handleToChange(to) {
    this.props.onChange('to', to);
    this.setState({ enteredTo: to }, this.showFromMonth);
  }

  onDayPickerHide = () => {
    const { to } = this.props;
    this.setState({
      enteredTo: to,
    });
  };

  clearSearch = () => {
    this.props.onChange('from', undefined);
    this.props.onChange('to', undefined);
  };

  render() {
    const { enteredTo } = this.state;
    const { from, to, isForFilter } = this.props;
    const modifiers = { start: from, end: to };

    const dayPickerProps = {
      selectedDays: [
        from,
        {
          from,
          to: enteredTo,
        },
      ],
      modifiers: {
        start: from,
        end: enteredTo,
      },
      weekdaysLong: WEEKDAYS_LONG,
      weekdaysShort: WEEKDAYS_SHORT,
      firstDayOfWeek: 1,
      numberOfMonths: 2,
      navbarElement,
    };
    return (
      <styled.Wrapper className="dateFilter" view="mainActivity" isForFilter={isForFilter}>
        <DayPickerInput
          value={from}
          placeholder={isForFilter ? 'Даты' : ''}
          format="DD.MM.YYYY"
          formatDate={formatDate}
          component={Input}
          parseDate={parseDate}
          dayPickerProps={{
            ...dayPickerProps,
            // selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
          onDayPickerHide={this.onDayPickerHide}
          hideOnDayClick
        />
        {typeof from !== 'undefined' && <span style={{ position: 'relative', top: '-1px', right: '1px', color: '#666666' }}>-</span>}
        <DayPickerInput
            // eslint-disable-next-line no-return-assign
          ref={(el) => (this.to = el)}
          value={to}
          placeholder=""
          format="DD.MM.YYYY"
          formatDate={formatDate}
          parseDate={parseDate}
          component={Input}
          dayPickerProps={{
            ...dayPickerProps,
            disabledDays: { before: from },
            onDayMouseEnter: this.handleDayMouseEnter,
          }}
          onDayChange={this.handleToChange}
          onDayPickerHide={this.onDayPickerHide}
          hideOnDayClick
        />
        {
          isForFilter && (
            <styled.ClearButton disabled={false} type="button" onClick={this.clearSearch}>
              <ClearIcon />
            </styled.ClearButton>
          )
        }
      </styled.Wrapper>
    );
  }
}

// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import Input from './Input';
// import * as styled from './styles';

// const WEEKDAYS_LONG = [
//   'Понедельник', 'Вторник', 'Среда',
//   'Четверг', 'Пятница', 'Суббота', 'Воскресенье',
// ];

// const WEEKDAYS_SHORT = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

// const MONTH_TITLE = [
//   'Январь', 'Февраль', 'Март',
//   'Апрель', 'Май', 'Июнь',
//   'Июль', 'Август', 'Сентябрь',
//   'Октябрь', 'Ноябрь', 'Декабрь'];

// const ArrowDownIcon = (
//   <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path
//       d="M6.96397 7.06891L12.8081 1.22284C13.064 0.966321 13.064 0.550715
//          12.80810.293546C12.5522 0.0370244 12.1366 0.0370244 11.8807 0.293546L6.50032
//          5.67591L1.11991 0.294195C0.864032 0.037673 0.448426 0.037673 0.191905
//          0.294195C-0.0639687  0.550716 -0.0639687 0.966969 0.191905 1.22349L6.03597
//          7.06956C6.2892 7.32214
//          6.71134 7.32214 6.96397 7.06891Z"
//       fill="#6A6E6E"
//     />
//   </svg>
// );

// function navbarElement({ onPreviousClick, onNextClick, month }) {
//   debugger;
//   const monthTitle = MONTH_TITLE[month.getMonth()];
//   const nextMonth = new Date(month.getFullYear(), month.getMonth() + 1, 1);
//   const nextMonthTitle = MONTH_TITLE[nextMonth.getMonth()];
//   return (
//     <styled.ControlsWrapper>
//       <styled.MonthBtn onClick={() => onPreviousClick()} rotate={90}>
//         <ArrowDownIcon height={13} fill="#a6a6a6" />
//       </styled.MonthBtn>
//       <styled.Month className="monthName">{`${monthTitle} ${month.getFullYear()}`}</styled.Month>
//       <styled.MonthBtn />
//       <styled.MonthBtn />
//       <styled.Month
//          className="monthName">{`${nextMonthTitle} ${nextMonth.getFullYear()}`}</styled.Month>
//       <styled.MonthBtn onClick={() => onNextClick()} rotate={-90}>
//         <ArrowDownIcon height={13} fill="#a6a6a6" />
//       </styled.MonthBtn>
//     </styled.ControlsWrapper>
//   );
// }

// // eslint-disable-next-line import/no-unused-modules
// export default function DateFilter({
//   to,
//   from,
//   onChange,
// }) {
//   const [enteredTo, setEnteredTo] = useState(to);
//   const [isOpen, setIsOpen] = useState(false);

//   useEffect(() => setEnteredTo(to), [to]);

//   useEffect(() => {
//     if (typeof to === 'undefined' || typeof from === 'undefined') setIsOpen(false);
//   }, [to, from]);

//   const clearSearch = () => {
//     onChange('from', undefined);
//     onChange('to', undefined);
//     setIsOpen(false);
//   };

//   const dayPickerProps = {
//     selectedDays: [
//       from,
//       {
//         from,
//         to: enteredTo,
//       },
//     ],
//     modifiers: {
//       start: from,
//       end: enteredTo,
//     },
//     weekdaysLong: WEEKDAYS_LONG,
//     weekdaysShort: WEEKDAYS_SHORT,
//     firstDayOfWeek: 1,
//     numberOfMonths: 2,
//     navbarElement,
//   };

//   const fromDayClick = (day) => {
//     if (to) {
//       if (to > day) {
//         to.getInput().focus();
//       }
//     } else {
//       to.getInput().focus();
//     }
//   };

//   const handleDayMouseEnter = (day) => {
//     if (from && from <= day) setEnteredTo(day);
//   };

//   const handleToChange = (day) => {
//     if (!from || day >= from) {
//       onChange('to', day);
//       setEnteredTo(day);
//     }
//   };

//   const handleFromChange = (selectedDay) => {
//     if (!to || selectedDay < to) {
//       onChange('from', selectedDay);
//     }
//   };

//   //   const onDayPickerHide = () => {
//   //     setEnteredTo(to);
//   //     if (
//   //       from
//   //       && to
//   //       && document.activeElement !== this.from.getInput().input
//   //       && document.activeElement !== this.to.getInput().input
//   //     ) {
//   //       this.setState({
//   //         isOpen: false,
//   //       });
//   //     }
//   //   };

//   return (
//     <styled.Wrapper>
//       <DayPickerInput
//         value={from}
//         placeholder=""
//         component={Input}
//         dayPickerProps={{
//           ...dayPickerProps,
//           disabledDays: {
//             after: to,
//           },
//         }}
//         onDayClick={fromDayClick}
//         onDayChange={handleFromChange}
//         hideOnDayClick
//       />
//       {typeof from !== 'undefined' && <span>-</span>}
//       <DayPickerInput
//         value={to}
//         placeholder=""
//         component={Input}
//         dayPickerProps={{
//           ...dayPickerProps,
//           disabledDays: {
//             before: from,
//           },
//           onDayMouseEnter: handleDayMouseEnter,
//         }}
//         onDayChange={handleToChange}
//         hideOnDayClick
//       />
//     </styled.Wrapper>
//   );
// }
