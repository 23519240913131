import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Pillow from '../Pillows';

import getDateToString from '../../../utils/formattingDate';

export default function RegistrationDate({
  tooltip,
  size,
  createDT,
  registeredDays,
}) {
  const date = getDateToString(createDT);
  const tooltipForPillow = tooltip && tooltip;
  return (
    <Wrapper size={size}>
      <Pillow content={registeredDays} size={size} tooltip={tooltipForPillow} />
      <span>{date}</span>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    & :nth-child(2) {
        color: #9E9E9E;
        margin-left: 8px;
        ${({ size }) => {
    switch (size) {
      case 'middle': {
        return `font-size: 14px;
                line-height: 17px;`;
      }
      case 'small':
      default: {
        return `font-size: 12px;
                line-height: 14px;`;
      }
    }
  }}
}`;

RegistrationDate.propTypes = {
  tooltip: PropTypes.shape(),
  size: PropTypes.string.isRequired,
  createDT: PropTypes.string.isRequired,
  registeredDays: PropTypes.number.isRequired,
};

RegistrationDate.defaultProps = {
  tooltip: {
    placement: 'top',
    text: 'Подсказка',
  },
};
