/* eslint-disable no-mixed-operators */
import { combineReducers } from 'redux';
import {
  UPDATE_HOSPITAL_SUCCESS,
  GET_HOSPITAL_LIST_SUCCESS,
  GET_HOSPITAL_STAT_SUCCESS,
  UPLOAD_HOSPITAL_FAILURE,
  UPLOAD_HOSPITAL_REQUEST,
  UPLOAD_HOSPITAL_SUCCESS,
  SWITCH_HOSPITAL_SUCCESS,
} from '../actions/hospitalsActions';

export const getHospitalsById = (state, ID) => {
  const allHospitals = state[ID] && state[ID].hospitals || [];
  const byYear = {};
  const activeHospitals = [];
  allHospitals.forEach((hospital) => {
    const year = new Date(hospital.startD).getFullYear();
    if (hospital.status === 'open') {
      activeHospitals.push(hospital);
    }
    if (hospital.status === 'closed') {
      if (byYear[year]) {
        byYear[year] = byYear[year].concat([hospital]);
      } else {
        byYear[year] = [hospital];
      }
    }
  });
  return { activeHospitals, byYear } || { activeHospitals: [], byYear: {} };
};

export const getStatByID = (hospitals, ID) => hospitals[ID] && hospitals[ID].stat || {};

export const getAllHospitalsByStatus = (hospitals, status) => {
  const result = [];
  for (const emplID in hospitals) {
    const emplHospitals = hospitals[emplID].hospitals;
    for (let i = 0; i < emplHospitals.length; i++) {
      const hospital = emplHospitals[i];
      if (hospital.status === status) result.push(hospital);
    }
  }
  return result;
};

const getHospitalObject = (emplHospital, obj, param) => {
  if (param === 'stat') {
    return {
      stat: obj,
      hospitals: typeof emplHospital === 'undefined' ? [] : emplHospital.hospitals,
    };
  }
  return {
    stat: typeof emplHospital === 'undefined' ? {} : emplHospital.stat,
    hospitals: obj,
  };
};

function byUserId(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_HOSPITAL_SUCCESS: {
      let hospitals = state[action.data.empID].hospitals;
      let index = 0;
      let isExist = false;
      while (index < hospitals.length && !isExist) {
        if (hospitals[index].id === action.data.id) isExist = true;
        index++;
      }
      if (isExist) {
        hospitals = [
          ...hospitals.slice(0, index - 1),
          action.data,
          ...hospitals.slice(index),
        ];
      } else {
        hospitals = [...hospitals, action.data];
      }
      return Object.assign({}, state, {
        [action.data.empID]: getHospitalObject(state[action.data.empID], hospitals, 'hospitals'),
      });
    }
    case SWITCH_HOSPITAL_SUCCESS: {
      let hospitals = state[action.data.empID].hospitals;
      let index = 0;
      let isExist = false;
      while (index < hospitals.length && !isExist) {
        if (hospitals[index].id === action.data.id) isExist = true;
        index++;
      }
      if (isExist) {
        hospitals = [
          ...hospitals.slice(0, index - 1),
          action.data,
          ...hospitals.slice(index),
        ];
        return Object.assign({}, state, {
          [action.data.empID]: getHospitalObject(state[action.data.empID], hospitals, 'hospitals'),
        });
      }
      return state;
    }
    case GET_HOSPITAL_LIST_SUCCESS: {
      let newState = { ...state };
      Object.keys(action.data).forEach((ID) => {
        const hospitals = action.data[ID];
        newState = Object.assign({}, newState, {
          [ID]: getHospitalObject(state[ID], hospitals, 'hospitals'),
        });
      });
      return newState;
    }
    case GET_HOSPITAL_STAT_SUCCESS: {
      let newState = { ...state };
      Object.keys(action.data).forEach((ID) => {
        const stat = action.data[ID];
        newState = Object.assign({}, newState, {
          [ID]: getHospitalObject(state[ID], stat, 'stat'),
        });
      });
      return newState;
    }
    case 'LOGOUT': {
      return {};
    }
    default:
      return state;
  }
}

const uploadingInitialState = {
  docID: '',
  pending: false,
};

function uploading(state = uploadingInitialState, action = {}) {
  switch (action.type) {
    case UPLOAD_HOSPITAL_SUCCESS: {
      return { ...state, docID: action.data.ID, pending: false };
    }
    case UPLOAD_HOSPITAL_REQUEST: {
      return { ...state, docID: '', pending: true };
    }
    case UPLOAD_HOSPITAL_FAILURE: {
      return { ...state, docID: '', pending: false };
    }
    case 'LOGOUT': {
      return uploadingInitialState;
    }
    default:
      return state;
  }
}

export default combineReducers({
  byUserId,
  uploading,
});
