import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React from 'react';
import PropTypes from 'prop-types';
import Login from './Login';
import Home from '../components/Home';
import { Loader } from '../components/Loader';
import * as app from '../redux/reducers/app';
import { redirect, setUri } from '../redux/actions/appActions';
import { getUserList, getUserInfo } from '../redux/actions/userActions';
import { getUserWorkstate } from '../redux/actions/workstatesActions';
import { updateDevice, logout, setGeoposition } from '../redux/actions/deviceActions';
import { getEmployee, getActiveEmployeesCount } from '../redux/reducers/employees';

const HomeRoute = ({
  component,
  isLogged,
  isLoaded,
  user,
  uri,
  workstate,
  redirect,
  onLogout,
  getUserList,
  getUserWorkstate,
  activeCount,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      (isLogged ? (
        React.createElement(
          component,
          Object.assign(props, {
            isLogged,
            isLoaded,
            getUserList,
            uri,
            getUserWorkstate,
            user,
            workstate,
            redirect,
            activeCount,
            logout: onLogout,
          }),
        )
      ) : (
        <Redirect to={{ pathname: '/login' }} />
      ))
    }
  />
);

HomeRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isLogged: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  getUserList: PropTypes.func.isRequired,
  getUserWorkstate: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  uri: PropTypes.object.isRequired,
  activeCount: PropTypes.number.isRequired,
};

class App extends React.Component {
  componentDidMount() {
    const self = this;
    let _lastDate = (new Date() / 1000) >> 0;

    navigator.geolocation.watchPosition((position) => {
      this.props.setGeoposition(position.coords);
    });

    setTimeout(() => {
      self.props.updateDevice();
      if (self.props.isLogged) {
        self.props.getUserWorkstate();
        self.props.getUserList();
        self.props.getUserInfo();
        self.props.setUri();
      }
    }, 100);

    setInterval(() => {
      const d = new Date();
      const ld = (d / 1000) >> 0;
      if (_lastDate !== ld) {
        if ((_lastDate / 60) >> 0 !== (ld / 60) >> 0) {
          self.props.updateTimeEvery1min();
          if (self.props.isLogged) {
            self.props.getUserWorkstate();
            if (document.location.pathname === '/workstate') self.props.getUserList();
          }
        }
        _lastDate = ld;
      }
    }, 1000);
  }

  render() {
    const {
      isLogged,
      isLoaded,
      user,
      uri,
      redirect,
      activeCount,
      logout,
      getUserList,
      getUserWorkstate,
      workstate,
    } = this.props;
    return (
      <Router>
        <div style={{ backgroundColor: '#E7E7E7' }}>
          <Loader show={isLogged && !isLoaded} />
          <HomeRoute
            path="/"
            component={Home}
            isLogged={isLogged}
            isLoaded={isLoaded}
            user={user}
            workstate={workstate}
            activeCount={activeCount}
            onLogout={logout}
            uri={uri}
            redirect={redirect}
            getUserList={getUserList}
            getUserWorkstate={getUserWorkstate}
          />
          <Route path="/login" component={Login} />
        </div>
      </Router>
    );
  }
}

App.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  setGeoposition: PropTypes.func.isRequired,
  getUserList: PropTypes.func.isRequired,
  getUserWorkstate: PropTypes.func.isRequired,
  setUri: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  activeCount: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    isLogged: state.app.login.state === 'logged',
    user: getEmployee(state.employees.byId, state.user.ID) || state.user,
    workstate: state.workstates[state.user.ID] || {},
    activeCount: getActiveEmployeesCount(state.employees, state.workstates),
    uri: app.getUri(state.app),
    isLoaded: app.isLoaded(state.app.dataLoaded, state.user.ID),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: bindActionCreators(logout, dispatch),
    setGeoposition: bindActionCreators(setGeoposition, dispatch),
    updateTimeEvery1min: bindActionCreators(app, dispatch).updateTimeEvery1min,
    redirect: bindActionCreators(redirect, dispatch),
    setUri: bindActionCreators(setUri, dispatch),
    updateDevice: bindActionCreators(updateDevice, dispatch),
    getUserList: bindActionCreators(getUserList, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
    getUserWorkstate: bindActionCreators(getUserWorkstate, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
