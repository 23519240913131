import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SearchPanel = ({
  loadingStatus,
  searchPanelValue,
  onSubmitSearchPanel,
}) => {
  const inputEl = useRef(null);

  const [value, setValue] = useState(searchPanelValue);

  useEffect(() => {
    setValue(searchPanelValue);
    if (!searchPanelValue) inputEl.current.focus();
  }, [searchPanelValue]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (value) onSubmitSearchPanel(value);
  };

  return (
    <FormWrapper onSubmit={onSubmit}>
      <span>Телефон / ID / Email / Доп. Email</span>
      <div className="input-and-button">
        <input ref={inputEl} onChange={onChange} value={value} className="form-control" type="text" placeholder="Введите данные" />
        <button disabled={loadingStatus === 1} type="submit" className="btn btn-primary">Найти</button>
      </div>
    </FormWrapper>
  );
};

const FormWrapper = styled.form`
    width: 1110px;
    height: 98px;
    background: #F3F3F3;
    border-radius: 5px;
    padding: 5px 40px;
    span {
        font-family: SF Pro Display, Helvetica Neue;
        font-size: 14px;
        line-height: 24px;
        color: #4A4A4A;
    }
    .form-control {
        width: 845px;
        height: 36px;
    }
    .btn {
        width: 160px;
        height: 36px;
        background: #007BFF;
        border-radius: 4px;
    }
    .input-and-button {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
    }
`;

SearchPanel.propTypes = {
  loadingStatus: PropTypes.number.isRequired,
  searchPanelValue: PropTypes.string.isRequired,
  onSubmitSearchPanel: PropTypes.func.isRequired,
};

export default SearchPanel;
