import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SubscribeListItem from './SubscribeListItem';

import * as fromStyled from '../styles';

const ListWrapper = styled(fromStyled.ListWrapperForHistory)`
  padding-right: 7px;  
  height: 348px;
`;

export default function SubscribeList({
  list,
}) {
  const renderSubscribeList = () => list.map((item) => (
    <SubscribeListItem
      key={item.purchaseDT}
      addedDays={item.addedDays}
      type={item.subscrType}
      source={item.source}
      startDT={item.purchaseDT}
      endDT={item.subscrEndDT}
      comment={item.comment}
    />
  ));
  return (
    <ListWrapper>
      {renderSubscribeList()}
    </ListWrapper>
  );
}

SubscribeList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    addedDays: PropTypes.number,
    comment: PropTypes.string,
    purchaseDT: PropTypes.string,
    source: PropTypes.string,
    subscrEndDT: PropTypes.string,
    subscrType: PropTypes.string,
  })).isRequired,
};
