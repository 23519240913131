import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import SprintInfo             from '../components/sprintInfo/SprintInfo';
import {
  getIteration,
  getIterationList,
  getAllIterations,
  getIterationForAllUsers,
  recalculateIterationRequest,
} from '../redux/actions/iterationActions';
import {
  setQccIssues,
  deleteWorklog,
}  from '../redux/actions/issuesActions';
import {
  getAllEmployees,
  getEmployee,
} from '../redux/reducers/employees';
import {
  getIterationsByID,
} from '../redux/reducers/iterations';

function mapStateToProps(state) {
  const user = getEmployee(state.employees.byId, state.user.ID) || state.user;
  return {
    iterations: state.iterations.list,
    employees: getAllEmployees(state.employees),
    employeesIterations: id => getIterationsByID(state.iterations.byUserID, id),
    getEmployeeByID: ID => getEmployee(state.employees.byId, ID),
    user,
    sprintId: state.workstates && state.workstates[state.user.ID] && state.workstates[state.user.ID].iteration.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getIteration: bindActionCreators(getIteration, dispatch),
    getIterationList: bindActionCreators(getIterationList, dispatch),
    setQccIssues: bindActionCreators(setQccIssues, dispatch),
    deleteWorklog: bindActionCreators(deleteWorklog, dispatch),
    getAllIterations: bindActionCreators(getAllIterations, dispatch),
    getIterationForAllUsers: bindActionCreators(getIterationForAllUsers, dispatch),
    recalculateIteration: bindActionCreators(recalculateIterationRequest, dispatch),
  };
}

const SprintInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SprintInfo);

export default SprintInfoContainer;
