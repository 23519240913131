import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import icons from '../../../utils/icons';

const IndicatorsContainer = () => icons.arrowDown;

const colourStyles = {
  control: (styles, { selectProps }) => ({
    ...styles,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    borderBottom: selectProps.renewing ? '1px solid #BBBBBB' : 'none',
    borderRadius: 'none',
    backgroundColor: 'none',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    position: 'relative',
    boxSizing: 'border-box',
    minHeight: 30,
    maxHeight: 90,
    fontSize: '15px',
    lineHeight: '17px',
    width: selectProps.language ? '39px' : selectProps.renewing ? '100%' : '87px',
    overflowY: 'auto',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  menu: (styles, { selectProps }) => ({
    ...styles,
    position: 'absolute',
    top: 'calc(100% + 2px)',
    // width: '100%',
    width: selectProps.language ? '50px' : selectProps.renewing ? '100%' : '303px',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
    overflowX: 'hidden',
    borderRadius: '4px',
    fontSize: '15px',
    lineHeight: '17px',
    zIndex: 99,
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    borderBottom: '1px solid #DFDFDF',
    backgroundColor: !isSelected ? '#FFFFFF' : '#F4F4F4',
    color: '#000000',
    cursor: 'pointer',
    ':hover': {
      ...styles[':hover'],
      backgroundColor: '#F4F4F4',
    },
  }),
  singleValue: (styles, { selectProps }) => ({
    ...styles,
    fontSize: selectProps.renewing ? 16 : 15,
    color: selectProps.renewing ? '##000000' : '#BFBFBF',
    ':hover': {
      color: '#585858',
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
};

export default function Select({
  language,
  patterns,
  renewing,
  options,
  selectedOption,
  onSelectOption,
}) {
  const selectOption = (option) => {
    if (String(selectedOption) === option.value) return;
    onSelectOption(option);
  };
  return (
    <ReactSelect
      // defaultValue={patterns ? { value: 'patterns', label: 'Шаблоны' } :
      // renewing ? selectedOption : optionsForSelect[0]}
      value={patterns ? { value: 'patterns', label: 'Шаблоны' } : selectedOption}
      options={options}
      maxMenuHeight={200}
      language={language}
      patterns={patterns}
      renewing={renewing}
      styles={colourStyles}
      onChange={selectOption}
      isSearchable={false}
      components={{
        IndicatorSeparator: () => null,
        IndicatorsContainer,
      }}
    />
  );
}

Select.propTypes = {
  language: PropTypes.bool,
  patterns: PropTypes.bool,
  renewing: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedOption: PropTypes.shape(),
  onSelectOption: PropTypes.func.isRequired,
};

Select.defaultProps = {
  language: false,
  patterns: false,
  renewing: false,
  selectedOption: null,
};
