import { buildUrl } from '../utils/request';

const defaultApiUrl = 'https://api.soft-artel.com';

let srvDelta = 0;

const getFetchPostParams = (data) => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(data),
});

const getFetchPatchParams = (data) => ({
  method: 'PATCH',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(data),
});

const fetchGetParams = { method: 'GET' };

function updateSrvDelta(srvTS) {
  if (srvTS) {
    const now = Date.now();
    srvDelta = srvTS - now;
  } else {
    console.log('Not data');
    window.close();
    window.location.replace('https://www.google.com');
  }
}

function getFetchUrl() {
  const { origin } = window.location;
  const regExp = new RegExp(/^https:\/\/(\w+)/);
  if (origin === 'http://localhost:3000' || origin === 'http://localhost:3001') return defaultApiUrl;
  const fetchUrl = origin.replace(regExp, 'https://api');
  return fetchUrl;
}

const apiUrl = getFetchUrl();

async function query(cmd, fetchParams, params) {
  const url = buildUrl(cmd, params, apiUrl);
  try {
    const response = await fetch(url, fetchParams);
    if (response.status === 403) {
      // window.location.reload(false);
      return null;
    }
    const data = await response.json();
    updateSrvDelta(data.srvTS);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const getAboutInfo = (data) => query('user/info', getFetchPostParams(data));
