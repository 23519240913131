import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useAlert } from 'react-alert';

import Spinner from 'react-bootstrap/Spinner';

const Wrapper = styled.div`
    position: relative;
    border: none;
    button {
      border: none;
    }
    width: auto;
    min-width: 46px;
    height: 47px;
    background: #2EA8E5;
    border-radius: 8px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span:first-of-type {
        font-size: 10px;
        line-height: 12px;
    }
    span:nth-of-type(2) {
        font-size: 18px;
        line-height: 21px;
    }
    ${({ visible }) => visible ? 'border-bottom-right-radius: 0px; border-bottom-left-radius: 0px;' : ''}
`;

const Buttons = styled.div`
    position: absolute;
    top: 42px;
    right: 0px;
    height: 43px;
    width: ${({ type }) => type === 'entries' ? '205px' : '288px'};
    background: #2EA8E5;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border-top-right-radius: 0px;
    padding: 7px;
    display: flex;
    justify-content: space-between;
    button {
      margin: 0 !important;
      width: auto;
      height: 31px; 
      background: #50B9ED;
      border-radius: 8px;
      color: #FFFFFF;
      font-size: 16px;
      line-height: 19px;
      padding: 0 10px;
      text-align: center;
      transition: all 0.1s ease;    
      &:hover {
        font-size: 18px;
        font-weight: 500;
      }
    }
`;

export default function BunusButton({
  type,
  onAddBonus,
}) {
  // visible отвечает за отображение кнопок с бонусами
  const [visible, setVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(0);

  const alert = useAlert();

  // При клике на главную кнопку меняется visible
  const onClickOnMainButton = () => {
    setVisible(!visible);
  };

  const hide = () => {
    setVisible(false);
  };

  const onClick = ({ target: { value, innerText } }) => {
    setSelectedValue(-1);
    onAddBonus(type, value).then(() => {
      alert.show(<span style={{ textTransform: 'none' }}>Бонус добавлен</span>, { type: 'success' });
      setSelectedValue(innerText);
    }).catch(() => {
      alert.show('Бонус не добавлен', { type: 'error' });
      setSelectedValue(0);
    });
  };

  return (
    <Wrapper
      className="bonusButton"
      disabled={selectedValue === -1}
      onMouseLeave={hide}
      visible={visible}
      onClick={onClickOnMainButton}
    >
      { selectedValue === -1
        ?  (
          <Spinner
            animation="border"
            variant="light"
          />
        ) : (
          <>
            <span>Бонус</span>
            <span>{selectedValue}</span>
          </>
        )}

      {
        visible && (
        <Buttons type={type}>
          {
            type === 'entries' ? (
              <>
                <button value={100} type="button" onClick={onClick}>+100</button>
                <button value={500} type="button" onClick={onClick}>+500</button>
                <button value={1000} type="button" onClick={onClick}>+1000</button>
              </>
            ) : (
              <>
                <button value={100} type="button" onClick={onClick}>100Mb</button>
                <button value={500} type="button" onClick={onClick}>500Mb</button>
                <button value={1000} type="button" onClick={onClick}>1Gb</button>
                <button value={10000} type="button" onClick={onClick}>10Gb</button>
              </>
            )
          }

        </Buttons>
        )
      }
    </Wrapper>
  );
}

BunusButton.propTypes = {
  type: PropTypes.oneOf(['entries', 'storage']).isRequired,
  onAddBonus: PropTypes.func.isRequired,
};
