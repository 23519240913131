import axios from 'axios';
import signatureLib from '../../utils/signature';
import BORequest from '../boModels/request';
import { apiConfig, defaultlogger } from '../../../shared/config';

const DOCUMENTS_LOCATION = 'documents';

function* uploadDocument(device, file, type, desc = '', ID = String.generateGUID()) {
  const apiUrl = `${apiConfig.schema}${apiConfig.host}:${apiConfig.port}/${apiConfig.ver}`;
  const url = `${apiUrl}/${DOCUMENTS_LOCATION}/upload?` +
    `ID=${ID}&type=${type}&desc=${encodeURIComponent(desc)}&fileName=${file.name}`
  ;
  let resp;
  try {
    resp = yield axios.post(url, file, {
      headers: {
        'Content-Type': file.type,
        d: device.ID,
        t: Date.now() / 1000,
        s: signatureLib.make(`${DOCUMENTS_LOCATION}/upload`, Date.now() / 1000, device.geo, device.ID, device.devToken),
      },
    });
  } catch (err) {
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* getDocumentsList(device, user = 'all', search = 'all', type = 'all') {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${DOCUMENTS_LOCATION}/list`, {
      user,
      search,
      type,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* getDocument(device, ID) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.get('files', {
      ID,
    });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* removeDocument(device, ID) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${DOCUMENTS_LOCATION}/remove`, { ID });
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function* updateDocument(device, doc) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  let resp;
  try {
    resp = yield request.post(`${DOCUMENTS_LOCATION}/update`, { ID: doc.ID }, doc);
  } catch (err) {
    request.logger.info(err);
    resp = {
      error: 'Произошла ошибка',
    };
  }
  return resp;
}

function getFileUrl(device, ID) {
  const request = new BORequest(apiConfig, device, defaultlogger);
  return request.fileUrl({ ID });
}

export { getDocumentsList, getDocument, uploadDocument, removeDocument, updateDocument, getFileUrl };
