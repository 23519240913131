import { Route, Redirect, Switch } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import Employee from '../containers/Employee';
import AnotherEmployee from '../containers/AnotherEmployee';
import { adminID } from '../../shared/config';
import Menu from './Menu';
import WorkState from '../containers/WorkState';
import Team from '../containers/Team';
import Productivity from '../containers/Productivity';
import Vacations from '../containers/Vacations';
import PersonalSprint from '../containers/PersonalSprint';
import SprintInfo from '../containers/SprintInfo';
import AdminVacations from '../containers/AdminVacations';
import NewEmployee from '../containers/NewEmployee';
import AdminPersonalVacation from '../containers/AdminPersonalVacations';
import SupportApp from '../../Support';

const Home = ({
  location,
  isLoaded,
  logout,
  workstate,
  user,
  uri,
  redirect,
  activeCount,
  getUserList,
  getUserWorkstate,
}) => {
  if (!uri.isRedirected && uri.path !== '/') {
    redirect();
  }
  return (
    <Route>
      <div style={{ opacity: isLoaded ? 1 : 0.05 }}>
        {user.ID !== adminID && (
          <Menu
            pathname={location.pathname}
            logout={logout}
            user={user}
            workstate={workstate}
            activeCount={activeCount}
            getUserList={getUserList}
            getUserWorkstate={getUserWorkstate}
          />
        )}
        <Switch>
        <Route component={Employee} exact path="/employee" />
        <Route component={WorkState} exact path="/workstate" />
        <Route component={Team} exact path="/team" />
        <Route component={AnotherEmployee} exact path="/team/:id" />
        <Route component={Vacations} exact path="/vacations" />
        <Route component={PersonalSprint} exact path="/personalSprint/:userID/:iterId" />
        <Route component={SprintInfo} exact path="/sprints" />
        <Route component={AdminVacations} exact path="/admin/vacations" />
        <Route component={AdminPersonalVacation} exact path="/admin/vacations/:id" />
        <Route component={NewEmployee} exact path="/admin/newEmployee" />
        <Route component={Productivity} exact path="/" />
        <Route component={SupportApp} path="/support" />
        <Redirect from="/" to="/" />
        {!uri.isRedirected && uri.path !== '/' && <Redirect to={uri.path} />}
        </Switch>
      </div>
    </Route>
  );
};

Home.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  logout: PropTypes.func.isRequired,
  getUserList: PropTypes.func.isRequired,
  getUserWorkstate: PropTypes.func.isRequired,
  workstate: PropTypes.shape().isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    workstate: PropTypes.object,
  }).isRequired,
  activeCount: PropTypes.number.isRequired,
};

export default Home;
