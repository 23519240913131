import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React from 'react';
import PropTypes from 'prop-types';
import {
  getHospitalList,
  updateHospital,
  getVacationList,
  getVacationStat,
  updateVacation,
  removeVacation,
  removeHospital,
  uploadHospital,
  switchHospital,
  getHospitalStat,
} from '../redux/actions/hospitalsActions';

import { getHospitalsById } from '../redux/reducers/hospitals';
import { getVacationsById, getStatByID } from '../redux/reducers/vacations';

import HospitalsComponent from '../components/vacations/Hospitals';
import VacationsComponent from '../components/vacations/Vacations';
import '../styles/vacations.css';

class Vacations extends React.Component {
  componentWillMount() {
    this.props.getVacationList();
    this.props.getHospitalList();
    this.props.getVacationStat();
    this.props.getHospitalStat();
  }
  render() {
    return (
      <div className="container vacations">
        <div className="row">
          <VacationsComponent
            getVacationList={this.props.getVacationList}
            getVacationStat={this.props.getVacationStat}
            vacationDays={this.props.vacationDays}
            updateVacation={this.props.updateVacation}
            removeVacation={this.props.removeVacation}
            vacations={this.props.vacations}
            device={this.props.device}
            editable
            isPending={this.props.isPending.addVacation}
          />
          <HospitalsComponent
            getHospitalList={this.props.getHospitalList}
            updateHospital={this.props.updateHospital}
            removeHospital={this.props.removeHospital}
            uploadHospital={this.props.uploadHospital}
            switchHospital={this.props.switchHospital}
            hospitals={this.props.hospitals}
            hospitalDocID={this.props.hospitalDocID}
            editable
            isPending={this.props.isPending.callInSick}
          />
        </div>
      </div>
    );
  }
}

Vacations.defaultProps = {
  vacationDays: 0,
};

Vacations.propTypes = {
  vacations: PropTypes.shape().isRequired,
  hospitals: PropTypes.shape().isRequired,
  device: PropTypes.shape().isRequired,
  getHospitalList: PropTypes.func.isRequired,
  updateHospital: PropTypes.func.isRequired,
  getVacationList: PropTypes.func.isRequired,
  getVacationStat: PropTypes.func.isRequired,
  getHospitalStat: PropTypes.func.isRequired,
  updateVacation: PropTypes.func.isRequired,
  removeVacation: PropTypes.func.isRequired,
  removeHospital: PropTypes.func.isRequired,
  uploadHospital: PropTypes.func.isRequired,
  switchHospital: PropTypes.func.isRequired,
  hospitalDocID: PropTypes.string.isRequired,
  vacationDays: PropTypes.number,
  isPending: PropTypes.objectOf(PropTypes.any).isRequired,
};

function mapStateToProps(state) {
  return {
    device: state.device,
    vacationStat: getVacationsById(state.vacations, state.user.ID),
    vacations: getVacationsById(state.vacations, state.user.ID),
    hospitals: getHospitalsById(state.hospitals.byUserId, state.user.ID),
    vacationDays: getStatByID(state.vacations, state.user.ID).vacationDays,
    hospitalDocID: state.hospitals.uploading.docID,
    isPending: state.app.isPending,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getHospitalList: bindActionCreators(getHospitalList, dispatch),
    updateHospital: bindActionCreators(updateHospital, dispatch),
    getVacationList: bindActionCreators(getVacationList, dispatch),
    getVacationStat: bindActionCreators(getVacationStat, dispatch),
    getHospitalStat: bindActionCreators(getHospitalStat, dispatch),
    updateVacation: bindActionCreators(updateVacation, dispatch),
    removeVacation: bindActionCreators(removeVacation, dispatch),
    removeHospital: bindActionCreators(removeHospital, dispatch),
    uploadHospital: bindActionCreators(uploadHospital, dispatch),
    switchHospital: bindActionCreators(switchHospital, dispatch),
  };
}

const VacationsContainer = connect(mapStateToProps, mapDispatchToProps)(Vacations);

export default VacationsContainer;
