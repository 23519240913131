import React        from 'react';
import PropTypes    from 'prop-types';
import AddVacation  from './AddVacation';
import VacationItem from './VacationItem';

class Vacation extends React.PureComponent {
  render() {
    const { removeVacation, editable, vacationDays, device, isPending } = this.props;

    const byYearList = Object.keys(this.props.vacations.byYear).map((year) => {
      const byYearVacations = this.props.vacations.byYear[year];
      let daysCount = 0;
      const vacationList = byYearVacations.map((vacation) => {
        daysCount += vacation.days;
        return (
          <VacationItem
            key={`vacation#${vacation.id}`}
            startDate={vacation.startD}
            endDate={vacation.exitD || ''}
            days={vacation.days}
            comment={vacation.comment}
            available={vacation.available}
            inactive
          />
        );
      });
      return (
        <div key={`vacations#${year}`} className="section">
          <div className="section-header">
            <div className="badge badge-default">{daysCount}</div>
            {year} год
          </div>
          {vacationList}
        </div>
      );
    });

    const newVacations = this.props.vacations.newVacations.map(vacation => (
      <VacationItem
        key={`vacation#${vacation.id}`}
        startDate={vacation.startD}
        endDate={vacation.exitD || ''}
        days={vacation.days}
        comment={vacation.comment}
        available={vacation.available}
        inactive
      >
        {
          editable &&
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-danger"
              onClick={() => { removeVacation(vacation.id, vacation.empID); }}
            >
              Отозвать
            </button>
          </div>
        }
      </VacationItem>
    ));

    const accepted = this.props.vacations.accepted.map(vacation => (
      <VacationItem
        key={`vacationAcc#${vacation.id}`}
        startDate={vacation.startD}
        endDate={vacation.exitD || ''}
        days={vacation.days}
        comment={vacation.comment}
        available={vacation.available}
      >
        {
          editable &&
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-danger"
              onClick={() => { removeVacation(vacation.id, vacation.empID); }}
            >
              Отозвать
            </button>
          </div>
        }
      </VacationItem>
    ));

    return (
      <div className="col-lg-6 border-right">
        <AddVacation
          updateVacation={this.props.updateVacation}
          vacationDays={vacationDays}
          device={device}
          userID={this.props.userID}
          isPending={isPending}
        />
        {
          newVacations.length > 0 &&
          <div className="section">
            <div className="section-header">
              Заявления на рассмотрении
            </div>
            { newVacations }
          </div>
        }
        {
          accepted.length > 0 &&
          <div className="section">
            <div className="section-header">
              Одобренные заявления
            </div>
            { accepted }
          </div>
        }
        {byYearList}
      </div>
    );
  }
}

Vacation.defaultProps = {
  vacationDays: 0,
  userID: 'self',
};

Vacation.propTypes = {
  updateVacation: PropTypes.func.isRequired,
  removeVacation: PropTypes.func.isRequired,
  vacations: PropTypes.shape().isRequired,
  userID: PropTypes.string,
  device: PropTypes.shape().isRequired,
  editable: PropTypes.bool.isRequired,
  vacationDays: PropTypes.number,
  isPending: PropTypes.bool.isRequired,
};

export default Vacation;
