import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { getNameUserType } from '../../../../utils/helpers';
import bossnoteIcon from '../../../../images/bossnoteLogo.svg';
import simplanumIcon from '../../../../images/simplanumLogo.svg';

export default function ServiceField(
  {
    userType,
    versionService,
  },
) {
  return (
    <Wrapper userType={userType}>
      {userType} {versionService}
    </Wrapper>
  );
}

const Wrapper = styled.span`
    font-size: 12px;
    line-height: 14px;
    color: #4A4A4A;
    background-image: url(${({ userType }) => getNameUserType(userType) === 'bossnote' ? bossnoteIcon : simplanumIcon});
    background-repeat: no-repeat;
    padding-left: 21px;
`;

ServiceField.propTypes = {
  userType: PropTypes.string,
  versionService: PropTypes.string,
};

ServiceField.defaultProps = {
  userType: '',
  versionService: '',
};
