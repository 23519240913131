import { getAvatar } from '../api';

import bossnoteIcon from '../images/bossnoteLogo.svg';
import simplanumIcon from '../images/simplanumLogo.svg';

export const backOfficeURL = 'http://localhost:3000';

export const noop = () => {};

// объект который будет хранить автварки пользователей по ID в формате base64
const cache = {};

// Метод для получение аватарки пользователя в формате base64
export const getAvatarBase64ByPhoneAndID = (ID, phone) => {
  const prefix = 'data:application/octet-stream;base64,';
  const promise = new Promise((resolve, reject) => {
    try {
      // Проверяем, есть ли ключ с ID в cache, если есть вовзращаем в промис base64
      if (typeof cache[ID] !== 'undefined') {
        resolve(cache[ID]);
        return;
      }
      // делаем запрос на сервер для получения бинарных данных и конвертируем в base64
      getAvatar(ID, phone).then((resp) => {
        const base64 = prefix + Buffer.from(resp.data, 'binary').toString('base64');
        cache[ID] = base64;
        resolve(base64);
      });
    } catch (error) {
      reject();
    }
  });
  return promise;
};

// Метод который возвращает иконку simplanum или bossnote в зависимости от userType
export const getNameUserType = (userType) => {
  let firstLetter;
  if (userType) {
    // Регулярное для поиска первой буквы каждого слова в строке
    const regex = /\b(\w)/g;
    // userType.match(regex) возвращает массив из первых букв каждого слова в строке
    firstLetter = [...userType.match(regex)[0]];
  }
  switch (firstLetter) {
    case ('B' || 'b'): {
      return 'bossnote';
    }
    case ('S' || 's'):
    default: {
      return 'simplanum';
    }
  }
};

// Метод, который вовзрващает значение для свойства background для стилей
export const getBackgroundForAvatar = (base64) => base64 ? `url(${base64})` : '#ADADAD';

// Метод, который возвращает количество дней из дат
export const getdaysCountFromDates = (from, to) => {
  const fromMillisecond = new Date(from).getTime();
  const toMillisecond = new Date(to).getTime();
  const days = Math.floor((toMillisecond - fromMillisecond) / 86400000);
  return days;
};

// метод который склоняет числовые значения
export function declOfNum(n, textForms) {
  // eslint-disable-next-line no-param-reassign
  n = Math.abs(n) % 100; const n1 = n % 10;
  if (n > 10 && n < 20) { return textForms[2]; }
  if (n1 > 1 && n1 < 5) { return textForms[1]; }
  if (n1 === 1) { return textForms[0]; }
  return textForms[2];
}
